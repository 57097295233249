import { Vocabulary } from "../../Utils/Vocabulary";
import moment from "moment";
import EditDeleteButtons from "../GenericComponents/EditDeleteButtons";
import { OrderContext } from "../../Contexts/OrderContext";
import { useContext } from "react";
import { useMediaQuery } from "@mui/material";
import { euFormat } from "../../Utils/Config";

type OrderDeviceProps = {
  onEdit: () => void;
};

export default function OrderDevice(props: OrderDeviceProps) {
  const { onEdit } = props;
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const orderContext = useContext(OrderContext);
  return (
    <div style={{ position: "relative" }}>
      {orderContext.order.device ? (
        <div>
          <p>
            <span style={{ fontWeight: "bold" }}>{Vocabulary.deviceName}:</span>
            &nbsp;&nbsp;
            {phoneStyle ? <br /> : null}
            {orderContext.order.device.name}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>
              {Vocabulary.deviceSerialNumber}:
            </span>
            &nbsp;&nbsp;
            {orderContext.order.device.serialNumber}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>
              {Vocabulary.deviceBrand}:
            </span>
            &nbsp;&nbsp;
            {orderContext.order.device.brand}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>
              {Vocabulary.deviceModel}:
            </span>
            &nbsp;&nbsp;
            {orderContext.order.device.model}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>
              {Vocabulary.informationSource}:
            </span>
            &nbsp;&nbsp;
            {moment(orderContext.order.device.createdAt).format(euFormat)}
          </p>
          <EditDeleteButtons
            onEdit={onEdit}
            onDelete={() =>
              orderContext.setOrder({ ...orderContext.order, device: null })
            }
            style={{ position: "absolute", top: 0, right: 0 }}
          />
        </div>
      ) : null}
    </div>
  );
}
