/* eslint-disable @typescript-eslint/no-explicit-any */
import { Vocabulary } from "../../Utils/Vocabulary";
import styles from "../../Styles/dashboard.module.css";
import MainTable from "../../Components/GenericComponents/MainTable";
import { urlEnum } from "../../Utils/UrlEnum";
import { useState } from "react";
import EmailTemplate from "../../Components/Templates/EmailTemplate";
import { EmailTemplateModel } from "../../Utils/Models";
import { updateData } from "../../Services/updateData";
import { postData } from "../../Services/postData";
import { styleForTableWithTabs } from "../Users";

export default function EmailTemplates() {
  const [open, setOpen] = useState(false);
  const [editItem, setEditItem] = useState<EmailTemplateModel | null>(null);
  const [shouldUpdate, setShouldUpdate] = useState(false);

  /**
   *
   */
  const emailHeader = [
    {
      label: Vocabulary.name,
      name: "name",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.status,
      name: "status",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return <>{value ? value.name : ""}</>;
        },
      },
    },
  ];
  /**
   *
   * @param item
   */
  function handleEdit(item: EmailTemplateModel | null) {
    setShouldUpdate(false);
    setOpen(!open);
    setEditItem(item);
  }
  /**
   *
   * @param emailTemplate
   */
  function addOrUpdateEmailTemplate(emailTemplate: EmailTemplateModel | null) {
    setShouldUpdate(true);
    if (emailTemplate?._id) {
      updateData(
        `${urlEnum.emailTemplates}/${emailTemplate._id}`,
        emailTemplate
      )
        .then((res) => {
          if (res) {
            setOpen(false);
          }
        })
        .catch((err) => {
          setOpen(false);
        });
    } else {
      postData(urlEnum.emailTemplates, emailTemplate)
        .then((res: any) => {
          if (res) {
            setOpen(false);
          }
        })
        .catch((err) => {
          setOpen(false);
        });
    }
  }
  return (
    <div className={styles.mainContainerUser}>
      <MainTable
        urlEnumApi={urlEnum.emailTemplates}
        titleDelete={Vocabulary.deleteEmailTemplate}
        textDelete={Vocabulary.deleteConfirmationEmailTemplate}
        header={emailHeader}
        tableTitle={Vocabulary.emailTemplatesList}
        shouldUpdate={shouldUpdate}
        handleEdit={handleEdit}
        style={styleForTableWithTabs}
      />
      {open ? (
        <EmailTemplate
          open={open}
          editEmailTemplate={editItem}
          onClose={() => setOpen(false)}
          addOrUpdateEmailTemplate={addOrUpdateEmailTemplate}
        />
      ) : null}
    </div>
  );
}
