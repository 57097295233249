/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import AddAndShowImages from "../GenericComponents/AddAndShowImages";
import GenericModal from "../GenericComponents/GenericModal";
import { ProductModel } from "../../Utils/Models";
import { Vocabulary } from "../../Utils/Vocabulary";
import { Button } from "@mui/material";
import { VariantProps } from "./ProductVariants";

type AddImagesToVariantsProps = {
  openAddImagesModal: boolean;
  product: ProductModel;
  variant: VariantProps;
  handleChangeAddImagesModal: () => void;
  handleSaveImages: (images: any, files: any, checkedImages: any) => void;
};
export default function AddImagesToVariants(props: AddImagesToVariantsProps) {
  const {
    openAddImagesModal,
    product,
    variant,
    handleChangeAddImagesModal,
    handleSaveImages,
  } = props;
  const [images, setImages] = useState<any>([]);
  const [checkedImages, setCheckedImages] = useState<any>([]);
  const [files, setFiles] = useState<any>([]);

  /**
   *
   */
  useEffect(() => {
    if (product.images) setImages(product.images);
    else setImages([]);
    if (
      variant.images &&
      Array.isArray(variant.images) &&
      variant.images.length !== 0
    )
      setCheckedImages(
        variant.images.map((variantImage) => {
          return product.images.findIndex((productImage) =>
            compareImages(variantImage, productImage)
          );
        })
      );
    else setCheckedImages([]);
  }, [product.images, variant.images]);

  /**
   *
   * @param img1
   * @param img2
   * @returns
   */
  function compareImages(img1: any, img2: any) {
    if (typeof img1 === "string" && typeof img2 === "string") {
      return img1 === img2;
    } else if (typeof img1 === "object" && typeof img2 === "object") {
      return img1.path === img2.path;
    }
    return false;
  }

  /**
   *
   * @param newImageList
   * @param newFileList
   */
  function handleDeleteImages(
    newImageList: any,
    newFileList: any,
    index: number
  ) {
    setImages(newImageList);
    setFiles(newFileList);
    const updatedIndexes = checkedImages.map((idx: number) => {
      if (idx >= index) {
        return idx - 1;
      }
      return idx;
    });
    if (checkedImages.includes(index))
      setCheckedImages(
        updatedIndexes.filter((checkedImage: any) => checkedImage !== index - 1)
      );
    else
      setCheckedImages(
        updatedIndexes.filter((checkedImage: any) => checkedImage !== index)
      );
  }

  /**
   *
   * @param newFileList
   */
  function handleChangeFiles(newFileList: any) {
    setFiles(newFileList);
  }

  /**
   *
   * @param newImageList
   */
  function displayDropzoneImages(newImageList: any) {
    setImages(newImageList);
  }

  /**
   *
   * @param index
   */
  function handleCheckImages(index: number) {
    const newCheckedImages = checkedImages.slice();
    if (checkedImages.includes(index)) {
      newCheckedImages.splice(newCheckedImages.indexOf(index), 1);
    } else {
      newCheckedImages.push(index);
    }
    setCheckedImages(newCheckedImages);
  }

  return (
    <GenericModal
      open={openAddImagesModal}
      onClose={handleChangeAddImagesModal}
      title={Vocabulary.addImages}
      actions={
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            padding: 20,
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            style={{ color: "white" }}
            onClick={handleChangeAddImagesModal}
          >
            {Vocabulary.no}
          </Button>
          <Button
            variant="contained"
            onClick={() => handleSaveImages(images, files, checkedImages)}
          >
            {Vocabulary.yes}
          </Button>
        </div>
      }
      style={{ zIndex: 1500, textAlign: "center", width: "100%" }}
    >
      <AddAndShowImages
        images={images}
        files={files}
        checkedImages={checkedImages}
        displayDropzoneImages={displayDropzoneImages}
        handleDeleteImages={handleDeleteImages}
        handleChangeFiles={handleChangeFiles}
        handleCheckImages={handleCheckImages}
      />
    </GenericModal>
  );
}
