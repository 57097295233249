/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, Divider, Grid, TextField } from "@mui/material";
import { ProductModel, ProductStockModel } from "../../Utils/Models";
import useCustomMemo from "../GenericComponents/useCustomMemo";
import { Vocabulary } from "../../Utils/Vocabulary";
import ProductStockList from "./ProductStockList";
import { useEffect, useState } from "react";

type ProductStockProps = {
  product: ProductModel;
  handleChangeProduct: (product: ProductModel) => void;
};

export default function ProductStock(props: ProductStockProps) {
  const cache = useCustomMemo();
  const inventories = cache.inventories || [];
  const taxRates = cache.taxRates || [];
  const { product, handleChangeProduct } = props;
  //to reset the autocomplete after adding a new inventory
  const [workaround, setWorkaround] = useState(null);

  useEffect(() => {
    if (workaround) setWorkaround(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product.inventories]);
  /**
   *
   * @param index
   * @param inventory
   */
  function handleChangeOneInventory(index: number, inventory: any) {
    const inventories = [...product.inventories];
    if (inventory) {
      inventories[index] = inventory;
    } else {
      inventories.splice(index, 1);
    }
    handleChangeProduct({
      ...product,
      inventories: inventories,
    });
  }
  return (
    <div style={{ marginTop: 20 }}>
      <Autocomplete
        freeSolo={false}
        disablePortal
        options={inventories}
        handleHomeEndKeys
        value={workaround}
        getOptionDisabled={(option) =>
          product.inventories &&
          !!product.inventories.find(
            (element: any) => element.inventory._id === option._id
          )
        }
        getOptionLabel={(option: any) =>
          `${option.name} - ${option.location?.name || ""}`
        }
        isOptionEqualToValue={(option, value) => option._id === value._id}
        renderInput={(params) => (
          <TextField {...params} label={Vocabulary.inventory} size="small" />
        )}
        onChange={(event, newValue: any) => {
          const inventories = product.inventories
            ? [...product.inventories]
            : [];
          const taxRate = taxRates && taxRates.find((x: any) => x.defaultValue);
          inventories.push({
            inventory: newValue,
            quantity: 0,
            price: 0,
            percentageVAT: taxRate ? taxRate.value : 0,
            isNew: true,
          });
          handleChangeProduct({
            ...product,
            inventories: inventories,
          });
          setWorkaround(newValue);
        }}
      />

      <Grid
        container
        spacing={2}
        style={{
          margin: "20px 0px",
          padding: 0,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={3} md={3} lg={3}>
          {Vocabulary.inventory}
        </Grid>
        <Grid item xs={12} sm={2.7} md={2.7} lg={2.7}>
          {Vocabulary.quantity}
        </Grid>
        <Grid item xs={12} sm={2.7} md={2.7} lg={2.7}>
          {Vocabulary.purchasePrice}
        </Grid>
        <Grid item xs={12} sm={2.7} md={2.7} lg={2.7}>
          {Vocabulary.percentageVAT}
        </Grid>
      </Grid>
      <Divider />
      {product.inventories &&
        product.inventories.map((inventory: ProductStockModel, index) => (
          <ProductStockList
            key={inventory?.inventory?._id}
            index={index}
            inventory={inventory}
            handleChangeOneInventory={handleChangeOneInventory}
          />
        ))}
    </div>
  );
}
