/* eslint-disable @typescript-eslint/no-explicit-any */
import { Tab, Tabs, useMediaQuery } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../../Styles/dashboard.module.css";
import { localUrlEnum } from "../../Utils/UrlEnum";
import { templatesTypes } from "../../Utils/AutocompleteUtils";
import theme from "../../Themes/Theme";
import EmailTemplates from "../../Pages/Templates/EmailTemplates";
import DocumentsTemplates from "../../Pages/Templates/DocumentsTemplates";

export default function TemplatesMainPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const [value, setValue] = useState(0);

  useEffect(() => {
    const search: any = location.search.split("?");
    search.forEach((value: any) => {
      if (value !== "") {
        const params = value.split("&");
        const values = params[0].split("=");
        setValueFromLocation(values[1]);
      }
    });
  }, [location]);

  /**
   *
   * @param newValue
   */
  function setValueFromLocation(newValue: string) {
    switch (newValue) {
      case templatesTypes.emailTemplates:
        setValue(0);
        break;
      case templatesTypes.documentsTemplates:
        setValue(1);
        break;
      default:
        setValue(0);
        break;
    }
  }
  /**
   *
   * @param event
   * @param newValue
   */
  function handleChangeValue(event: React.SyntheticEvent, newValue: number) {
    switch (newValue) {
      case 0:
        navigate(
          `${localUrlEnum.emailTemplates}?type=${templatesTypes.emailTemplates}`
        );
        break;
      case 1:
        navigate(
          `${localUrlEnum.emailTemplates}?type=${templatesTypes.documentsTemplates}`
        );
        break;
      default:
        navigate(
          `${localUrlEnum.emailTemplates}?type=${templatesTypes.emailTemplates}`
        );
        break;
    }
  }

  /**
   *
   */
  function showInformationForTabs() {
    switch (value) {
      case 0:
        return <EmailTemplates />;
      case 1:
        return <DocumentsTemplates />;
      default:
        return <EmailTemplates />;
    }
  }

  /**
   *
   * @param tabValue
   * @returns
   */
  function getTabStyle(tabValue: number) {
    return {
      backgroundColor:
        value === tabValue
          ? theme.palette.secondary.main
          : theme.palette.cancel?.main,
      color: value === tabValue ? "white" : "#9c9b9b",
      borderTopRightRadius: "10px",
      borderTopLeftRadius: "10px",
      marginRight: "1px",
    };
  }

  return (
    <div
      className={phoneStyle ? styles.mainContainerMobile : styles.mainContainer}
    >
      <Tabs
        value={value}
        onChange={handleChangeValue}
        TabIndicatorProps={{
          style: {
            backgroundColor: theme.palette.secondary.main,
          },
        }}
      >
        <Tab label={Vocabulary.emailTemplates} style={getTabStyle(0)} />
        <Tab label={Vocabulary.documentsTemplates} style={getTabStyle(1)} />
      </Tabs>
      <> {showInformationForTabs()}</>
    </div>
  );
}
