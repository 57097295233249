/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import MUIDataTable from "mui-datatables";
import styles from "../../Styles/dashboard.module.css";
import { Vocabulary } from "../../Utils/Vocabulary";
import Config from "../../Utils/Config";
import clsx from "clsx";
import { LocalFilter } from "./ReportsPerUser";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";

type TechnicianReportsProps = {
  tableHeader: any;
  items: any;
  filters: LocalFilter;
  handleChangeLocalFilters: (newFilters: LocalFilter) => void;
  count: number;
  withStyles: boolean;
};
export default function TechnicianReports(props: TechnicianReportsProps) {
  const {
    tableHeader,
    items,
    count,
    withStyles,
    handleChangeLocalFilters,
    filters,
  } = props;
  const theme = createTheme({
    components: {
      MuiPaper: {
        styleOverrides: {
          root: withStyles
            ? {
                borderRadius: "0px",
                boxShadow:
                  " 0px -1px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
              }
            : {},
        },
      },
    },
  });
  /**
   *
   * @returns
   */
  const getTableOptions = () => {
    const responsive: "standard" | "vertical" | "simple" | undefined =
      "standard";
    return {
      selectableRows: "none" as any,
      viewColumns: false as any,
      responsive: responsive,
      rowsPerPageOptions: Config.rowsPerPage,
      confirmFilters: false,
      filter: false,
      search: false,
      download: false,
      print: false,
      expandableRows: false,
      expandableRowsHeader: false,
      textLabels: {
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
        pagination: {
          next: Vocabulary.nextPage,
          previous: Vocabulary.previousPage,
          rowsPerPage: Vocabulary.rowsPerPage,
          displayRows: Vocabulary.of,
        },
        toolbar: {
          search: Vocabulary.search,
          downloadCsv: Vocabulary.downloadCSV,
          print: Vocabulary.print,
        },
        selectedRows: {
          text: Vocabulary.rowsSelected,
          delete: Vocabulary.delete,
        },
      },
      count: count,
      rowsPerPage: filters.perPage,
      page: filters.page,
      serverSide: true,
      onChangePage: (page: any) => {
        handleChangeLocalFilters({ ...filters, page: page });
        // window.scrollTo(0, 0);
      },
      onChangeRowsPerPage: (numberOfRows: any) => {
        handleChangeLocalFilters({
          ...filters,
          perPage: numberOfRows,
          page: 0,
        });
      },
      setRowProps: (row: any, dataIndex: any, rowIndex: any) => {
        if (items[rowIndex].trackedTime && items[rowIndex].predefinedTime) {
          if (items[rowIndex].trackedTime > items[rowIndex].predefinedTime)
            return {
              style: {
                backgroundColor: "#fbb4ae",
              },
            };
        } else if (
          items[rowIndex].trackedTime === 0 ||
          items[rowIndex].predefinedTime === 0
        ) {
          if (items[rowIndex].trackedTime > items[rowIndex].predefinedTime)
            return {
              style: {
                backgroundColor: "#fbb4ae",
              },
            };
        }
        return {
          style: {},
        };
      },
    };
  };

  return (
    <ThemeProvider theme={theme}>
      <MUIDataTable
        title={""}
        data={items}
        columns={tableHeader}
        options={getTableOptions()}
      />
    </ThemeProvider>
  );
}
