/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { ProductModel, TaxRateModel } from "../../Utils/Models";
import { Vocabulary } from "../../Utils/Vocabulary";
import DividerWithLabel from "../GenericComponents/DividerWithLabel";
import { TextValidator } from "react-material-ui-form-validator";
import { onChangeTextField } from "../../Utils/Utils";
import useCustomMemo from "../GenericComponents/useCustomMemo";

type PriceProps = {
  product: ProductModel;
  handleChangeProduct: (product: ProductModel) => void;
};

export default function Price(props: PriceProps) {
  const { product, handleChangeProduct } = props;
  const cache = useCustomMemo();
  const taxRates = cache.taxRates || [];

  return (
    <Grid container spacing={2} style={{ marginTop: 20 }}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <DividerWithLabel label={Vocabulary.selling} />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="stockPrice"
          name="stockPrice"
          label={Vocabulary.stockPrice}
          fullWidth
          variant="outlined"
          value={product.stockPrice}
          onChange={(event: any) =>
            handleChangeProduct(onChangeTextField(event, product))
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <FormControl fullWidth>
          <InputLabel>{Vocabulary.vat}</InputLabel>
          <Select
            size="small"
            id="vat"
            value={product.vat || ""}
            label={Vocabulary.vat}
            fullWidth
            onChange={(event: SelectChangeEvent) => {
              handleChangeProduct({
                ...product,
                vat: event.target.value,
              });
            }}
          >
            {taxRates.map((taxRate: TaxRateModel) => {
              return <MenuItem value={taxRate.value}>{taxRate.value}</MenuItem>;
            })}
            {!taxRates.some(
              (taxRate: TaxRateModel) => taxRate.value === product.vat
            ) &&
              product.vat && (
                <MenuItem disabled value={product.vat}>
                  {product.vat}
                </MenuItem>
              )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="exchangePrice"
          name="exchangePrice"
          label={Vocabulary.exchangePrice}
          fullWidth
          variant="outlined"
          value={product.exchangePrice}
          onChange={(event: any) =>
            handleChangeProduct(onChangeTextField(event, product))
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <DividerWithLabel label={Vocabulary.purchase} />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="purchaseStockPrice"
          name="purchaseStockPrice"
          label={Vocabulary.purchaseStockPrice}
          fullWidth
          variant="outlined"
          value={product.purchaseStockPrice}
          onChange={(event: any) =>
            handleChangeProduct(onChangeTextField(event, product))
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="purchaseExchangePrice"
          name="purchaseExchangePrice"
          label={Vocabulary.purchaseExchangePrice}
          fullWidth
          variant="outlined"
          value={product.purchaseExchangePrice}
          onChange={(event: any) =>
            handleChangeProduct(onChangeTextField(event, product))
          }
        />
      </Grid>
    </Grid>
  );
}
