/* eslint-disable @typescript-eslint/no-explicit-any */
import { ThemeProvider, createTheme } from "@mui/material";
import MUIDataTable from "mui-datatables";

type Props = {
  title: string;
  data: [];
  columns: any;
  options: any;
  style?: any;
};

export default function MUIDataTableCustomStyle(props: Props) {
  const { title, data, columns, options, style } = props;
  const theme = createTheme({
    components: {
      MuiInputBase: {
        styleOverrides: {
          root:
            style && style.MuiInputBaseRoot
              ? style.MuiInputBaseRoot
              : {
                  "&:after": {
                    borderBottom: "2px solid #189DD9 !important",
                  },
                },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root:
            style && style.MuiIconButtonRoot
              ? style.MuiIconButtonRoot
              : {
                  "&:hover": {
                    color: "#189DD9",
                  },
                },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root:
            style && style.MuiToolbarRoot
              ? style.MuiToolbarRoot
              : {
                  backgroundColor: "#404a694d",
                  borderTopRightRadius: "5px",
                  borderTopLeftRadius: "5px",
                  display: "flex",
                },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            backgroundColor: "rgb(64, 74, 105, 0.1)",
          },
          root: {
            padding: "7px !important",
            paddingLeft: "25px !important",
          },
        },
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: { textTransform: "uppercase" },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          toolbar: {
            backgroundColor: "#FFF",
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <MUIDataTable
        title={title}
        data={data}
        columns={columns}
        options={options}
      />
    </ThemeProvider>
  );
}
