/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppBar, Box, Button, useMediaQuery } from "@mui/material";
import styles from "../../Styles/dashboard.module.css";
import theme from "../../Themes/Theme";
import { useNavigate } from "react-router-dom";
import { settingsMenu } from "../../Utils/Constants";

export default function SettingsMenu() {
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const navigate = useNavigate();

  return (
    <AppBar
      component="nav"
      position="fixed"
      className={
        phoneStyle ? styles.settingsHeaderMobile : styles.settingsHeader
      }
      style={{ backgroundColor: theme.palette.secondary?.main }}
    >
      <Box>
        {settingsMenu.map((menu: any) => (
          <Button sx={{ color: "#fff" }} onClick={() => navigate(menu.path)}>
            {menu.name}
          </Button>
        ))}
      </Box>
    </AppBar>
  );
}
