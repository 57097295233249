/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
import { InputAdornment, InputLabel, TextField } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { onChangeTextField } from "../../Utils/Utils";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import OptionValue from "../Products/OptionValue";
import { AttributeModel } from "../../Utils/Models";

type GenericAttributeProps = {
  attribute: AttributeModel;
  handleChangeAttribute: (attribute: AttributeModel) => void;
};

export default function GenericAttribute(props: GenericAttributeProps) {
  const { attribute, handleChangeAttribute } = props;

  /**
   *
   * @param event
   * @param valueIndex
   */
  function handleChangeCurrentOptionValue(value: string, valueIndex: number) {
    const newCurrentAttribute: any = {
      ...attribute,
      values: [...attribute.values],
    };
    newCurrentAttribute.values[valueIndex] = value;
    // Find all indices of empty string values
    const emptyIndices = newCurrentAttribute.values.reduce(
      (acc: Array<string>, val: string, idx: string) => {
        if (val === "") acc.push(idx);
        return acc;
      },
      []
    );
    // Remove the last empty string if there are more than one
    if (emptyIndices.length > 1) {
      newCurrentAttribute.values.splice(
        emptyIndices[emptyIndices.length - 1],
        1
      );
    }
    // Push a new empty string if there is no empty string left
    if (newCurrentAttribute.values.findIndex((val: any) => val === "") === -1) {
      newCurrentAttribute.values.push("");
    }
    handleChangeAttribute(newCurrentAttribute);
  }

  /**
   *
   * @param valueIndex
   */
  function deleteCurrentOptionValue(valueIndex: number) {
    const newCurrentAttribute = Object.assign({}, attribute);
    newCurrentAttribute.values.splice(valueIndex, 1);
    handleChangeAttribute(newCurrentAttribute);
  }

  /**
   *
   * @param value
   * @param valueIndex
   * @returns
   */
  function validateCurrentOptionValue(value: string, valueIndex: number) {
    return (
      value === "" &&
      (attribute.values.length === 1
        ? true
        : attribute.values.length !== valueIndex + 1
        ? true
        : false)
    );
  }

  return (
    <>
      <InputLabel style={{ paddingLeft: 2 }}>
        {Vocabulary.optionName}
      </InputLabel>
      <TextField
        id="name"
        name="name"
        size="small"
        variant="outlined"
        value={attribute.name}
        onChange={(event: any) => {
          handleChangeAttribute(onChangeTextField(event, attribute));
        }}
        fullWidth
        style={{ marginBottom: 10 }}
        error={attribute.name === ""}
        helperText={attribute.name === "" ? Vocabulary.requiredField : null}
      />
      <InputLabel style={{ paddingLeft: 2 }}>
        {Vocabulary.optionValues}
      </InputLabel>
      {attribute.values.map((value: string, valueIndex: number) => (
        <OptionValue
          key={`values[${valueIndex}]`}
          value={value}
          valueIndex={valueIndex}
          icon={
            <>
              {attribute.values.length > 1 && value !== "" ? (
                <InputAdornment
                  position="end"
                  style={{ cursor: "pointer" }}
                  onClick={() => deleteCurrentOptionValue(valueIndex)}
                >
                  <DeleteForeverIcon />
                </InputAdornment>
              ) : null}
            </>
          }
          handleChangeCurrentOptionValue={handleChangeCurrentOptionValue}
          validateCurrentOptionValue={validateCurrentOptionValue}
        />
      ))}
    </>
  );
}
