/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useState } from "react";
import { OrderModel } from "../Utils/Models";

export const OrderContext = createContext<{
  order: OrderModel;
  setOrder: (order: OrderModel) => void;
}>({
  order: new OrderModel(),
  setOrder: () => {},
});

export const OrderContextProvider = (props: any) => {
  const setOrder = (order: OrderModel) => {
    setState({ ...state, order: order });
  };
  const initState = {
    order: new OrderModel(),
    setOrder: setOrder,
  };
  const [state, setState] = useState(initState);
  return (
    <OrderContext.Provider value={state}>
      {props.children}
    </OrderContext.Provider>
  );
};
