/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { FiltersProps } from "./Reports";
import ReportsAndOrdersFilters from "../../Components/Reports/ReportsAndOrdersFilters";
import moment from "moment";
import styles from "../../Styles/dashboard.module.css";
import { useLocation } from "react-router-dom";
import { Grid, useMediaQuery } from "@mui/material";
import useCustomMemo from "../../Components/GenericComponents/useCustomMemo";
import WorkflowReport from "../../Components/Reports/WorkflowReport";
import { getData } from "../../Services/getData";
import { urlEnum } from "../../Utils/UrlEnum";
import { dayPilotFormat } from "../../Utils/Config";

export default function WorkflowsReports() {
  const location = useLocation();
  const phoneStyle = useMediaQuery("(max-width:800px)");
  const cache = useCustomMemo();
  const workflows = cache.workflows || [];
  const [indexedWorkflows, setIndexedWorkflows] = useState<any>({
    evenIndexedWorkflows: [],
    oddIndexedWorkflows: [],
  });
  const [filters, setFilters] = useState<FiltersProps>({
    startDate: moment().subtract(1, "months"),
    endDate: moment(),
    user: null,
    orderId: null,
    workflowId: [],
  });

  /**
   *
   */
  useEffect(() => {
    const search = location.search.split(/[?&]+/);
    const newFilters: any = Object.assign({}, filters);
    let workflowId: any = [];
    search.forEach((value: any) => {
      if (value !== "") {
        const values = value.split("=");
        newFilters[values[0]] = isNaN(+values[1]) ? values[1] : +values[1];
        if (values[0] === "page") newFilters[values[0]] = +values[1] - 1;
        if (values[0] === "workflowId") {
          newFilters[values[0]] =
            values[1] === "null" ? [] : values[1].split(",");
          workflowId = values[1] === "null" ? null : [];
        }
        if (values[1] === "null") newFilters[values[0]] = null;
      }
    });
    if (
      newFilters.workflowId.length === 0 &&
      filters.workflowId.length === 0 &&
      workflowId !== null
    ) {
      newFilters.workflowId =
        workflows.length !== 0 && workflows[0]._id ? [workflows[0]._id] : [];
    }
    if (newFilters.workflowId.length === 0) {
      setIndexedWorkflows({
        evenIndexedWorkflows: workflows.filter(
          (_: any, index: any) => index % 2 === 0
        ),
        oddIndexedWorkflows: workflows.filter(
          (_: any, index: any) => index % 2 !== 0
        ),
      });
    } else {
      setIndexedWorkflows({
        evenIndexedWorkflows: newFilters.workflowId.filter(
          (_: any, index: any) => index % 2 === 0
        ),
        oddIndexedWorkflows: newFilters.workflowId.filter(
          (_: any, index: any) => index % 2 !== 0
        ),
      });
    }
    setFilters({
      ...newFilters,
      startDate: moment(newFilters.startDate, dayPilotFormat),
      endDate: moment(newFilters.endDate, dayPilotFormat),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /**
   *
   */
  async function recalculateReports() {
    const response = await getData(urlEnum.workflowsReports);
    if (
      response &&
      response.data &&
      (response.status === 200 || response.status === 201)
    ) {
      window.location.reload();
    }
  }
  return (
    <div
      className={
        phoneStyle
          ? styles.mainContainerReportsMobile
          : styles.mainContainerReports
      }
    >
      <ReportsAndOrdersFilters
        filters={filters}
        url=""
        disableClearable={true}
        multiple={true}
        showWorkflowId={true}
        recalculateReportsButton={true}
        recalculateReportsFunction={recalculateReports}
      />
      <Grid
        container
        spacing={4}
        style={phoneStyle ? { marginBottom: 80 } : {}}
      >
        {filters.workflowId.length !== 0 ? (
          <>
            <Grid
              item
              xs={12}
              md={12}
              lg={filters.workflowId.length === 1 ? 12 : 6}
            >
              {indexedWorkflows.evenIndexedWorkflows.map((workflow: any) => (
                <WorkflowReport
                  key={workflow}
                  workflowId={workflow}
                  filters={filters}
                />
              ))}
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              lg={filters.workflowId.length === 1 ? 12 : 6}
            >
              {indexedWorkflows.oddIndexedWorkflows.map((workflow: any) => (
                <WorkflowReport
                  key={workflow}
                  workflowId={workflow}
                  filters={filters}
                />
              ))}
            </Grid>
          </>
        ) : (
          <>
            <Grid
              item
              xs={12}
              md={12}
              lg={filters.workflowId.length === 1 ? 12 : 6}
            >
              {indexedWorkflows.evenIndexedWorkflows.map((workflow: any) => (
                <WorkflowReport
                  key={workflow._id}
                  workflowId={workflow._id}
                  filters={filters}
                />
              ))}
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              lg={filters.workflowId.length === 1 ? 12 : 6}
            >
              {indexedWorkflows.oddIndexedWorkflows.map((workflow: any) => (
                <WorkflowReport
                  key={workflow._id}
                  workflowId={workflow._id}
                  filters={filters}
                />
              ))}
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}
