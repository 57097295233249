/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextValidator } from "react-material-ui-form-validator";
import { Vocabulary } from "../../../Utils/Vocabulary";
import { Editor } from "react-draft-wysiwyg";
import { onChangeTextField } from "../../../Utils/Utils";
import DropzoneComponent from "../../GenericComponents/DropzoneComponent";

type EmailContentProps = {
  emailInfo: any;
  handleChangeEmailInfo: (emailInfo: any) => void;
};

export default function EmailContent(props: EmailContentProps) {
  const { emailInfo, handleChangeEmailInfo } = props;

  return (
    <div style={{ marginTop: 20 }}>
      <TextValidator
        label={Vocabulary.to}
        name="to"
        size="small"
        value={emailInfo.to || ""}
        validators={["required", "isEmail"]}
        errorMessages={[Vocabulary.requiredField, Vocabulary.invalidEmail]}
        fullWidth
        style={{ marginBottom: 15 }}
        onChange={(e: any) =>
          handleChangeEmailInfo(onChangeTextField(e, emailInfo))
        }
      />
      <TextValidator
        label={Vocabulary.from}
        name="from"
        size="small"
        value={emailInfo.from || ""}
        validators={["required", "isEmail"]}
        errorMessages={[Vocabulary.requiredField, Vocabulary.invalidEmail]}
        fullWidth
        style={{ marginBottom: 15 }}
        onChange={(e: any) =>
          handleChangeEmailInfo(onChangeTextField(e, emailInfo))
        }
      />
      <TextValidator
        label={Vocabulary.cc}
        name="cc"
        size="small"
        value={emailInfo.cc || ""}
        fullWidth
        style={{ marginBottom: 15 }}
        onChange={(e: any) =>
          handleChangeEmailInfo(onChangeTextField(e, emailInfo))
        }
      />
      <TextValidator
        label="Subject"
        name="subject"
        size="small"
        value={emailInfo.subject || ""}
        validators={["required"]}
        errorMessages={[Vocabulary.requiredField]}
        fullWidth
        style={{ marginBottom: 15 }}
        onChange={(e: any) =>
          handleChangeEmailInfo(onChangeTextField(e, emailInfo))
        }
      />
      <div
        style={{
          border: "1px solid #f1f1f1",
          padding: 10,
          marginBottom: 20,
        }}
      >
        <Editor
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbarClassName"
          toolbar={{
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
            inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
          }}
          editorState={emailInfo.html}
          onEditorStateChange={(e: any) =>
            handleChangeEmailInfo({ ...emailInfo, html: e })
          }
        />
      </div>
      <DropzoneComponent
        onSave={(files: any) =>
          handleChangeEmailInfo({ ...emailInfo, attachments: files })
        }
        maxFiles={10}
        accept={{
          "application/msword": [".doc"],
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            [".docx"],
          "image/*": [".png", ".jpeg", ".jpg"],
          "application/pdf": [".pdf"],
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            ".xlsx",
          "application/vnd.ms-excel": ".xls",
        }}
      />
    </div>
  );
}
