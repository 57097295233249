/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, Grid, TextField } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { handleChangeAutocomplete, onChangeTextField } from "../../Utils/Utils";
import { EmailTemplateModel } from "../../Utils/Models";

type EmailTemplateDetailsProps = {
  emailTemplate: any;
  statusEnum: any;
  handleChangeEmailTemplate: (emailTemplate: EmailTemplateModel) => void;
  handleChangeOnFocus: (value: string) => void;
};
export default function EmailTemplateDetails(props: EmailTemplateDetailsProps) {
  const {
    emailTemplate,
    statusEnum,
    handleChangeEmailTemplate,
    handleChangeOnFocus,
  } = props;

  return (
    <Grid container spacing={3} style={{ marginBottom: 15 }}>
      <Grid item xs={12} md={6} lg={6}>
        <TextField
          type="text"
          label={Vocabulary.name}
          name="name"
          variant="standard"
          fullWidth
          value={emailTemplate.name}
          onChange={(e: any) =>
            handleChangeEmailTemplate(onChangeTextField(e, emailTemplate))
          }
          size="small"
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <Autocomplete
          size="small"
          id="status"
          freeSolo={false}
          disablePortal
          options={statusEnum}
          getOptionLabel={(option: any) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              label={Vocabulary.status}
              variant="standard"
            />
          )}
          value={emailTemplate.status}
          onChange={(e: any, value: any) =>
            handleChangeEmailTemplate(
              handleChangeAutocomplete(e, value, emailTemplate, "status")
            )
          }
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <TextField
          size="small"
          type="text"
          label={Vocabulary.subject}
          name="subject"
          variant="standard"
          fullWidth
          value={emailTemplate.subject}
          onFocus={() => handleChangeOnFocus(Vocabulary.subject)}
          onChange={(e: any) =>
            handleChangeEmailTemplate(onChangeTextField(e, emailTemplate))
          }
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6}>
        <TextField
          size="small"
          type="text"
          label={Vocabulary.cc}
          placeholder={Vocabulary.enterEmailAddressesSeparatedByCommas}
          name="cc"
          variant="standard"
          fullWidth
          value={emailTemplate.cc}
          onChange={(e: any) =>
            handleChangeEmailTemplate(onChangeTextField(e, emailTemplate))
          }
        />
      </Grid>
    </Grid>
  );
}
