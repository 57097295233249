/* eslint-disable @typescript-eslint/no-explicit-any */
import { Vocabulary } from "../../../../Utils/Vocabulary";
import AddOrUpdateModel from "../../../GenericComponents/AddOrUpdateModel";
import { PurchaseOrderModel } from "../../../../Utils/Models";
import PurchaseOrderGeneralInfo from "./PurchaseOrderGeneralInfo";
import PurchaseOrderProducts from "./PurchaseOrderProducts";
import styles from "../../../../Styles/purchaseOrder.module.css";
import { Button, Grid } from "@mui/material";
import { useState } from "react";
import { PurchaseOrderStatuses } from "../../../../Utils/AutocompleteUtils";

type PurchaseOrderProps = {
  editPurchaseOrder: PurchaseOrderModel;
  open: boolean;
  onClose: () => void;
  receiveSelectedProducts?: (
    id: string | null,
    selectedProducts: string[]
  ) => void;
  viewOnly?: boolean;
};

export default function ShowPurchaseOrder(props: PurchaseOrderProps) {
  const {
    open,
    editPurchaseOrder,
    viewOnly,
    onClose,
    receiveSelectedProducts,
  } = props;
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);

  /**
   *
   * @param selectedAll
   * @param id
   */
  function handleChangeSelectedProducts(
    selectedAll: boolean,
    id: string | null
  ) {
    if (selectedAll) {
      if (
        editPurchaseOrder.products.filter(
          (product) => product?.status === PurchaseOrderStatuses.open
        ).length === selectedProducts.length
      ) {
        setSelectedProducts([]);
      } else {
        setSelectedProducts(
          editPurchaseOrder.products
            .filter((product) => product?.status === PurchaseOrderStatuses.open)
            .map((product) => product.product?._id || "")
        );
      }
    } else {
      const index = selectedProducts.findIndex((productId) => productId === id);
      if (index === -1 && id) {
        setSelectedProducts([...selectedProducts, id]);
      } else {
        setSelectedProducts(
          selectedProducts.filter((productId) => productId !== id)
        );
      }
    }
  }

  /**
   *
   */

  return (
    <AddOrUpdateModel
      open={open}
      title={Vocabulary.showPurchaseOrder}
      onClose={onClose}
      addOrUpdateItem={() => null}
      hideButtons={true}
    >
      <PurchaseOrderGeneralInfo purchaseOrder={editPurchaseOrder} />
      <Grid container spacing={2} style={{ marginTop: 15 }}>
        <Grid item xs={12} md={12} lg={12}>
          <PurchaseOrderProducts
            productId={null}
            product={Vocabulary.product}
            manufacturer={Vocabulary.manufacturer}
            quantity={Vocabulary.quantity}
            stock={Vocabulary.stock}
            price={Vocabulary.price}
            styles={styles.productLineHeaderP}
            showDivider={false}
            handleChangeSelectedProducts={handleChangeSelectedProducts}
            checked={
              selectedProducts.length ===
              editPurchaseOrder.products.filter(
                (product) => product?.status === PurchaseOrderStatuses.open
              ).length
                ? true
                : false
            }
            disabled={
              viewOnly
                ? true
                : editPurchaseOrder.products.filter(
                    (product) => product?.status === PurchaseOrderStatuses.open
                  ).length > 0
                ? false
                : true
            }
          />
        </Grid>
        {editPurchaseOrder.products.map((product, index) => (
          <Grid item xs={12} md={12} lg={12}>
            <PurchaseOrderProducts
              productId={product.product?._id || null}
              product={
                <>
                  {product.product?.code} <br />
                  {product.product &&
                  product.product.name &&
                  product.product.name.length > 15
                    ? `${product.product?.name.substring(0, 15)}...`
                    : product.product?.name || ""}
                </>
              }
              manufacturer={product?.product?.manufacturer || ""}
              quantity={product.quantity}
              stock={
                <>
                  {editPurchaseOrder?.inventory?.name} <br />{" "}
                  {editPurchaseOrder?.inventory?.location?.name || ""}
                </>
              }
              price={product.price}
              styles={styles.productLineP}
              showDivider={true}
              handleChangeSelectedProducts={handleChangeSelectedProducts}
              checked={
                selectedProducts.find((id) => id === product.product?._id)
                  ? true
                  : false
              }
              disabled={
                viewOnly
                  ? true
                  : product?.status !== PurchaseOrderStatuses.open
                  ? true
                  : false
              }
            />
          </Grid>
        ))}
      </Grid>
      <div style={{ float: "right", marginTop: 15, fontSize: 20 }}>
        {Vocabulary.purchaseOrderTotal}: &nbsp;&nbsp;
        {editPurchaseOrder.products.reduce(
          (acc, product: any) => acc + product.price * product.quantity,
          0
        )}
      </div>
      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: 25 }}
        disabled={
          viewOnly ? true : selectedProducts.length === 0 ? true : false
        }
        onClick={() => {
          if (receiveSelectedProducts)
            receiveSelectedProducts(editPurchaseOrder._id, selectedProducts);
        }}
        // fullWidth
      >
        {Vocabulary.receiveSelected}
      </Button>
    </AddOrUpdateModel>
  );
}
