import styles from "../Styles/dashboard.module.css";
import SettingsMenu from "../Components/Settings/SettingsMenu";
import { useMediaQuery } from "@mui/material";
import TaxRates from "../Components/Settings/TaxRates";
import { useLocation } from "react-router-dom";
import { settingsMenuEnum } from "../Utils/Constants";

export default function Settings() {
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const location = useLocation();

  /**
   *
   */
  function showInformation() {
    const path = location.search.split("=");
    switch (path[1]) {
      case settingsMenuEnum.taxRates:
        return <TaxRates />;
      case settingsMenuEnum.emailConfiguration:
        return null;
      default:
        return <TaxRates />;
    }
  }
  return (
    <div
      className={phoneStyle ? styles.mainContainerMobile : styles.mainContainer}
    >
      <SettingsMenu />
      {showInformation()}
    </div>
  );
}
