/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { useMediaQuery } from "@mui/material";
import DashboardContainer from "../DashboardContainer";
import styles from "../../Styles/dashboard.module.css";

type Props = {
  text: string;
  icon: any;
};

export default function PageNotFound(props: Props) {
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  return (
    <DashboardContainer>
      <div
        className={
          phoneStyle
            ? styles.mainContainerMobile
            : styles.mainContainerDashboard
        }
        style={{ alignItems: "center" }} //justifyContent: "center"
      >
        <div style={{ textAlign: "center" }}>
          {props.icon}
          <h2>{props.text}</h2>
        </div>
      </div>
    </DashboardContainer>
  );
}
