import moment from "moment";
import { ShipmentModel } from "../../Utils/Models";
import { Vocabulary } from "../../Utils/Vocabulary";
import { euFormatForDateTime } from "../../Utils/Config";
import OrderShipmentsList from "./OrderShipmentsList";

type OrderShipmentProps = {
  shipment: ShipmentModel | null;
  shipments: ShipmentModel[];
};

export default function OrderShipment(props: OrderShipmentProps) {
  const { shipment, shipments } = props;
  return (
    <>
      {!shipment ? (
        <p style={{ fontSize: 18, textAlign: "center" }}>
          {Vocabulary.noPendingShipmentRequests}
        </p>
      ) : (
        <>
          <p style={{ fontSize: 18, fontWeight: 500 }}>
            {Vocabulary.pendingShipmentRequests}
          </p>
          <p style={{ padding: 5, margin: 0, fontWeight: 300 }}>
            {shipment.recipient?.firstName} {shipment.recipient?.lastName}
          </p>
          <p style={{ padding: 5, margin: 0, fontWeight: 300 }}>
            {shipment.recipientAddress?.address1}
          </p>
          <p style={{ padding: 5, margin: 0, fontWeight: 300 }}>
            {shipment.recipientAddress?.zip}, {shipment.recipientAddress?.city}
          </p>
          <p
            style={{
              padding: 5,
              margin: 0,
              color: "#898989",
              fontSize: 15,
              fontWeight: 350,
            }}
          >
            {Vocabulary.created} &nbsp;
            {moment(shipment.createdAt).format(euFormatForDateTime)}
          </p>
        </>
      )}
      {shipments.length === 0 ? null : (
        <OrderShipmentsList shipments={shipments} />
      )}
    </>
  );
}
