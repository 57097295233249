/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from "react";
import { Vocabulary } from "../../Utils/Vocabulary";
import AddOrUpdateModel from "../GenericComponents/AddOrUpdateModel";
import { getData } from "../../Services/getData";
import { urlEnum } from "../../Utils/UrlEnum";
import { Autocomplete, TextField } from "@mui/material";
import { handleChangeAutocomplete } from "../../Utils/Utils";
import { DeviceModel, Step, WorkflowModel } from "../../Utils/Models";
import { OrderContext } from "../../Contexts/OrderContext";

type StateType = {
  device: DeviceModel | null;
  workflow: WorkflowModel | null;
};
type AddDeviceToOrderProps = {
  open: boolean;
  onClose: () => void;
};
export default function AddDeviceToOrder(props: AddDeviceToOrderProps) {
  const { open, onClose } = props;
  const orderContext = useContext(OrderContext);
  const [devices, setDevices] = useState([]);
  const [workflows, setWorkflows] = useState([]);
  const [state, setState] = useState<StateType>({
    device: null,
    workflow: null,
  });

  useEffect(() => {
    getDevices();
    getWorkflows();
  }, []);

  /**
   *
   */
  function getDevices() {
    getData(urlEnum.devices).then((res) => {
      if (res) {
        setDevices(res.data.result);
      }
    });
  }

  /**
   *
   */
  function getWorkflows() {
    getData(urlEnum.workflows).then((res) => {
      if (res) {
        setWorkflows(res.data.result);
      }
    });
  }

  /**
   *
   * @param workflowId
   * @returns
   */
  async function getWorkflowStepsByWorkflowId(workflowId: string | null) {
    if (!workflowId) return [];
    const res = await getData(
      `${urlEnum.steps}/stepsForOneWorkflow/${workflowId}`
    );
    const steps = [];
    for (const step of res.data.result) {
      const newItem = new Step();
      newItem.stepId = step;
      steps.push(newItem);
    }
    return steps;
  }

  /**
   *
   * @param state
   */
  async function handleChangeOrder(state: StateType) {
    if (!state.workflow) {
      orderContext.setOrder({
        ...orderContext.order,
        device: state.device,
      });
    } else {
      const steps = await getWorkflowStepsByWorkflowId(
        state.workflow && state.workflow._id ? state.workflow._id : null
      );
      orderContext.setOrder({
        ...orderContext.order,
        device: state.device,
        workflow: state.workflow,
        workflowSteps: steps,
      });
    }
  }

  return (
    <AddOrUpdateModel
      open={open}
      title={Vocabulary.chooseDevice}
      onClose={onClose}
      addOrUpdateItem={() => {
        handleChangeOrder(state);
        onClose();
      }}
      saveButtonTitle={Vocabulary.select}
    >
      <Autocomplete
        size="small"
        id="device"
        freeSolo={false}
        disablePortal
        getOptionLabel={(option: any) => option.name}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        options={devices}
        value={state.device}
        onChange={(event: any, value: any) => {
          setState(handleChangeAutocomplete(event, value, state, "device"));
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name="device"
            label={Vocabulary.devices}
            variant="outlined"
          />
        )}
        style={{ margin: "20px 0px" }}
      />
      <Autocomplete
        size="small"
        id="workflow"
        freeSolo={false}
        disablePortal
        getOptionLabel={(option: any) => option.name}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        options={workflows}
        value={state.workflow}
        onChange={(event: any, value: any) => {
          setState(handleChangeAutocomplete(event, value, state, "workflow"));
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name="workflow"
            label={Vocabulary.workflows}
            variant="outlined"
          />
        )}
        style={{ marginTop: 20 }}
      />
    </AddOrUpdateModel>
  );
}
