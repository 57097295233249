/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid, Paper } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { displayTimeString } from "../../Utils/Utils";
import { localUrlEnum } from "../../Utils/UrlEnum";
import styles from "../../Styles/reports.module.css";
import { numberOfDigits } from "../../Utils/Config";

type AdminReportHeaderProps = {
  report: any;
};
export default function OrderReportHeader(props: AdminReportHeaderProps) {
  const { report } = props;
  return (
    <>
      <Paper className={styles.reportsHeader}>
        <div style={{ fontWeight: 600 }}>
          <a
            href={`${localUrlEnum.orders}/${report.orderId}`}
            style={{
              textDecoration: "none",
              cursor: "pointer",
              color: "#008470",
            }}
          >
            {Vocabulary.orderNumber}: {report.orderNumber}
          </a>
        </div>
      </Paper>
      <Paper className={styles.reportsPaperWithTotals}>
        <Grid container spacing={1} style={{ margin: 0, width: "100%" }}>
          <Grid
            item
            xs={12}
            md={2.5}
            lg={2.5}
            textAlign="center"
            style={{
              borderLeft: "1px solid #f3f3f3",
            }}
            className={styles.reportsTotalsGridItems}
          >
            <div className={styles.reportsTotalsItemContainer}>
              <div
                className={styles.reportsTotalsItemLabel}
                style={{ fontSize: 14 }}
              >
                {Vocabulary.trackedTime}
              </div>
              <div
                className={styles.reportsTotalsItemValue}
                style={{ fontSize: 16 }}
              >
                {displayTimeString(report.trackedTime)}
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={2.5}
            lg={2.5}
            textAlign="center"
            className={styles.reportsTotalsGridItems}
          >
            <div className={styles.reportsTotalsItemContainer}>
              <div
                className={styles.reportsTotalsItemLabel}
                style={{ fontSize: 14 }}
              >
                {Vocabulary.predefinedTime}
              </div>
              <div
                className={styles.reportsTotalsItemValue}
                style={{ fontSize: 16 }}
              >
                {displayTimeString(report.predefinedTime)}
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            lg={2}
            textAlign="center"
            className={styles.reportsTotalsGridItems}
          >
            <div className={styles.reportsTotalsItemContainer}>
              <div
                className={styles.reportsTotalsItemLabel}
                style={{ fontSize: 14 }}
              >
                {Vocabulary.timeFactor}
              </div>
              <div
                className={styles.reportsTotalsItemValue}
                style={{ fontSize: 16 }}
              >
                {report.timeFactor.toFixed(numberOfDigits)}%
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={2.5}
            lg={2.5}
            textAlign="center"
            className={styles.reportsTotalsGridItems}
          >
            <div className={styles.reportsTotalsItemContainer}>
              <div
                className={styles.reportsTotalsItemLabel}
                style={{ fontSize: 14 }}
              >
                {Vocabulary.orderCost}
              </div>
              <div
                className={styles.reportsTotalsItemValue}
                style={{ fontSize: 16 }}
              >
                {report.orderCost.toFixed(numberOfDigits)}
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={2.5}
            lg={2.5}
            textAlign="center"
            className={styles.reportsTotalsGridItems}
          >
            <div className={styles.reportsTotalsItemContainer}>
              <div
                className={styles.reportsTotalsItemLabel}
                style={{ fontSize: 14 }}
              >
                {Vocabulary.trackedCost}
              </div>
              <div
                className={styles.reportsTotalsItemValue}
                style={{ fontSize: 16 }}
              >
                {report.trackedCost.toFixed(numberOfDigits)}
              </div>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
