/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { DocumentTemplateModel } from "../../Utils/Models";
import AddOrUpdateModel from "../GenericComponents/AddOrUpdateModel";
import { useEffect, useRef, useState } from "react";
import { Vocabulary } from "../../Utils/Vocabulary";
import { Autocomplete, Button, Grid } from "@mui/material";
import { TemplatesType } from "../../Utils/AutocompleteUtils";
import DropzoneComponent from "../GenericComponents/DropzoneComponent";
import {
  download,
  handleChangeAutocomplete,
  onChangeTextField,
} from "../../Utils/Utils";
import { urlEnum } from "../../Utils/UrlEnum";
import moment from "moment";

type DocumentTemplateProps = {
  open: boolean;
  editDocumentTemplate: DocumentTemplateModel | null;
  onClose: () => void;
  addOrUpdateDocumentTemplate: (
    documentTemplate: DocumentTemplateModel
  ) => void;
};

export default function DocumentTemplate(props: DocumentTemplateProps) {
  const { editDocumentTemplate, onClose, open, addOrUpdateDocumentTemplate } =
    props;
  const ref: any = useRef();
  const [pressOnSubmit, setPressOnSubmit] = useState(false);
  const [documentTemplate, setDocumentTemplate] =
    useState<DocumentTemplateModel>(new DocumentTemplateModel());
  const [errorText, setErrorText] = useState("");
  /**
   *
   */
  useEffect(() => {
    if (editDocumentTemplate) {
      setDocumentTemplate(editDocumentTemplate);
    } else {
      setDocumentTemplate(new DocumentTemplateModel());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editDocumentTemplate]);

  /**
   *
   */
  useEffect(() => {
    if (pressOnSubmit) {
      ref.current.submit();
      //reset the value
      handleChangePressOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressOnSubmit]);

  /**
   *
   */
  function handleChangePressOnSubmit() {
    setPressOnSubmit(!pressOnSubmit);
  }

  /**
   *
   * @param files
   */
  function handleChangeDropFile(files: any) {
    if (files !== null && files.length > 0) {
      setDocumentTemplate({ ...documentTemplate, file: files[0] });
    }
  }

  /**
   *
   */
  function onSubmit() {
    if (documentTemplate.file || editDocumentTemplate?.filePath) {
      setErrorText("");
      addOrUpdateDocumentTemplate(documentTemplate);
    } else {
      setErrorText(Vocabulary.uploadADocument);
    }
  }

  /**
   *
   */
  function downloadDoc() {
    const token = localStorage.getItem("access_token");
    const fileName =
      documentTemplate && documentTemplate.filePath
        ? documentTemplate.filePath.split("/").pop() ||
          `Doc${moment().unix()}.docx`
        : `Doc${moment().unix()}.docx`;
    if (documentTemplate.filePath)
      fetch(
        `${urlEnum.documentsTemplates}/download${documentTemplate.filePath}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => response.blob())
        .then((response) => {
          download(
            response,
            fileName,
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          );
        });
  }

  return (
    <ValidatorForm ref={ref} onSubmit={onSubmit}>
      <AddOrUpdateModel
        open={open}
        title={Vocabulary.documentTemplate}
        onClose={onClose}
        isSubmit={true}
        addOrUpdateItem={() => null}
        handleChangePressOnSubmit={handleChangePressOnSubmit}
      >
        <Grid container spacing={2} style={{ marginTop: 5 }}>
          <Grid item lg={6} md={6} xs={12}>
            <Autocomplete
              id="type"
              freeSolo={false}
              disablePortal
              getOptionLabel={(option: any) => option.name}
              size="small"
              value={
                TemplatesType.find((item) =>
                  item.name === documentTemplate.type ? item : null
                ) || null
              }
              options={TemplatesType}
              onChange={(event: any, newValue: any) => {
                const newDocumentTemplate = handleChangeAutocomplete(
                  event,
                  newValue,
                  documentTemplate,
                  "type"
                );
                newDocumentTemplate.type = newDocumentTemplate.type
                  ? newDocumentTemplate.type.name
                  : null;
                setDocumentTemplate(newDocumentTemplate);
              }}
              sx={{ width: "100%" }}
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  name="type"
                  label={Vocabulary.type}
                  variant="outlined"
                  size="small"
                  value={TemplatesType.find((item) =>
                    item.name === documentTemplate.type ? item : null
                  )}
                  fullWidth
                  validators={["required"]}
                  errorMessages={[Vocabulary.requiredField]}
                />
              )}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <TextValidator
              name="name"
              label={Vocabulary.name}
              fullWidth
              size="small"
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              value={documentTemplate.name}
              onChange={(e: any) =>
                setDocumentTemplate(onChangeTextField(e, documentTemplate))
              }
            />
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            {editDocumentTemplate && editDocumentTemplate.filePath ? (
              <>
                <p style={{ color: "#00000099" }}>
                  {Vocabulary.filename}: &nbsp;
                  {editDocumentTemplate.filePath.split("/").pop()}
                </p>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={downloadDoc}
                >
                  {Vocabulary.downloadDocument}
                </Button>
              </>
            ) : null}
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <DropzoneComponent
              onSave={(files: any) => handleChangeDropFile(files)}
              maxFiles={1}
              accept={{
                "application/msword": [".doc"],
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                  [".docx"],
              }}
            />
            <p style={{ color: "red", marginTop: 7 }}>{errorText}</p>
          </Grid>
        </Grid>
      </AddOrUpdateModel>
    </ValidatorForm>
  );
}
