/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Divider, TextField } from "@mui/material";
import { ShipmentModel } from "../../Utils/Models";
import { Vocabulary } from "../../Utils/Vocabulary";
import AddOrUpdateModel from "../GenericComponents/AddOrUpdateModel";
import DividerWithLabel from "../GenericComponents/DividerWithLabel";
import PackagesList from "./PackagesList";
import { useState } from "react";
import { shipmentStatuses } from "../../Utils/AutocompleteUtils";

type PendingShipmentInfoProps = {
  editShipment: ShipmentModel;
  open: boolean;
  onClose: () => void;
  viewOnly: boolean;
  confirmAndProcessSelectedPackages?: (
    id: string | null,
    selectedPackages: string[],
    trackingNumber: string
  ) => void;
};

export default function PendingShipmentInfo(props: PendingShipmentInfoProps) {
  const {
    open,
    editShipment,
    viewOnly,
    onClose,
    confirmAndProcessSelectedPackages,
  } = props;
  const [shipment, setShipment] = useState<ShipmentModel>(
    Object.assign({}, editShipment)
  );
  const [selectedPackages, setSelectedPackages] = useState<string[]>([]);

  /**
   *
   * @param selectedAll
   * @param id
   */
  function handleChangeSelectedPackages(
    selectedAll: boolean,
    id: string | null
  ) {
    if (selectedAll) {
      if (
        shipment.packages.filter(
          (pack) =>
            pack?.status === shipmentStatuses.inbound ||
            pack?.status === shipmentStatuses.outbound
        ).length === selectedPackages.length
      ) {
        setSelectedPackages([]);
      } else {
        setSelectedPackages(
          shipment.packages
            .filter(
              (pack) =>
                pack?.status === shipmentStatuses.inbound ||
                pack?.status === shipmentStatuses.outbound
            )
            .map((pack: any) => pack.orders[0]?._id || "")
        );
      }
    } else {
      const index = selectedPackages.findIndex(
        (shipmentId) => shipmentId === id
      );
      if (index === -1 && id) {
        setSelectedPackages([...selectedPackages, id]);
      } else {
        setSelectedPackages(
          selectedPackages.filter((shipmentId) => shipmentId !== id)
        );
      }
    }
  }

  return (
    <AddOrUpdateModel
      open={open}
      title={Vocabulary.shipment}
      onClose={onClose}
      isSubmit={true}
      addOrUpdateItem={() => null}
      hideButtons={true}
    >
      <div style={{ marginTop: 10, marginBottom: 10, display: "flex" }}>
        <div style={{ flex: 1, lineHeight: "1.7em", fontWeight: 300 }}>
          <span style={{ fontWeight: 450 }}>{Vocabulary.customer}</span>
          <br />
          {editShipment.recipient?.firstName} {editShipment.recipient?.lastName}
          <br />
          {editShipment.recipient?.email} <br />
          {editShipment.recipient?.phone} <br />
          {Vocabulary.address}: &nbsp; {editShipment.recipientAddress?.address1}
          <br />
          {editShipment.recipientAddress?.city},{" "}
          {editShipment.recipientAddress?.zip}
        </div>
        <div style={{ flex: 1, lineHeight: "1.7em", fontWeight: 300 }}>
          <span style={{ fontWeight: "bold" }}>{Vocabulary.senderAddress}</span>
          <br />
          {editShipment.senderLocation?.name} <br />
          {editShipment.senderLocation?.email} <br />
          {editShipment.senderLocation?.phone} <br />
          {Vocabulary.address}: &nbsp; {editShipment.senderLocation?.address}{" "}
          <br />
          {editShipment.senderLocation?.city},{" "}
          {editShipment.senderLocation?.zip}
        </div>
      </div>
      <DividerWithLabel label={Vocabulary.packages} />
      <PackagesList
        packageId={null}
        order={Vocabulary.order}
        trackingNumber={Vocabulary.trackingNumber}
        device={Vocabulary.device}
        disabled={false}
        checked={
          selectedPackages.length ===
          shipment.packages.filter(
            (pack) =>
              pack?.status === shipmentStatuses.inbound ||
              pack?.status === shipmentStatuses.outbound
          ).length
            ? true
            : false
        }
        viewOnly={viewOnly}
        handleChangeSelectedPackages={handleChangeSelectedPackages}
      />
      <Divider />
      {shipment.packages?.map((item: any, index) => (
        <div key={item.orders[0]._id}>
          <PackagesList
            packageId={item.orders[0]?._id}
            order={item.orders[0]?.fixablyId}
            trackingNumber={
              item.trackingNumber || shipment.trackingNumber || ""
            }
            device={item.orders[0]?.device?.name || ""}
            disabled={item.status === shipmentStatuses.delivered ? true : false}
            checked={
              selectedPackages.find((id) => id === item.orders[0]._id)
                ? true
                : false
            }
            viewOnly={viewOnly}
            handleChangeSelectedPackages={handleChangeSelectedPackages}
          />
          <Divider />
        </div>
      ))}
      {viewOnly ? null : (
        <div
          style={{
            marginTop: 40,
            display: "flex",
            alignItems: "center",
          }}
        >
          {selectedPackages.length === 0 ? null : (
            <TextField
              id="trackingNumber"
              name="trackingNumber"
              size="small"
              label={Vocabulary.trackingNumber}
              value={shipment.trackingNumber}
              onChange={(e) =>
                setShipment({ ...shipment, trackingNumber: e.target.value })
              }
              style={{ flex: 1, marginRight: 10 }}
            />
          )}
          <Button
            variant="contained"
            color="primary"
            disabled={selectedPackages.length === 0 ? true : false}
            onClick={() => {
              if (confirmAndProcessSelectedPackages)
                confirmAndProcessSelectedPackages(
                  shipment._id,
                  selectedPackages,
                  shipment.trackingNumber || ""
                );
            }}
            style={{ flex: 1, marginLeft: 10 }}
          >
            {Vocabulary.confirmAndProcess}
          </Button>
        </div>
      )}
    </AddOrUpdateModel>
  );
}
