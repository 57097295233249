/* eslint-disable @typescript-eslint/no-explicit-any */
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type ExpendButtonsProps = {
  showMore: boolean;
  style: any;
  handleChangeShowMore: () => void;
};
export default function ExpendButtons(props: ExpendButtonsProps) {
  const { showMore, style, handleChangeShowMore } = props;
  return (
    <>
      {showMore ? (
        <ExpandLessIcon
          tabIndex={-1}
          style={style ? style : {}}
          onClick={handleChangeShowMore}
        />
      ) : (
        <ExpandMoreIcon
          tabIndex={-1}
          style={style ? style : {}}
          onClick={handleChangeShowMore}
        />
      )}
    </>
  );
}
