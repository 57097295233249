/* eslint-disable camelcase */
import {
  Autocomplete,
  Box,
  Checkbox,
  Collapse,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { useState } from "react";
import ExpendButtons from "../GenericComponents/ExpendButtons";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { VariantProps } from "./ProductVariants";
import {
  deleteImagesFromProductVariants,
  onChangeTextField,
} from "../../Utils/Utils";
import AddImagesToVariants from "./AddImagesToVariants";
import { ProductModel } from "../../Utils/Models";
import { toast } from "react-toastify";

/* eslint-disable @typescript-eslint/no-explicit-any */
type RenderVariantProps = {
  index: number;
  variant: VariantProps;
  product: ProductModel;
  selectedVariants: Array<number>;
  changeOneVariant: (index: number, variant: VariantProps) => void;
  handleChangeSelectedVariants: (index: number) => void;
  handleChangeProduct: (product: ProductModel) => void;
  undoDeleteVariant: (indexes: Array<number>) => void;
};
export default function RenderVariant(props: RenderVariantProps) {
  const {
    index,
    variant,
    product,
    selectedVariants,
    changeOneVariant,
    undoDeleteVariant,
    handleChangeProduct,
    handleChangeSelectedVariants,
  } = props;
  const [openAddImagesModal, setOpenAddImagesModal] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [selectedInventory, setSelectedInventory] = useState<any>(
    variant.inventory || null
  );

  /**
   *
   * @param variant
   * @returns
   */
  function displayVariantName() {
    const options = Object.keys(variant)
      .filter((key) => key.startsWith("option"))
      .map((key) => variant[key])
      .join(" - ");

    return <p>{options}</p>;
  }

  /**
   *
   */
  function handleChangeAddImagesModal() {
    setOpenAddImagesModal(!openAddImagesModal);
  }

  /**
   *
   * @param e
   * @returns
   */
  function getSumOfVariantQuantities(e: any, newSelectedInventory?: any) {
    let variants = product.variants.slice();
    if (e) variants[index] = onChangeTextField(e, variant);
    if (newSelectedInventory)
      variants = variants.filter(
        (v: any) =>
          v.inventory &&
          v.inventory.inventory._id === newSelectedInventory.inventory._id
      );
    else
      variants = variants.filter(
        (v: any) =>
          v.inventory &&
          v.inventory.inventory._id === selectedInventory.inventory._id
      );
    const totalQuantity = variants.reduce(
      (acc: number, v: any) => acc + parseInt(v.inventory_quantity),
      0
    );
    return totalQuantity;
  }

  /**
   *
   * @returns
   */
  function getQuantityLeft(newSelectedInventory: any) {
    const totalQuantity = getSumOfVariantQuantities(null, newSelectedInventory);
    if (newSelectedInventory)
      return parseFloat(newSelectedInventory.quantity) - totalQuantity >= 0
        ? parseFloat(newSelectedInventory.quantity) - totalQuantity
        : 0;
    return parseFloat(selectedInventory.quantity) - totalQuantity >= 0
      ? parseFloat(selectedInventory.quantity) - totalQuantity
      : 0;
  }

  /**
   *
   * @param e
   */
  function validateVariantQuantity(e: any) {
    const totalQuantity = getSumOfVariantQuantities(e);
    if (totalQuantity > parseFloat(selectedInventory.quantity)) {
      return false;
    }
    return true;
  }

  /**
   *
   */
  function handleSaveImages(images: any, files: any, checkedImages: any) {
    const newProduct = Object.assign({}, product);
    newProduct.images = images;
    newProduct.files = newProduct.files
      ? [...newProduct.files, ...files]
      : files;
    newProduct.variants = deleteImagesFromProductVariants(
      images,
      product.variants
    );
    newProduct.variants[index].images = checkedImages.map((index: any) => {
      return images[index];
    });
    handleChangeProduct(newProduct);
    setOpenAddImagesModal(false);
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: variant.mustBeDeleted ? "auto" : "pointer",
        }}
        onClick={() => setShowMore(!showMore)}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Checkbox
            style={{
              marginRight: 10,
              padding: 0,
            }}
            checked={selectedVariants.includes(index) ? true : false}
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              handleChangeSelectedVariants(index);
            }}
          />
          {displayVariantName()}
        </div>
        {variant.mustBeDeleted ? (
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography style={{ color: "#999999", fontSize: 15 }}>
              {Vocabulary.deletedVariantMessage}
            </Typography>
            &nbsp; &nbsp;
            <Typography
              color="primary"
              style={{ fontSize: 15, cursor: "pointer" }}
              onClick={() => undoDeleteVariant([index])}
            >
              {Vocabulary.undo}
            </Typography>
          </p>
        ) : (
          <ExpendButtons
            showMore={showMore}
            handleChangeShowMore={() => null}
            style={{ marginLeft: 5 }}
          />
        )}
      </div>
      {variant.mustBeDeleted ? null : (
        <Collapse in={showMore}>
          <div style={{ display: "flex", alignItems: "center", padding: 10 }}>
            {variant.images &&
            Array.isArray(variant.images) &&
            variant.images.length !== 0 ? (
              <img
                src={
                  variant.images[0].path
                    ? variant.images[0].path
                    : variant.images[0]
                }
                style={{
                  border: "1px dashed #cccccc",
                  borderRadius: 4,
                  padding: 3,
                  marginRight: 10,
                  height: 40,
                  cursor: "pointer",
                }}
                alt="One variant icon"
                onClick={() => handleChangeAddImagesModal()}
              />
            ) : (
              <Box
                style={{
                  border: "1px dashed #cccccc",
                  borderRadius: 4,
                  padding: 10,
                  marginRight: 10,
                  cursor: "pointer",
                }}
                onClick={() => handleChangeAddImagesModal()}
              >
                <AddPhotoAlternateIcon color="primary" />
              </Box>
            )}
            <TextField
              type="number"
              size="small"
              name="price"
              label={Vocabulary.price}
              value={variant.price}
              onChange={(e: any) => {
                changeOneVariant(index, onChangeTextField(e, variant));
              }}
              style={{ flex: 1, marginRight: 10 }}
            />

            <TextField
              type="text"
              size="small"
              name="sku"
              label={Vocabulary.sku}
              value={variant.sku}
              onChange={(e: any) => {
                changeOneVariant(index, onChangeTextField(e, variant));
              }}
              style={{ flex: 1, marginRight: 10 }}
            />

            <Autocomplete
              freeSolo={false}
              disablePortal
              options={product.inventories || []}
              getOptionLabel={(option: any) => option.inventory.name}
              isOptionEqualToValue={(option, value) => option._id === value._id}
              value={selectedInventory}
              handleHomeEndKeys
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={Vocabulary.inventory}
                  size="small"
                />
              )}
              onChange={(event, newValue: any) => {
                if (newValue) {
                  setSelectedInventory(newValue);
                  const quantityLeft = getQuantityLeft(newValue);
                  changeOneVariant(index, {
                    ...variant,
                    inventory: newValue,
                    inventory_quantity: quantityLeft,
                  });
                } else {
                  setSelectedInventory(null);
                  changeOneVariant(index, {
                    ...variant,
                    inventory: null,
                    inventory_quantity: 0,
                  });
                }
              }}
              style={{ flex: 1, marginRight: 10 }}
            />
            <TextField
              type="number"
              size="small"
              name="inventory_quantity"
              label={Vocabulary.quantity}
              value={variant.inventory_quantity}
              onChange={(e: any) => {
                if (!validateVariantQuantity(e)) {
                  toast.error(Vocabulary.quantityExceedsInventory);
                  return;
                }
                changeOneVariant(index, onChangeTextField(e, variant));
              }}
              disabled={selectedInventory ? false : true}
              style={{ flex: 1 }}
            />
          </div>
        </Collapse>
      )}
      <Divider />
      <AddImagesToVariants
        openAddImagesModal={openAddImagesModal}
        product={product}
        variant={variant}
        handleChangeAddImagesModal={handleChangeAddImagesModal}
        handleSaveImages={handleSaveImages}
      />
    </>
  );
}
