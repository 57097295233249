/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditorState, Modifier, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useEffect, useRef, useState } from "react";
import AdditionalData from "./AdditionalData";
import { Vocabulary } from "../../Utils/Vocabulary";
import EmailTemplateDetails from "./EmailTemplateDetails";
import { getData } from "../../Services/getData";
import { urlEnum } from "../../Utils/UrlEnum";
import { resizeImage } from "../../Utils/UtilsForImages";
import { ValidatorForm } from "react-material-ui-form-validator";
import AddOrUpdateModel from "../GenericComponents/AddOrUpdateModel";
import { EmailTemplateModel } from "../../Utils/Models";
import { getHtml } from "../../Utils/Utils";

type EmailTemplateProps = {
  open: boolean;
  editEmailTemplate: any;
  onClose: () => void;
  addOrUpdateEmailTemplate: (emailTemplate: EmailTemplateModel | null) => void;
};

export default function EmailTemplate(props: EmailTemplateProps) {
  const { editEmailTemplate, onClose, open, addOrUpdateEmailTemplate } = props;
  const [emailTemplate, setEmailTemplate] = useState<EmailTemplateModel>(
    new EmailTemplateModel()
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [lastFocused, setLastFocused] = useState(Vocabulary.subject);
  const [statusEnum, setStatusEnum] = useState<any>([]);
  const [uploadedImages, setUploadedImages] = useState<any[]>([]);
  const [pressOnSubmit, setPressOnSubmit] = useState(false);
  const openMenu = Boolean(anchorEl);
  const ref: any = useRef();

  /**
   *
   */
  useEffect(() => {
    if (editEmailTemplate) {
      const newEditEmailTemplate = Object.assign({}, editEmailTemplate) as any;
      newEditEmailTemplate.html = getHtml(editEmailTemplate.html);
      setEmailTemplate(newEditEmailTemplate);
    } else {
      const newEmailTemplate = new EmailTemplateModel();
      setEmailTemplate({
        ...newEmailTemplate,
        html: EditorState.createEmpty(),
      });
    }
    getStatusEnum();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editEmailTemplate]);

  /**
   *
   */
  useEffect(() => {
    if (pressOnSubmit) {
      ref.current.submit();
      //reset the value
      handleChangePressOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressOnSubmit]);

  /**
   *
   */
  function handleChangePressOnSubmit() {
    setPressOnSubmit(!pressOnSubmit);
  }

  /**
   *
   */
  function getStatusEnum() {
    getData(`${urlEnum.statuses}/distinct`)
      .then((res) => {
        setStatusEnum(res.data.result);
      })
      .catch((err) => {
        //
      });
  }
  /**
   *
   * @param event
   */
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   *
   * @param tag
   */
  const handleClose = (tag: any) => {
    if (tag !== "") {
      const text = `{{${tag}}}`;

      if (lastFocused === Vocabulary.subject) {
        setEmailTemplate({
          ...emailTemplate,
          subject: `${emailTemplate.subject} ${text}`,
        });
      } else {
        const editorState: any = emailTemplate.html;
        const contentState = editorState.getCurrentContent();

        let newContentState = contentState.createEntity(
          "unstyled",
          "IMMUTABLE",
          text
        );
        const entityKey = contentState.getLastCreatedEntityKey();
        const selectionState = emailTemplate.html.getSelection();
        newContentState = Modifier.insertText(
          newContentState,
          selectionState,
          text,
          "" as any,
          entityKey
        );
        const newHtml = EditorState.push(
          editorState,
          newContentState,
          "apply-entity"
        );
        setEmailTemplate({ ...emailTemplate, html: newHtml });
      }
    }
    setAnchorEl(null);
  };

  /**
   *
   * @param e
   */
  function handleChangeEmailTemplate(newEmailTemplate: EmailTemplateModel) {
    setEmailTemplate(newEmailTemplate);
  }

  /**
   *
   * @param value
   */
  function handleChangeOnFocus(value: string) {
    setLastFocused(value);
  }

  /**
   *
   * @param file
   * @returns
   */
  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  /**
   *
   * @param file
   * @returns
   */
  const uploadImage = async (file: any) => {
    const newUploadedImages = uploadedImages.slice();
    const resizedFile = (await resizeImage(file)) as any;
    const base64 = await toBase64(resizedFile);
    const imageObject = {
      file: resizedFile,
      localSrc: URL.createObjectURL(resizedFile),
    };
    newUploadedImages.push(imageObject);
    setUploadedImages(newUploadedImages);
    return new Promise((resolve, reject) => {
      resolve({
        data: {
          link: base64,
        },
      });
    });
  };

  return (
    <ValidatorForm
      ref={ref}
      onSubmit={() => {
        const html = draftToHtml(
          convertToRaw(emailTemplate.html.getCurrentContent())
        );
        const newEmailTemplate = Object.assign({}, emailTemplate) as any;
        newEmailTemplate.html = html;
        addOrUpdateEmailTemplate(newEmailTemplate);
      }}
    >
      <AddOrUpdateModel
        open={open}
        title={Vocabulary.emailTemplate}
        onClose={onClose}
        isSubmit={true}
        addOrUpdateItem={() => null}
        handleChangePressOnSubmit={handleChangePressOnSubmit}
      >
        <AdditionalData
          openMenu={openMenu}
          anchorEl={anchorEl}
          handleClose={handleClose}
          handleClick={handleClick}
        />

        <EmailTemplateDetails
          emailTemplate={emailTemplate}
          handleChangeEmailTemplate={handleChangeEmailTemplate}
          statusEnum={statusEnum}
          handleChangeOnFocus={handleChangeOnFocus}
        />

        <div
          style={{
            border: "1px solid #f1f1f1",
            padding: 10,
          }}
        >
          <Editor
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbarClassName"
            toolbar={{
              inline: { inDropdown: true },
              list: { inDropdown: true },
              textAlign: { inDropdown: true },
              link: { inDropdown: true },
              history: { inDropdown: true },
              image: {
                uploadCallback: uploadImage,
                alt: { present: false, mandatory: false },
              },
              inputAccept: "image/gif,image/jpeg,image/jpg,image/png,image/svg",
            }}
            editorState={emailTemplate.html}
            onEditorStateChange={(e) =>
              handleChangeEmailTemplate({ ...emailTemplate, html: e })
            }
            onFocus={() => handleChangeOnFocus(Vocabulary.editor)}
          />
        </div>
      </AddOrUpdateModel>
    </ValidatorForm>
  );
}
