import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface Theme {
    header?: Palette["primary"];
    dashboard?: Palette["primary"];
    successColor?: Palette["primary"];
    errorColor?: Palette["primary"];
    textColor?: Palette["primary"];
    textColorSecondary?: Palette["primary"];
    blueColor?: Palette["primary"];
    greenColor?: Palette["primary"];
    pinkColor?: Palette["primary"];
    yellowColor?: Palette["primary"];
    orangeColor?: Palette["primary"];
    iconBlue?: Palette["primary"];
    limeColor?: Palette["primary"];
    iconDefault?: Palette["primary"];
    cancel?: Palette["primary"];
  }

  interface ThemeOptions {
    header?: Palette["primary"];
    dashboard?: Palette["primary"];
    successColor?: Palette["primary"];
    errorColor?: Palette["primary"];
    textColor?: Palette["primary"];
    textColorSecondary?: Palette["primary"];
    blueColor?: Palette["primary"];
    greenColor?: Palette["primary"];
    pinkColor?: Palette["primary"];
    yellowColor?: Palette["primary"];
    orangeColor?: Palette["primary"];
    iconBlue?: Palette["primary"];
    limeColor?: Palette["primary"];
    iconDefault?: Palette["primary"];
    cancel?: Palette["primary"];
  }

  interface Palette {
    header?: Palette["primary"];
    dashboard?: Palette["primary"];
    successColor?: Palette["primary"];
    errorColor?: Palette["primary"];
    textColor?: Palette["primary"];
    textColorSecondary?: Palette["primary"];
    blueColor?: Palette["primary"];
    greenColor?: Palette["primary"];
    pinkColor?: Palette["primary"];
    yellowColor?: Palette["primary"];
    orangeColor?: Palette["primary"];
    iconBlue?: Palette["primary"];
    limeColor?: Palette["primary"];
    iconDefault?: Palette["primary"];
    cancel?: Palette["primary"];
  }
  interface PaletteOptions {
    header?: PaletteOptions["primary"];
    dashboard?: PaletteOptions["primary"];
    successColor?: PaletteOptions["primary"];
    errorColor?: PaletteOptions["primary"];
    textColor?: PaletteOptions["primary"];
    textColorSecondary?: PaletteOptions["primary"];
    blueColor?: PaletteOptions["primary"];
    greenColor?: PaletteOptions["primary"];
    pinkColor?: PaletteOptions["primary"];
    orangeColor?: PaletteOptions["primary"];
    yellowColor?: PaletteOptions["primary"];
    iconBlue?: PaletteOptions["primary"];
    limeColor?: PaletteOptions["primary"];
    iconDefault?: PaletteOptions["primary"];
    cancel?: PaletteOptions["primary"];
  }

  interface PaletteColor {
    header?: PaletteOptions["primary"];
    dashboard?: PaletteOptions["primary"];
    successColor?: PaletteOptions["primary"];
    errorColor?: PaletteOptions["primary"];
    textColor?: PaletteOptions["primary"];
    textColorSecondary?: PaletteOptions["primary"];
    blueColor?: PaletteOptions["primary"];
    greenColor?: PaletteOptions["primary"];
    pinkColor?: PaletteOptions["primary"];
    orangeColor?: PaletteOptions["primary"];
    yellowColor?: PaletteOptions["primary"];
    iconBlue?: PaletteOptions["primary"];
    limeColor?: PaletteOptions["primary"];
    iconDefault?: PaletteOptions["primary"];
    cancel?: PaletteOptions["primary"];
  }

  interface SimplePaletteColorOptions {
    header?: PaletteOptions["primary"];
    dashboard?: PaletteOptions["primary"];
    successColor?: PaletteOptions["primary"];
    errorColor?: PaletteOptions["primary"];
    textColor?: PaletteOptions["primary"];
    textColorSecondary?: PaletteOptions["primary"];
    blueColor?: PaletteOptions["primary"];
    greenColor?: PaletteOptions["primary"];
    pinkColor?: PaletteOptions["primary"];
    orangeColor?: PaletteOptions["primary"];
    yellowColor?: PaletteOptions["primary"];
    iconBlue?: PaletteOptions["primary"];
    limeColor?: PaletteOptions["primary"];
    iconDefault?: PaletteOptions["primary"];
    cancel?: PaletteOptions["primary"];
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#189DD9",
    },
    dashboard: {
      main: "#000000",
    },
    secondary: {
      main: "#5EC0B1",
    },
    cancel: {
      main: "#D8D8D8",
    },
    header: {
      main: "#189DD9",
    },
    successColor: {
      main: "#12A152",
    },
    errorColor: {
      main: "#FF5E68",
    },
    textColor: {
      main: "black",
    },
    textColorSecondary: {
      main: "white",
    },
    iconDefault: {
      main: "#63C460",
    },
    iconBlue: {
      main: "#2196f3",
    },

    blueColor: {
      main: "#348BB9",
    },
    greenColor: {
      main: "#12A152",
    },
    limeColor: {
      main: "#B5CB09",
    },
    pinkColor: {
      main: "#f56666",
    },
    yellowColor: {
      main: "#FED049",
    },
    orangeColor: {
      main: "#ff5e00",
    },
  },
  typography: {
    fontSize: 12,
  },
  direction: "ltr",
});

export default theme;
