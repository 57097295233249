/* eslint-disable @typescript-eslint/no-explicit-any */
import { Checkbox } from "@mui/material";
import { localUrlEnum } from "../../Utils/UrlEnum";

type PackagesListProps = {
  packageId: any;
  order: string;
  trackingNumber: string;
  device: string;
  disabled: boolean;
  checked: boolean;
  viewOnly: boolean;
  handleChangeSelectedPackages: (
    selectedAll: boolean,
    id: string | null
  ) => void;
};
export default function PackagesList(props: PackagesListProps) {
  const {
    packageId,
    order,
    trackingNumber,
    device,
    disabled,
    checked,
    viewOnly,
    handleChangeSelectedPackages,
  } = props;
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {viewOnly ? null : (
        <Checkbox
          disabled={disabled}
          checked={checked}
          onChange={(event) => {
            handleChangeSelectedPackages(packageId ? false : true, packageId);
          }}
        />
      )}
      {packageId ? (
        <a
          href={`${localUrlEnum.orders}/${packageId}`}
          style={{
            flex: 1,
            textDecoration: "none",
            cursor: "pointer",
            color: "#008470",
          }}
        >
          {order}
        </a>
      ) : (
        <p style={{ flex: 1 }}>{order}</p>
      )}
      <p style={{ flex: 1 }}>{trackingNumber}</p>
      <p style={{ flex: 1 }}>{device}</p>
    </div>
  );
}
