import { Box, Divider } from "@mui/material";
import moment from "moment";
import { euFormatForDateTime } from "../../../../Utils/Config";
import styles from "../../../../Styles/purchaseOrder.module.css";
import { Vocabulary } from "../../../../Utils/Vocabulary";
import { PurchaseOrderModel } from "../../../../Utils/Models";

type PurchaseOrderGeneralInfoProps = {
  purchaseOrder: PurchaseOrderModel;
};
export default function PurchaseOrderGeneralInfo(
  props: PurchaseOrderGeneralInfoProps
) {
  const { purchaseOrder } = props;
  return (
    <Box component="fieldset" className={styles.box}>
      <div className={styles.informationDiv}>
        <p className={styles.labelP}>{Vocabulary.status}</p>
        <p className={styles.valueP}>{purchaseOrder.status}</p>
      </div>
      <Divider />
      <div className={styles.informationDiv}>
        <p className={styles.labelP}>{Vocabulary.referenceNumber}</p>
        <p className={styles.valueP}>{purchaseOrder.referenceNumber}</p>
      </div>
      <Divider />
      <div className={styles.informationDiv}>
        <p className={styles.labelP}>{Vocabulary.vendor}</p>
        <p className={styles.valueP}>{purchaseOrder.vendor?.company}</p>
      </div>
      <Divider />
      <div className={styles.informationDiv}>
        <p className={styles.labelP}>{Vocabulary.inventory}</p>
        <p className={styles.valueP}>
          {purchaseOrder.inventory?.name} &nbsp; - &nbsp;
          {purchaseOrder.inventory?.location?.name}
        </p>
      </div>
      <Divider />
      <div className={styles.informationDiv}>
        <p className={styles.labelP}>{Vocabulary.date}</p>
        <p className={styles.valueP}>
          {moment(purchaseOrder.createdAt).format(euFormatForDateTime)}
        </p>
      </div>
    </Box>
  );
}
