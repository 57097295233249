/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import styles from "../Styles/dashboard.module.css";
import { Vocabulary } from "../Utils/Vocabulary";
import { urlEnum } from "../Utils/UrlEnum";
import MainTable from "../Components/GenericComponents/MainTable";
import { useState } from "react";
import { CustomerModel } from "../Utils/Models";
import { updateData } from "../Services/updateData";
import { postData } from "../Services/postData";
import CustomerMainPage from "../Components/Customers/CustomerMainPage";
import { styleForTableWithTabs } from "./Users";

type CustomersProps = {
  title: string;
};
export default function Customers(props: CustomersProps) {
  const { title } = props;
  const [open, setOpen] = useState(false);
  const [editItem, setEditItem] = useState<CustomerModel | null>(null);
  const [shouldUpdate, setShouldUpdate] = useState(false);

  const customersHeader = [
    {
      label: Vocabulary.firstName,
      name: "firstName",
      options: { sort: false },
    },
    {
      label: Vocabulary.lastName,
      name: "lastName",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.company,
      name: "company",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.phone,
      name: "phone",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.email,
      name: "email",
      options: {
        sort: false,
      },
    },
  ];
  /**
   *
   * @param item
   */
  function handleEdit(item: CustomerModel | null) {
    setShouldUpdate(false);
    setOpen(!open);
    setEditItem(item);
  }
  /**
   *
   * @param step
   */
  function addOrUpdateCustomer(customer: CustomerModel | null) {
    setShouldUpdate(true);
    if (customer?._id) {
      updateData(`${urlEnum.customers}/${customer._id}`, customer)
        .then((res) => {
          if (res) {
            setOpen(false);
          }
        })
        .catch((err) => {
          setOpen(false);
        });
    } else {
      postData(urlEnum.customers, customer)
        .then((res: any) => {
          if (res) {
            setOpen(false);
          }
        })
        .catch((err) => {
          setOpen(false);
        });
    }
  }

  return (
    <div className={styles.mainContainerUser}>
      <MainTable
        urlEnumApi={`${urlEnum.customers}`}
        titleDelete={Vocabulary.deleteCustomer}
        textDelete={Vocabulary.deleteConfirmationCustomer}
        header={customersHeader}
        tableTitle={title}
        shouldUpdate={shouldUpdate}
        handleEdit={handleEdit}
        style={styleForTableWithTabs}
      />
      {open ? (
        <CustomerMainPage
          editCustomer={editItem}
          open={open}
          addOrUpdateCustomer={(customer) => addOrUpdateCustomer(customer)}
          onClose={() => handleEdit(null)}
        />
      ) : null}
    </div>
  );
}
