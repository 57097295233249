/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
import { Box, Button, Divider } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { Add } from "@mui/icons-material";
import OneOption from "./OneOption";
import { VariantProps } from "./ProductVariants";
import { AttributeModel } from "../../Utils/Models";

type ProductOptionsProps = {
  options: AttributeModel[];
  price: string | null;
  handleChangeOptions: (
    options: AttributeModel[],
    variants?: Array<VariantProps>
  ) => void;
};

export default function ProductOptions(props: ProductOptionsProps) {
  const { options, price, handleChangeOptions } = props;

  /**
   *
   * @param options
   * @param current
   * @param optionIndex
   * @param result
   * @returns
   */
  function generate(
    options: AttributeModel[],
    current: VariantProps,
    optionIndex: number,
    result: VariantProps[]
  ) {
    if (optionIndex === options.length) {
      result.push(current);
      return;
    }

    const option = options[optionIndex];
    for (const value of option.values) {
      generate(
        options,
        { ...current, [`option${optionIndex + 1}`]: value },
        optionIndex + 1,
        result
      );
    }
  }
  /**
   *
   * @param options
   * @returns
   */
  function generateVariants(options: AttributeModel[]): Array<VariantProps> {
    const result: Array<VariantProps> = [];

    generate(
      options,
      {
        price: price ? parseFloat(price) : 0,
        inventory_quantity: 0,
        images: null,
        mustBeDeleted: false,
        sku: "",
      },
      0,
      result
    );

    return result;
  }

  /**
   *
   * @param index
   * @param option
   */
  function handleChangeOption(
    index: number,
    option: AttributeModel,
    recalculateVariants: boolean
  ) {
    let newOptions = options.slice();
    newOptions[index] = option;
    if (recalculateVariants) {
      newOptions = newOptions.map((opt) => {
        if (opt.values && Array.isArray(opt.values)) {
          return {
            ...opt,
            values: opt.values.filter((value) => value !== ""),
          };
        }
        return opt;
      });
      const variants =
        newOptions.length === 0 ? [] : generateVariants(newOptions);
      handleChangeOptions(newOptions, variants);
    } else {
      handleChangeOptions(newOptions);
    }
  }

  /**
   *
   * @param index
   */
  function handleRemoveOption(index: number, recalculateVariants: boolean) {
    const newOptions = options.slice();
    newOptions.splice(index, 1);
    if (recalculateVariants) {
      const variants =
        newOptions.length === 0 ? [] : generateVariants(newOptions);
      handleChangeOptions(newOptions, variants);
    } else {
      handleChangeOptions(newOptions);
    }
  }
  return (
    <Box
      component="fieldset"
      style={{ border: "1px solid #ccc", borderRadius: 4, margin: 10 }}
    >
      <legend style={{ color: "#999999" }}>{Vocabulary.options}</legend>
      {options.length === 0 ? (
        <p style={{ color: "#999999" }}>{Vocabulary.thereIsNoOptions}</p>
      ) : (
        <>
          {options.map((option, index) => (
            <div key={`${option.name}_${index}`}>
              <OneOption
                option={option}
                index={index}
                handleRemoveOption={handleRemoveOption}
                handleChangeOption={handleChangeOption}
              />
              {options.length !== index + 1 ? <Divider /> : null}
            </div>
          ))}
        </>
      )}
      <Divider />
      <Button
        variant="text"
        color="primary"
        onClick={() => {
          const newOptions = options.slice();
          newOptions.push({
            name: "",
            values: [""],
          });
          handleChangeOptions(newOptions, []);
        }}
        disabled={
          options.find(
            (option) =>
              option.name === "" &&
              option.values.length === 1 &&
              option.values[0] === ""
          )
            ? true
            : false
        }
        style={{ padding: "6px 0px 0px 0px" }}
      >
        <Add style={{ marginRight: 10 }} fontSize="small" />
        {Vocabulary.addOptions}
      </Button>
    </Box>
  );
}
