/* eslint-disable @typescript-eslint/no-explicit-any */
import TechnicianReports from "./TechnicianReports";
import { useEffect, useState } from "react";
import { getData } from "../../Services/getData";
import { urlEnum } from "../../Utils/UrlEnum";
import { FiltersProps } from "../../Pages/Reports/Reports";
import moment from "moment";
import { Vocabulary } from "../../Utils/Vocabulary";
import { displayTimeString } from "../../Utils/Utils";
import WorkflowReportHeader from "./WorkflowReportHeader";
import CircleIcon from "@mui/icons-material/Circle";
import {
  dayPilotFormat,
  euFormatForDateTime,
  numberOfDigits,
} from "../../Utils/Config";

type WorkflowReportProps = {
  workflowId: string;
  filters: FiltersProps;
};
export type LocalFilter = {
  page: number;
  perPage: number;
};
export type WorkflowStepsReportModel = {
  stepId: string;
  stepName: string;
  userName: string;
  averageTrackedTime: number;
  averageTrackedPercent: number;
  predefinedTime: number;
  predictedPercent: number;
  numberOfSteps: number;
  date: Date;
};

export type WorkflowReportModel = {
  workflowId: string;
  workflowName: string;
  predefinedTime: number;
  averageTrackedTime: number;
  averageTimeFactor: number;
  stepsNumber: number;
  steps: Array<WorkflowStepsReportModel>;
};

export default function WorkflowReport(props: WorkflowReportProps) {
  const { workflowId, filters } = props;
  const [workflowsReports, setWorkflowsReports] =
    useState<WorkflowReportModel | null>(null);
  const [timeGraph, setTimeGraph] = useState<any>(null);
  const [localFilters, setLocalFilters] = useState<LocalFilter>({
    page: 0,
    perPage: 10,
  });

  const tableHeader = [
    {
      label: Vocabulary.stepName,
      name: "stepName",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.numberOfSteps,
      name: "numberOfSteps",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.averageTrackedTime,
      name: "averageTrackedTime",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          const durationFormatted = displayTimeString(value);
          return <>{durationFormatted}</>;
        },
      },
    },
    {
      label: Vocabulary.averageTrackedPercent,
      name: "averageTrackedPercent",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return (
            <div style={{ display: "flex", alignItems: "center" }}>
              <span>{value.toFixed(numberOfDigits)}%</span>
              <CircleIcon
                style={{
                  marginLeft: 10,
                  verticalAlign: "middle",
                  color:
                    value >= 100
                      ? value === 100
                        ? "#ADD8E6"
                        : "#FF7F7F"
                      : "#CFFDBC",
                }}
              />
            </div>
          );
        },
      },
    },
    {
      label: Vocabulary.predefinedTime,
      name: "predefinedTime",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          const durationFormatted = displayTimeString(value);
          return <>{durationFormatted}</>;
        },
      },
    },
    {
      label: Vocabulary.predictedPercent,
      name: "predictedPercent",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return <>{value.toFixed(numberOfDigits)}%</>;
        },
      },
    },
    {
      label: Vocabulary.date,
      name: "date",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return <>{moment(value).format(euFormatForDateTime)}</>;
        },
      },
    },
  ];

  useEffect(() => {
    getWorkflowsReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowId, localFilters, filters]);

  /**
   *
   */
  function getWorkflowsReports() {
    const url = `${urlEnum.workflowsReports}/${localFilters.page}/${
      localFilters.perPage
    }/${workflowId}/${moment(filters.startDate).format(
      dayPilotFormat
    )}/${moment(filters.endDate).format(dayPilotFormat)}`;
    getData(url).then((response) => {
      if (
        response &&
        response.data &&
        (response.data.status === 200 || response.data.status === 201)
      ) {
        setWorkflowsReports(response.data.result[0]);
        const time = response.data.timeGraph.map((item: any) => ({
          percent: item.percent,
          time: parseFloat((item.time / 60).toFixed(2)),
          name: item.name,
        }));
        setTimeGraph(time);
      }
    });
  }

  /**
   *
   */
  function handleChangeLocalFilters(newFilters: LocalFilter) {
    setLocalFilters(newFilters);
  }

  return (
    <>
      {workflowsReports ? (
        <div style={{ marginBottom: 20 }}>
          <WorkflowReportHeader
            report={workflowsReports}
            timeGraph={timeGraph}
          />
          <TechnicianReports
            tableHeader={tableHeader}
            items={workflowsReports.steps || []}
            count={workflowsReports.stepsNumber || 0}
            handleChangeLocalFilters={handleChangeLocalFilters}
            filters={localFilters}
            withStyles={false}
          />
        </div>
      ) : null}
    </>
  );
}
