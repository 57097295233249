/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { getData } from "../../Services/getData";
import { urlEnum } from "../../Utils/UrlEnum";
import { Vocabulary } from "../../Utils/Vocabulary";

type ProductsAutocompleteProps = {
  parentProduct: any;
  handleChangeProductInParent: (event: any, value: any) => void;
  variant?: "standard" | "outlined" | "filled";
  disabled?: boolean;
};
export default function ProductsAutocomplete(props: ProductsAutocompleteProps) {
  const { parentProduct, handleChangeProductInParent, variant, disabled } =
    props;
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState<string>("");

  useEffect(() => {
    getProducts(null);
  }, []);

  /**
   *
   */
  function getProducts(search: string | null) {
    getData(`${urlEnum.products}/search/${search}`).then((res: any) => {
      if (res.data.status === 200) setProducts(res.data.result);
    });
  }

  /**
   *
   * @param event
   * @param value
   */
  function handleChangeProduct(event: any, value: any) {
    handleChangeProductInParent(event, value);
    setProduct("");
    getProducts(null);
  }
  return (
    <>
      <Autocomplete
        size="small"
        id="product"
        freeSolo={false}
        disablePortal
        disabled={disabled ? disabled : false}
        getOptionLabel={(option: any) => {
          return option.name
            ? `${option.code} - ${
                option.name.length > 20
                  ? `${option.name.substring(0, 20)}...`
                  : option.name
              }`
            : option.code;
        }}
        isOptionEqualToValue={(option, value) => option._id === value._id}
        options={products}
        value={
          (parentProduct ? parentProduct : { _id: "", code: product }) || null
        }
        onChange={(event: any, value: any) => {
          handleChangeProduct(event, value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name="product"
            label={Vocabulary.product}
            variant={variant ? variant : "standard"}
            onChange={(event: any) => {
              setProduct(event.target.value);
              getProducts(
                event.target.value === "" ? null : event.target.value
              );
            }}
          />
        )}
      />
    </>
  );
}
