/* eslint-disable @typescript-eslint/no-explicit-any */
import { ThemeProvider } from "@emotion/react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { WebsocketProvider } from "../Contexts/WebsocketContext";
import theme from "../Themes/Theme";
import Loading from "../Utils/Loading";
import { OrderContextProvider } from "../Contexts/OrderContext";
import { ToastContainer } from "react-toastify";
import { routes } from "../Utils/Constants";
import { LanguageProvider } from "../Contexts/LanguageContext";
import "react-toastify/dist/ReactToastify.css";

function Router() {
  return (
    <ThemeProvider theme={theme}>
      <ToastContainer
        hideProgressBar={false}
        closeButton={true}
        closeOnClick={true}
        style={{ margin: "10px", width: "auto" }}
      />
      <LanguageProvider>
        <WebsocketProvider>
          <OrderContextProvider>
            <RouterProvider
              router={createBrowserRouter(routes)}
            ></RouterProvider>
            <Loading />
          </OrderContextProvider>
        </WebsocketProvider>
      </LanguageProvider>
    </ThemeProvider>
  );
}
export default Router;
