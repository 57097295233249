/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, TextField } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import GenericModal from "../GenericComponents/GenericModal";
import { EditModalProps } from "./ProductVariants";
import { additionalInformationForVariants } from "../../Utils/AutocompleteUtils";

type EditMultipleVariantsProps = {
  editModal: EditModalProps;
  handleEditModal: (editModal: EditModalProps) => void;
  handleEditVariantInformation: (type: string, value: string) => void;
};

export default function EditMultipleVariants(props: EditMultipleVariantsProps) {
  const { editModal, handleEditModal, handleEditVariantInformation } = props;
  return (
    <GenericModal
      open={editModal.openModal}
      onClose={() => handleEditModal({ openModal: false, type: "", value: "" })}
      title={
        editModal.type === additionalInformationForVariants.price
          ? Vocabulary.editPrice
          : editModal.type ===
            additionalInformationForVariants.inventory_quantity
          ? Vocabulary.editQuantity
          : ""
      }
      actions={
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            padding: 20,
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            style={{ color: "white" }}
            onClick={() =>
              handleEditModal({ openModal: false, type: "", value: "" })
            }
          >
            {Vocabulary.no}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleEditModal({ openModal: false, type: "", value: "" });
              handleEditVariantInformation(editModal.type, editModal.value);
            }}
          >
            {Vocabulary.yes}
          </Button>
        </div>
      }
      style={{ zIndex: 1500, textAlign: "center" }}
    >
      <TextField
        type="number"
        size="small"
        name={editModal.type}
        label={
          editModal.type === additionalInformationForVariants.price
            ? Vocabulary.editPrice
            : editModal.type ===
              additionalInformationForVariants.inventory_quantity
            ? Vocabulary.editQuantity
            : ""
        }
        value={editModal.value}
        onChange={(e: any) => {
          handleEditModal({ ...editModal, value: e.target.value });
        }}
        fullWidth
        style={{ marginTop: 10 }}
      />
    </GenericModal>
  );
}
