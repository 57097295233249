/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Autocomplete,
  Checkbox,
  Chip,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { WorkflowModel } from "../../Utils/Models";
import { Vocabulary } from "../../Utils/Vocabulary";
import { TextValidator } from "react-material-ui-form-validator";
import {
  handleChangeAutocomplete,
  onChangeCheckbox,
  onChangeTextField,
} from "../../Utils/Utils";
import useCustomMemo from "../GenericComponents/useCustomMemo";

type WorkflowProps = {
  workflow: WorkflowModel;
  subWorkflows: WorkflowModel[];
  workflows: WorkflowModel[];
  handleChangeWorkflow: (workflow: WorkflowModel) => void;
  changeSubWorkflows: (
    event: any,
    value: any,
    parentId: string | null,
    subWorkflows: any
  ) => void;
};
export default function Workflow(props: WorkflowProps) {
  const {
    workflow,
    workflows,
    subWorkflows,
    handleChangeWorkflow,
    changeSubWorkflows,
  } = props;
  const cache = useCustomMemo();
  const statuses = setStatuses();
  const phoneStyle = useMediaQuery("(min-width: 381px) and (max-width: 450px)");
  const miniPhoneStyle = useMediaQuery("(max-width: 380px)");

  /**
   *
   * @returns
   */
  function setStatuses() {
    const workflowStatuses = cache.statuses.filter(
      (status: any) => status.workflowId._id === workflow._id
    );
    if (workflowStatuses.length === 0) {
      const parentWorkflowStatuses = cache.workflows.find(
        (w: any) => w._id === workflow._id
      );
      if (parentWorkflowStatuses)
        return cache.statuses.filter(
          (status: any) =>
            status.workflowId._id === parentWorkflowStatuses.parentId
        );
    } else {
      return workflowStatuses;
    }
    return [];
  }
  return (
    <Grid container spacing={2} style={{ marginTop: 10 }}>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          variant="outlined"
          label={Vocabulary.name}
          value={workflow.name || ""}
          fullWidth
          size="small"
          id={"name"}
          name="name"
          // InputProps={{
          //   readOnly: workflow?.fixablyId ? true : false,
          // }}
          onChange={(event) =>
            handleChangeWorkflow(onChangeTextField(event, workflow))
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          variant="outlined"
          label={Vocabulary.description}
          value={workflow.description || ""}
          fullWidth
          size="small"
          id={"description"}
          name="description"
          // InputProps={{
          //   readOnly: workflow?.fixablyId ? true : false,
          // }}
          onChange={(event) =>
            handleChangeWorkflow(onChangeTextField(event, workflow))
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Autocomplete
          size="small"
          id={"statuses"}
          multiple
          freeSolo={false}
          disablePortal
          options={statuses}
          value={workflow.statuses || []}
          getOptionLabel={(option: any) => option.name}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          getOptionDisabled={(option) =>
            !!(
              workflow.statuses &&
              workflow.statuses.find(
                (element: any) => element.name === option.name
              )
            )
          }
          // readOnly={workflow?.fixablyId ? true : false}
          style={
            phoneStyle
              ? { maxWidth: "330px" }
              : miniPhoneStyle
              ? { maxWidth: "300px" }
              : {}
          }
          onChange={(event: any, value: any) =>
            handleChangeWorkflow(
              handleChangeAutocomplete(event, value, workflow, "statuses")
            )
          }
          renderInput={(params: any) => (
            <TextField
              {...params}
              fullWidth
              size="small"
              label={Vocabulary.statuses}
              variant="outlined"
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Checkbox
          // disabled={workflow?.fixablyId ? true : false}
          name="enabled"
          checked={workflow.enabled || false}
          onChange={(event: any) =>
            handleChangeWorkflow(onChangeCheckbox(event, workflow))
          }
          style={{ color: "#189DD9" }}
        ></Checkbox>
        {Vocabulary.enabled}
      </Grid>

      {/* parent for subWorkflow */}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {workflow?.parentId ? (
          <>
            <Typography variant="h6">{Vocabulary.workflowParent}</Typography>
            <Chip
              label={
                workflows.find(
                  (element: any) => element._id === workflow?.parentId
                )?.name
              }
              color="primary"
              style={{
                fontSize: 16,
              }}
            />
            <br />
          </>
        ) : null}
      </Grid>
      {/* end parent for subWorkflow */}

      {/* subWorkflows */}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {workflow?._id ? (
          <>
            <Typography variant="h6" style={{ marginLeft: 5 }}>
              {Vocabulary.selectSubWorkflows}:
            </Typography>
            <Autocomplete
              size="small"
              id={"subWorkflows"}
              multiple
              freeSolo={false}
              disablePortal
              options={workflows}
              value={subWorkflows || []}
              getOptionLabel={(option: any) => option.name}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              getOptionDisabled={(option) =>
                !!(
                  subWorkflows &&
                  [...subWorkflows, workflow].find(
                    (element: any) => element._id === option._id
                  )
                )
              }
              // readOnly={workflow?.fixablyId ? true : false}
              onChange={(event: any, value: any) =>
                changeSubWorkflows(
                  event,
                  value,
                  workflow?._id || null,
                  subWorkflows
                )
              }
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  fullWidth
                  size="small"
                  label={Vocabulary.subWorkflows}
                  variant="outlined"
                />
              )}
            />
          </>
        ) : null}
      </Grid>
      {/* end subWorkflows */}
    </Grid>
  );
}
