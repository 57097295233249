/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import MUIDataTable from "mui-datatables";
import { Vocabulary } from "../../Utils/Vocabulary";
import Config, {
  euFormatForDateTime,
  numberOfDigits,
} from "../../Utils/Config";
import { displayTimeString } from "../../Utils/Utils";
import moment from "moment";
import { createTheme } from "@mui/material";
import { ThemeProvider } from "@emotion/react";

type StepsReportsForOrderProps = {
  reportSteps: any;
};
export default function StepsReportsForOrder(props: StepsReportsForOrderProps) {
  const { reportSteps } = props;

  const theme = createTheme({
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: "6px 10px 6px 10px !important",
            fontSize: "13px",
          },
        },
      },
    },
  });
  const tableHeader = [
    {
      label: Vocabulary.stepName,
      name: "stepName",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.userName,
      name: "userName",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.trackedTime,
      name: "trackedTime",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          const durationFormatted = displayTimeString(value);
          return <>{durationFormatted}</>;
        },
      },
    },
    {
      label: Vocabulary.trackedPercent,
      name: "trackedPercent",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return <>{value}%</>;
        },
      },
    },
    {
      label: Vocabulary.predefinedTime,
      name: "predefinedTime",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          const durationFormatted = displayTimeString(value);
          return <>{durationFormatted}</>;
        },
      },
    },
    {
      label: Vocabulary.cost,
      name: "cost",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return <>{value.toFixed(numberOfDigits)}</>;
        },
      },
    },
    {
      label: Vocabulary.date,
      name: "date",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return <>{moment(value).format(euFormatForDateTime)}</>;
        },
      },
    },
  ];
  /**
   *
   * @returns
   */
  const getTableOptions = () => {
    const responsive: "standard" | "vertical" | "simple" | undefined =
      "standard";
    return {
      selectableRows: "none" as any,
      viewColumns: false as any,
      responsive: responsive,
      rowsPerPageOptions: Config.rowsPerPage,
      confirmFilters: false,
      filter: false,
      search: false,
      download: false,
      print: false,
      expandableRows: false,
      expandableRowsHeader: false,
      textLabels: {
        body: {
          noMatch: Vocabulary.noResultsFound,
        },
      },
      serverSide: true,
      customFooter: () => null,
      setRowProps: (row: any, dataIndex: any, rowIndex: any) => {
        if (
          reportSteps[rowIndex].trackedTime &&
          reportSteps[rowIndex].predefinedTime
        ) {
          if (
            reportSteps[rowIndex].trackedTime >
            reportSteps[rowIndex].predefinedTime
          )
            return {
              style: {
                backgroundColor: "#fbb4ae",
              },
            };
        }
        return {
          style: {},
        };
      },
    };
  };
  return (
    <ThemeProvider theme={theme}>
      <MUIDataTable
        title={""}
        data={reportSteps}
        columns={tableHeader}
        options={getTableOptions()}
      />
    </ThemeProvider>
  );
}
