/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid, Paper } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { displayTimeString } from "../../Utils/Utils";
// import HorizontalGraphicBar from "../GenericComponents/HorizontalGraphicBar";
import styles from "../../Styles/reports.module.css";
import { numberOfDigits } from "../../Utils/Config";

type ReportHeaderProps = {
  report: any;
  timeGraph: any;
};
export default function WorkflowReportHeader(props: ReportHeaderProps) {
  const { report } = props;
  return (
    <>
      <Paper className={styles.reportsHeader}>
        <div style={{ color: "#008470", fontWeight: 600 }}>
          {Vocabulary.workflow}: {report.workflowName}
        </div>
      </Paper>
      <Paper className={styles.reportsPaperWithTotals}>
        <Grid container spacing={1} style={{ margin: 0, width: "100%" }}>
          <Grid
            item
            xs={12}
            md={4}
            lg={4}
            textAlign="center"
            style={{
              borderLeft: "1px solid #f3f3f3",
            }}
            className={styles.reportsTotalsGridItems}
          >
            <div className={styles.reportsTotalsItemContainer}>
              <div className={styles.reportsTotalsItemLabel}>
                {Vocabulary.predefinedTime}
              </div>
              <div className={styles.reportsTotalsItemValue}>
                {displayTimeString(report.predefinedTime)}
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            lg={4}
            textAlign="center"
            className={styles.reportsTotalsGridItems}
          >
            <div className={styles.reportsTotalsItemContainer}>
              <div className={styles.reportsTotalsItemLabel}>
                {Vocabulary.averageTrackedTime}
              </div>
              <div className={styles.reportsTotalsItemValue}>
                {displayTimeString(report.averageTrackedTime)}
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            lg={4}
            textAlign="center"
            className={styles.reportsTotalsGridItems}
          >
            <div className={styles.reportsTotalsItemContainer}>
              <div className={styles.reportsTotalsItemLabel}>
                {Vocabulary.averageTimeFactor}
              </div>
              <div className={styles.reportsTotalsItemValue}>
                {report.averageTimeFactor.toFixed(numberOfDigits)}%
              </div>
            </div>
          </Grid>
          {/* <Grid item xs={12} md={6} lg={6}>
            <HorizontalGraphicBar
              title={`${Vocabulary.averageTrackedTime} (minutes)`}
              keys={[{ dataKey: "time" }]}
              data={timeGraph}
            />
          </Grid> */}
        </Grid>
      </Paper>
    </>
  );
}
