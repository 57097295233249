/* eslint-disable @typescript-eslint/no-explicit-any */
import { NotesModel } from "../../Utils/Models";
import { useEffect, useRef, useState } from "react";
import AddOrUpdateModel from "../GenericComponents/AddOrUpdateModel";
import { Vocabulary } from "../../Utils/Vocabulary";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Autocomplete, Button, Checkbox, Grid, TextField } from "@mui/material";
import {
  handleChangeAutocomplete,
  onChangeCheckboxWithNumbers,
  onChangeTextField,
} from "../../Utils/Utils";
import useCustomMemo from "../GenericComponents/useCustomMemo";
import {
  notesTypeArray,
  notesVisibilityArray,
} from "../../Utils/AutocompleteUtils";

type NoteProps = {
  editNote: NotesModel | null;
  open: boolean;
  addOrUpdateNote: (note: NotesModel | null) => void;
  onClose: () => void;
  isFromOrder?: boolean;
};
export default function Note(props: NoteProps) {
  const { editNote, open, isFromOrder, addOrUpdateNote, onClose } = props;
  const ref: any = useRef();
  const [note, setNote] = useState<NotesModel>(new NotesModel());
  const [pressOnSubmit, setPressOnSubmit] = useState(false);
  const cache = useCustomMemo();
  const users = cache.users || [];
  const buttons = (
    <Button
      onClick={resetNote}
      variant="contained"
      style={{ marginBottom: 10, marginTop: 10, color: "white" }}
      fullWidth
      color="secondary"
    >
      {Vocabulary.reset}
    </Button>
  );

  /**
   *
   */
  useEffect(() => {
    if (editNote) {
      setNote(editNote);
    } else {
      setNote(new NotesModel());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editNote]);

  /**
   *
   */
  useEffect(() => {
    if (pressOnSubmit) {
      ref.current.submit();
      //reset the value
      handleChangePressOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressOnSubmit]);

  /**
   *
   */
  function handleChangePressOnSubmit() {
    setPressOnSubmit(!pressOnSubmit);
  }
  /**
   *
   */
  function resetNote() {
    if (editNote) {
      setNote(editNote);
    } else {
      setNote(new NotesModel());
    }
  }
  return (
    <ValidatorForm ref={ref} onSubmit={() => addOrUpdateNote(note)}>
      <AddOrUpdateModel
        open={open}
        title={Vocabulary.note}
        onClose={onClose}
        isSubmit={true}
        addOrUpdateItem={() => null}
        handleChangePressOnSubmit={handleChangePressOnSubmit}
        buttons={buttons ? buttons : null}
        saveButtonTitle={
          isFromOrder
            ? editNote
              ? Vocabulary.saveToOrder
              : Vocabulary.addToOrder
            : null
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Autocomplete
              size="small"
              id="type"
              freeSolo={false}
              disablePortal
              getOptionLabel={(option: any) => option}
              isOptionEqualToValue={(option, value) => option === value}
              options={notesTypeArray}
              value={note.type}
              onChange={(event: any, value: any) => {
                setNote(handleChangeAutocomplete(event, value, note, "type"));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="type"
                  label={Vocabulary.type}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextValidator
              size="small"
              id="title"
              name="title"
              label={Vocabulary.title}
              fullWidth
              variant="outlined"
              value={note.title}
              onChange={(event: any) => setNote(onChangeTextField(event, note))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextValidator
              size="small"
              id="text"
              name="text"
              label={Vocabulary.text}
              fullWidth
              variant="outlined"
              value={note.text}
              onChange={(event: any) => setNote(onChangeTextField(event, note))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Autocomplete
              size="small"
              id="visibility"
              freeSolo={false}
              disablePortal
              getOptionLabel={(option: any) => option.label || option}
              isOptionEqualToValue={(option, value) =>
                option.label === value.label || option === value
              }
              options={notesVisibilityArray}
              value={
                note.visibility
                  ? note.visibility?.name
                    ? note.visibility
                    : {
                        label: Vocabulary[note.visibility],
                        name: note.visibility,
                      }
                  : null
              }
              onChange={(event: any, value: any) => {
                setNote(
                  handleChangeAutocomplete(event, value, note, "visibility")
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="visibility"
                  label={Vocabulary.visibility}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextValidator
              size="small"
              id="status"
              name="status"
              type="number"
              label={Vocabulary.status}
              fullWidth
              variant="outlined"
              value={note.status}
              onChange={(event: any) => setNote(onChangeTextField(event, note))}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Autocomplete
              size="small"
              id="createdBy"
              freeSolo={false}
              disablePortal
              getOptionLabel={(option: any) => option.username}
              isOptionEqualToValue={(option, value) =>
                option.username === value.username
              }
              options={users}
              value={note.createdBy}
              onChange={(event: any, value: any) => {
                setNote(
                  handleChangeAutocomplete(event, value, note, "createdBy")
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="createdBy"
                  label={Vocabulary.createdBy}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div style={{ display: "flex" }}>
              <Checkbox
                name="report"
                style={{
                  margin: "13px 0px",
                  padding: 0,
                }}
                checked={note.report === 1 ? true : false}
                onChange={(event: any) =>
                  setNote(onChangeCheckboxWithNumbers(event, note))
                }
              />
              <p style={{ margin: "15px 15px 0px 15px" }}>
                {Vocabulary.report}
              </p>
            </div>
            <p>{Vocabulary.noteReportMessage}</p>
          </Grid>
        </Grid>
      </AddOrUpdateModel>
    </ValidatorForm>
  );
}
