/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import {
  Box,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { NotesModel } from "../../Utils/Models";
import DisplayOneNote, { iconsForNotes } from "./DisplayOneNote";
import moment from "moment";
import { defaultNote_OrderCreated } from "../../Utils/AutocompleteUtils";
import { OrderContext } from "../../Contexts/OrderContext";
import { useContext } from "react";
import { euFormatForDateTime } from "../../Utils/Config";

type OrderNotesProps = {
  handleEdit: (note: NotesModel, index: number) => void;
  handleChangeOpenSendEmailValue: (value: any) => void;
};
export default function OrderNotes(props: OrderNotesProps) {
  const { handleEdit, handleChangeOpenSendEmailValue } = props;
  const orderContext = useContext(OrderContext);
  return (
    <div>
      <Stepper orientation="vertical">
        {orderContext.order.notes.map((note, index) => {
          return (
            <DisplayOneNote
              key={note?._id}
              note={note}
              index={index}
              handleEdit={handleEdit}
              handleChangeOpenSendEmailValue={handleChangeOpenSendEmailValue}
            />
          );
        })}

        {/* Notes for order created -> this note is not save in database */}
        <Step active={true}>
          <StepLabel
            StepIconComponent={() => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p>
                  {moment(orderContext.order.createdAt || new Date()).format(
                    euFormatForDateTime
                  )}
                </p>
                <span
                  style={{
                    verticalAlign: "middle",
                    textAlign: "center",
                    borderRadius: "100%",
                    padding: 7,
                    color: "white",
                    backgroundColor:
                      iconsForNotes[defaultNote_OrderCreated.type || ""]
                        ?.backgroundColor,
                    marginLeft: 10,
                  }}
                >
                  <span>
                    {iconsForNotes[defaultNote_OrderCreated.type || ""]?.icon}
                  </span>
                </span>
              </div>
            )}
          ></StepLabel>
          <StepContent>
            <Box
              style={{
                marginLeft: 70,
                padding: 20,
                border: "1px solid #eeeeee",
                boxShadow: "0 0 10px #f0f0f0",
                borderRadius: 20,
                position: "relative",
              }}
            >
              <Typography variant="h6">
                {defaultNote_OrderCreated.title}
              </Typography>
            </Box>
          </StepContent>
        </Step>
      </Stepper>
    </div>
  );
}
