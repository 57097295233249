/* eslint-disable @typescript-eslint/no-explicit-any */
import { Vocabulary } from "../../Utils/Vocabulary";
import { CustomerModel, TaxRateModel } from "../../Utils/Models";
import { TextValidator } from "react-material-ui-form-validator";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import {
  handleChangeAutocomplete,
  onChangeCheckbox,
  onChangeTextField,
} from "../../Utils/Utils";
import useCustomMemo from "../GenericComponents/useCustomMemo";

type CustomerProps = {
  customer: CustomerModel;
  arraysOfString: any;
  handleChangeArraysOfString: (arraysOfString: any) => void;
  handleChangeCustomer: (product: CustomerModel) => void;
};
export default function Customer(props: CustomerProps) {
  const {
    customer,
    arraysOfString,
    handleChangeCustomer,
    handleChangeArraysOfString,
  } = props;
  const cache = useCustomMemo();
  const taxRates = cache.taxRates || [];
  const roles = cache.roles || [];

  return (
    <Grid container spacing={2} style={{ marginTop: 10 }}>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="firstName"
          name="firstName"
          label={Vocabulary.firstName}
          fullWidth
          variant="outlined"
          value={customer.firstName}
          onChange={(event: any) =>
            handleChangeCustomer(onChangeTextField(event, customer))
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="lastName"
          name="lastName"
          label={Vocabulary.lastName}
          fullWidth
          variant="outlined"
          value={customer.lastName}
          onChange={(event: any) =>
            handleChangeCustomer(onChangeTextField(event, customer))
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="company"
          name="company"
          label={Vocabulary.company}
          fullWidth
          variant="outlined"
          value={customer.company || ""}
          onChange={(event: any) =>
            handleChangeCustomer(onChangeTextField(event, customer))
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="phone"
          name="phone"
          label={Vocabulary.phone}
          fullWidth
          variant="outlined"
          value={customer.phone}
          onChange={(event: any) =>
            handleChangeCustomer(onChangeTextField(event, customer))
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="email"
          name="email"
          label={Vocabulary.email}
          fullWidth
          variant="outlined"
          value={customer.email}
          onChange={(event: any) =>
            handleChangeCustomer(onChangeTextField(event, customer))
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="businessId"
          name="businessId"
          label={Vocabulary.business}
          fullWidth
          variant="outlined"
          value={customer.businessId || ""}
          onChange={(event: any) =>
            handleChangeCustomer(onChangeTextField(event, customer))
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <FormControl fullWidth>
          <InputLabel>{Vocabulary.vatNumber}</InputLabel>
          <Select
            size="small"
            id="vatNumber"
            value={customer.vatNumber || ""}
            label={Vocabulary.vatNumber}
            fullWidth
            onChange={(event: SelectChangeEvent) => {
              handleChangeCustomer({
                ...customer,
                vatNumber: event.target.value,
              });
            }}
          >
            {taxRates.map((taxRate: TaxRateModel) => {
              return <MenuItem value={taxRate.value}>{taxRate.value}</MenuItem>;
            })}
            {!taxRates.some(
              (taxRate: TaxRateModel) => taxRate.value === customer.vatNumber
            ) &&
              customer.vatNumber && (
                <MenuItem disabled value={customer.vatNumber}>
                  {customer.vatNumber}
                </MenuItem>
              )}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="group"
          name="group"
          label={Vocabulary.group}
          fullWidth
          variant="outlined"
          value={customer.group || ""}
          onChange={(event: any) =>
            handleChangeCustomer(onChangeTextField(event, customer))
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="language"
          name="language"
          label={Vocabulary.language}
          fullWidth
          variant="outlined"
          value={customer.language || ""}
          onChange={(event: any) =>
            handleChangeCustomer(onChangeTextField(event, customer))
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="parent"
          name="parent"
          label={Vocabulary.parent}
          fullWidth
          variant="outlined"
          value={customer.parent || ""}
          onChange={(event: any) =>
            handleChangeCustomer(onChangeTextField(event, customer))
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="identifier"
          name="identifier"
          label={Vocabulary.identifier}
          fullWidth
          variant="outlined"
          value={customer.identifier || ""}
          onChange={(event: any) =>
            handleChangeCustomer(onChangeTextField(event, customer))
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="provider"
          name="provider"
          label={Vocabulary.provider}
          fullWidth
          variant="outlined"
          value={customer.provider || ""}
          onChange={(event: any) =>
            handleChangeCustomer(onChangeTextField(event, customer))
          }
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="priority"
          name="priority"
          type="number"
          label={Vocabulary.priority}
          fullWidth
          variant="outlined"
          value={customer.priority}
          onChange={(event: any) =>
            handleChangeCustomer(onChangeTextField(event, customer))
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="notes"
          name="notes"
          label={Vocabulary.notes}
          fullWidth
          variant="outlined"
          value={customer.notes}
          onChange={(event: any) =>
            handleChangeCustomer(onChangeTextField(event, customer))
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="defaultPaymentTerm"
          name="defaultPaymentTerm"
          label={Vocabulary.defaultPaymentTerm}
          fullWidth
          variant="outlined"
          value={customer.defaultPaymentTerm || ""}
          onChange={(event: any) =>
            handleChangeCustomer(onChangeTextField(event, customer))
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Autocomplete
          size="small"
          id="roles"
          multiple
          freeSolo={false}
          options={roles}
          value={customer.roles || []}
          getOptionLabel={(option: any) => option.name}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          getOptionDisabled={(option) =>
            !!customer.roles.find(
              (element: any) => element.name === option.name
            )
          }
          onChange={(event: any, value: any) =>
            handleChangeCustomer(
              handleChangeAutocomplete(event, value, customer, "roles")
            )
          }
          renderInput={(params: any) => (
            <TextField
              {...params}
              fullWidth
              label={Vocabulary.roles}
              variant="outlined"
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Autocomplete
          size="small"
          id="tags"
          multiple
          freeSolo={true}
          options={arraysOfString.tags}
          value={customer.tags || []}
          getOptionLabel={(option: any) => option}
          isOptionEqualToValue={(option, value) => option === value}
          getOptionDisabled={(option) =>
            !!customer.tags.find((element: any) => element === option)
          }
          onChange={(event: any, value: any) => {
            handleChangeCustomer(
              handleChangeAutocomplete(event, value, customer, "tags")
            );
            const tags = new Set([...arraysOfString.tags, ...value]);
            handleChangeArraysOfString({
              ...arraysOfString,
              tags: Array.from(tags),
            });
          }}
          renderInput={(params: any) => (
            <TextField
              {...params}
              fullWidth
              label={Vocabulary.tags}
              variant="outlined"
            />
          )}
        />
      </Grid>
      {/* ESTE UN ARRAY DE ADRESE NU DE STRING, NU L-AM MAI AFISAT
              -> allShippingAddresses
          */}
      <Grid item xs={12} sm={12} md={4}>
        <div style={{ display: "flex" }}>
          <Checkbox
            name="allowShareEmail"
            style={{
              margin: 0,
              padding: 0,
            }}
            checked={customer.allowShareEmail}
            onChange={(event: any) =>
              handleChangeCustomer(onChangeCheckbox(event, customer))
            }
          />
          <p style={{ margin: 15 }}>{Vocabulary.allowShareEmail}</p>
        </div>
      </Grid>
    </Grid>
  );
}
