/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState } from "react";
import { Vocabulary } from "../Utils/Vocabulary";
import { urlEnum } from "../Utils/UrlEnum";
import { WorkflowArrayFromStepModel, WorkflowStepModel } from "../Utils/Models";
import { postData } from "../Services/postData";
import { updateData } from "../Services/updateData";
import styles from "../Styles/dashboard.module.css";
import MainTable from "../Components/GenericComponents/MainTable";
import StepMainPage from "../Components/WorkflowsSteps/StepMainPage";
import { Button, Typography, useMediaQuery } from "@mui/material";
import theme from "../Themes/Theme";
import GenericModal from "../Components/GenericComponents/GenericModal";

export default function Steps() {
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const [open, setOpen] = useState(false);
  const [editItem, setEditItem] = useState<WorkflowStepModel | null>(null);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [shouldSave, setShouldSave] = useState(false);
  const [backupData, setBackupData] = useState<any>({
    step: null,
    checkedItems: null,
  });

  /**
   * Header for the table
   */
  const workflowStepsHeader = [
    {
      label: Vocabulary.name,
      name: "name",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.internalName,
      name: "internalName",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.type,
      name: "type",
      options: {
        sort: false,
      },
    },
  ];

  /**
   *
   * @param item
   */
  function handleEdit(item: WorkflowStepModel | null) {
    setShouldUpdate(false);
    setOpen(!open);
    setEditItem(item);
  }
  /**
   *
   * @param step
   */
  function addOrUpdateStep(
    step: WorkflowStepModel | null,
    checkedItems: Set<WorkflowArrayFromStepModel> | null
  ) {
    if (step) {
      const checkedItemsArray = checkedItems ? Array.from(checkedItems) : [];
      step.workflows = checkedItemsArray.map((item: any) => {
        const workflow = step.workflows.find(
          (workflow) => workflow.workflowId === item
        );
        return {
          workflowId: item,
          time: workflow?.time || "00:00:00",
        };
      });
    }
    setShouldUpdate(true);
    if (step?._id) {
      updateData(`${urlEnum.steps}/${step._id}`, step)
        .then((res) => {
          if (res) {
            setOpen(false);
            setShouldSave(false);
            setBackupData({ step: null, checkedItems: null });
          }
        })
        .catch((err) => {
          setOpen(false);
          setShouldSave(false);
          setBackupData({ step: null, checkedItems: null });
        });
    } else {
      postData(urlEnum.steps, step)
        .then((res: any) => {
          if (res) {
            setOpen(false);
            setShouldSave(false);
            setBackupData({ step: null, checkedItems: null });
          }
        })
        .catch((err) => {
          setOpen(false);
          setShouldSave(false);
          setBackupData({ step: null, checkedItems: null });
        });
    }
  }

  /**
   *
   * @param step
   * @param checkedItems
   */
  function saveStep(
    step: WorkflowStepModel | null,
    checkedItems: Set<WorkflowArrayFromStepModel> | null
  ) {
    if (checkedItems && step && checkedItems.size !== step?.workflows.length) {
      setShouldSave(true);
      setBackupData({ step, checkedItems });
    } else {
      addOrUpdateStep(step, checkedItems);
    }
  }

  return (
    <div
      className={phoneStyle ? styles.mainContainerMobile : styles.mainContainer}
    >
      <MainTable
        urlEnumApi={urlEnum.steps}
        titleDelete={Vocabulary.deleteStep}
        textDelete={Vocabulary.deleteConfirmationStep}
        header={workflowStepsHeader}
        tableTitle={Vocabulary.stepsList}
        shouldUpdate={shouldUpdate}
        handleEdit={handleEdit}
      />
      {open ? (
        <StepMainPage
          editStep={editItem}
          open={open}
          addOrUpdateStep={saveStep}
          onClose={() => {
            handleEdit(null);
          }}
        />
      ) : null}
      {shouldSave ? (
        <GenericModal
          open={shouldSave}
          onClose={() => {
            setShouldSave(false);
          }}
          title={Vocabulary.savingStep}
          styleTitle={{
            backgroundColor: theme.palette.primary?.main,
            color: "white",
            minWidth: 500,
          }}
          style={{ zIndex: 1500 }}
          actions={
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                padding: 20,
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                style={{ color: "white" }}
                onClick={() => {
                  setShouldSave(false);
                }}
              >
                {Vocabulary.no}
              </Button>
              <Button
                variant="contained"
                onClick={() =>
                  addOrUpdateStep(backupData.step, backupData.checkedItems)
                }
              >
                {Vocabulary.yes}
              </Button>
            </div>
          }
        >
          <div style={{ textAlign: "center" }}>
            <Typography variant="h5">{Vocabulary.saveStepMessage}</Typography>
          </div>
        </GenericModal>
      ) : null}
    </div>
  );
}
