/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import styles from "../../Styles/dashboard.module.css";
import { Vocabulary } from "../../Utils/Vocabulary";
import { urlEnum } from "../../Utils/UrlEnum";
import MainTable from "../GenericComponents/MainTable";
import { useState } from "react";
import { ShipmentModel } from "../../Utils/Models";
import moment from "moment";
import { euFormatForDateTime } from "../../Utils/Config";
import { styleForTableWithTabs } from "../../Pages/Users";
import PendingShipmentInfo from "./PendingShipmentInfo";
import { ModifierFlags } from "typescript";

export default function ProcessedShipments() {
  const [open, setOpen] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [editItem, setEditItem] = useState<ShipmentModel | null>(null);

  const shipmentHeader = [
    {
      label: Vocabulary.trackingNumber,
      name: "trackingNumber",
      options: { sort: false },
    },
    {
      label: Vocabulary.from,
      name: "recipient",
      options: {
        sort: false,
        customBodyRender: (recipient: any) => {
          return (
            <>
              {recipient ? `${recipient.firstName} ${recipient.lastName}` : ""}
            </>
          );
        },
      },
    },
    {
      label: Vocabulary.to,
      name: "senderLocation",
      options: {
        sort: false,
        customBodyRender: (senderLocation: any) => {
          return <>{senderLocation ? senderLocation.name : ""}</>;
        },
      },
    },
    {
      label: Vocabulary.date,
      name: "createdAt",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return <>{moment(value).format(euFormatForDateTime)}</>;
        },
      },
    },
  ];
  /**
   *
   * @param item
   */
  function handleEdit(item: ShipmentModel | null) {
    setShouldUpdate(false);
    setOpen(!open);
    setEditItem(item);
  }
  
  return (
    <div className={styles.mainContainerUser}>
      <MainTable
        urlEnumApi={urlEnum.shipments}
        titleDelete={Vocabulary.deleteShipment}
        textDelete={Vocabulary.deleteConfirmationShipment}
        header={shipmentHeader}
        tableTitle={Vocabulary.shipmentsList}
        shouldUpdate={shouldUpdate}
        handleEdit={handleEdit}
        style={styleForTableWithTabs}
        hideOptionButtons={true}
        hideAddButton={true}
      />
      {open && editItem ? (
        <PendingShipmentInfo
          viewOnly={true}
          editShipment={editItem}
          open={open}
          onClose={() => setOpen(false)}
        />
      ) : null}
    </div>
  );
}
