/* eslint-disable @typescript-eslint/no-explicit-any */
// import { ResponsiveLine } from "@nivo/line";
import style from "../../Styles/reports.module.css";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";
// import {
//   blueberryTwilightPalette,
//   mangoFusionPalette,
// } from "@mui/x-charts/colorPalettes";

type GraphicBarProps = {
  title: string;
  data: any;
  keys: any;
  color: boolean;
};

// const categories = {
//   blueberryTwilight: blueberryTwilightPalette,
//   mangoFusion: mangoFusionPalette,
//   // cheerfulFiesta: cheerfulFiestaPalette,
// } as const;

const categories: { [key: string]: string[] } = {
  pastel1: [
    "#fbb4ae",
    "#b3cde3",
    "#ccebc5",
    "#decbe4",
    "#fed9a6",
    "#ffffcc",
    "#e5d8bd",
    "#fddaec",
    "#f2f2f2",
  ],
  pastel2: [
    "#b3e2cd",
    "#fdcdac",
    "#cbd5e8",
    "#f4cae4",
    "#e6f5c9",
    "#fff2ae",
    "#f1e2cc",
    "#cccccc",
    "#f2f2f2",
  ],
};

export default function GraphicBar(props: GraphicBarProps) {
  const { title, data, keys, color } = props;
  const chartSetting = {
    yAxis: [
      {
        label: title,
      },
    ],
    height: 400,
    sx: {
      [`.${axisClasses.left} .${axisClasses.label}`]: {
        transform: "translate(-10px, 0)",
      },
    },
  };
  return (
    <div
      style={{
        width: "100%",
        height: "500px",
        marginTop: 20,
        textAlign: "center",
        paddingLeft: 10,
        // backgroundColor: "white",
        // borderRadius: 5,
      }}
    >
      <p className={style.statisticallyText}>
        <span>{title}</span>
      </p>
      <BarChart
        dataset={data}
        xAxis={[{ scaleType: "band", dataKey: "month" }]}
        series={keys}
        colors={color ? categories.pastel1 : categories.pastel2}
        grid={{ horizontal: true }}
        {...chartSetting}
      />
    </div>
  );
}
