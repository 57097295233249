import { Vocabulary } from "../../Utils/Vocabulary";
import EditDeleteButtons from "../GenericComponents/EditDeleteButtons";
import { OrderContext } from "../../Contexts/OrderContext";
import { useContext } from "react";
import { useMediaQuery } from "@mui/material";

type OrderCustomerProps = {
  onEdit: () => void;
};

export default function OrderCustomer(props: OrderCustomerProps) {
  const { onEdit } = props;
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const orderContext = useContext(OrderContext);
  return (
    <div style={{ position: "relative" }}>
      {orderContext.order.customer ? (
        <div>
          <p>
            <span style={{ fontWeight: "bold" }}>
              {Vocabulary.customerName}:
            </span>
            &nbsp;&nbsp;
            {phoneStyle ? <br /> : null}
            {orderContext.order.customer.firstName}{" "}
            {orderContext.order.customer.lastName}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>{Vocabulary.phone}:</span>
            &nbsp;&nbsp;
            {orderContext.order.customer.phone}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>{Vocabulary.email}:</span>
            &nbsp;&nbsp;
            {orderContext.order.customer.email}
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>
              {Vocabulary.customerAddress}:
            </span>
            &nbsp;&nbsp;
            {orderContext.order.customer.shippingAddress?.address1},{" "}
            {orderContext.order.customer.shippingAddress?.zip}
            {", "}
            {orderContext.order.customer.shippingAddress?.city}
          </p>
          <EditDeleteButtons
            onEdit={onEdit}
            onDelete={() =>
              orderContext.setOrder({
                ...orderContext.order,
                customer: null,
              })
            }
            style={{ position: "absolute", top: 0, right: 0 }}
          />
        </div>
      ) : null}
    </div>
  );
}
