/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-destructuring */

/**
 *
 * @param file
 * @returns
 */
export function resizeImage(file: any) {
  const promise = new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = function (readerEvent) {
        const image = new Image();
        image.src = readerEvent.target?.result as string;
        image.onload = function (imageEvent) {
          const imageFile = resize(image, file.name);
          resolve(imageFile);
        };
        image.onerror = function (err) {
          reject(err);
        };
      };
      reader.readAsDataURL(file);
    } catch (e) {
      reject(e);
    }
  });
  return promise;
}
/**
 *
 * @param image
 * @param fileName
 * @returns
 */
function resize(image: any, fileName: any) {
  const canvas = document.createElement("canvas");
  let { width } = image,
    { height } = image;
  const maxWidth = width > 200 ? 200 : width;
  if (width > height) {
    if (width > maxWidth) {
      height *= maxWidth / width;
      width = maxWidth;
    }
  } else {
    if (height > maxWidth) {
      width *= maxWidth / height;
      height = maxWidth;
    }
  }
  canvas.width = width;
  canvas.height = height;
  canvas?.getContext("2d")?.drawImage(image, 0, 0, width, height);
  const dataUrl = canvas.toDataURL("image/png");
  const resizedImage = dataURLToBlob(dataUrl);
  return blobToFile(resizedImage, fileName);
}

/**
 *
 * @param {*} dataURL
 * @param {*} fileName
 * @returns
 */
export function dataURLToBlob(dataURL: any) {
  const BASE64_MARKER = ";base64,";

  let parts = dataURL.split(BASE64_MARKER);
  let contentType = parts[0].split(":")[1];
  let raw = window.atob(parts[1]);
  const rawLength = raw.length;

  if (dataURL.indexOf(BASE64_MARKER) === -1) {
    parts = dataURL.split(",");
    contentType = parts[0].split(":")[1];
    raw = parts[1];
    return new Blob([raw], { type: contentType });
  }
  const uInt8Array = new Uint8Array(rawLength);
  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i);
  }
  const blob = new Blob([uInt8Array], { type: contentType });
  return blob;
}

/**
 *
 * @param {*} ab
 * @param {*} fileName
 * @returns
 */
function blobToFile(ab: any, fileName: any) {
  const f = new File([ab], fileName, {
    type: "image/jpeg",
    lastModified: new Date().getTime(),
  });
  return f;
}
