/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Close } from "@mui/icons-material";

type Props = {
  open: boolean;
  onClose: () => void;
  children: any;
  title: any;
  actions?: any;
  style?: any;
  styleTitle?: any;
  styleDialogPaper?: any;
};

export default function GenericModal(props: Props) {
  const {
    open,
    children,
    onClose,
    title,
    actions,
    style,
    styleTitle,
    styleDialogPaper,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: styleDialogPaper ? styleDialogPaper : { width: "100%" },
      }}
      style={style ? style : { zIndex: 2 }}
    >
      <DialogTitle
        sx={
          styleTitle
            ? styleTitle
            : {
                padding: "10px",
                backgroundColor: "primary.main",
                color: "textColorSecondary.main",
              }
        }
      >
        <p style={{ margin: "0px 15px 0px" }}>{title}</p>
        <p style={{ position: "absolute", right: "20px", top: "-5px" }}>
          <Close onClick={onClose} style={{ cursor: "pointer" }} />
        </p>
      </DialogTitle>
      <DialogContent style={{ paddingTop: "20px" }}>{children}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
}
