import { Button } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import GenericModal from "./GenericModal";

type DeleteWorkflowModalProps = {
  title: string;
  text: string;
  openDeleteModal: boolean;
  onClose: () => void;
  deleteItem: () => void;
};
export default function GenericConfirmModal(props: DeleteWorkflowModalProps) {
  const { openDeleteModal, title, text, onClose, deleteItem } = props;
  return (
    <GenericModal
      title={title}
      open={openDeleteModal}
      onClose={onClose}
      actions={
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            padding: 20,
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            style={{ color: "white" }}
            onClick={onClose}
          >
            {Vocabulary.no}
          </Button>
          <Button type="submit" variant="contained" onClick={deleteItem}>
            {Vocabulary.yes}
          </Button>
        </div>
      }
      children={
        <div>
          <p>{text}</p>
        </div>
      }
      style={{ textAlign: "center" }}
      styleTitle={{
        padding: "10px",
        backgroundColor: "primary.main",
        color: "white",
        fontSize: 20,
      }}
    ></GenericModal>
  );
}
