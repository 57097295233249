/* eslint-disable @typescript-eslint/no-explicit-any */
import { Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { getData } from "../../Services/getData";
import { urlEnum } from "../../Utils/UrlEnum";

type TechnicianEarningProps = {
  technicianId: string | null;
  technicianName: string;
  labels?: any;
  showDivider: boolean;
};

type TechnicianEarningStateProps = {
  totalEarningsForADay: number;
  totalEarningsForAMonth: number;
  totalEarningsForAYear: number;
};
export default function TechnicianEarning(props: TechnicianEarningProps) {
  const { technicianId, technicianName, labels, showDivider } = props;
  const [technicianEarning, setTechnicianEarning] =
    useState<TechnicianEarningStateProps>({
      totalEarningsForADay: 0,
      totalEarningsForAMonth: 0,
      totalEarningsForAYear: 0,
    });

  useEffect(() => {
    if (technicianId)
      getData(`${urlEnum.reports}/totalEarnings/${technicianId}`).then(
        (res: any) => {
          if (res) {
            setTechnicianEarning(res.data.result);
          }
        }
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <p style={{ flex: 1, verticalAlign: "center", fontWeight: 300 }}>
          {technicianId ? technicianName : labels.username}
        </p>
        <p
          style={{
            margin: "5px 0px",
            padding: 10,
            fontWeight: 300,
            flex: 1,
          }}
        >
          {technicianId
            ? technicianEarning.totalEarningsForADay.toFixed(2)
            : labels.day}
        </p>
        <p
          style={{
            margin: "5px 0px",
            padding: 10,
            fontWeight: 300,
            flex: 1,
          }}
        >
          {technicianId
            ? technicianEarning.totalEarningsForAMonth.toFixed(2)
            : labels.month}
        </p>
        <p
          style={{
            margin: "5px 0px",
            padding: 10,
            fontWeight: 300,
            flex: 1,
          }}
        >
          {technicianId
            ? technicianEarning.totalEarningsForAYear.toFixed(2)
            : labels.year}
        </p>
      </div>
      {showDivider ? <Divider /> : null}
    </>
  );
}
