import { useEffect, useMemo, useState } from "react";

export default function useCustomMemo() {
  const [cache, setCache] = useState(localStorage.getItem("cache"));

  /**
   *
   */
  useEffect(() => {
    const handleStorageChange = () => {
      setCache(localStorage.getItem("cache"));
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /**
   *
   */
  const cacheData = useMemo(() => {
    const localStorageCache = localStorage.getItem("cache");
    if (!localStorageCache) return [];
    const cache = JSON.parse(localStorageCache);
    if (cache) return cache || [];
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cache]);

  return cacheData;
}
