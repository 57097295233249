/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextValidator } from "react-material-ui-form-validator";
import { Vocabulary } from "../../Utils/Vocabulary";
import { ProductModel } from "../../Utils/Models";
import { Checkbox, Grid } from "@mui/material";
import { onChangeCheckbox, onChangeTextField } from "../../Utils/Utils";
import CustomFields from "../GenericComponents/CustomFields";

type AdditionalInfoProps = {
  product: ProductModel;
  handleChangeProduct: (product: ProductModel) => void;
};

export default function AdditionalInfo(props: AdditionalInfoProps) {
  const { product, handleChangeProduct } = props;

  return (
    <>
      <Grid container spacing={2} style={{ marginTop: 20 }}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextValidator
            size="small"
            id="partNumber"
            name="partNumber"
            label={Vocabulary.partNumber}
            fullWidth
            validators={["required"]}
            errorMessages={[Vocabulary.requiredField]}
            variant="outlined"
            value={product.partNumber}
            onChange={(event: any) =>
              handleChangeProduct(onChangeTextField(event, product))
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextValidator
            size="small"
            id="ean"
            name="ean"
            label={Vocabulary.ean}
            fullWidth
            validators={["required"]}
            errorMessages={[Vocabulary.requiredField]}
            variant="outlined"
            value={product.ean}
            onChange={(event: any) =>
              handleChangeProduct(onChangeTextField(event, product))
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextValidator
            size="small"
            id="family"
            name="family"
            label={Vocabulary.family}
            fullWidth
            variant="outlined"
            value={product.family || ""}
            onChange={(event: any) =>
              handleChangeProduct(onChangeTextField(event, product))
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextValidator
            size="small"
            id="line"
            name="line"
            label={Vocabulary.line}
            fullWidth
            variant="outlined"
            value={product.line || ""}
            onChange={(event: any) =>
              handleChangeProduct(onChangeTextField(event, product))
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextValidator
            size="small"
            id="version"
            name="version"
            label={Vocabulary.version}
            fullWidth
            validators={["required"]}
            errorMessages={[Vocabulary.requiredField]}
            variant="outlined"
            value={product.version || ""}
            onChange={(event: any) =>
              handleChangeProduct(onChangeTextField(event, product))
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextValidator
            size="small"
            id="web"
            name="web"
            type="number"
            label={Vocabulary.web}
            fullWidth
            variant="outlined"
            value={product.web}
            onChange={(event: any) =>
              handleChangeProduct(onChangeTextField(event, product))
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextValidator
            size="small"
            id="markup"
            name="markup"
            label={Vocabulary.markup}
            fullWidth
            variant="outlined"
            value={product.markup}
            onChange={(event: any) =>
              handleChangeProduct(onChangeTextField(event, product))
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextValidator
            size="small"
            id="eeeCode"
            name="eeeCode"
            label={Vocabulary.eeeCode}
            fullWidth
            variant="outlined"
            value={product.eeeCode}
            onChange={(event: any) =>
              handleChangeProduct(onChangeTextField(event, product))
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextValidator
            size="small"
            id="provider"
            name="provider"
            label={Vocabulary.provider}
            fullWidth
            variant="outlined"
            value={product.provider}
            onChange={(event: any) =>
              handleChangeProduct(onChangeTextField(event, product))
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <TextValidator
            size="small"
            id="identifier"
            name="identifier"
            label={Vocabulary.identifier}
            fullWidth
            variant="outlined"
            value={product.identifier}
            onChange={(event: any) =>
              handleChangeProduct(onChangeTextField(event, product))
            }
          />
        </Grid>
      </Grid>
      <CustomFields
        customFields={product.customFields}
        onChangeCustomField={(customFields: any) =>
          handleChangeProduct({ ...product, customFields: customFields })
        }
      />
      {/* checkbox */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={4}>
          <div style={{ display: "flex" }}>
            <Checkbox
              name="serialized"
              style={{
                margin: 0,
                padding: 0,
              }}
              checked={product.serialized}
              onChange={(event: any) =>
                handleChangeProduct(onChangeCheckbox(event, product))
              }
            />
            <p style={{ margin: 15 }}>{Vocabulary.serialized}</p>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <div style={{ display: "flex" }}>
            <Checkbox
              name="fixed"
              style={{
                margin: 0,
                padding: 0,
              }}
              checked={product.fixed}
              onChange={(event: any) =>
                handleChangeProduct(onChangeCheckbox(event, product))
              }
            />
            <p style={{ margin: 15 }}>{Vocabulary.fixed}</p>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <div style={{ display: "flex" }}>
            <Checkbox
              name="visibleOnPrintouts"
              style={{
                margin: 0,
                padding: 0,
              }}
              checked={product.visibleOnPrintouts}
              onChange={(event: any) =>
                handleChangeProduct(onChangeCheckbox(event, product))
              }
            />
            <p style={{ margin: 15 }}>{Vocabulary.visibleOnPrintouts}</p>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
