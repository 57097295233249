/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { FiltersProps } from "./Reports";
import ReportsAndOrdersFilters from "../../Components/Reports/ReportsAndOrdersFilters";
import moment from "moment";
import styles from "../../Styles/dashboard.module.css";
import { useLocation } from "react-router-dom";
import { Grid, useMediaQuery } from "@mui/material";
import { urlEnum } from "../../Utils/UrlEnum";
import { getData } from "../../Services/getData";
import OrderReport from "../../Components/Reports/OrderReport";
import { dayPilotFormat } from "../../Utils/Config";

export default function OrdersReports() {
  const location = useLocation();
  const phoneStyle = useMediaQuery("(max-width:800px)");
  const [ordersReports, setOrdersReports] = useState<any>([]);
  const [filters, setFilters] = useState<FiltersProps>({
    startDate: moment().subtract(1, "months"),
    endDate: moment(),
    user: null,
    orderId: null,
  });

  /**
   *
   */
  useEffect(() => {
    const search = location.search.split(/[?&]+/);
    const newFilters: any = Object.assign({}, filters);
    search.forEach((value: any) => {
      if (value !== "") {
        const values = value.split("=");
        newFilters[values[0]] = isNaN(+values[1]) ? values[1] : +values[1];
        if (values[0] === "page") newFilters[values[0]] = +values[1] - 1;
        if (values[1] === "null") newFilters[values[0]] = null;
      }
    });
    setFilters({
      ...newFilters,
      startDate: moment(newFilters.startDate, dayPilotFormat),
      endDate: moment(newFilters.endDate, dayPilotFormat),
    });
    getOrderReports(newFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /**
   *
   */
  function getOrderReports(newFilter: FiltersProps) {
    const url = `${urlEnum.ordersReports}/${newFilter.orderId}/${moment(
      newFilter.startDate
    ).format(dayPilotFormat)}/${moment(newFilter.endDate).format(
      dayPilotFormat
    )}`;
    getData(url).then((response) => {
      if (
        response &&
        response.data &&
        (response.data.status === 200 || response.data.status === 201)
      ) {
        setOrdersReports(response.data.result);
      }
    });
  }

  /**
   *
   */
  async function recalculateReports() {
    const response = await getData(urlEnum.ordersReports);
    if (
      response &&
      response.data &&
      (response.data.status === 200 || response.data.status === 201)
    ) {
      getOrderReports(filters);
    }
  }

  return (
    <div
      className={
        phoneStyle
          ? styles.mainContainerReportsMobile
          : styles.mainContainerReports
      }
    >
      <ReportsAndOrdersFilters
        filters={filters}
        url=""
        disableClearable={true}
        multiple={false}
        showOrderId={true}
        recalculateReportsButton={true}
        recalculateReportsFunction={recalculateReports}
      ></ReportsAndOrdersFilters>
      <Grid
        container
        spacing={4}
        style={phoneStyle ? { marginBottom: 80 } : {}}
      >
        {ordersReports.map((report: any) => (
          <Grid
            item
            xs={12}
            md={12}
            lg={ordersReports.length === 1 ? 12 : 6}
            key={report._id}
          >
            <OrderReport orderReport={report} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
