import { Typography } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { useContext } from "react";
import { OrderContext } from "../../Contexts/OrderContext";

export default function ProductTotals() {
  const orderContext = useContext(OrderContext);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: 10,
        backgroundColor: "#f3f3f34d",
      }}
    >
      <div>
        <Typography style={{ fontSize: 17, fontWeight: "bold" }}>
          <span>
            {Vocabulary.totalDiscount}:{" "}
            {orderContext.order.lines
              .reduce((accumulator, line) => {
                return +accumulator + (+line.discount / 100) * +line.price;
              }, 0)
              .toFixed(2)}
          </span>
        </Typography>
      </div>
      <div>
        <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
          <span>
            {Vocabulary.subTotal}:{" "}
            {orderContext.order.lines
              .reduce((accumulator, line) => {
                return +accumulator + +line.total0;
              }, 0)
              .toFixed(2)}
          </span>
        </Typography>
        <Typography style={{ fontSize: 23, fontWeight: "bold" }}>
          <span>
            {Vocabulary.total}:{" "}
            {orderContext.order.lines
              .reduce((accumulator, line) => {
                return +accumulator + +line.total;
              }, 0)
              .toFixed(2)}
          </span>
        </Typography>
      </div>
    </div>
  );
}
