/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import { Vocabulary } from "../../Utils/Vocabulary";
import { ShipmentModel } from "../../Utils/Models";
import moment from "moment";
import styles from "../../Styles/dashboard.module.css";
import { euFormatForDateTime } from "../../Utils/Config";
import MainTable from "../GenericComponents/MainTable";
import { localUrlEnum, urlEnum } from "../../Utils/UrlEnum";
import { styleForTableWithTabs } from "../../Pages/Users";
import PendingShipmentInfo from "./PendingShipmentInfo";
import { updateData } from "../../Services/updateData";

export default function PendingShipments() {
  const [open, setOpen] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [editItem, setEditItem] = useState<ShipmentModel | null>(null);

  const shipmentHeader = [
    {
      label: Vocabulary.trackingNumber,
      name: "package",
      options: {
        sort: false,
        customBodyRender: (pack: any) => {
          return <>{pack.trackingNumber}</>;
        },
      },
    },
    {
      label: Vocabulary.order,
      name: "package",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return (
            <>
              <a
                href={`${localUrlEnum.orders}/${value.orders[0]._id}`}
                style={{
                  textDecoration: "none",
                  cursor: "pointer",
                  color: "#008470",
                }}
              >
                {value.orders[0].fixablyId || ""}
              </a>
              <br />
              {value.orders[0].device.name || ""}
            </>
          );
        },
      },
    },
    {
      label: Vocabulary.status,
      name: "package",
      options: {
        sort: false,
        customBodyRender: (pack: any) => {
          return <>{Vocabulary[pack.status]}</>;
        },
      },
    },
    {
      label: Vocabulary.from,
      name: "recipient",
      options: {
        sort: false,
        customBodyRender: (recipient: any) => {
          return (
            <>
              {recipient ? `${recipient.firstName} ${recipient.lastName}` : ""}
            </>
          );
        },
      },
    },
    {
      label: Vocabulary.to,
      name: "senderLocation",
      options: {
        sort: false,
        customBodyRender: (senderLocation: any) => {
          return <>{senderLocation ? senderLocation.name : ""}</>;
        },
      },
    },
    {
      label: Vocabulary.date,
      name: "createdAt",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return <>{moment(value).format(euFormatForDateTime)}</>;
        },
      },
    },
  ];
  /**
   *
   * @param item
   */
  function handleEdit(item: ShipmentModel | null) {
    setShouldUpdate(false);
    setOpen(!open);
    setEditItem(item);
  }

  /**
   *
   * @param id
   * @param selectedProducts
   */
  function confirmAndProcessSelectedPackages(
    id: string | null,
    selectedPackages: string[],
    trackingNumber: string
  ) {
    setShouldUpdate(false);
    updateData(`${urlEnum.shipments}/confirmShipment/${id}`, {
      selectedPackages: selectedPackages,
      trackingNumber: trackingNumber,
    }).then((response) => {
      if (response) {
        setShouldUpdate(true);
        setOpen(!open);
        setEditItem(null);
      }
    });
  }

  return (
    <div className={styles.mainContainerUser}>
      <MainTable
        urlEnumApi={urlEnum.shipments}
        titleDelete={Vocabulary.deleteShipment}
        textDelete={Vocabulary.deleteConfirmationShipment}
        header={shipmentHeader}
        tableTitle={Vocabulary.shipmentsList}
        shouldUpdate={shouldUpdate}
        handleEdit={handleEdit}
        style={styleForTableWithTabs}
        hideOptionButtons={true}
        hideAddButton={true}
      />
      {open && editItem ? (
        <PendingShipmentInfo
          viewOnly={false}
          editShipment={editItem}
          open={open}
          onClose={() => setOpen(false)}
          confirmAndProcessSelectedPackages={confirmAndProcessSelectedPackages}
        />
      ) : null}
    </div>
  );
}
