/* eslint-disable @typescript-eslint/no-explicit-any */
import { Tab, Tabs, useMediaQuery } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import theme from "../../Themes/Theme";
import styles from "../../Styles/dashboard.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { localUrlEnum } from "../../Utils/UrlEnum";
import { stocksManagementTypes } from "../../Utils/AutocompleteUtils";
import Inventories from "../../Components/Products/Stocks/StockManagement/Inventories";
import StocksTransfer from "../../Components/Products/Stocks/StockManagement/StockTransfers";
import Goods from "../../Components/Products/Stocks/StockManagement/Goods";
import StocksFilters from "../../Components/Products/Stocks/StocksFilters";

export default function StocksManagement() {
  const navigate = useNavigate();
  const location = useLocation();
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const [value, setValue] = useState(0);
  const [filters, setFilters] = useState<any>({
    inventory: null,
    product: null,
    status: null,
    vendor: null,
  });

  useEffect(() => {
    const search: any = location.search.split(/[?&]+/);
    const newFilters: any = {
      inventory: null,
      product: null,
      vendor: null,
    };

    search.forEach((value: any) => {
      if (value !== "") {
        const values = value.split("=");
        newFilters[values[0]] = isNaN(+values[1]) ? values[1] : +values[1];
        if (values[0] === "page") newFilters[values[0]] = +values[1] - 1;
        if (values[1] === "null") newFilters[values[0]] = null;
        if (values[1].includes("+"))
          newFilters[values[0]] = values[1].replace("+", " ");
      }
    });
    setFilters(newFilters);
    setValueFromLocation(newFilters.type);
  }, [location]);

  /**
   *
   * @param newValue
   */
  function setValueFromLocation(newValue: string) {
    switch (newValue) {
      case stocksManagementTypes.inventories:
        setValue(0);
        break;
      case stocksManagementTypes.transfers:
        setValue(1);
        break;
      case stocksManagementTypes.incomingGoods:
        setValue(2);
        break;
      case stocksManagementTypes.receivedGoods:
        setValue(3);
        break;
      default:
        setValue(0);
        break;
    }
  }
  /**
   *
   * @param event
   * @param newValue
   */
  function handleChangeValue(event: React.SyntheticEvent, newValue: number) {
    switch (newValue) {
      case 0:
        navigate(
          `${localUrlEnum.stocksManagement}?type=${stocksManagementTypes.inventories}`
        );
        break;
      case 1:
        navigate(
          `${localUrlEnum.stocksManagement}?type=${stocksManagementTypes.transfers}`
        );
        break;
      case 2:
        navigate(
          `${localUrlEnum.stocksManagement}?type=${stocksManagementTypes.incomingGoods}`
        );
        break;
      case 3:
        navigate(
          `${localUrlEnum.stocksManagement}?type=${stocksManagementTypes.receivedGoods}`
        );
        break;
      default:
        navigate(
          `${localUrlEnum.stocksManagement}?type=${stocksManagementTypes.inventories}`
        );
        break;
    }
  }

  /**
   *
   */
  function showInformationForTabs() {
    switch (value) {
      case 0:
        return <Inventories />;
      case 1:
        return <StocksTransfer />;
      case 2:
        return <Goods type={stocksManagementTypes.incomingGoods} />;
      case 3:
        return <Goods type={stocksManagementTypes.receivedGoods} />;
      default:
        return <Inventories />;
    }
  }

  function showFilters() {
    switch (value) {
      case 0:
        return null;
      case 1:
        return <StocksFilters filters={filters} hideVendor={true} />;
      case 2:
      case 3:
        return <StocksFilters filters={filters} hideStatus={true} />;
      default:
        return null;
    }
  }

  /**
   *
   * @param tabValue
   * @returns
   */
  function getTabStyle(tabValue: number) {
    return {
      backgroundColor:
        value === tabValue
          ? theme.palette.secondary.main
          : theme.palette.cancel?.main,
      color: value === tabValue ? "white" : "#9c9b9b",
      borderTopRightRadius: "10px",
      borderTopLeftRadius: "10px",
      marginRight: "1px",
    };
  }

  return (
    <div
      className={phoneStyle ? styles.mainContainerMobile : styles.mainContainer}
    >
      {showFilters()}
      <Tabs
        value={value}
        onChange={handleChangeValue}
        TabIndicatorProps={{
          style: {
            backgroundColor: theme.palette.secondary.main,
          },
        }}
        style={{ width: "100%" }}
      >
        <Tab label={Vocabulary.inventories} style={getTabStyle(0)} />
        <Tab label={Vocabulary.stockTransfers} style={getTabStyle(1)} />
        <Tab label={Vocabulary.incomingGoods} style={getTabStyle(2)} />
        <Tab label={Vocabulary.receivedGoods} style={getTabStyle(3)} />
      </Tabs>
      <> {showInformationForTabs()}</>
    </div>
  );
}
