/* eslint-disable @typescript-eslint/no-explicit-any */
import { urlEnum } from "../../Utils/UrlEnum";
import { postData } from "../../Services/postData";
import { WebsocketContext } from "../../Contexts/WebsocketContext";
import { useContext, useState } from "react";
import { Vocabulary } from "../../Utils/Vocabulary";
import { TaxRateModel } from "../../Utils/Models";
import MainTable from "../GenericComponents/MainTable";
import { updateData } from "../../Services/updateData";
import TaxRate from "./TaxRate";
import { Button, Chip } from "@mui/material";
import GenericConfirmModal from "../GenericComponents/GenericConfirmModal";

export default function TaxRates() {
  const websocketContext = useContext(WebsocketContext);
  const [open, setOpen] = useState(false);
  const [editItem, setEditItem] = useState<TaxRateModel | null>(null);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [setAsDefault, setSetAsDefault] = useState({
    open: false,
    id: null,
  });

  const taxRateHeader = [
    {
      label: "",
      name: "_id",
      options: {
        display: "excluded",
      },
    },
    {
      label: Vocabulary.name,
      name: "name",
      options: { sort: false },
    },
    {
      label: Vocabulary.value,
      name: "value",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.default,
      name: "defaultValue",
      options: {
        sort: false,
        customBodyRender: (value: any, meta: any) => {
          return value ? (
            <Chip label={Vocabulary.default.toUpperCase()} />
          ) : (
            <Button
              onClick={(e: any) => {
                e.stopPropagation();
                setShouldUpdate(false);
                setSetAsDefault({ open: true, id: meta.rowData[1] });
              }}
              variant="outlined"
              style={{ borderRadius: 20 }}
            >
              {Vocabulary.setAsDefault}
            </Button>
          );
        },
      },
    },
  ];

  /**
   *
   * @param item
   */
  function handleEdit(item: TaxRateModel | null) {
    setShouldUpdate(false);
    setOpen(!open);
    setEditItem(item);
  }

  /**
   *
   * @param taxRate
   */
  function addOrUpdateTaxRate(taxRate: TaxRateModel | null) {
    setShouldUpdate(true);
    if (taxRate?._id) {
      updateData(`${urlEnum.taxRates}/${taxRate._id}`, taxRate)
        .then((res) => {
          if (res) {
            websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
            setOpen(false);
          }
        })
        .catch((err) => {
          setOpen(false);
        });
    } else {
      postData(urlEnum.taxRates, taxRate)
        .then((res: any) => {
          if (res) {
            websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
            setOpen(false);
          }
        })
        .catch((err) => {
          setOpen(false);
        });
    }
  }

  /**
   *
   */
  function setTaxRateAsDefault() {
    updateData(`${urlEnum.taxRates}/${setAsDefault.id}/default`, {})
      .then((res) => {
        if (res) {
          setShouldUpdate(true);
          websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
          setSetAsDefault({ open: false, id: null });
        }
      })
      .catch((err) => {
        setShouldUpdate(true);
        setSetAsDefault({ open: false, id: null });
      });
  }
  return (
    <>
      <div style={{ marginTop: 50 }}></div>
      <MainTable
        urlEnumApi={urlEnum.taxRates}
        titleDelete={Vocabulary.deleteTaxRate}
        textDelete={Vocabulary.deleteConfirmationTaxRate}
        header={taxRateHeader}
        tableTitle={Vocabulary.taxRatesList}
        shouldUpdate={shouldUpdate}
        handleEdit={handleEdit}
      />
      {open ? (
        <TaxRate
          editTaxRate={editItem}
          open={open}
          addOrUpdateTaxRate={(taxRate) => addOrUpdateTaxRate(taxRate)}
          onClose={() => handleEdit(null)}
        />
      ) : null}
      {setAsDefault.open ? (
        <GenericConfirmModal
          title={Vocabulary.taxRate}
          text={Vocabulary.setAsDefaultConfirmation}
          openDeleteModal={setAsDefault.open}
          onClose={() => setSetAsDefault({ open: false, id: null })}
          deleteItem={setTaxRateAsDefault}
        />
      ) : null}
    </>
  );
}
