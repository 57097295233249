import { Divider } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { ShipmentModel } from "../../Utils/Models";
import DividerWithLabel from "../GenericComponents/DividerWithLabel";
import moment from "moment";
import { euFormatForDateTime } from "../../Utils/Config";

type OrderShipmentsListProps = {
  shipments: ShipmentModel[];
};
export default function OrderShipmentsList(props: OrderShipmentsListProps) {
  const { shipments } = props;
  return (
    <>
      <DividerWithLabel
        label={Vocabulary.allShipments}
        styles={{ marginTop: 15, marginBottom: 0, width: "100%" }}
      />
      <div style={{ display: "flex", alignItems: "center" }}>
        <p style={{ flex: 1, fontWeight: 450 }}>{Vocabulary.trackingNumber}</p>
        <p style={{ flex: 1, fontWeight: 450 }}>{Vocabulary.customerName}</p>
        <p style={{ flex: 1, fontWeight: 450 }}>{Vocabulary.senderLocation}</p>
        <p style={{ flex: 1, fontWeight: 450 }}>{Vocabulary.status}</p>
        <p style={{ flex: 1, fontWeight: 450 }}>{Vocabulary.date}</p>
      </div>
      <Divider />
      {shipments.map((shipment) => (
        <>
          <div
            key={shipment._id}
            style={{ display: "flex", alignItems: "center" }}
          >
            <p style={{ flex: 1, fontWeight: 300 }}>
              {shipment.trackingNumber}
            </p>
            <p style={{ flex: 1, fontWeight: 300 }}>
              {shipment.recipient?.firstName} {shipment.recipient?.lastName}
            </p>
            <p style={{ flex: 1, fontWeight: 300 }}>
              {shipment.senderLocation?.name}
            </p>
            <p style={{ flex: 1, fontWeight: 300 }}>
              {Vocabulary[shipment.status || ""]}
            </p>
            <p style={{ flex: 1, fontWeight: 300 }}>
              {moment(shipment.createdAt).format(euFormatForDateTime)}
            </p>
          </div>
          <Divider />
        </>
      ))}
    </>
  );
}
