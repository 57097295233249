/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  useMediaQuery,
} from "@mui/material";

import { useEffect, useState } from "react";
import { roles } from "../../Utils/Constants";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Vocabulary } from "../../Utils/Vocabulary";
import useCustomMemo from "../GenericComponents/useCustomMemo";
import { useNavigate } from "react-router-dom";
import { UserModel, WorkflowModel } from "../../Utils/Models";
import { onChangeTextField } from "../../Utils/Utils";
import CircleIcon from "@mui/icons-material/Circle";
import { dayPilotFormat, euFormat } from "../../Utils/Config";
import { FiltersProps } from "../../Pages/Reports/Reports";
import GenericFilters from "../GenericComponents/GenericFilters";

type ReportsAndOrdersFiltersProps = {
  filters: FiltersProps;
  url: string;
  disableClearable: boolean;
  multiple: boolean;
  recalculateReportsButton: boolean;
  recalculateReportsFunction?: () => void;
  showOrderId?: boolean;
  showWorkflowId?: boolean;
  showUser?: boolean;
};
export default function ReportsAndOrdersFilters(
  props: ReportsAndOrdersFiltersProps
) {
  const {
    filters,
    url,
    disableClearable,
    multiple,
    recalculateReportsButton,
    recalculateReportsFunction,
    showOrderId,
    showWorkflowId,
    showUser,
  } = props;
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const navigate = useNavigate();
  const [orderNumber, setOrderNumber] = useState<string | number | null>(null);
  const role = localStorage.getItem("role");
  const cache = useCustomMemo();
  const users = cache.users || [];
  const workflows = cache.workflows || [];

  useEffect(() => {
    if (filters.orderId && filters.orderId !== "null")
      setOrderNumber(filters.orderId);
  }, [filters.orderId]);
  /**
   *
   * @returns
   */
  function displayWorkflow() {
    if (showWorkflowId)
      return (
        <>
          <Grid item xs={12} md={6} lg={4}>
            <Autocomplete
              options={workflows}
              multiple={multiple}
              getOptionLabel={(option: any) => option.name}
              isOptionEqualToValue={(option, value) =>
                option.name === value.name
              }
              getOptionDisabled={(option) =>
                !!(
                  filters.workflowId &&
                  filters.workflowId.find(
                    (element: any) => option.name === element.name
                  )
                )
              }
              value={
                workflows.filter((workflow: WorkflowModel) =>
                  filters.workflowId.includes(workflow._id)
                ) || []
              }
              freeSolo={false}
              disableClearable={disableClearable}
              selectOnFocus
              handleHomeEndKeys
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={Vocabulary.workflow}
                  size="small"
                />
              )}
              onChange={(event, newValue: any) => {
                const value =
                  newValue.length === 0
                    ? null
                    : newValue.map((workflow: WorkflowModel) => workflow._id);
                const valueString = value ? value.join(",") : null;
                navigate(
                  `${url}?startDate=${filters.startDate.format(
                    dayPilotFormat
                  )}&endDate=${filters.endDate.format(
                    dayPilotFormat
                  )}&workflowId=${valueString}`
                );
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={4}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={
                phoneStyle
                  ? { flexGrow: 1 }
                  : {
                      display: "flex",
                      alignItems: "center",
                      flexGrow: 1,
                    }
              }
            >
              <CircleIcon
                style={{
                  color: "#ADD8E6",
                  verticalAlign: "middle",
                }}
              ></CircleIcon>
              <div style={{ fontSize: 14 }}>
                &nbsp; = &nbsp;{Vocabulary.predTimeShort}
              </div>
            </div>
            <div
              style={
                phoneStyle
                  ? { flexGrow: 1 }
                  : {
                      display: "flex",
                      alignItems: "center",
                      flexGrow: 1,
                    }
              }
            >
              <CircleIcon
                style={{
                  color: "#FF7F7F",
                  verticalAlign: "middle",
                }}
              ></CircleIcon>{" "}
              <div style={{ fontSize: 14 }}>
                &nbsp; &gt; &nbsp;{Vocabulary.predTimeShort}
              </div>
            </div>
            <div
              style={
                phoneStyle
                  ? { flexGrow: 1 }
                  : { display: "flex", alignItems: "center", flexGrow: 1 }
              }
            >
              <CircleIcon
                style={{
                  color: "#CFFDBC",
                  verticalAlign: "middle",
                }}
              ></CircleIcon>{" "}
              <div style={{ fontSize: 14 }}>
                &nbsp; &lt;&nbsp; {Vocabulary.predTimeShort}
              </div>
            </div>
          </Grid>
        </>
      );
    return null;
  }
  /**
   *
   * @returns
   */
  function displayOrder() {
    if (showOrderId)
      return (
        <Grid item xs={12} md={6} lg={4}>
          <TextField
            fullWidth
            size="small"
            name="orderId"
            label={Vocabulary.orderNumber}
            value={orderNumber || ""}
            onBlur={() =>
              navigate(
                `${url}?startDate=${filters.startDate.format(
                  dayPilotFormat
                )}&endDate=${filters.endDate.format(dayPilotFormat)}&user=${
                  filters.user
                    ? filters.user.length === 0
                      ? null
                      : filters.user
                    : null
                }&orderId=${orderNumber}`
              )
            }
            onKeyUp={(e: any) => {
              if (e.key === "Enter") {
                navigate(
                  `${url}?startDate=${filters.startDate.format(
                    dayPilotFormat
                  )}&endDate=${filters.endDate.format(dayPilotFormat)}&user=${
                    filters.user
                      ? filters.user.length === 0
                        ? null
                        : filters.user
                      : null
                  }&orderId=${orderNumber}`
                );
              }
            }}
            onChange={(e) => {
              const newOrderNumber = onChangeTextField(e, filters);
              if (newOrderNumber.orderId === "") newOrderNumber.orderId = null;
              setOrderNumber(newOrderNumber.orderId);
            }}
          ></TextField>
        </Grid>
      );
    return null;
  }
  /**
   *
   * @returns
   */
  function displayUser() {
    if (showUser)
      return (
        <Grid item xs={12} md={6} lg={4}>
          <Autocomplete
            options={users}
            multiple={multiple}
            getOptionLabel={(option: any) =>
              `${option.firstName} ${option.lastName}`
            }
            isOptionEqualToValue={(option, value) =>
              `${option.firstName} ${option.lastName}` ===
              `${value.firstName} ${value.lastName}`
            }
            getOptionDisabled={(option) =>
              multiple
                ? !!(
                    filters.user &&
                    filters.user.find(
                      (element: any) =>
                        `${option.firstName} ${option.lastName}` ===
                        `${element.firstName} ${element.lastName}`
                    )
                  )
                : false
            }
            value={
              multiple
                ? Array.isArray(filters.user)
                  ? users.filter((user: UserModel) =>
                      filters.user.includes(user._id)
                    )
                  : []
                : users.filter(
                    (user: UserModel) => user._id === filters.user
                  )[0] || null
            }
            freeSolo={false}
            disableClearable={disableClearable}
            selectOnFocus
            handleHomeEndKeys
            renderInput={(params) => (
              <TextField {...params} label={Vocabulary.user} size="small" />
            )}
            disabled={
              role === roles.admin || role === roles.reporting ? false : true
            }
            onChange={(event, newValue: any) => {
              let valueString: any = null;
              if (
                typeof newValue === "object" &&
                !Array.isArray(newValue) &&
                newValue !== null
              ) {
                valueString = newValue ? newValue._id : null;
              } else if (
                newValue &&
                Array.isArray(newValue) &&
                newValue.length !== 0
              ) {
                const value = newValue.map((user: UserModel) => user._id);
                valueString = value.join(",");
              }
              navigate(
                `${url}?startDate=${filters.startDate.format(
                  dayPilotFormat
                )}&endDate=${filters.endDate.format(
                  dayPilotFormat
                )}&user=${valueString}${
                  filters.orderId ? `&orderId=${filters.orderId}` : ""
                }`
              );
            }}
          />
        </Grid>
      );
    return null;
  }

  return (
    <GenericFilters>
      <Grid container spacing={2} style={{ marginBottom: 10 }}>
        <Grid item xs={12} md={6} lg={4} style={{ maxWidth: "100%" }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              ampm={false}
              name="startTime"
              label={Vocabulary.startDate}
              slotProps={{
                textField: {
                  style: { width: "100%" },
                  size: "small",
                },
              }}
              maxDate={filters.endDate}
              value={filters.startDate}
              onChange={(e: any) =>
                navigate(
                  `${url}?startDate=${e.format(
                    dayPilotFormat
                  )}&endDate=${filters.endDate.format(dayPilotFormat)}&user=${
                    filters.user
                      ? filters.user.length === 0
                        ? null
                        : filters.user
                      : null
                  }${filters.orderId ? `&orderId=${filters.orderId}` : ""}${
                    filters.workflowId && filters.workflowId.length !== 0
                      ? `&workflowId=${filters.workflowId}`
                      : `&workflowId=null`
                  }`
                )
              }
              format={euFormat}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              ampm={false}
              name="startTime"
              minDate={filters.startDate}
              label={Vocabulary.endDate}
              slotProps={{
                textField: {
                  style: { width: "100%" },
                  size: "small",
                },
              }}
              value={filters.endDate}
              onChange={(e: any) =>
                navigate(
                  `${url}?startDate=${filters.startDate.format(
                    dayPilotFormat
                  )}&endDate=${e.format(dayPilotFormat)}&user=${
                    filters.user
                      ? filters.user.length === 0
                        ? null
                        : filters.user
                      : null
                  }${filters.orderId ? `&orderId=${filters.orderId}` : ""}${
                    filters.workflowId && filters.workflowId.length !== 0
                      ? `&workflowId=${filters.workflowId}`
                      : `&workflowId=null`
                  }`
                )
              }
              format={euFormat}
            />
          </LocalizationProvider>
        </Grid>
        {displayOrder()}
        {displayWorkflow()}
        {displayUser()}
      </Grid>
      {recalculateReportsButton && role === roles.admin ? (
        <div style={{ float: "right", marginBottom: 20 }}>
          <Button
            onClick={recalculateReportsFunction}
            variant="contained"
            color="primary"
          >
            {Vocabulary.recalculateReports}
          </Button>
        </div>
      ) : null}
    </GenericFilters>
  );
}
