/* eslint-disable @typescript-eslint/no-explicit-any */
import ReportHeader from "./ReportHeader";
import TechnicianReports from "./TechnicianReports";
import { useEffect, useState } from "react";
import { getData } from "../../Services/getData";
import { localUrlEnum, urlEnum } from "../../Utils/UrlEnum";
import { FiltersProps } from "../../Pages/Reports/Reports";
import styles from "../../Styles/reports.module.css";
import moment from "moment";
import { Vocabulary } from "../../Utils/Vocabulary";
import { displayTimeString, showFormattedDuration } from "../../Utils/Utils";
import { Paper, Typography } from "@mui/material";
import { dayPilotFormat, euFormatForDateTime, numberOfDigits } from "../../Utils/Config";

type ReportsPerUserProps = {
  userId: string;
  filters: FiltersProps;
};
export type TechnicianReportProps = {
  orderFixablyId: number | null;
  orderId: string | null;
  stepName: string;
  stepId: string | null;
  trackedTime: number;
  predefinedTime: number;
  trackedCost: number;
  predefinedCost: number;
  date: Date;
};

export type ReportProps = {
  technicianId: string | null;
  technicianName: string;
  stepsNumber: number;
  totalTrackedTime: number;
  totalPredefinedTime: number;
  totalTrackedCosts: number;
  totalPredefinedCost: number;
  technicianReports: Array<TechnicianReportProps>;
  technicianReportsNumber: number;
};
export type LocalFilter = {
  page: number;
  perPage: number;
};

export default function ReportsPerUser(props: ReportsPerUserProps) {
  const { userId, filters } = props;
  const [userReport, setUserReport] = useState<ReportProps | null>(null);
  const [localFilters, setLocalFilters] = useState<LocalFilter>({
    page: 0,
    perPage: 10,
  });

  const tableHeader = [
    {
      label: Vocabulary.stepName,
      name: "stepName",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.order,
      name: "orderFixablyId",
      options: {
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          const id = userReport?.technicianReports[tableMeta.rowIndex].orderId;
          return (
            <a
              href={`${localUrlEnum.orders}/${id}`}
              style={{
                cursor: "pointer",
                textDecoration: "none",
                color: "black",
              }}
            >
              {value}
            </a>
          );
        },
      },
    },

    {
      label: Vocabulary.trackedTime,
      name: "trackedTime",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          const durationFormatted = showFormattedDuration(value);
          return <>{durationFormatted}</>;
        },
      },
    },
    {
      label: Vocabulary.predefinedTime,
      name: "predefinedTime",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          const durationFormatted = displayTimeString(value);
          return <>{durationFormatted}</>;
        },
      },
    },
    {
      label: Vocabulary.trackedCost,
      name: "trackedCost",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return <>{value.toFixed(numberOfDigits)}</>;
        },
      },
    },
    {
      label: Vocabulary.predefinedCost,
      name: "predefinedCost",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return <>{value.toFixed(numberOfDigits)}</>;
        },
      },
    },
    {
      label: Vocabulary.date,
      name: "date",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return <>{moment(value).format(euFormatForDateTime)}</>;
        },
      },
    },
  ];

  useEffect(() => {
    getReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, localFilters]);

  /**
   *
   */
  useEffect(() => {
    setLocalFilters({
      page: 0,
      perPage: 10,
    });
  }, [filters]);
  /**
   *
   * @param newFilters
   */
  function getReports() {
    const url = `${urlEnum.reports}/${localFilters.page}/${
      localFilters.perPage
    }/${moment(filters.startDate).format(dayPilotFormat)}/${moment(
      filters.endDate
    ).format(dayPilotFormat)}/${userId}/${filters.orderId}`;

    getData(url).then((response) => {
      if (
        response &&
        response.data &&
        (response.data.status === 200 || response.data.status === 201)
      )
        setUserReport(response.data.result[0]);
    });
  }

  /**
   *
   */
  function handleChangeLocalFilters(newFilters: LocalFilter) {
    setLocalFilters(newFilters);
  }

  /**
   *
   * @returns
   */
  function calcTotalEarnings() {
    const totalTrackedCosts =
      userReport?.technicianReports.reduce(
        (acc, item) =>
          item.predefinedTime && item.predefinedTime !== 0
            ? acc + 0
            : acc + item.trackedCost,
        0
      ) || 0;
    return (totalTrackedCosts + (userReport?.totalPredefinedCost || 0)).toFixed(
      2
    );
  }

  /**
   *
   * @returns
   */
  function calcTotalDelayedSteps() {
    return userReport?.technicianReports.reduce(
      (acc, item) => (item.trackedTime > item.predefinedTime ? acc + 1 : acc),
      0
    );
  }
  return (
    <>
      {userReport ? (
        <>
          <ReportHeader report={userReport} />
          <TechnicianReports
            tableHeader={tableHeader}
            items={userReport.technicianReports}
            count={userReport.stepsNumber}
            handleChangeLocalFilters={handleChangeLocalFilters}
            filters={localFilters}
            withStyles={true}
          />
          <Paper className={styles.reportsFooter}>
            <Typography>
              <span style={{ fontWeight: 200, fontSize: "18px" }}>
                {Vocabulary.totalEarnings}:
              </span>
              <span
                style={{ fontWeight: 600, marginLeft: 10, fontSize: "18px" }}
              >
                {calcTotalEarnings()}
              </span>
            </Typography>
            <Typography>
              <span style={{ fontWeight: 200, fontSize: "18px" }}>
                {Vocabulary.totalDelayedSteps}:
              </span>
              <span
                style={{ fontWeight: 600, marginLeft: 10, fontSize: "18px" }}
              >
                {calcTotalDelayedSteps()}
              </span>
            </Typography>
          </Paper>
        </>
      ) : null}
    </>
  );
}
