/* eslint-disable @typescript-eslint/no-explicit-any */
import CloseIcon from "@mui/icons-material/Close";
import { Button, Drawer, Typography, useMediaQuery } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";

type AddOrUpdateModelProps = {
  open: boolean;
  title: string;
  onClose: () => void;
  addOrUpdateItem: () => void;
  children: any;
  isSubmit?: boolean;
  handleChangePressOnSubmit?: () => void;
  buttons?: any;
  saveButtonTitle?: string;
  submitButtonDisabled?: boolean;
  hideButtons?: boolean;
};

export default function AddOrUpdateModel(props: AddOrUpdateModelProps) {
  const isMobile = useMediaQuery("(max-width:1000px)");
  // Define breakpoints for different screen sizes
  const isXs = useMediaQuery("(max-width:450px)");
  const {
    open,
    title,
    isSubmit,
    children,
    buttons,
    saveButtonTitle,
    submitButtonDisabled,
    hideButtons,
    onClose,
    addOrUpdateItem,
    handleChangePressOnSubmit,
  } = props;
  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          minWidth: isMobile ? (isXs ? "100vw" : "450px") : "700px",
          width: isMobile ? "100%" : "auto",
        },
      }}
    >
      <CloseIcon
        style={{ position: "absolute", top: 20, right: 20, cursor: "pointer" }}
        onClick={onClose}
      />
      <div
        style={
          isMobile
            ? { padding: 20, margin: 10 }
            : { padding: 20, margin: 10, maxWidth: 800, minWidth: 300 }
        }
      >
        <div style={{ textAlign: "left", paddingTop: 10 }}>
          <Typography variant="h4">{title}</Typography>
        </div>
        <br />
        {children}
        <br />
        {hideButtons ? null : (
          <>
            {!isSubmit ? (
              <Button
                variant="contained"
                style={{ marginBottom: 10, marginTop: 10 }}
                fullWidth
                color="primary"
                onClick={() => {
                  addOrUpdateItem();
                }}
              >
                {saveButtonTitle ? saveButtonTitle : Vocabulary.save}
              </Button>
            ) : (
              <Button
                type="submit"
                variant="contained"
                style={{ marginBottom: 10, marginTop: 10 }}
                fullWidth
                color="primary"
                disabled={submitButtonDisabled ? submitButtonDisabled : false}
                onClick={handleChangePressOnSubmit}
              >
                {saveButtonTitle ? saveButtonTitle : Vocabulary.save}
              </Button>
            )}
            {buttons ? (
              buttons
            ) : (
              <Button
                variant="contained"
                style={{ marginBottom: 10, marginTop: 10, color: "white" }}
                fullWidth
                color="secondary"
                onClick={onClose}
              >
                {Vocabulary.cancel}
              </Button>
            )}
          </>
        )}
      </div>
    </Drawer>
  );
}
