/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Grid, TextField } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { onChangeTextField } from "../../Utils/Utils";
import { Add } from "@mui/icons-material";

type MultiSelectProps = {
  defaultValues: Array<any>;
  handleChangeMultiselect: (defaultValues: Array<any>) => void;
};

export default function MultiSelect(props: MultiSelectProps) {
  const { defaultValues, handleChangeMultiselect } = props;
  return (
    <Grid container spacing={2}>
      {defaultValues.map((item, index) => {
        return (
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            key={`${index}`}
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <TextField
              name="label"
              size="small"
              fullWidth
              label={Vocabulary.name}
              value={item.label ? item.label : ""}
              onChange={(event: any) => {
                const newDefaultValues = [...defaultValues];
                newDefaultValues[index] = onChangeTextField(event, item);
                handleChangeMultiselect(newDefaultValues);
              }}
              style={{ marginRight: 10 }}
            ></TextField>
            <TextField
              name="value"
              size="small"
              fullWidth
              label={Vocabulary.value}
              value={item.value ? item.value : ""}
              onChange={(event: any) => {
                const newDefaultValues = [...defaultValues];
                newDefaultValues[index] = onChangeTextField(event, item);
                handleChangeMultiselect(newDefaultValues);
              }}
              style={{ marginLeft: 10 }}
            ></TextField>
          </Grid>
        );
      })}
      <Grid item xs={12} md={12} lg={12}>
        <Button
          variant="contained"
          style={{
            marginTop: 5,
            color: "white",
          }}
          color="secondary"
          onClick={() => {
            const newDefaultValues = [...defaultValues];
            newDefaultValues.push({ label: "", value: "" });
            handleChangeMultiselect(newDefaultValues);
          }}
        >
          <Add style={{ marginRight: 5, marginBottom: 2 }} fontSize="medium" />
          {Vocabulary.newOption}
        </Button>
      </Grid>
    </Grid>
  );
}
