/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Step } from "../../../Utils/Models";
import AddOrUpdateModel from "../../GenericComponents/AddOrUpdateModel";
import AssignToUser from "./AssignToUser";
import OrderStep from "./OrderStep";
import { OrderContext } from "../../../Contexts/OrderContext";
import AddAndShowImages from "../../GenericComponents/AddAndShowImages";
import useCustomMemo from "../../GenericComponents/useCustomMemo";
import { getData } from "../../../Services/getData";
import { urlEnum } from "../../../Utils/UrlEnum";
import { Vocabulary } from "../../../Utils/Vocabulary";
import { toggleTimeTracking } from "../../../Utils/AutocompleteUtils";

type TimeTrackingProps = {
  modifyStep: any;
  open: boolean;
  onClose: () => void;
  addOrUpdateStepToOrder: (createdAt?: any) => void;
  handleChangeModifyStep: (step: Step, index: number) => void;
};
export default function TimeTracking(props: TimeTrackingProps) {
  const {
    modifyStep,
    open,
    onClose,
    addOrUpdateStepToOrder,
    handleChangeModifyStep,
  } = props;
  const orderContext = useContext(OrderContext);
  const [databaseCurrentStep, setDatabaseCurrentStep] = useState<any>(null);
  const [alignment, setAlignment] = useState<string>(
    toggleTimeTracking[0].value
  );
  // all users from cache
  const cache = useCustomMemo();
  const users = cache.users || [];

  useEffect(() => {
    getStepFromDatabase();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modifyStep]);

  /**
   *
   */
  function getStepFromDatabase() {
    if (
      orderContext.order &&
      orderContext.order.workflow &&
      orderContext.order.workflow._id &&
      modifyStep &&
      modifyStep.step &&
      modifyStep.step.stepId._id
    ) {
      getData(
        `${urlEnum.steps}/oneStepForOneWorkflow/${modifyStep.step.stepId._id}/${orderContext.order.workflow._id}`
      ).then((response) => {
        if (response) {
          setDatabaseCurrentStep(response.data.result[0]);
        }
      });
    }
  }
  /**
   *
   * @param step
   */
  function handleChangeWorkflowSteps(step: Step) {
    const newWorkflowSteps = [...orderContext.order.workflowSteps];
    newWorkflowSteps[modifyStep.index] = step;
    handleChangeModifyStep(step, modifyStep.index);
    orderContext.setOrder({
      ...orderContext.order,
      workflowSteps: newWorkflowSteps,
    });
  }
  /////////////////////////// ADD/UPDATE IMAGES ///////////////////////////
  /**
   *
   */
  function handleDeleteImages(newImageList: any, newFileList: any) {
    const newStep: any = Object.assign({}, modifyStep.step);
    newStep.images = newImageList;
    newStep.files = newFileList;
    handleChangeWorkflowSteps(newStep);
    handleChangeModifyStep(newStep, modifyStep.index);
  }

  /**
   *
   * @param files
   */
  function handleChangeFiles(files: any) {
    const newStep: any = Object.assign({}, modifyStep.step);
    newStep.files = files;
    handleChangeWorkflowSteps(newStep);
    handleChangeModifyStep(newStep, modifyStep.index);
  }

  /**
   *
   * @param images
   */
  function displayDropzoneImages(images: any) {
    const newStep: any = Object.assign({}, modifyStep.step);
    newStep.images = images;
    handleChangeWorkflowSteps(newStep);
    handleChangeModifyStep(newStep, modifyStep.index);
  }
  /////////////////////////// END ADD/UPDATE IMAGES ///////////////////////////

  /**
   * Assign the step to the current user
   */
  function handleAssignToSelf() {
    const newStep: any = Object.assign({}, modifyStep.step);
    const user = users.find(
      (user: any) => user._id === localStorage.getItem("userId")
    );
    newStep.createdBy = user;
    handleChangeWorkflowSteps(newStep);
    handleChangeModifyStep(newStep, modifyStep.index);
    addOrUpdateStepToOrder(user);
  }
  /**
   *
   * @param event
   * @param newAlignment
   */
  function handleChange(
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) {
    setAlignment(newAlignment);
  }

  /**
   *
   * @returns
   */
  function showInformationForStep() {
    switch (alignment) {
      case "takePredefinedCost":
        return (
          <AssignToUser
            step={modifyStep.step}
            index={modifyStep.index}
            users={users}
            handleChangeModifyStep={handleChangeModifyStep}
          />
        );
      case "timeTracking":
        return <OrderStep modifyStep={modifyStep} />;
      default:
        return (
          <AssignToUser
            step={modifyStep.step}
            index={modifyStep.index}
            users={users}
            handleChangeModifyStep={handleChangeModifyStep}
          />
        );
    }
  }

  /**
   *
   * @returns
   */
  function showPredefinedTime() {
    if (
      databaseCurrentStep &&
      databaseCurrentStep.workflows &&
      databaseCurrentStep.workflows[0] &&
      databaseCurrentStep.workflows[0].time
    ) {
      const [hours, minutes, seconds] = databaseCurrentStep.workflows[0].time
        .split(":")
        .map(Number);
      return `${Vocabulary.predefinedTime}: ${hours}h ${minutes}m ${seconds}s`;
    }
    return null;
  }

  return (
    <AddOrUpdateModel
      open={open}
      title={modifyStep.step?.stepId?.name || ""}
      onClose={onClose}
      addOrUpdateItem={addOrUpdateStepToOrder}
    >
      <ToggleButtonGroup
        color="primary"
        value={alignment}
        exclusive
        fullWidth
        onChange={handleChange}
      >
        {toggleTimeTracking.map((toggle) => (
          <ToggleButton
            key={toggle.value}
            value={toggle.value}
            sx={{
              "&.MuiToggleButtonGroup-firstButton": {
                borderBottomRightRadius: "0px !important",
                borderBottomLeftRadius: "0px !important",
              },
              "&.MuiToggleButtonGroup-middleButton": {
                borderBottomRightRadius: "0px !important",
                borderBottomLeftRadius: "0px !important",
              },
              "&.MuiToggleButtonGroup-lastButton": {
                borderBottomRightRadius: "0px !important",
                borderBottomLeftRadius: "0px !important",
              },
            }}
          >
            {toggle.name}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <div
        style={{
          padding: "20px 10px",
          backgroundColor: "#f7f6f663",
          borderRadius: 4,
          border: "1px solid rgba(0, 0, 0, 0.12)",
          borderTop: "none",
          borderTopRightRadius: "0px",
          borderTopLeftRadius: "0px",
        }}
      >
        {showInformationForStep()}
      </div>
      <div style={{ marginTop: 25 }}>
        <p>{showPredefinedTime()}</p>
        <AddAndShowImages
          images={modifyStep.step.images}
          files={modifyStep.step.files}
          displayDropzoneImages={displayDropzoneImages}
          handleDeleteImages={handleDeleteImages}
          handleChangeFiles={handleChangeFiles}
        />
        <Button
          variant="outlined"
          style={{ marginBottom: -10, marginTop: 10 }}
          fullWidth
          color="primary"
          onClick={handleAssignToSelf}
        >
          {Vocabulary.assignToSelf}
        </Button>
      </div>
    </AddOrUpdateModel>
  );
}
