import "./index.css";
import "./i18n.js";
import reportWebVitals from "./reportWebVitals";
import * as ReactDOM from "react-dom/client";
import Router from "./Router/Router";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  // <React.StrictMode>
  <Router />
  // </React.StrictMode>
);

reportWebVitals();
