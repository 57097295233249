/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import AddOrUpdateModel from "../../../GenericComponents/AddOrUpdateModel";
import { Vocabulary } from "../../../../Utils/Vocabulary";
import { InventoryModel } from "../../../../Utils/Models";
import { useEffect, useRef, useState } from "react";
import { Autocomplete, Checkbox, Grid, TextField } from "@mui/material";
import {
  handleChangeAutocomplete,
  onChangeCheckbox,
  onChangeTextField,
} from "../../../../Utils/Utils";
import useCustomMemo from "../../../GenericComponents/useCustomMemo";

type InventoryProps = {
  editInventory: InventoryModel | null;
  open: boolean;
  addOrUpdateInventory: (inventory: InventoryModel | null) => void;
  onClose: () => void;
};

export default function Inventory(props: InventoryProps) {
  const { editInventory, open, addOrUpdateInventory, onClose } = props;
  const ref: any = useRef();
  const cache = useCustomMemo();
  const locations = cache.locations || [];
  const [inventory, setInventory] = useState<InventoryModel>(
    new InventoryModel()
  );
  const [pressOnSubmit, setPressOnSubmit] = useState(false);

  /**
   *
   */
  useEffect(() => {
    if (editInventory) {
      setInventory(editInventory);
    } else {
      setInventory(new InventoryModel());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editInventory]);

  /**
   *
   */
  useEffect(() => {
    if (pressOnSubmit) {
      ref.current.submit();
      //reset the value
      handleChangePressOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressOnSubmit]);

  /**
   *
   */
  function handleChangePressOnSubmit() {
    setPressOnSubmit(!pressOnSubmit);
  }

  return (
    <ValidatorForm ref={ref} onSubmit={() => addOrUpdateInventory(inventory)}>
      <AddOrUpdateModel
        open={open}
        title={Vocabulary.inventory}
        onClose={onClose}
        isSubmit={true}
        addOrUpdateItem={() => null}
        handleChangePressOnSubmit={handleChangePressOnSubmit}
      >
        <Grid container spacing={2} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextValidator
              size="small"
              id="name"
              name="name"
              label={Vocabulary.name}
              fullWidth
              variant="outlined"
              value={inventory.name}
              onChange={(e: any) =>
                setInventory(onChangeTextField(e, inventory))
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextValidator
              size="small"
              id="description"
              name="description"
              label={Vocabulary.description}
              fullWidth
              variant="outlined"
              value={inventory.description}
              onChange={(e: any) =>
                setInventory(onChangeTextField(e, inventory))
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Autocomplete
              size="small"
              id="location"
              freeSolo={false}
              disablePortal
              options={locations}
              value={inventory.location || null}
              getOptionLabel={(option: any) => option.name}
              isOptionEqualToValue={(option: any, value: any) =>
                option.name === value.name
              }
              onChange={(event: any, value: any) => {
                setInventory(
                  handleChangeAutocomplete(event, value, inventory, "location")
                );
              }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  label={Vocabulary.location}
                />
              )}
              style={{ minWidth: 250 }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <div style={{ display: "flex" }}>
              <Checkbox
                name="defaultInventory"
                style={{
                  margin: 0,
                  padding: 0,
                }}
                checked={inventory.defaultInventory}
                onChange={(event: any) =>
                  setInventory(onChangeCheckbox(event, inventory))
                }
              />
              <p style={{ margin: 15 }}>{Vocabulary.defaultInventory}</p>
            </div>
          </Grid>
        </Grid>
      </AddOrUpdateModel>
    </ValidatorForm>
  );
}
