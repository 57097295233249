/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Visibility } from "@mui/icons-material";
import styles from "../Styles/loginPage.module.css";
import {
  Button,
  Paper,
  IconButton,
  InputAdornment,
  Grid,
} from "@mui/material";
import { postData } from "../Services/postData";
import { urlEnum } from "../Utils/UrlEnum";
import { useNavigate } from "react-router-dom";
import { Vocabulary } from "../Utils/Vocabulary";
import { LoginModel } from "../Utils/Models";
import { onChangeTextField } from "../Utils/Utils";

export default function Login() {
  const navigate = useNavigate();
  const [user, setUser] = useState<LoginModel>(new LoginModel());
  const [showPassword, setShowPassword] = useState(false);

  /**
   *
   */
  async function handleSubmit() {
    try {
      const response: any = await postData(urlEnum.login, user);
      if (response && response.data) {
        localStorage.setItem("userId", response.data.userId);
        localStorage.setItem("userName", response.data.userName);
        localStorage.setItem("access_token", response.data.access_token);
        localStorage.setItem("role", response.data.roleId.name);
        navigate("/");
        window.location.reload();
      }
    } catch (err: any) {
      //
    }
  }

  return (
    <div className={styles.App}>
      <Paper className={styles.loginContainer} elevation={5}>
        <Grid container spacing={1} className={styles.gridMainContainer}>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className={styles.firstGrid}
          >
            <img src={"/upvolt.png"} alt="UPVOLT" width={"65%"} />
            <ValidatorForm className={styles.form} onSubmit={handleSubmit}>
              <TextValidator
                size="small"
                variant="standard"
                id="username"
                name="username"
                fullWidth
                label="Username"
                validators={["required"]}
                errorMessages={[Vocabulary.requiredField]}
                value={user.username}
                onChange={(event) => setUser(onChangeTextField(event, user))}
                className={styles.textfield}
              />
              <TextValidator
                size="small"
                variant="standard"
                id="password"
                name="password"
                type={!showPassword ? "password" : "text"}
                label={Vocabulary.password}
                fullWidth
                validators={["required"]}
                value={user.password}
                onChange={(event) => setUser(onChangeTextField(event, user))}
                className={styles.textfield}
                errorMessages={[Vocabulary.requiredField]}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        style={{ color: "#a5b3c0" }}
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      >
                        <Visibility />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                variant="contained"
                type="submit"
                className={styles.submit}
              >
                {Vocabulary.login}
              </Button>
            </ValidatorForm>
          </Grid>
          <Grid
            item
            xs={0}
            sm={0}
            md={6}
            lg={6}
            className={styles.imageContainer}
          >
            <img src={"/login.png"} alt="UPVOLT" className={styles.img} />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
