/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TuneIcon from "@mui/icons-material/Tune";
import { Vocabulary } from "../../Utils/Vocabulary";
import { useState } from "react";

export default function GenericFilters({ children }: any) {
  const [accordionState, setAccordionState] = useState<boolean>(true);

  return (
    <Accordion
      onChange={() => {
        setAccordionState(!accordionState);
      }}
      expanded={accordionState}
      style={{ margin: "0px 0px 20px 0px", width: "100%" }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <TuneIcon style={{ marginRight: 10 }}></TuneIcon>
        <Typography style={{ marginTop: 1 }}>{Vocabulary.filters}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}
