// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: true,
    backend: {
      loadPath: "./Utils/Vocabulary.tsx",
    },
    detection: {
      // Order of language detection
      order: [
        "cookie",
        "localStorage",
        "sessionStorage",
        "queryString",
        "navigator",
      ],
      // Cache the language in cookies
      caches: ["cookie"],
      cookieMinutes: 10080, // 7 days
      cookieDomain: window.location.hostname,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
