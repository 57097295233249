/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, Grid, TextField } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import useCustomMemo from "../GenericComponents/useCustomMemo";
import { useLocation, useNavigate } from "react-router-dom";
import { WorkflowModel } from "../../Utils/Models";
import GenericFilters from "../GenericComponents/GenericFilters";

type StatusesFiltersProps = {
  filters: any;
};
export default function StatusesFilters(props: StatusesFiltersProps) {
  const { filters } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const cache = useCustomMemo();
  const workflows = cache.workflows || [];

  return (
    <GenericFilters>
      <Grid container spacing={2} style={{ marginBottom: 10 }}>
        <Grid item xs={12} md={6} lg={4}>
          <Autocomplete
            options={workflows}
            getOptionLabel={(option: any) => option.name}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            value={
              workflows.filter(
                (workflow: WorkflowModel) => workflow._id === filters.workflowId
              )[0] || null
            }
            freeSolo={false}
            selectOnFocus
            handleHomeEndKeys
            renderInput={(params) => (
              <TextField {...params} label={Vocabulary.workflow} size="small" />
            )}
            onChange={(event, newValue: any) => {
              const params = new URLSearchParams(location.search);
              params.set("workflowId", newValue ? newValue._id : null);
              navigate({
                pathname: location.pathname,
                search: params.toString(),
              });
            }}
          />
        </Grid>
      </Grid>
    </GenericFilters>
  );
}
