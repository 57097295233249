/* eslint-disable @typescript-eslint/no-explicit-any */
import { Typography } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { useState } from "react";
import { WorkflowStepModel } from "../../Utils/Models";
import { TextValidator } from "react-material-ui-form-validator";

type DisplayTimeForOneStepProps = {
  step: WorkflowStepModel;
  index: number;
  saveTimeInStep: (event: any, step: WorkflowStepModel, index: number) => void;
};
export default function DisplayTimeForOneStep(
  props: DisplayTimeForOneStepProps
) {
  const { step, index, saveTimeInStep } = props;
  const [time, setTime] = useState<any>(
    step.workflows[0]?.time ? step.workflows[0]?.time : "00:00:00"
  );

  /**
   *
   * @param event
   * @param step
   * @param index
   * @returns
   */
  function onChangeTime(event: any, step: WorkflowStepModel, index: number) {
    const newTime = event.target.value;
    setTime(newTime);
    saveTimeInStep(event, step, index);
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "end",
        margin: "10px 0px",
      }}
    >
      <Typography variant="h6">{step.name}</Typography>
      <TextValidator
        id="time"
        name="time"
        variant="standard"
        label={`${Vocabulary.time}`}
        value={time}
        size="small"
        type="text"
        onChange={(event: any) => onChangeTime(event, step, index)}
        style={{ marginLeft: 10 }}
        helperText="HH:mm:ss"
        validators={["matchRegexp:^(?:[01]\\d|2[0-3]):[0-5]\\d:[0-5]\\d$"]}
        errorMessages={["HH:mm:ss"]}
        error={
          RegExp("^(?:[01]\\d|2[0-3]):[0-5]\\d:[0-5]\\d$").test(time)
            ? false
            : true
        }
      />
    </div>
  );
}
