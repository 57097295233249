/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconButton, Tooltip, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import styles from "../Styles/mainSlider.module.css";
import { Link } from "react-router-dom";
import { useRef } from "react";

type SubMenuForMainSliderMobileProps = {
  element: any;
  location: any;
  handleMenuClick: (menuId?: any) => void;
  isOpen: any;
};

export default function SubMenuForMainSliderMobile(
  props: SubMenuForMainSliderMobileProps
) {
  const { element, location, isOpen, handleMenuClick } = props;
  const scrollRef: any = useRef(null);

  const scrollLeft = () => {
    scrollRef.current.scrollLeft -= 100; // Adjust the scrolling distance as needed
  };

  const scrollRight = () => {
    scrollRef.current.scrollLeft += 100; // Adjust the scrolling distance as needed
  };
  return (
    <span style={{ marginLeft: 10 }}>
      <Tooltip title={element.name}>
        <Link
          style={{
            margin: 5,
            display: "flex",
            textDecoration: "none",
          }}
          to={element.path}
          onClick={
            element?.subMenu
              ? (e: any) => {
                  e.preventDefault();
                  handleMenuClick();
                }
              : () => {
                  ("");
                }
          }
        >
          <div style={{ textAlign: "center" }}>
            <IconButton
              className={
                location.pathname === element.path ||
                `${location.pathname}${location.search}` === element.path
                  ? styles.selectedMobile
                  : styles.nonSelectedMobile
              }
              id="1"
            >
              {element.icon}
            </IconButton>
            <Typography
              style={{ fontSize: 12, textDecoration: "none" }}
              className={
                location.pathname === element.path ||
                `${location.pathname}${location.search}` === element.path
                  ? styles.selectedTextMobile
                  : styles.nonSelectedTextMobile
              }
            >
              {element.name}
            </Typography>
          </div>
        </Link>
      </Tooltip>

      {element?.subMenu && isOpen ? (
        <div
          style={{
            position: "absolute",
            left: 0,
            bottom: 92,
            right: 0,
            paddingLeft: 30,
            paddingRight: 30,
          }}
        >
          <div
            style={{
              display: "flex",
              overflowX: "auto",
              overflowY: "hidden",
              whiteSpace: "nowrap",
            }}
            ref={scrollRef}
          >
            {element?.subMenu?.map((el: any, key: any) => {
              return (
                <Tooltip title={el.name}>
                  <Link
                    key={`${el.orderId}-${el.name}`}
                    style={{
                      textDecoration: "none",
                      margin: "5px 7px 0px 7px",
                    }}
                    to={el.path}
                  >
                    <IconButton
                      id="1"
                      size="small"
                      className={
                        location.pathname === el.path ||
                        `${location.pathname}${location.search}` === el.path
                          ? styles.selectedMobile
                          : styles.nonSelectedMobile
                      }
                    >
                      {el.icon}
                    </IconButton>
                  </Link>
                </Tooltip>
              );
            })}
          </div>
          <KeyboardArrowLeftIcon
            style={{
              position: "absolute",
              left: 0,
              top: "50%",
              transform: "translateY(-50%)",
            }}
            onClick={scrollLeft}
          />
          <KeyboardArrowRightIcon
            style={{
              position: "absolute",
              right: 0,
              top: "50%",
              transform: "translateY(-50%)",
            }}
            onClick={scrollRight}
          />
        </div>
      ) : null}
    </span>
  );
}
