/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import styles from "../Styles/dashboard.module.css";
import { Vocabulary } from "../Utils/Vocabulary";
import { urlEnum } from "../Utils/UrlEnum";
import MainTable from "../Components/GenericComponents/MainTable";
import { useContext, useState } from "react";
import { RoleModel } from "../Utils/Models";
import { updateData } from "../Services/updateData";
import { postData } from "../Services/postData";
import Role from "../Components/Roles/Role";
import { WebsocketContext } from "../Contexts/WebsocketContext";
import { useMediaQuery } from "@mui/material";

export default function Roles() {
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const websocketContext = useContext(WebsocketContext);
  const [open, setOpen] = useState(false);
  const [editItem, setEditItem] = useState<RoleModel | null>(null);
  const [shouldUpdate, setShouldUpdate] = useState(false);

  const roleHeader = [
    {
      label: Vocabulary.name,
      name: "name",
      options: { sort: false },
    },
    {
      label: Vocabulary.displayName,
      name: "displayName",
      options: {
        sort: false,
      },
    },
  ];
  /**
   *
   * @param item
   */
  function handleEdit(item: RoleModel | null) {
    setShouldUpdate(false);
    setOpen(!open);
    setEditItem(item);
  }
  /**
   *
   * @param role
   */
  function addOrUpdateRole(role: RoleModel | null) {
    setShouldUpdate(true);
    if (role?._id) {
      updateData(`${urlEnum.roles}/${role._id}`, role)
        .then((res) => {
          if (res) {
            websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
            setOpen(false);
          }
        })
        .catch((err) => {
          setOpen(false);
        });
    } else {
      postData(urlEnum.roles, role)
        .then((res: any) => {
          if (res) {
            websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
            setOpen(false);
          }
        })
        .catch((err) => {
          setOpen(false);
        });
    }
  }

  return (
    <div
      className={phoneStyle ? styles.mainContainerMobile : styles.mainContainer}
    >
      <MainTable
        urlEnumApi={urlEnum.roles}
        titleDelete={Vocabulary.deleteRole}
        textDelete={Vocabulary.deleteConfirmationRole}
        header={roleHeader}
        tableTitle={Vocabulary.rolesList}
        shouldUpdate={shouldUpdate}
        handleEdit={handleEdit}
      />
      {open ? (
        <Role
          editRole={editItem}
          open={open}
          addOrUpdateRole={(role) => addOrUpdateRole(role)}
          onClose={() => handleEdit(null)}
        />
      ) : null}
    </div>
  );
}
