/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useRef, useState } from "react";
import { EmailTemplateModel } from "../../../Utils/Models";
import AddOrUpdateModel from "../../GenericComponents/AddOrUpdateModel";
import { urlEnum } from "../../../Utils/UrlEnum";
import EmailContent from "./EmailContent";
import { ValidatorForm } from "react-material-ui-form-validator";
import { getHtml } from "../../../Utils/Utils";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { postData } from "../../../Services/postData";
import { OrderContext } from "../../../Contexts/OrderContext";

type SendEmailModalProps = {
  open: boolean;
  to: string;
  from: string;
  databaseTemplate: EmailTemplateModel | null;
  noteId: string | null;
  onClose: () => void;
  title: string;
};

export default function SendEmailModal(props: SendEmailModalProps) {
  const { open, to, from, title, databaseTemplate, noteId, onClose } = props;
  const ref: any = useRef();
  const orderContext = useContext(OrderContext);
  const [pressOnSubmit, setPressOnSubmit] = useState(false);

  const [emailInfo, setEmailInfo] = useState<any>({
    to: "",
    from: "",
    attachments: [],
    ...new EmailTemplateModel(),
  });

  /**
   *
   */
  useEffect(() => {
    if (databaseTemplate && to) {
      const newDatabaseTemplate = Object.assign({}, databaseTemplate) as any;
      newDatabaseTemplate.html = getHtml(newDatabaseTemplate.html);
      newDatabaseTemplate.to = to;
      newDatabaseTemplate.from = from;
      newDatabaseTemplate.attachments = [];
      setEmailInfo(newDatabaseTemplate);
    } else {
      const newEmailInfo = new EmailTemplateModel();
      setEmailInfo({ ...newEmailInfo, html: EditorState.createEmpty() });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [databaseTemplate]);

  /**
   *
   */
  useEffect(() => {
    if (pressOnSubmit) {
      ref.current.submit();
      //reset the value
      handleChangePressOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressOnSubmit]);

  /**
   *
   */
  function handleChangePressOnSubmit() {
    setPressOnSubmit(!pressOnSubmit);
  }

  /**
   *
   * @param newEmailInfo
   */
  function handleChangeEmailInfo(newEmailInfo: any) {
    setEmailInfo(newEmailInfo);
  }

  /**
   *
   */
  async function sendEmailToCustomer() {
    const html = draftToHtml(convertToRaw(emailInfo.html.getCurrentContent()));
    const formData = new FormData() as any;
    formData.append("to", emailInfo.to);
    formData.append("from", emailInfo.from);
    formData.append("cc", emailInfo.cc);
    formData.append("subject", emailInfo.subject);
    formData.append("html", html);
    emailInfo.attachments &&
      emailInfo.attachments.forEach((attachment: any) => {
        formData.append("files", attachment, attachment.name);
      });
    formData.append("noteId", noteId);
    postData(
      `${urlEnum.orders}/sendEmail/${orderContext.order._id}`,
      formData
    ).then((res) => {
      if (res) {
        window.location.reload();
      }
    });
  }

  return (
    <ValidatorForm
      ref={ref}
      onSubmit={() => {
        sendEmailToCustomer();
        onClose();
      }}
    >
      <AddOrUpdateModel
        open={open}
        title={title}
        onClose={onClose}
        isSubmit={true}
        addOrUpdateItem={() => null}
        handleChangePressOnSubmit={handleChangePressOnSubmit}
      >
        <EmailContent
          emailInfo={emailInfo}
          handleChangeEmailInfo={handleChangeEmailInfo}
        />
      </AddOrUpdateModel>
    </ValidatorForm>
  );
}
