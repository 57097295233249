/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Step,
  StepLabel,
  Typography,
  useMediaQuery,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import StepContent from "@mui/material/StepContent";
import { EmailTemplateModel, NotesModel } from "../../Utils/Models";
import EmailIcon from "@mui/icons-material/Email";
import SmsIcon from "@mui/icons-material/Sms";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import BuildIcon from "@mui/icons-material/Build";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import moment from "moment";
import { Edit } from "@mui/icons-material";
import { notesType, notesVisibility } from "../../Utils/AutocompleteUtils";
import { Vocabulary } from "../../Utils/Vocabulary";
import {
  euFormat,
  euFormatForDateTime,
  euFormatForTime,
} from "../../Utils/Config";
import parse from "html-react-parser";
import { useState } from "react";
import { download, getDocExtension } from "../../Utils/Utils";
import { urlEnum } from "../../Utils/UrlEnum";

export const iconsForNotes = {
  "Auftrag angelegt": {
    name: "Auftrag angelegt",
    label: "Order created",
    icon: (
      <CircleIcon
        style={{
          verticalAlign: "middle",
          color: "#404a694d",
        }}
        fontSize="small"
      />
    ),
    backgroundColor: "none",
  },
  ISSUE: {
    name: "ISSUE",
    label: "Issue",
    icon: (
      <BuildIcon
        style={{
          verticalAlign: "middle",
        }}
      />
    ),
    backgroundColor: "#3f903f",
  },
  "Auftrag / Fehlerbeschreib": {
    name: "Auftrag / Fehlerbeschreib",
    label: "Order / Error description",
    icon: (
      <BuildIcon
        style={{
          verticalAlign: "middle",
        }}
      />
    ),
    backgroundColor: "#3f903f",
  },
  DIAGNOSIS: {
    name: "DIAGNOSIS",
    label: "Diagnosis",
    icon: (
      <SearchIcon
        style={{
          verticalAlign: "middle",
        }}
      />
    ),
    backgroundColor: "#3f903f",
  },
  RESOLUTION: {
    name: "RESOLUTION",
    label: "Resolution",
    icon: (
      <CheckCircleIcon
        style={{
          verticalAlign: "middle",
        }}
      />
    ),
    backgroundColor: "#3f903f",
  },
  EMAIL: {
    name: "EMAIL",
    label: "Email",
    icon: (
      <EmailIcon
        style={{
          verticalAlign: "middle",
        }}
      />
    ),
    backgroundColor: "#404a694d",
  },
  INTERNAL: {
    name: "INTERNAL",
    label: "Internal",
    icon: (
      <EditIcon
        style={{
          verticalAlign: "middle",
        }}
      />
    ),
    backgroundColor: "#5bc0de",
  },
  SMS: {
    name: "SMS",
    label: "SMS",
    icon: (
      <SmsIcon
        style={{
          verticalAlign: "middle",
        }}
      />
    ),
    backgroundColor: "#404a694d",
  },
  OTHER: {
    name: "OTHER",
    label: "Other",
    icon: "",
  },
} as any;

type OrderNotesProps = {
  note: NotesModel;
  index: number;
  handleEdit: (note: NotesModel, index: number) => void;
  handleChangeOpenSendEmailValue: (value: any) => void;
};
export default function DisplayOneNote(props: OrderNotesProps) {
  const { note, index, handleEdit, handleChangeOpenSendEmailValue } = props;
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const [isExpanded, setIsExpanded] = useState(false);

  /**
   *
   */
  function toggleExpand() {
    setIsExpanded(!isExpanded);
  }

  /**
   *
   * @param attachment
   */
  async function handleClick(attachment: any) {
    const token = localStorage.getItem("access_token");
    const type = getDocExtension(attachment.split(".").pop());

    fetch(`${urlEnum.orders}/download${attachment}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.blob())
      .then((response) => {
        download(response, attachment.split("/").pop(), type);
      });
  }

  return (
    <div key={`${note._id}_${note.title}`}>
      <Step>
        <StepLabel>
          <Chip
            style={{
              backgroundColor: "#404a694d",
              color: "white",
              fontSize: 17,
              padding: 10,
            }}
            label={moment(note.createdAt).format(euFormat)}
          />
        </StepLabel>
      </Step>
      <Step active={true}>
        <StepLabel
          StepIconComponent={() => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p style={{ color: "#404a694d" }}>
                {moment(note.createdAt).format(euFormatForTime)}
              </p>
              <span
                style={{
                  verticalAlign: "middle",
                  textAlign: "center",
                  borderRadius: "100%",
                  padding: 7,
                  color: "white",
                  backgroundColor:
                    iconsForNotes[note.type || ""]?.backgroundColor,
                  marginLeft: 10,
                }}
              >
                <span>{iconsForNotes[note.type || ""]?.icon}</span>
              </span>
            </div>
          )}
        ></StepLabel>
        <StepContent>
          <Box
            style={{
              marginLeft: 70,
              padding: 20,
              border: "1px solid #eeeeee",
              boxShadow: "0 0 10px #f0f0f0",
              borderRadius: 20,
              position: "relative",
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={3} md={3} lg={3}>
                <Typography style={{ fontSize: 13 }}>
                  {note && note.createdBy
                    ? `${note.createdBy.firstName} ${note.createdBy.lastName}`
                    : ""}
                </Typography>
              </Grid>

              <Grid item xs={9} md={9} lg={9} style={{ textAlign: "end" }}>
                <span style={phoneStyle ? {} : { marginRight: 15 }}>
                  {note.visibility === notesVisibility.visible ? (
                    <Chip
                      label={Vocabulary.customerVisibility}
                      style={phoneStyle ? { margin: "5px 0px" } : {}}
                    />
                  ) : null}
                </span>
                <span>
                  {note.report === 1 ? (
                    <Chip
                      label={Vocabulary.printOutsVisibility}
                      style={phoneStyle ? { margin: "5px 0px" } : {}}
                    />
                  ) : null}
                </span>
                {note.type === notesType.email ? (
                  <Button
                    onClick={() => {
                      const newTemplate = new EmailTemplateModel();
                      newTemplate.html = "";
                      newTemplate.subject = note.emailDetails?.subject || "";
                      let to = note.emailDetails?.to.split("<");
                      if (to.length > 1) {
                        to = to[1];
                        to = to.replace(">", "");
                      } else {
                        to = to[0];
                      }
                      let from = note.emailDetails?.from.split("<");
                      if (from.length > 1) {
                        from = from[1];
                        from = from.replace(">", "");
                      } else {
                        from = from[0];
                      }
                      handleChangeOpenSendEmailValue({
                        open: true,
                        template: newTemplate,
                        to: to || "",
                        from: from || "",
                        noteId: note._id,
                      });
                    }}
                    style={
                      phoneStyle
                        ? {
                            margin: 5,
                            border: "1px solid #404a69b0",
                            color: "#404a69b0",
                          }
                        : {
                            margin: "0px 5px",
                            border: "1px solid #404a69b0",
                            color: "#404a69b0",
                          }
                    }
                  >
                    <EmailIcon style={{ marginRight: 5 }} />{" "}
                    {Vocabulary.replyEmail}
                  </Button>
                ) : null}
                <span>
                  <Edit
                    style={{
                      cursor: "pointer",
                      color: "#404a69b0",
                      paddingTop: 15,
                      paddingLeft: 5,
                    }}
                    onClick={() => handleEdit(note, index)}
                  />
                </span>
              </Grid>
            </Grid>

            <Typography style={{ fontSize: 10 }}>{note.type}</Typography>
            <Typography
              variant="h3"
              style={{ fontWeight: "bold", marginTop: 10 }}
            >
              {note.title}
            </Typography>
            {note.emailDetails ? (
              <div style={{ marginTop: 5 }}>
                <Typography style={{ fontSize: 12 }}>
                  {Vocabulary.subject}: {note.emailDetails.subject}
                </Typography>
                <Typography style={{ fontSize: 12 }}>
                  {Vocabulary.to}: {note.emailDetails.to}
                </Typography>
                <Typography style={{ fontSize: 12 }}>
                  {Vocabulary.from}: {note.emailDetails.from}
                </Typography>
                <Typography style={{ fontSize: 12 }}>
                  {Vocabulary.date}:{" "}
                  {moment(note.emailDetails.date).format(euFormatForDateTime)}
                </Typography>
              </div>
            ) : null}
            <Divider style={{ margin: "20px 0px" }} />
            <Typography>
              {isExpanded
                ? note.text
                  ? parse(note.text)
                  : ""
                : note.text
                ? parse(`${note.text.substring(0, 200)}...`)
                : ""}
            </Typography>
            {note.text && note.text.length > 200 && (
              <IconButton onClick={toggleExpand} size="small">
                {isExpanded ? <ArrowCircleUpIcon /> : <ArrowCircleDownIcon />}
              </IconButton>
            )}
            <div>
              {note.attachments
                ? note.attachments.map((attachment: any) => (
                    <Chip
                      key={attachment}
                      label={attachment.split("/").pop()}
                      onClick={() => handleClick(attachment)}
                    />
                  ))
                : null}
            </div>
          </Box>
        </StepContent>
      </Step>
    </div>
  );
}
