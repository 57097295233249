/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Autocomplete,
  Checkbox,
  Grid,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  FormControlLabel,
} from "@mui/material";
import { Visibility } from "@mui/icons-material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { useEffect, useRef, useState } from "react";
import { UserModel } from "../../Utils/Models";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import AddOrUpdateModel from "../GenericComponents/AddOrUpdateModel";
import { getData } from "../../Services/getData";
import { urlEnum } from "../../Utils/UrlEnum";
import { v4 as uuidv4 } from "uuid";
import { handleChangeAutocomplete, onChangeTextField } from "../../Utils/Utils";
type Props = {
  editUser: UserModel | null;
  open: boolean;
  addOrUpdateUser: (user: UserModel | null) => void;
  onClose: () => void;
};

export default function UserForm(props: Props) {
  const { editUser, open, addOrUpdateUser, onClose } = props;
  const ref: any = useRef();
  const [user, setUser] = useState<UserModel>(new UserModel());
  const [roles, setRoles] = useState<Array<any>>([]);
  const [showPassword, setShowPassword] = useState(false);
  const [pressOnSubmit, setPressOnSubmit] = useState(false);

  /**
   *
   */
  useEffect(() => {
    if (editUser) {
      setUser(editUser);
    } else {
      setUser(new UserModel());
    }
    getRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editUser]);

  useEffect(() => {
    if (pressOnSubmit) {
      ref.current.submit();
      //reset the value
      handleChangePressOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressOnSubmit]);

  /**
   *
   */
  function handleChangePressOnSubmit() {
    setPressOnSubmit(!pressOnSubmit);
  }
  /**
   *
   */
  function getRoles() {
    getData(urlEnum.roles).then((res: any) => {
      if (res.data.status === 200) setRoles(res.data.result);
    });
  }

  /**
   *
   */
  function generateCode() {
    const code = uuidv4();
    setUser({
      ...user,
      password: code.substr(0, 10),
      confirmPassword: code.substr(0, 10),
    });
  }

  /**
   *
   */
  function handleChangeShowPassword() {
    setShowPassword(!showPassword);
  }

  return (
    <ValidatorForm ref={ref} onSubmit={() => addOrUpdateUser(user)}>
      <AddOrUpdateModel
        open={open}
        title={Vocabulary.user}
        onClose={onClose}
        isSubmit={true}
        addOrUpdateItem={() => null}
        handleChangePressOnSubmit={handleChangePressOnSubmit}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <TextValidator
              size="small"
              id="lastName"
              name="lastName"
              label={Vocabulary.lastName}
              fullWidth
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              variant="outlined"
              value={user.lastName}
              onChange={(event: any) => setUser(onChangeTextField(event, user))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextValidator
              size="small"
              name="firstName"
              id="firstName"
              label={Vocabulary.firstName}
              fullWidth
              variant="outlined"
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              value={user.firstName}
              onChange={(event: any) => setUser(onChangeTextField(event, user))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextValidator
              size="small"
              variant="outlined"
              name="email"
              id="email"
              label={Vocabulary.email}
              fullWidth
              validators={["required", "isEmail"]}
              errorMessages={[
                Vocabulary.requiredField,
                Vocabulary.emailValidation,
              ]}
              value={user.email}
              onChange={(event: any) => setUser(onChangeTextField(event, user))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Autocomplete
              size="small"
              id="roleId"
              freeSolo={false}
              disablePortal
              getOptionLabel={(option: any) => option.displayName}
              isOptionEqualToValue={(option, value) =>
                option.displayName === value.displayName
              }
              options={roles}
              value={user.roleId}
              onChange={(event: any, value: any) => {
                setUser(handleChangeAutocomplete(event, value, user, "roleId"));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="roleId"
                  label={Vocabulary.roles}
                  variant="outlined"
                  error={!user.roleId}
                  helperText={!user.roleId && Vocabulary.required}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextValidator
              size="small"
              variant="outlined"
              name="username"
              id="username"
              label={Vocabulary.userName}
              fullWidth
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              value={user.username}
              onChange={(event: any) => setUser(onChangeTextField(event, user))}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {user._id ? (
                <FormControlLabel
                  id="isEditable"
                  name="isEditable"
                  control={<Checkbox checked={user.isEditable} />}
                  label={Vocabulary.changePassword}
                  onChange={(event) =>
                    setUser({ ...user, isEditable: !user.isEditable })
                  }
                />
              ) : null}
              <FormControlLabel
                id="isActive"
                name="isActive"
                control={<Checkbox checked={user.isActive} />}
                label={Vocabulary.activate}
                onChange={(event) =>
                  setUser({ ...user, isActive: !user.isActive })
                }
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextValidator
              size="small"
              id="password"
              label={Vocabulary.password}
              name="password"
              type={!showPassword ? "password" : "text"}
              validators={user.isEditable ? ["required"] : ("" as any)}
              errorMessages={
                user.isEditable
                  ? [Vocabulary.requiredField, Vocabulary.passwordNoMatch]
                  : ""
              }
              error={user.password && user.confirmPassword !== user.password}
              value={user.password || ""}
              onChange={(event) => setUser(onChangeTextField(event, user))}
              disabled={!user.isEditable}
              fullWidth
              helperText={
                user.password &&
                user.confirmPassword !== user.password &&
                Vocabulary.passwordNoMatch
              }
              InputProps={{
                endAdornment: (
                  <Button
                    variant="outlined"
                    style={{
                      maxWidth: 100,
                      fontSize: 10,
                    }}
                    onClick={generateCode}
                    fullWidth
                    disabled={!user.isEditable}
                  >
                    {Vocabulary.generate}
                  </Button>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextValidator
              size="small"
              id="confirmPassword"
              label={Vocabulary.confirmPassword}
              name="confirmPassword"
              type={!showPassword ? "password" : "text"}
              validators={user.isEditable ? ["required"] : ("" as any)}
              errorMessages={
                user.isEditable
                  ? [Vocabulary.requiredField, Vocabulary.passwordNoMatch]
                  : ""
              }
              error={user.password && user.confirmPassword !== user.password}
              value={user.confirmPassword || ""}
              onChange={(event) => setUser(onChangeTextField(event, user))}
              disabled={!user.isEditable}
              fullWidth
              helperText={
                user.password &&
                user.confirmPassword !== user.password &&
                Vocabulary.passwordNoMatch
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      style={{ color: "#a5b3c0" }}
                      onClick={handleChangeShowPassword}
                    >
                      <Visibility />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </AddOrUpdateModel>
    </ValidatorForm>
  );
}
