/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextField } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";

type OptionValueProps = {
  value: string;
  valueIndex: number;
  icon: any;
  handleChangeCurrentOptionValue: (event: any, valueIndex: number) => void;
  validateCurrentOptionValue: (value: string, valueIndex: number) => boolean;
};
export default function OptionValue(props: OptionValueProps) {
  const {
    value,
    valueIndex,
    icon,
    handleChangeCurrentOptionValue,
    validateCurrentOptionValue,
  } = props;
  return (
    <TextField
      id={`values[${valueIndex}]`}
      name="values"
      size="small"
      variant="outlined"
      value={value}
      label={value === "" ? Vocabulary.value : ""}
      onChange={(event: any) =>
        handleChangeCurrentOptionValue(event.target.value, valueIndex)
      }
      InputProps={{
        endAdornment: icon,
      }}
      fullWidth
      style={{ marginBottom: 10 }}
      error={validateCurrentOptionValue(value, valueIndex)}
      helperText={
        validateCurrentOptionValue(value, valueIndex)
          ? Vocabulary.requiredField
          : null
      }
    />
  );
}
