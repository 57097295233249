/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Autocomplete,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
} from "@mui/material";

import Checkbox from "@mui/material/Checkbox";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useEffect, useRef, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Config from "../../Utils/Config";
import { DeviceModel } from "../../Utils/Models";
import { Vocabulary } from "../../Utils/Vocabulary";
import AddOrUpdateModel from "../GenericComponents/AddOrUpdateModel";
import {
  handleChangeAutocomplete,
  onChangeCheckbox,
  onChangeDatePicker,
  onChangeTextField,
} from "../../Utils/Utils";
import { urlEnum } from "../../Utils/UrlEnum";
import { getData } from "../../Services/getData";
import moment from "moment";

type DeviceProps = {
  editDevice: DeviceModel | null;
  open: boolean;
  addOrUpdateDevice: (device: DeviceModel) => void;
  onClose: () => void;
};
export default function Device(props: DeviceProps) {
  const { editDevice, open, addOrUpdateDevice, onClose } = props;
  const [device, setDevice] = useState<DeviceModel>(new DeviceModel());
  const [pressOnSubmit, setPressOnSubmit] = useState(false);
  const [arraysOfString, setArraysOfString] = useState<any>({
    tags: [],
    customFields: [],
  });
  const ref: any = useRef();
  const buttons = (
    <Button
      onClick={resetDevice}
      variant="contained"
      style={{ marginBottom: 10, marginTop: 10, color: "white" }}
      fullWidth
      color="secondary"
    >
      {Vocabulary.reset}
    </Button>
  );
  /**
   *
   */
  useEffect(() => {
    if (editDevice) {
      setDevice(editDevice);
    } else {
      setDevice(new DeviceModel());
      getArraysOfString();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editDevice]);

  useEffect(() => {
    if (pressOnSubmit) {
      ref.current.submit();
      //reset the value
      handleChangePressOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressOnSubmit]);

  /**
   *
   */
  function getArraysOfString() {
    getData(`${urlEnum.devices}/arraysOfString`).then((res: any) => {
      if (res.data.status === 200) setArraysOfString(res.data.result);
    });
  }

  /**
   *
   */
  function handleChangePressOnSubmit() {
    setPressOnSubmit(!pressOnSubmit);
  }
  /**
   *
   */
  function resetDevice() {
    if (editDevice) {
      setDevice(editDevice);
    } else {
      setDevice(new DeviceModel());
      getArraysOfString();
    }
  }

  return (
    <ValidatorForm ref={ref} onSubmit={() => addOrUpdateDevice(device)}>
      <AddOrUpdateModel
        open={open}
        title={Vocabulary.device}
        onClose={onClose}
        isSubmit={true}
        addOrUpdateItem={() => null}
        handleChangePressOnSubmit={handleChangePressOnSubmit}
        buttons={buttons ? buttons : null}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextValidator
              size="small"
              variant="outlined"
              name="serialNumber"
              id="serialNumber"
              label={Vocabulary.serialNumber}
              fullWidth
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              value={device.serialNumber}
              onChange={(event) => setDevice(onChangeTextField(event, device))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextValidator
              size="small"
              variant="outlined"
              name="imeiNumber1"
              id="imeiNumber1"
              label={Vocabulary.imeiNumber1}
              fullWidth
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              value={device.imeiNumber1}
              onChange={(event) => setDevice(onChangeTextField(event, device))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextValidator
              size="small"
              variant="outlined"
              name="imeiNumber2"
              id="imeiNumber2"
              label={Vocabulary.imeiNumber2}
              fullWidth
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              value={device.imeiNumber2}
              onChange={(event) => setDevice(onChangeTextField(event, device))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextValidator
              size="small"
              variant="outlined"
              name="name"
              id="name"
              label={Vocabulary.name}
              fullWidth
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              value={device.name}
              onChange={(event) => setDevice(onChangeTextField(event, device))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextValidator
              size="small"
              variant="outlined"
              name="configuration"
              id="configuration"
              label={Vocabulary.configuration}
              fullWidth
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              value={device.configuration}
              onChange={(event) => setDevice(onChangeTextField(event, device))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextValidator
              size="small"
              variant="outlined"
              name="code"
              id="code"
              label={Vocabulary.code}
              fullWidth
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              value={device.code}
              onChange={(event) => setDevice(onChangeTextField(event, device))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextValidator
              size="small"
              variant="outlined"
              name="family"
              id="family"
              label={Vocabulary.deviceFamily}
              fullWidth
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              value={device.family}
              onChange={(event) => setDevice(onChangeTextField(event, device))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextValidator
              size="small"
              variant="outlined"
              name="brand"
              id="brand"
              label={Vocabulary.brand}
              fullWidth
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              value={device.brand}
              onChange={(event) => setDevice(onChangeTextField(event, device))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextValidator
              size="small"
              variant="outlined"
              name="model"
              id="model"
              label={Vocabulary.model}
              fullWidth
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              value={device.model}
              onChange={(event) => setDevice(onChangeTextField(event, device))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextValidator
              size="small"
              variant="outlined"
              name="distributor"
              id="distributor"
              label={Vocabulary.distributor}
              fullWidth
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              value={device.distributor}
              onChange={(event) => setDevice(onChangeTextField(event, device))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextValidator
              size="small"
              variant="outlined"
              name="purchaseCountry"
              id="purchaseCountry"
              label={Vocabulary.purchaseCountry}
              fullWidth
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              value={device.purchaseCountry}
              onChange={(event) => setDevice(onChangeTextField(event, device))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextValidator
              size="small"
              variant="outlined"
              name="productImageUrl"
              id="productImageUrl"
              label={Vocabulary.productImageUrl}
              fullWidth
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              value={device.productImageUrl}
              onChange={(event) => setDevice(onChangeTextField(event, device))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Autocomplete
              size="small"
              id="tags"
              multiple
              freeSolo={true}
              value={device.tags || []}
              options={arraysOfString.tags || []}
              getOptionLabel={(option) => option}
              isOptionEqualToValue={(option, value) => option === value}
              getOptionDisabled={(option) =>
                !!device.tags.find((element: any) => element === option)
              }
              onChange={(event, value) => {
                setDevice(
                  handleChangeAutocomplete(event, value, device, "tags")
                );
                const tags = new Set([...arraysOfString.tags, ...value]);
                setArraysOfString({
                  ...arraysOfString,
                  tags: Array.from(tags),
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label={Vocabulary.tags}
                  placeholder="Select a option"
                />
              )}
            />
          </Grid>
          {/*  customFields vine de tip obiect nu string. Exemplu:
              {kaufdatum-jahr:'2017'
              konfiguration:'2s1p'} 
              Vine ori o proprietate, ori alta, ori amandoua*/}
          {/* <Grid item xs={12} sm={12} md={6} lg={6}>
            <Autocomplete size="small"
              id="customFields"
              multiple
              freeSolo={true}
              value={device.customFields || []}
              options={arraysOfString.customFields || []}
              getOptionLabel={(option) => option}
              getOptionDisabled={(option) =>
                !!device.customFields.find((element: any) => element === option)
              }
              isOptionEqualToValue={(option, value) => option === value}
              filterSelectedOptions
              onChange={(event, value) => {
                setDevice(
                  handleChangeAutocomplete(event, value, device, "customFields")
                );
                const customFields = new Set([
                  ...arraysOfString.customFields,
                  ...value,
                ]);
                setArraysOfString({
                  ...arraysOfString,
                  customFields: Array.from(customFields),
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label={Vocabulary.customFields}
                  placeholder="Select a option"
                />
              )}
            />
          </Grid> */}
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                name="purchaseDate"
                label={Vocabulary.purchaseDate}
                slotProps={{
                  textField: { style: { width: "100%" }, size: "small" },
                }}
                value={
                  typeof device.purchaseDate === "string"
                    ? moment(device.purchaseDate)
                    : device.purchaseDate
                }
                onChange={(e: any) =>
                  setDevice(onChangeDatePicker(e, device, "purchaseDate"))
                }
                format={Config.momentEUDateFormat}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <FormGroup
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <FormControlLabel
                id="isActivationLocked"
                name="isActivationLocked"
                control={
                  <Checkbox checked={device.isActivationLocked || false} />
                }
                label={Vocabulary.isActivationLocked}
                value={device.isActivationLocked}
                onChange={(event) => setDevice(onChangeCheckbox(event, device))}
              />
              <FormControlLabel
                name="isCarrierLocked"
                id={"isCarrierLocked"}
                control={
                  <Checkbox
                    checked={Boolean(device.isCarrierLocked) || false}
                  />
                }
                label={Vocabulary.isCarrierLocked}
                value={device.isCarrierLocked}
                onChange={(event) => setDevice(onChangeCheckbox(event, device))}
              />
              <FormControlLabel
                name="isVintage"
                id="isVintage"
                control={
                  <Checkbox checked={Boolean(device.isVintage) || false} />
                }
                label={Vocabulary.isVintage}
                value={device.isVintage}
                onChange={(event) => setDevice(onChangeCheckbox(event, device))}
              />
              <FormControlLabel
                name="isObsolete"
                id="isObsolete"
                control={
                  <Checkbox checked={Boolean(device.isObsolete) || false} />
                }
                label={Vocabulary.isObsolete}
                value={device.isObsolete}
                onChange={(event) => setDevice(onChangeCheckbox(event, device))}
              />
              <FormControlLabel
                name="isLoaner"
                id="isLoaner"
                control={
                  <Checkbox checked={Boolean(device.isLoaner) || false} />
                }
                label={Vocabulary.isLoaner}
                value={device.isLoaner}
                onChange={(event) => setDevice(onChangeCheckbox(event, device))}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </AddOrUpdateModel>
    </ValidatorForm>
  );
}
