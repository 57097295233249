/* eslint-disable @typescript-eslint/no-explicit-any */
import { Chip, Divider } from "@mui/material";
import { Vocabulary } from "../../../../Utils/Vocabulary";
import AddOrUpdateModel from "../../../GenericComponents/AddOrUpdateModel";
import LoginIcon from "@mui/icons-material/Login";

type ShowProductInfoProps = {
  product: any;
  open: boolean;
  onClose: () => void;
};

export default function ShowProductInfo(props: ShowProductInfoProps) {
  const { open, product, onClose } = props;
  return (
    <AddOrUpdateModel
      open={open}
      title={Vocabulary.product}
      onClose={onClose}
      addOrUpdateItem={() => null}
      hideButtons={true}
    >
      <div style={{ textAlign: "justify" }}>
        <p>
          <span style={{ fontWeight: 300 }}> {Vocabulary.name}:</span>
          &nbsp;&nbsp;
          <span style={{ fontWeight: 500 }}>{product.product?.name || ""}</span>
        </p>

        <p>
          <span style={{ fontWeight: 300 }}>
            {Vocabulary.code}:&nbsp;&nbsp;
          </span>
          <span style={{ fontWeight: 500 }}>{product.product?.code || ""}</span>
        </p>
        <p>
          <span style={{ fontWeight: 300 }}>{Vocabulary.ean}:&nbsp;&nbsp;</span>
          <span style={{ fontWeight: 500 }}>{product.product?.ean || ""}</span>
        </p>
        <p>
          <span style={{ fontWeight: 300 }}> {Vocabulary.manufacturer}:</span>
          &nbsp;&nbsp;
          <span style={{ fontWeight: 500 }}>
            {product.product?.manufacturer || ""}
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 300 }}> {Vocabulary.type}:</span>
          &nbsp;&nbsp;
          <span style={{ fontWeight: 500 }}>
            {product.product?.typeString || ""}
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 300 }}>
            {Vocabulary.tags}:&nbsp;&nbsp;
          </span>
          <span style={{ fontWeight: 500 }}>
            {product.product?.tags.map((tag: string) => (
              <Chip
                label={tag}
                style={{
                  marginBottom: 10,
                  marginRight: 10,
                  borderRadius: 4,
                  height: "auto",
                  padding: 5,
                }}
                color="primary"
              />
            ))}
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 300 }}> {Vocabulary.productPrice}:</span>
          &nbsp;&nbsp;
          <span style={{ fontWeight: 500, verticalAlign: "middle" }}>
            {product.product?.purchaseStockPrice || 0}{" "}
            <LoginIcon
              style={{ verticalAlign: "middle", margin: "0px 10px 3px 5px" }}
            />
            {product.product?.stockPrice || 0}
          </span>
        </p>
        <Divider />
        <p>
          <span style={{ fontWeight: 300 }}> {Vocabulary.inventoryPrice}:</span>
          &nbsp;&nbsp;
          <span style={{ fontWeight: 500 }}>{product.price || 0}</span>
        </p>
        <p>
          <span style={{ fontWeight: 300 }}>
            {Vocabulary.inventoryQuantity}:
          </span>
          &nbsp;&nbsp;
          <span style={{ fontWeight: 500 }}>{product.quantity || 0}</span>
        </p>
      </div>
    </AddOrUpdateModel>
  );
}
