/* eslint-disable @typescript-eslint/no-explicit-any */
import { Chip } from "@mui/material";
import styles from "../Styles/dashboard.module.css";
import { Vocabulary } from "../Utils/Vocabulary";
import { urlEnum } from "../Utils/UrlEnum";
import { useContext, useState } from "react";
import UserForm from "../Components/Users/UserForm";
import { postData } from "../Services/postData";
import { updateData } from "../Services/updateData";
import MainTable from "../Components/GenericComponents/MainTable";
import { UserModel } from "../Utils/Models";
import { WebsocketContext } from "../Contexts/WebsocketContext";
import theme from "../Themes/Theme";

export const styleForTableWithTabs = {
  MuiToolbarRoot: {
    backgroundColor: "#404a694d",
    borderTopRightRadius: "0px",
    borderTopLeftRadius: "0px",
    display: "flex",
  },
};

export default function Users() {
  const websocketContext = useContext(WebsocketContext);
  const [openModal, setOpenModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserModel | null>(null);
  const [shouldUpdate, setShouldUpdate] = useState(false);

  const userHeader = [
    { label: Vocabulary.lastName, name: "lastName", options: { sort: false } },
    {
      label: Vocabulary.firstName,
      name: "firstName",
      options: { sort: false },
    },
    { label: Vocabulary.userName, name: "username", options: { sort: false } },
    { label: Vocabulary.email, name: "email", options: { sort: false } },
    {
      label: Vocabulary.roles,
      name: "roleId",
      options: {
        sort: false,
        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          if (value) return <Chip label={value.displayName} />;
          return null;
        },
      },
    },
    {
      label: Vocabulary.activate,
      name: "isActive",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return value ? (
            <Chip
              label={Vocabulary.active}
              style={{
                backgroundColor: theme.palette.successColor?.main,
                color: "white",
              }}
            />
          ) : (
            <Chip
              label={Vocabulary.inactive}
              style={{
                backgroundColor: theme.palette.errorColor?.main,
                color: "white",
              }}
            />
          );
        },
      },
    },
  ];

  /**
   *
   * @param user
   */
  const handleCreateOrUpdateUser = (user: any) => {
    const newUser = Object.assign({}, user);
    newUser.roleId = newUser.roleId._id;
    setShouldUpdate(true);
    if (user._id) {
      updateData(`${urlEnum.users}/${newUser._id}`, newUser)
        .then((res: any) => {
          if (res) {
            websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
            setOpenModal(false);
          }
        })
        .catch((ex) => {
          setOpenModal(false);
        });
    } else {
      postData(urlEnum.register, newUser)
        .then((res: any) => {
          if (res) {
            websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
            setOpenModal(false);
          }
        })
        .catch((ex) => {
          setOpenModal(false);
        });
    }
  };

  /**
   *
   * @param item
   */
  function handleEdit(item: UserModel | null) {
    setShouldUpdate(false);
    setOpenModal(!openModal);
    setSelectedUser(item);
  }

  return (
    <div className={styles.mainContainerUser}>
      <MainTable
        urlEnumApi={urlEnum.users}
        titleDelete={Vocabulary.deleteUsers}
        textDelete={Vocabulary.deleteConfirmationUser}
        header={userHeader}
        tableTitle={Vocabulary.employeesList}
        shouldUpdate={shouldUpdate}
        handleEdit={handleEdit}
        style={styleForTableWithTabs}
      />
      {openModal ? (
        <UserForm
          editUser={selectedUser}
          open={openModal}
          addOrUpdateUser={handleCreateOrUpdateUser}
          onClose={() => handleEdit(null)}
        />
      ) : null}
    </div>
  );
}
