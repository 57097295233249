/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Checkbox, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Vocabulary } from "../../Utils/Vocabulary";
import { CloudUpload } from "@mui/icons-material";
import DropzoneComponent from "./DropzoneComponent";
import styles from "../../Styles/showImages.module.css";
import { useState } from "react";
import theme from "../../Themes/Theme";

type AddAndShowImagesProps = {
  images: string[];
  files: any;
  checkedImages?: any;
  isSmall?: boolean;
  handleDeleteImages: (
    newImageList: any,
    newFileList: any,
    index: number
  ) => void;
  handleChangeFiles: (files: any) => void;
  displayDropzoneImages: (images: any) => void;
  handleCheckImages?: (index: number) => void;
};

export default function AddAndShowImages(props: AddAndShowImagesProps) {
  const {
    images,
    files,
    checkedImages,
    isSmall,
    handleDeleteImages,
    handleChangeFiles,
    displayDropzoneImages,
    handleCheckImages,
  } = props;
  const [showDropzone, setShowDropzone] = useState<boolean>(false);
  const [dropzoneFiles, setDropzoneFiles] = useState<any>([]);
  /**
   *
   * @param files
   */
  function handleChangeDropFile(files: any) {
    if (files.length !== 0) {
      handleChangeFiles(files);
      const images = [] as any;
      files.forEach((file: any) => {
        const reader = new FileReader();
        if (file.type.match("image.*")) {
          reader.readAsDataURL(file);
          reader.onloadend = function () {
            images.push({
              path: reader.result,
              name: file.name,
            });
            setDropzoneFiles(images);
          };
        }
      });
    }
  }

  /**
   *
   * @param index
   */
  function deleteImage(index: any) {
    const newImageList = images.slice() as any;
    let newFileList = [];
    if (files) {
      newFileList = files.slice() as any;
      const fileIndex = newFileList.findIndex(
        (file: any) => file.name === newImageList[index].name
      );
      if (fileIndex >= 0) newFileList.splice(fileIndex, 1);
    }
    newImageList.splice(index, 1);
    handleDeleteImages(newImageList, newFileList, index);
  }

  /**
   *
   */
  function showImages() {
    displayDropzoneImages([...images, ...dropzoneFiles]);
    setDropzoneFiles([]);
    setShowDropzone(false);
  }
  return (
    <>
      {showDropzone ? (
        <div style={{ marginTop: 20 }}>
          <DropzoneComponent
            onSave={(files: any) => handleChangeDropFile(files)}
          />
          <Button
            variant="contained"
            size="large"
            className={styles.showImagesBtn}
            onClick={showImages}
          >
            {Vocabulary.finish}
          </Button>
        </div>
      ) : (
        <>
          <Button
            variant="contained"
            size="medium"
            style={{ marginTop: 20, marginBottom: 10 }}
            onClick={() => setShowDropzone(true)}
          >
            <CloudUpload
              fontSize="medium"
              style={{
                marginRight: 10,
              }}
            />
            {Vocabulary.attachFiles}
          </Button>
          <div>
            <div className={styles.displayImagesContainer}>
              {images.map((image: any, index: any) => {
                return (
                  <div
                    key={`image_${index}`}
                    className={
                      isSmall ? styles.displayImagesSmall : styles.displayImages
                    }
                  >
                    {handleCheckImages && handleCheckImages ? (
                      <Checkbox
                        checked={checkedImages.includes(index) ? true : false}
                        onChange={() => handleCheckImages(index)}
                        className={styles.checkboxImagesBtn}
                        style={{ color: theme.palette.primary.main }}
                      />
                    ) : null}
                    <IconButton
                      className={styles.deleteImagesBtn}
                      onClick={() => deleteImage(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <img
                      src={image.path ? image.path : image}
                      alt="preview"
                      style={{ height: "100%" }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </>
  );
}
