/* eslint-disable @typescript-eslint/no-explicit-any */
import { RoleModel } from "../../Utils/Models";
import { useEffect, useRef, useState } from "react";
import AddOrUpdateModel from "../GenericComponents/AddOrUpdateModel";
import { Vocabulary } from "../../Utils/Vocabulary";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Grid } from "@mui/material";
import { onChangeTextField } from "../../Utils/Utils";

type RoleProps = {
  editRole: RoleModel | null;
  open: boolean;
  addOrUpdateRole: (role: RoleModel | null) => void;
  onClose: () => void;
};
export default function Role(props: RoleProps) {
  const { editRole, open, addOrUpdateRole, onClose } = props;
  const ref: any = useRef();
  const [role, setRole] = useState<RoleModel>(new RoleModel());
  const [pressOnSubmit, setPressOnSubmit] = useState(false);

  /**
   *
   */
  useEffect(() => {
    if (editRole) {
      setRole(editRole);
    } else {
      setRole(new RoleModel());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editRole]);

  /**
   *
   */
  useEffect(() => {
    if (pressOnSubmit) {
      ref.current.submit();
      //reset the value
      handleChangePressOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressOnSubmit]);

  /**
   *
   */
  function handleChangePressOnSubmit() {
    setPressOnSubmit(!pressOnSubmit);
  }

  return (
    <ValidatorForm ref={ref} onSubmit={() => addOrUpdateRole(role)}>
      <AddOrUpdateModel
        open={open}
        title={Vocabulary.role}
        onClose={onClose}
        isSubmit={true}
        addOrUpdateItem={() => null}
        handleChangePressOnSubmit={handleChangePressOnSubmit}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextValidator
              size="small"
              id="name"
              name="name"
              label={Vocabulary.name}
              fullWidth
              variant="outlined"
              value={role.name}
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              onChange={(event: any) => setRole(onChangeTextField(event, role))}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextValidator
              size="small"
              id="displayName"
              name="displayName"
              label={Vocabulary.displayName}
              fullWidth
              variant="outlined"
              value={role.displayName}
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              onChange={(event: any) => setRole(onChangeTextField(event, role))}
            />
          </Grid>
        </Grid>
      </AddOrUpdateModel>
    </ValidatorForm>
  );
}
