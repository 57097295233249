/* eslint-disable @typescript-eslint/no-explicit-any */

import moment from "moment";
import { TimeTracking } from "./Models";
import { postData } from "../Services/postData";
import { urlEnum } from "./UrlEnum";
import htmlToDraft from "html-to-draftjs";
import { ContentState, EditorState } from "draft-js"; //, Modifier, convertToRaw

/**
 *
 * @param model
 * @param propertiesArray
 * @returns
 */
export function generateFormData(model: any) {
  const formData = new FormData();

  for (const property in model) {
    if (typeof model[property] === "boolean")
      formData.append(property, model[property]);
    else formData.append(property, model[property] ? model[property] : null);
  }

  return formData;
}

/**
 *
 * @param event
 * @param model
 * @returns
 */
export function onChangeTextField(event: any, model: any) {
  const newModel: any = Object.assign({}, model);
  newModel[event.target.name] = event.target.value;
  return newModel;
}

/**
 *
 * @param event
 * @param model
 * @returns
 */
export function onChangeCheckbox(event: any, model: any) {
  const newModel: any = Object.assign({}, model);
  newModel[event.target.name] = !newModel[event.target.name];
  return newModel;
}

export function onChangeCheckboxWithNumbers(event: any, model: any) {
  const newModel: any = Object.assign({}, model);
  newModel[event.target.name] = !newModel[event.target.name] ? 1 : 0;
  return newModel;
}

/**
 *
 * @param event
 * @param model
 * @param value
 * @param name
 * @returns
 */
export function handleChangeAutocomplete(
  event: any,
  value: any,
  model: any,
  name?: string
) {
  if (name) {
    const newModel: any = Object.assign({}, model);
    if (value) newModel[name] = value;
    else newModel[name] = null;
    return newModel;
  }
  return value;
}

/**
 *
 * @param event
 * @param model
 * @param name
 * @returns
 */
export function onChangeDatePicker(event: any, model: any, name: string) {
  const newModel: any = Object.assign({}, model);
  newModel[name] = event;
  return newModel;
}

/**
 *
 * @param event
 * @param index
 * @param model
 * @returns
 */
export function handleChangeCustomFields(
  event: any,
  index: number,
  model: any
) {
  const newModel: any = [...model];
  newModel[index][event.target.name] = event.target.value;
  return newModel;
}

/**
 *
 * @param timeTracking
 * @param interval
 * @returns
 */
export function verifyInterval(
  timeTracking: Array<TimeTracking>,
  interval: TimeTracking
) {
  if (interval.endTime && interval.endTime < interval.startTime) return false;
  for (const item of timeTracking) {
    if (interval.startTime < item.startTime) {
      if (interval.endTime && interval.endTime > item.startTime) {
        return false;
      }
    } else if (interval.startTime > item.startTime) {
      if (item.endTime && interval.startTime < item.endTime) {
        return false;
      }
    }
  }
  return true;
}

/**
 *
 * @param interval
 * @returns
 */
export function calculateDurationForInterval(interval: any) {
  return interval.endTime - interval.startTime;
}
/**
 *
 * @param duration
 * @returns
 */
export function showFormattedDuration(time: any) {
  const durationInMilliseconds = time * 1000;
  const duration = moment.duration(durationInMilliseconds);
  // const days = Math.floor(duration.asDays());
  const hours = Math.floor(duration.asHours());
  const minutes = Math.floor(duration.asMinutes()) % 60;
  const seconds = Math.floor(duration.asSeconds()) % 60;

  const formattedDuration = `${hours}h ${minutes}m ${seconds}s`;
  return formattedDuration;
}

/**
 *
 */
export function logout() {
  localStorage.removeItem("userId");
  localStorage.removeItem("lastName");
  localStorage.removeItem("userEmail");
  localStorage.removeItem("userName");
  localStorage.removeItem("access_token");
  localStorage.removeItem("cache");
}

/**
 *
 * @param array
 * @param name
 * @returns
 */
export function findObjectByName(array: any[], name: string) {
  return array.find((item) => item.name === name);
}

/**
 *
 * @param discount
 * @param quantity
 * @param unitPrice
 * @returns
 */
export const calcSubTotal = (discount: any, quantity: any, unitPrice: any) => {
  const subTotal =
    parseFloat(quantity) * parseFloat(unitPrice) -
    (parseFloat(quantity) * parseFloat(unitPrice) * parseFloat(discount)) / 100;
  return isNaN(subTotal) ? 0 : subTotal.toFixed(2);
};
/**
 *
 * @param subtotal
 * @param tva
 * @returns
 */
export const calcSubTotalWithTVA = (subtotal: any, tva: any) => {
  const subTotalWithTVA =
    parseFloat(subtotal) + parseFloat(subtotal) * (parseFloat(tva) / 100);
  return isNaN(subTotalWithTVA) ? 0 : subTotalWithTVA.toFixed(2);
};

/**
 *
 * @param discount
 * @param quantity
 * @param unitPrice
 * @returns
 */
export const calcDiscount = (discount: any, quantity: any, unitPrice: any) => {
  const subTotal =
    (parseFloat(quantity) * parseFloat(unitPrice) * parseFloat(discount)) / 100;
  return isNaN(subTotal) ? 0 : subTotal.toFixed(2);
};

export async function saveTimeTrackingToDatabase(
  orderId: string,
  stepIndex: number,
  timeTracking: TimeTracking[]
) {
  // save timeTracking
  const res = await postData(
    `${urlEnum.orders}/saveTimeTracking/${orderId}/${stepIndex}`,
    timeTracking
  );
  if (res) return res.data;
  return null;
}

/**
 *
 * @param src
 * @returns
 */
export const checkIfSrcBelongsToPdf = (src: string) => {
  return src.endsWith(".pdf");
};
/**
 *
 * @param time
 * @returns
 */
export function displayTime(time: number) {
  // Convert total seconds back to time format (HH:MM:SS)
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.ceil(time % 60);

  // Format hours, minutes, and seconds to always have two digits
  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");
  return [formattedHours, formattedMinutes, formattedSeconds];
}

/**
 *
 * @param time
 * @returns
 */
export function displayTimeString(time: number) {
  const [formattedHours, formattedMinutes, formattedSeconds] =
    displayTime(time);
  return `${formattedHours}h ${formattedMinutes}m ${formattedSeconds}s`;
}

/**
 *
 * @param response
 * @param fileName
 * @param type
 */
export async function download(response: Blob, fileName: string, type: string) {
  const blob = new Blob([response], { type: type });
  const link = document.createElement("a");
  link.download = fileName;
  link.href = URL.createObjectURL(blob);
  link.click();
}

/**
 *
 * @param newImageList
 * @param variants
 * @returns
 */
export function deleteImagesFromProductVariants(
  newImageList: any,
  variants: any
) {
  const updatedVariants = variants.map((variant: any) => {
    if (!variant.images) return variant;
    const updatedImages = variant.images.filter((image: any) => {
      if (typeof image === "string") {
        return newImageList.includes(image);
      } else if (typeof image === "object" && image.name) {
        return newImageList.some((imgPath: any) =>
          imgPath.name
            ? imgPath.name === image.name
            : imgPath.includes(image.name.split(".")[0])
        );
      }
      return false;
    });
    return {
      ...variant,
      images: updatedImages,
    };
  });

  return updatedVariants;
}

/**
 *
 * @param html
 * @returns
 */
export function getHtml(html: string) {
  const contentBlock = htmlToDraft(html);
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );
  return EditorState.createWithContent(contentState);
}

/**
 *
 * @param ext
 */
export function getDocExtension(ext: string) {
  switch (ext) {
    case "pdf":
      return "application/pdf";
    case "docx":
    case "doc":
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
      return "image/*";
    case "xls":
    case "xlsx":
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    default:
      return "";
  }
}
