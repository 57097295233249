/* eslint-disable @typescript-eslint/no-explicit-any */
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { StockTransferModel } from "../../../../Utils/Models";
import AddOrUpdateModel from "../../../GenericComponents/AddOrUpdateModel";
import { Vocabulary } from "../../../../Utils/Vocabulary";
import { useEffect, useRef, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import {
  handleChangeAutocomplete,
  onChangeTextField,
} from "../../../../Utils/Utils";
import ProductsAutocomplete from "../../ProductsAutocomplete";
import ProductsList from "./ProductsList";
import { RenderAddComponent } from "./StockTransfers";
import {
  PurchaseOrderStatuses,
  StockTransferStatuses,
} from "../../../../Utils/AutocompleteUtils";
import useCustomMemo from "../../../GenericComponents/useCustomMemo";

type StockTransferProps = {
  editStockTransfer: StockTransferModel | null;
  open: boolean;
  component: string;
  addOrUpdateStockTransfer: (stockTransfer: StockTransferModel | null) => void;
  onClose: () => void;
};

export default function StockTransfer(props: StockTransferProps) {
  const {
    editStockTransfer,
    open,
    component,
    addOrUpdateStockTransfer,
    onClose,
  } = props;
  const ref: any = useRef();
  const cache = useCustomMemo();
  const inventories = cache.inventories || [];
  const [stockTransfer, setStockTransfer] = useState<StockTransferModel>(
    new StockTransferModel()
  );
  const [pressOnSubmit, setPressOnSubmit] = useState(false);

  /**
   *
   */
  useEffect(() => {
    if (editStockTransfer) {
      setStockTransfer(editStockTransfer);
    } else {
      setStockTransfer(new StockTransferModel());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editStockTransfer]);

  /**
   *
   */
  useEffect(() => {
    if (pressOnSubmit) {
      ref.current.submit();
      //reset the value
      handleChangePressOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressOnSubmit]);

  /**
   *
   */
  function handleChangePressOnSubmit() {
    setPressOnSubmit(!pressOnSubmit);
  }

  /**
   * Add new product in stock transfer
   */
  function addNewProductInStockTransfer(event: any, value: any) {
    if (value) {
      const products = [...stockTransfer.products];
      const inventory = value.inventories.find((element: any) => {
        return element.inventory._id === stockTransfer?.from?._id;
      });
      if (inventory) {
        products.push({
          product: value,
          quantity: inventory.quantity,
          price: inventory.price,
          status: PurchaseOrderStatuses.open,
          maxQuantity: inventory.quantity,
        });
      } else {
        products.push({
          product: value,
          quantity: 0,
          price: null,
          status: PurchaseOrderStatuses.open,
          maxQuantity: 0,
        });
      }
      setStockTransfer({ ...stockTransfer, products });
    }
  }

  /**
   *
   * @param event
   * @param index
   */
  function onChangeProducts(products: any) {
    setStockTransfer({ ...stockTransfer, products });
  }

  return (
    <ValidatorForm
      ref={ref}
      onSubmit={() => {
        if (component === RenderAddComponent.outtake)
          stockTransfer.status = StockTransferStatuses.outtake;
        else stockTransfer.status = StockTransferStatuses.inProgress;
        addOrUpdateStockTransfer(stockTransfer);
      }}
    >
      <AddOrUpdateModel
        open={open}
        title={
          component === RenderAddComponent.outtake
            ? Vocabulary.outtake
            : Vocabulary.stockTransfer
        }
        onClose={onClose}
        isSubmit={true}
        addOrUpdateItem={() => null}
        handleChangePressOnSubmit={handleChangePressOnSubmit}
        submitButtonDisabled={
          stockTransfer.status === StockTransferStatuses.received
        }
      >
        <Autocomplete
          size="small"
          id="from"
          freeSolo={false}
          disablePortal
          getOptionLabel={(option: any) =>
            `${option.name} - ${option.location?.name || ""}`
          }
          isOptionEqualToValue={(option, value) => option._id === value._id}
          options={inventories}
          value={stockTransfer.from}
          onChange={(event: any, value: any) => {
            const newStockTransfer = handleChangeAutocomplete(
              event,
              value,
              stockTransfer,
              "from"
            );
            newStockTransfer.products = [];
            setStockTransfer(newStockTransfer);
          }}
          renderInput={(params) => (
            <TextValidator
              {...params}
              name="from"
              label={Vocabulary.from}
              value={stockTransfer.from || ""}
              variant="outlined"
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
            />
          )}
          style={{ margin: "20px 0px" }}
        />
        {component === RenderAddComponent.transfer ? (
          <Autocomplete
            size="small"
            id="to"
            freeSolo={false}
            disablePortal
            getOptionLabel={(option: any) =>
              `${option.name} - ${option.location?.name || ""}`
            }
            isOptionEqualToValue={(option, value) => option._id === value._id}
            options={inventories}
            value={stockTransfer.to}
            onChange={(event: any, value: any) => {
              setStockTransfer(
                handleChangeAutocomplete(event, value, stockTransfer, "to")
              );
            }}
            renderInput={(params) => (
              <TextValidator
                {...params}
                name="to"
                label={Vocabulary.to}
                value={stockTransfer.to || ""}
                variant="outlined"
                validators={["required"]}
                errorMessages={[Vocabulary.requiredField]}
              />
            )}
            style={{ margin: "20px 0px" }}
          />
        ) : null}
        <ProductsAutocomplete
          parentProduct={null}
          handleChangeProductInParent={(event: any, value: any) => {
            addNewProductInStockTransfer(event, value);
          }}
          variant="outlined"
          disabled={!stockTransfer.from ? true : false}
        />
        <ProductsList
          products={stockTransfer.products}
          showPrice={false}
          onChangeProducts={onChangeProducts}
        />
        {component === RenderAddComponent.outtake ? (
          <TextField
            size="small"
            id="comments"
            name="comments"
            fullWidth
            label={Vocabulary.comments}
            variant="outlined"
            value={stockTransfer.comments}
            onChange={(event: any) => {
              setStockTransfer(onChangeTextField(event, stockTransfer));
            }}
            style={{ margin: "20px 0px" }}
          />
        ) : null}
      </AddOrUpdateModel>
    </ValidatorForm>
  );
}
