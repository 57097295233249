/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from "react";

export default function useQueryStringParser(
  requestFunction: (params: any) => void
) {
  /**
   *
   */
  useEffect(() => {
    const url = new URL("", window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const entries = searchParams.entries();
    requestFunction(Object.fromEntries(entries));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.location.search]);

  return {};
}
