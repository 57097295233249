/* eslint-disable @typescript-eslint/no-explicit-any */
import { Tab, Tabs, useMediaQuery } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "../../Styles/dashboard.module.css";
import { localUrlEnum } from "../../Utils/UrlEnum";
import { usersTypes } from "../../Utils/AutocompleteUtils";
import Users from "../../Pages/Users";
import Customers from "../../Pages/Customers";
import theme from "../../Themes/Theme";

export default function UsersMainPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const [value, setValue] = useState(0);

  useEffect(() => {
    const search: any = location.search.split("?");
    search.forEach((value: any) => {
      if (value !== "") {
        const params = value.split("&");
        const values = params[0].split("=");
        setValueFromLocation(values[1]);
      }
    });
  }, [location]);

  /**
   *
   * @param newValue
   */
  function setValueFromLocation(newValue: string) {
    switch (newValue) {
      case usersTypes.employees:
        setValue(0);
        break;
      case usersTypes.customers:
        setValue(1);
        break;
      case usersTypes.vendors:
        setValue(2);
        break;
      default:
        setValue(0);
        break;
    }
  }
  /**
   *
   * @param event
   * @param newValue
   */
  function handleChangeValue(event: React.SyntheticEvent, newValue: number) {
    switch (newValue) {
      case 0:
        navigate(`${localUrlEnum.users}?type=${usersTypes.employees}`);
        break;
      case 1:
        navigate(`${localUrlEnum.users}?type=${usersTypes.customers}`);
        break;
      case 2:
        navigate(`${localUrlEnum.users}?type=${usersTypes.vendors}`);
        break;
      default:
        navigate(`${localUrlEnum.users}?type=${usersTypes.employees}`);
        break;
    }
  }

  /**
   *
   */
  function showInformationForTabs() {
    switch (value) {
      case 0:
        return <Users />;
      case 1:
        return <Customers title={Vocabulary.customersList} />;
      case 2:
        return <Customers title={Vocabulary.vendorsList} />;
      default:
        return <Users />;
    }
  }

  /**
   *
   * @param tabValue
   * @returns
   */
  function getTabStyle(tabValue: number) {
    return {
      backgroundColor:
        value === tabValue
          ? theme.palette.secondary.main
          : theme.palette.cancel?.main,
      color: value === tabValue ? "white" : "#9c9b9b",
      borderTopRightRadius: "10px",
      borderTopLeftRadius: "10px",
      marginRight: "1px",
    };
  }

  return (
    <div className={phoneStyle ? styles.mainContainerMobile : styles.mainContainer}>
      <Tabs
        value={value}
        onChange={handleChangeValue}
        TabIndicatorProps={{
          style: {
            backgroundColor: theme.palette.secondary.main,
          },
        }}
      >
        <Tab label={Vocabulary.employees} style={getTabStyle(0)} />
        <Tab label={Vocabulary.customers} style={getTabStyle(1)} />
        <Tab label={Vocabulary.vendors} style={getTabStyle(2)} />
      </Tabs>
      <> {showInformationForTabs()}</>
    </div>
  );
}
