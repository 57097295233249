/* eslint-disable @typescript-eslint/no-explicit-any */
import { AttributeModel } from "../../Utils/Models";
import AddOrUpdateModel from "../GenericComponents/AddOrUpdateModel";
import { useEffect, useRef, useState } from "react";
import { Vocabulary } from "../../Utils/Vocabulary";
import GenericAttribute from "./GenericAttribute";
import { ValidatorForm } from "react-material-ui-form-validator";

type AttributeProps = {
  editAttribute: AttributeModel | null;
  open: boolean;
  addOrUpdateAttribute: (attribute: AttributeModel | null) => void;
  onClose: () => void;
};

export default function Attribute(props: AttributeProps) {
  const { editAttribute, open, addOrUpdateAttribute, onClose } = props;
  const ref: any = useRef();
  const [pressOnSubmit, setPressOnSubmit] = useState(false);
  const [attribute, setAttribute] = useState<AttributeModel>(
    new AttributeModel()
  );

  /**
   *
   */
  useEffect(() => {
    if (editAttribute) {
      const newAttribute = {
        ...editAttribute,
        values: [...editAttribute.values],
      };
      newAttribute.values.push("");
      setAttribute(newAttribute);
    } else {
      setAttribute(new AttributeModel());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editAttribute]);

  /**
   *
   */
  useEffect(() => {
    if (pressOnSubmit) {
      ref.current.submit();
      //reset the value
      handleChangePressOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressOnSubmit]);

  /**
   *
   */
  function handleChangePressOnSubmit() {
    setPressOnSubmit(!pressOnSubmit);
  }

  /**
   *
   * @param newAttribute
   */
  function handleChangeAttribute(newAttribute: AttributeModel) {
    setAttribute(newAttribute);
  }

  return (
    <ValidatorForm ref={ref} onSubmit={() => addOrUpdateAttribute(attribute)}>
      <AddOrUpdateModel
        open={open}
        title={Vocabulary.attribute}
        onClose={onClose}
        isSubmit={true}
        addOrUpdateItem={() => null}
        handleChangePressOnSubmit={handleChangePressOnSubmit}
      >
        <GenericAttribute
          attribute={attribute}
          handleChangeAttribute={handleChangeAttribute}
        />
      </AddOrUpdateModel>
    </ValidatorForm>
  );
}
