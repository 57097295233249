/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid } from "@mui/material";
import { TextValidator } from "react-material-ui-form-validator";
import { Vocabulary } from "../../Utils/Vocabulary";
import { displayTime, onChangeTextField } from "../../Utils/Utils";
import { WorkflowModel, WorkflowStepModel } from "../../Utils/Models";
import DisplayTimeForOneStep from "./DisplayTimeForOneStep";

type WorkflowCostProps = {
  workflow: WorkflowModel;
  steps: WorkflowStepModel[];
  handleChangeWorkflow: (workflow: WorkflowModel) => void;
  handleChangeStep: (steps: WorkflowStepModel[]) => void;
};
export default function WorkflowCost(props: WorkflowCostProps) {
  const { workflow, steps, handleChangeWorkflow, handleChangeStep } = props;

  function stepsTimeSum(steps: any) {
    // Convert time strings to seconds and sum them up
    const totalSeconds = steps.reduce((acc: any, curr: any) => {
      if (
        !RegExp("^(?:[01]\\d|2[0-3]):[0-5]\\d:[0-5]\\d$").test(
          curr.workflows[0].time
        )
      ) {
        return acc;
      }
      const [hours, minutes, seconds] = curr.workflows[0].time
        .split(":")
        .map(Number);
      return acc + hours * 3600 + minutes * 60 + seconds;
    }, 0);

    const [formattedHours, formattedMinutes, formattedSeconds] =
      displayTime(totalSeconds);

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }
  /**
   *
   * @param event
   * @param step
   * @param index
   */
  function saveTimeInStep(event: any, step: WorkflowStepModel, index: number) {
    //if is correct format
    const newStep = onChangeTextField(event, step.workflows[0]);
    const newSteps = [...steps];
    newSteps[index].workflows[0] = newStep;
    const newWorkflow = { ...workflow };
    const sum = stepsTimeSum(newSteps);
    newWorkflow.time = sum;
    handleChangeWorkflow(newWorkflow);
    handleChangeStep(newSteps);
  }

  function calcCostPerWorkflow() {
    const cost = workflow.cost || 0;
    const [hours, minutes, seconds] = (workflow.time || "00:00:00")
      .split(":")
      .map(Number);
    const timeInHours = hours + minutes / 60 + seconds / 3600;
    return cost * timeInHours;
  }

  return (
    <Grid container spacing={2} style={{ marginTop: 10 }}>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          variant="outlined"
          label={`${Vocabulary.workflowCost} (${Vocabulary.perHour})`}
          value={workflow?.cost || 0}
          type="number"
          fullWidth
          size="small"
          id="cost"
          name="cost"
          onChange={(event) =>
            handleChangeWorkflow(onChangeTextField(event, workflow))
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} style={{ textAlign: "center" }}>
        <span style={{ fontWeight: 250 }}>{Vocabulary.timePerWorkflow}</span>
        <br />
        <span style={{ fontWeight: 500 }}>{workflow?.time}</span>
      </Grid>
      <Grid item xs={12} sm={12} md={3} lg={3} style={{ textAlign: "center" }}>
        <span style={{ fontWeight: 250 }}>{Vocabulary.costPerWorkflow}</span>
        <br />
        <span style={{ fontWeight: 500 }}>{calcCostPerWorkflow()}</span>
      </Grid>
      {workflow?._id ? (
        <>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {steps.map((step: any, index: number) => (
              <DisplayTimeForOneStep
                key={`${step._id}`}
                step={step}
                index={index}
                saveTimeInStep={saveTimeInStep}
              />
            ))}
          </Grid>
        </>
      ) : null}
    </Grid>
  );
}
