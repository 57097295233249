/* eslint-disable @typescript-eslint/no-explicit-any */
import { Checkbox } from "@mui/material";
import { WorkflowModel } from "../../Utils/Models";

type AssignWithWorkflowProps = {
  workflows: WorkflowModel[];
  checkedItems: any;
  handleChangeCheckedItems: (checkedItems: any) => void;
};
export default function AssignWithWorkflow(props: AssignWithWorkflowProps) {
  const { workflows, checkedItems, handleChangeCheckedItems } = props;
  /**
   *
   * @param element
   */
  function handleCheckboxClick(event: any, element: WorkflowModel) {
    let updateCheckedItems = new Set(checkedItems);
    updateCheckedItems = saveCheckedList(
      element,
      updateCheckedItems,
      event.target.checked
    );
    handleChangeCheckedItems(updateCheckedItems);
  }

  /**
   *
   * @param element
   * @param updateCheckedItems
   * @returns
   */
  function saveCheckedList(
    element: WorkflowModel,
    updateCheckedItems: any,
    checked: any
  ) {
    if (checkedItems.has(element._id)) {
      if (checked) updateCheckedItems.add(element._id);
      else updateCheckedItems.delete(element._id);
    } else {
      if (checked) updateCheckedItems.add(element._id);
    }
    for (let i = 0; i < element.children.length; i++) {
      saveCheckedList(element.children[i], updateCheckedItems, checked);
    }
    return updateCheckedItems;
  }

  function showWorkflows(array: any) {
    return (
      <ul style={{ listStyleType: "none" }}>
        {array.map((element: any) => {
          return (
            <span key={`${element._id}`}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Checkbox
                  style={{ verticalAlign: "middle" }}
                  onClick={(event: any) => {
                    handleCheckboxClick(event, element);
                  }}
                  checked={checkedItems.has(element._id)}
                  color="primary"
                ></Checkbox>
                <li key={element._id} style={{ verticalAlign: "middle" }}>
                  {element.name}
                </li>
              </div>
              {showWorkflows(element.children)}
            </span>
          );
        })}
      </ul>
    );
  }
  return <div>{showWorkflows(workflows)}</div>;
}
