/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, Button, TextField } from "@mui/material";
import { Vocabulary } from "../../../../Utils/Vocabulary";
import GenericModal from "../../../GenericComponents/GenericModal";
import { StockTransferStatusesAutocompleteWithoutOuttake } from "../../../../Utils/AutocompleteUtils";
import { useState } from "react";

type ChangeStatusForStockTransferProps = {
  openChangeStatus: boolean;
  onClose: () => void;
  changeStatusForStockTransfer: (status: string | null) => void;
};
export default function ChangeStatusForStockTransfer(
  props: ChangeStatusForStockTransferProps
) {
  const { openChangeStatus, onClose, changeStatusForStockTransfer } = props;
  const [newStatus, setNewStatus] = useState(null);

  return (
    <GenericModal
      open={openChangeStatus}
      onClose={onClose}
      title={Vocabulary.changeStockTransferStatus}
      actions={
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            padding: 20,
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            style={{ color: "white" }}
            onClick={onClose}
          >
            {Vocabulary.no}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              changeStatusForStockTransfer(newStatus);
            }}
          >
            {Vocabulary.yes}
          </Button>
        </div>
      }
      styleDialogPaper={{ minHeight: 300, width: "100%" }}
      style={{ zIndex: 1500, textAlign: "center" }}
    >
      <Autocomplete
        freeSolo={false}
        disablePortal
        options={StockTransferStatusesAutocompleteWithoutOuttake}
        getOptionLabel={(option: any) => option}
        isOptionEqualToValue={(option, value) => option === value}
        value={newStatus}
        handleHomeEndKeys
        renderInput={(params) => (
          <TextField {...params} label={Vocabulary.status} size="small" />
        )}
        onChange={(event, newValue: any) => {
          setNewStatus(newValue);
        }}
      />
    </GenericModal>
  );
}
