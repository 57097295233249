/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Divider, InputLabel, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Vocabulary } from "../../Utils/Vocabulary";

const additionalData = {
  orderNumber: "Order number",
  customerName: "Customer name",
  customerEmail: "Customer email",
  customerPhone: "Customer phone",
  deviceName: "Device name",
  deviceSerialNumber: "Device serial number",
  products: "Products",
};

type AdditionalDataProps = {
  openMenu: boolean;
  anchorEl: any;
  handleClose: (tag: any) => void;
  handleClick: (event: any) => void;
};
export default function AdditionalData(props: AdditionalDataProps) {
  const { openMenu, anchorEl, handleClose, handleClick } = props;
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <InputLabel style={{ fontSize: 16 }}>
        {Vocabulary.addDynamicData} &nbsp; &nbsp;
      </InputLabel>
      <Button
        aria-controls={openMenu ? "demo-customized-menu" : undefined}
        aria-expanded={openMenu ? "true" : undefined}
        variant="outlined"
        size="large"
        disableElevation
        endIcon={<KeyboardArrowDownIcon />}
        onClick={handleClick}
        style={{
          margin: "15px 0px",
        }}
      >
        {Vocabulary.additionalData}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={() => handleClose("")}
        elevation={0}
        PaperProps={{
          style: { boxShadow: "1px 1px 12px 5px #e9e9e9", width: 185 },
        }}
      >
        {Object.entries(additionalData).map((data) => (
          <span key={`additionalData_${data[0]}`}>
            <MenuItem onClick={() => handleClose(data[0])} disableRipple>
              {data[1]}
            </MenuItem>
            <Divider />
          </span>
        ))}
      </Menu>
    </div>
  );
}
