import { Button, Divider, Menu, MenuItem, useMediaQuery } from "@mui/material";
import DividerWithLabel from "../GenericComponents/DividerWithLabel";
import { Vocabulary } from "../../Utils/Vocabulary";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { OrderContext } from "../../Contexts/OrderContext";
import { useContext } from "react";
import styles from "../../Styles/dashboard.module.css";

export const priorityArray = [
  {
    name: "NORMAL",
    label: Vocabulary.normal,
    icon: <KeyboardArrowRightIcon />,
  },
  {
    name: "HIGHER",
    label: Vocabulary.higher,
    icon: <KeyboardArrowUpIcon />,
  },
  {
    name: "HIGHEST",
    label: Vocabulary.highest,
    icon: <KeyboardDoubleArrowUpIcon />,
  },
];

type OrderHeaderProps = {
  anchorEl: null | HTMLElement;
  open: boolean;
  addOrUpdateOrder: () => void;
  handleCloseMenu: () => void;
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleChangePriority: (name: string) => void;
  handleDuplicateOrder: () => void;
};
export default function OrderHeader(props: OrderHeaderProps) {
  const {
    anchorEl,
    open,
    addOrUpdateOrder,
    handleChangePriority,
    handleClick,
    handleCloseMenu,
    handleDuplicateOrder,
  } = props;
  const phoneStyle = useMediaQuery("(max-width:800px)");
  const orderContext = useContext(OrderContext);

  return (
    <div
      id="orderTopMenu"
      className={phoneStyle ? styles.orderHeaderMobile : styles.orderHeader}
    >
      <p style={{ color: "#008470", fontWeight: 600 }}>
        {orderContext.order.fixablyId
          ? `${Vocabulary.orderNumber}: ${orderContext.order.fixablyId}`
          : ""}
      </p>
      <div>
        <Button
          size="medium"
          variant="contained"
          style={{
            marginRight: 15,
          }}
          onClick={addOrUpdateOrder}
        >
          {Vocabulary.saveOrder}
        </Button>
        <Button
          size="medium"
          variant="outlined"
          style={{ color: "#333", borderColor: "#333", padding: "5px 0px" }}
          onClick={handleClick}
        >
          <SettingsRoundedIcon fontSize="medium" />
        </Button>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleCloseMenu}
          onClick={handleCloseMenu}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <DividerWithLabel label={Vocabulary.priority} />
          {priorityArray.map((item, index) => (
            <MenuItem
              key={item.name}
              onClick={() => handleChangePriority(item.name)}
              style={
                item.name === orderContext.order.priority
                  ? { backgroundColor: "#f7f8fa" }
                  : {}
              }
            >
              {item.icon} {item.label}
            </MenuItem>
          ))}
          <Divider />
          <MenuItem
            onClick={handleDuplicateOrder}
            disabled={orderContext.order?._id ? false : true}
          >
            {Vocabulary.duplicateOrder}
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
}
