/* eslint-disable @typescript-eslint/no-explicit-any */
import Dropzone from "react-dropzone";
import { useState } from "react";
import { Paper } from "@mui/material";
import { CloudUpload } from "@mui/icons-material";
import styles from "../../Styles/dropzone.module.css";
import { toast } from "react-toastify";
import { FilesTypes } from "../../Utils/AutocompleteUtils";
import { Vocabulary } from "../../Utils/Vocabulary";
import EmptyPdf from "./EmptyPdf";
import { checkIfSrcBelongsToPdf } from "../../Utils/Utils";
import GenericDocIcon from "./GenericDocIcon";

type Props = {
  onSave: (file: any) => void;
  accept?: any;
  maxFiles?: number;
};
export default function DropzoneComponent(props: Props) {
  const { onSave, accept, maxFiles } = props;
  const [paths, setPaths] = useState<any>([]);
  const [shouldRerender, setShouldRerender] = useState(false);
  const nrOfAttachments = maxFiles ? maxFiles : 5;

  /**
   *
   * @param acceptedFiles
   */
  const handleDropFiles = (acceptedFiles: any) => {
    onSave(acceptedFiles);
    setShouldRerender(!shouldRerender);
    const newPaths = acceptedFiles.map((file: any) => {
      if (file.type === FilesTypes.pdf) {
        return {
          typePdf: true,
          src: URL.createObjectURL(file),
        };
      }
      if (file.type === FilesTypes.doc || file.type === FilesTypes.docx) {
        return {
          typeDoc: true,
          src: URL.createObjectURL(file),
        };
      }
      return URL.createObjectURL(file);
    });
    if (maxFiles) {
      if (maxFiles === 1) {
        setPaths(newPaths);
      } else {
        const finalPathsArr = paths.concat(newPaths);
        if (finalPathsArr.length > nrOfAttachments) {
          toast.error(
            `${Vocabulary.attachingFilesNumberError}${nrOfAttachments}!`
          );
        } else {
          setPaths(finalPathsArr);
        }
      }
    } else {
      setPaths(newPaths);
    }
  };

  return (
    <Dropzone
      accept={
        accept
          ? accept
          : {
              "image/*": [".png", ".jpeg", ".jpg"],
            }
      }
      maxFiles={nrOfAttachments}
      onDrop={(acceptedFiles) => {
        handleDropFiles(acceptedFiles);
      }}
      onDropRejected={(rejectedFiles) => {
        if (rejectedFiles.length > nrOfAttachments)
          toast.error(
            `${Vocabulary.attachingFilesNumberError}${nrOfAttachments}!`
          );
        else toast.error(Vocabulary.generalAttachmentError);
      }}
      onError={(error) => {
        toast.error(Vocabulary.generalAttachmentError);
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <Paper elevation={4}>
          <div {...getRootProps()} className={styles.dropzoneArea}>
            <input {...getInputProps()} />
            <p>{Vocabulary.dragAndDrop}</p>
            <CloudUpload fontSize="large" />
            <div className={styles.imageContainer}>
              {paths &&
                paths?.map((path: any) => {
                  if (
                    checkIfSrcBelongsToPdf(path.src ? path.src : path) ||
                    path.typePdf
                  ) {
                    return <EmptyPdf />;
                  }
                  if (path.typeDoc) {
                    return <GenericDocIcon />;
                  }
                  return (
                    <img
                      className={styles.imageStyle}
                      width={200}
                      key={path}
                      src={path}
                      alt={Vocabulary.missingAttachment}
                    />
                  );
                })}
            </div>
          </div>
        </Paper>
      )}
    </Dropzone>
  );
}
