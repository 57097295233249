/* eslint-disable @typescript-eslint/no-explicit-any */
import { Tab, Tabs } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import AddOrUpdateModel from "../GenericComponents/AddOrUpdateModel";
import { Vocabulary } from "../../Utils/Vocabulary";
import { WorkflowModel, WorkflowStepModel } from "../../Utils/Models";
import { urlEnum } from "../../Utils/UrlEnum";
import { getData } from "../../Services/getData";
import Step from "./Step";
import AssignWithWorkflow from "./AssignWithWorkflow";

type StepMainPageProps = {
  editStep: WorkflowStepModel | null;
  open: boolean;
  addOrUpdateStep: (
    step: WorkflowStepModel | null,
    checkedItems: Set<any> | null
  ) => void;
  onClose: () => void;
};
export default function StepMainPage(props: StepMainPageProps) {
  const { editStep, open, addOrUpdateStep, onClose } = props;
  const ref: any = useRef();
  const [step, setStep] = useState<WorkflowStepModel>(new WorkflowStepModel());
  const [pressOnSubmit, setPressOnSubmit] = useState(false);
  const [value, setValue] = useState(0);
  const [workflows, setWorkflows] = useState<WorkflowModel[]>([]);
  const [checkedItems, setCheckedItems] = useState(new Set());
  const [types, setTypes] = useState<string[]>([]);

  /**
   *
   */
  useEffect(() => {
    if (editStep) {
      setStep(editStep);
      if (editStep.workflows) {
        setCheckedItems(
          new Set(editStep.workflows.map((item) => item.workflowId))
        );
      } else {
        setCheckedItems(new Set());
      }
    } else {
      setStep(new WorkflowStepModel());
      setCheckedItems(new Set());
    }
    getWorkflow();
    getTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editStep]);

  /**
   *
   */
  useEffect(() => {
    if (pressOnSubmit) {
      ref.current.submit();
      //reset the value
      handleChangePressOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressOnSubmit]);

  function getTypes() {
    getData(`${urlEnum.steps}/types`).then((res: any) => {
      if (res) {
        setTypes(res.data.result.types);
      }
    });
  }
  /**
   * Get statuses
   */
  function getWorkflow() {
    getData(`${urlEnum.workflows}/hierarchical`).then((res: any) => {
      if (res) {
        setWorkflows(res.data.result);
      }
    });
  }
  /**
   *
   */
  function handleChangePressOnSubmit() {
    setPressOnSubmit(!pressOnSubmit);
  }
  /**
   *
   * @param event
   * @param newValue
   */
  function handleChangeValue(event: React.SyntheticEvent, newValue: number) {
    setValue(newValue);
  }

  /**
   *
   * @param value
   */
  function handleChangeStep(value: WorkflowStepModel) {
    setStep(value);
  }

  /**
   *
   * @param value
   */
  function handleChangeCheckedItems(value: Set<any>) {
    setCheckedItems(value);
  }

  /**
   *
   */
  function showInformation() {
    switch (value) {
      case 0:
        return (
          <Step step={step} handleChangeStep={handleChangeStep} types={types} />
        );
      case 1:
        return (
          <AssignWithWorkflow
            workflows={workflows}
            checkedItems={checkedItems}
            handleChangeCheckedItems={handleChangeCheckedItems}
          />
        );
      default:
        return (
          <Step step={step} handleChangeStep={handleChangeStep} types={types} />
        );
    }
  }
  return (
    <ValidatorForm
      ref={ref}
      onSubmit={() => addOrUpdateStep(step, checkedItems)}
    >
      <AddOrUpdateModel
        open={open}
        title={Vocabulary.step}
        onClose={onClose}
        isSubmit={true}
        addOrUpdateItem={() => null}
        handleChangePressOnSubmit={handleChangePressOnSubmit}
      >
        <Tabs value={value} onChange={handleChangeValue} textColor="inherit">
          <Tab label={Vocabulary.stepInformation} />
          <Tab label={Vocabulary.assignWithWorkflow} />
        </Tabs>
        {showInformation()}
      </AddOrUpdateModel>
    </ValidatorForm>
  );
}
