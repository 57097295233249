/* eslint-disable @typescript-eslint/no-explicit-any */
import { Checkbox, Collapse, Divider, Typography } from "@mui/material";
import TimeTrackingHandledBy from "../TimeTrackingHandledBy";
import CreateIcon from "@mui/icons-material/Create";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Vocabulary } from "../../../Utils/Vocabulary";
import { updateData } from "../../../Services/updateData";
import { urlEnum } from "../../../Utils/UrlEnum";
import { OrderContext } from "../../../Contexts/OrderContext";
import ExpendButtons from "../../GenericComponents/ExpendButtons";

type DisplayStepsListProps = {
  step: any;
  index: number;
  checkedSteps: string[];
  showMoreForAll: boolean;
  onEdit?: () => void;
  handleChangeCheckedSteps: (event: any, step: any) => void;
  handleChangeModifyStep: (step: any, index: number) => void;
  handleModifyStep: () => void;
};
export default function DisplayStepsList(props: DisplayStepsListProps) {
  const {
    step,
    index,
    checkedSteps,
    showMoreForAll,
    onEdit,
    handleChangeCheckedSteps,
    handleChangeModifyStep,
    handleModifyStep,
  } = props;
  const orderContext = useContext(OrderContext);
  const [showMore, setShowMore] = useState(false);
  const [assignUserToStep, setAssignUserToStep] = useState<any>({
    stepId: null,
    handledBy: null,
  });

  /**
   *
   */
  useEffect(() => {
    if (step.createdBy) {
      setAssignUserToStep({
        stepId: null,
        handledBy: step.createdBy || null,
      });
    } else {
      setAssignUserToStep({
        stepId: null,
        handledBy: null,
      });
    }
  }, [step.createdBy]);

  /**
   *
   */
  useEffect(() => {
    setShowMore(showMoreForAll);
  }, [showMoreForAll]);
  /**
   *
   * @param step
   * @param index
   */
  function changeAssignUserToStep(orderStep: any, index: number) {
    setAssignUserToStep({
      stepId: step.stepId?._id || null,
      handledBy: orderStep.createdBy || null,
    });
  }

  /**
   *
   */
  function saveStepsInOrder() {
    if (!assignUserToStep.stepId || !assignUserToStep.handledBy) {
      toast.error(Vocabulary.selectStepAndUser);
      return;
    }
    const body = {
      workflowSteps: [assignUserToStep.stepId],
      createdBy: assignUserToStep.handledBy?._id || null,
    };
    updateData(
      `${urlEnum.orders}/saveStep/${orderContext.order._id}`,
      body
    ).then((res) => {
      if (res)
        orderContext.setOrder({
          ...orderContext.order,
          workflowSteps: res.data.result,
        });
    });
  }

  return (
    <>
      <div
        key={`${step.stepId?.name}`}
        style={{
          display: "flex",
          alignItems: "center",
          margin: onEdit ? 7 : 2,
          cursor: "pointer",
        }}
      >
        {onEdit ? null : (
          <Checkbox
            tabIndex={-1}
            style={{ marginRight: 5 }}
            checked={
              checkedSteps.findIndex(
                (checkedStep) => checkedStep === step.stepId?._id
              ) >= 0
                ? true
                : false
            }
            onChange={(event) => handleChangeCheckedSteps(event, step)}
          ></Checkbox>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flex: "1",
          }}
          onClick={() => {
            handleModifyStep();
            handleChangeModifyStep(step, index);
          }}
        >
          <Typography>
            {step.stepId?.name}
            <span style={{ fontWeight: "bold" }}>
              {" "}
              {assignUserToStep.handledBy
                ? ` - ${assignUserToStep.handledBy.firstName} ${assignUserToStep.handledBy.lastName}`
                : null}
            </span>
          </Typography>
          <CreateIcon
            tabIndex={-1}
            fontSize="small"
            style={{ verticalAlign: "center" }}
          />
        </div>
        {onEdit ? null : (
          <ExpendButtons
            showMore={showMore}
            handleChangeShowMore={() => setShowMore(!showMore)}
            style={{ marginLeft: 5 }}
          />
        )}
      </div>
      <Collapse in={showMore}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "left",
            cursor: "pointer",
            flexWrap: "wrap",
            boxShadow: "0 0 10px #f0f0f0",
            margin: 0,
            borderRadius: 5,
            padding: 15,
          }}
        >
          <TimeTrackingHandledBy
            handledBy={assignUserToStep.handledBy}
            changeHandledBy={changeAssignUserToStep}
            saveStepsInOrder={saveStepsInOrder}
          />
        </div>
      </Collapse>
      <Divider />
    </>
  );
}
