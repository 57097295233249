/* eslint-disable @typescript-eslint/no-explicit-any */
import { StatusModel } from "../../Utils/Models";
import { useEffect, useRef, useState } from "react";
import AddOrUpdateModel from "../GenericComponents/AddOrUpdateModel";
import { Vocabulary } from "../../Utils/Vocabulary";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Autocomplete, Checkbox, Grid, TextField } from "@mui/material";
import {
  handleChangeAutocomplete,
  onChangeCheckbox,
  onChangeTextField,
} from "../../Utils/Utils";
import useCustomMemo from "../GenericComponents/useCustomMemo";

type StatusProps = {
  editStatus: StatusModel | null;
  open: boolean;
  addOrUpdateStatus: (status: StatusModel | null) => void;
  onClose: () => void;
};
export default function Status(props: StatusProps) {
  const { editStatus, open, addOrUpdateStatus, onClose } = props;
  const ref: any = useRef();
  const cache = useCustomMemo();
  const workflows = cache.workflows || [];
  const [status, setStatus] = useState<StatusModel>(new StatusModel());
  const [pressOnSubmit, setPressOnSubmit] = useState(false);

  /**
   *
   */
  useEffect(() => {
    if (editStatus) {
      setStatus(editStatus);
    } else {
      setStatus(new StatusModel());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editStatus]);

  /**
   *
   */
  useEffect(() => {
    if (pressOnSubmit) {
      ref.current.submit();
      //reset the value
      handleChangePressOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressOnSubmit]);

  /**
   *
   */
  function handleChangePressOnSubmit() {
    setPressOnSubmit(!pressOnSubmit);
  }

  return (
    <ValidatorForm ref={ref} onSubmit={() => addOrUpdateStatus(status)}>
      <AddOrUpdateModel
        open={open}
        title={Vocabulary.status}
        onClose={onClose}
        isSubmit={true}
        addOrUpdateItem={() => null}
        handleChangePressOnSubmit={handleChangePressOnSubmit}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextValidator
              size="small"
              id="name"
              name="name"
              label={Vocabulary.name}
              fullWidth
              variant="outlined"
              value={status.name}
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              onChange={(event: any) =>
                setStatus(onChangeTextField(event, status))
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextValidator
              size="small"
              id="description"
              name="description"
              label={Vocabulary.description}
              fullWidth
              variant="outlined"
              value={status.description}
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              onChange={(event: any) =>
                setStatus(onChangeTextField(event, status))
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextValidator
              size="small"
              id="type"
              name="type"
              label={Vocabulary.type}
              fullWidth
              variant="outlined"
              value={status.type}
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              onChange={(event: any) =>
                setStatus(onChangeTextField(event, status))
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextValidator
              size="small"
              id="liable"
              name="liable"
              label={Vocabulary.liable}
              fullWidth
              variant="outlined"
              value={status.liable}
              onChange={(event: any) =>
                setStatus(onChangeTextField(event, status))
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Autocomplete
              options={workflows}
              getOptionLabel={(option: any) => option?.name}
              isOptionEqualToValue={(option, value) =>
                option?.name === value?.name
              }
              value={status.workflowId}
              freeSolo={false}
              selectOnFocus
              handleHomeEndKeys
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={Vocabulary.workflow}
                  size="small"
                />
              )}
              onChange={(event, newValue: any) => {
                setStatus(
                  handleChangeAutocomplete(
                    event,
                    newValue,
                    status,
                    "workflowId"
                  )
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <div style={{ display: "flex" }}>
              <Checkbox
                name="isEnabled"
                style={{
                  margin: 0,
                  padding: 0,
                }}
                checked={status.isEnabled}
                onChange={(event: any) =>
                  setStatus(onChangeCheckbox(event, status))
                }
              />
              <p style={{ margin: 15 }}>{Vocabulary.isEnabled}</p>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <div style={{ display: "flex" }}>
              <Checkbox
                name="isDefault"
                style={{
                  margin: 0,
                  padding: 0,
                }}
                checked={status.isDefault}
                onChange={(event: any) =>
                  setStatus(onChangeCheckbox(event, status))
                }
              />
              <p style={{ margin: 15 }}>{Vocabulary.isDefault}</p>
            </div>
          </Grid>
        </Grid>
      </AddOrUpdateModel>
    </ValidatorForm>
  );
}
