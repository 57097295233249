/* eslint-disable @typescript-eslint/no-explicit-any */
import { Paper } from "@mui/material";

type StatisticCardPaperProps = {
  icon: any;
  value: number | string;
  name: string;
};
export default function StatisticCardPaper(props: StatisticCardPaperProps) {
  const { icon, value, name } = props;
  return (
    <Paper
      style={{
        borderRadius: 5,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 20,
        marginBottom: 15,
      }}
    >
      {icon}
      <div style={{ verticalAlign: "middle", textAlign: "right" }}>
        <p style={{ margin: "5px 0px", fontWeight: 500, fontSize: 22 }}>
          {value}
        </p>
        <p style={{ margin: "5px 0px" }}> {name}</p>
      </div>
    </Paper>
  );
}
