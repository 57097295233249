/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import styles from "../Styles/dashboard.module.css";
import { Vocabulary } from "../Utils/Vocabulary";
import { urlEnum } from "../Utils/UrlEnum";
import MainTable from "../Components/GenericComponents/MainTable";
import { useState } from "react";
import { DeliveryModel } from "../Utils/Models";
import { updateData } from "../Services/updateData";
import { postData } from "../Services/postData";
import Delivery from "../Components/Deliveries/Delivery";
import { useMediaQuery } from "@mui/material";

export default function Deliveries() {
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const [open, setOpen] = useState(false);
  const [editItem, setEditItem] = useState<DeliveryModel | null>(null);
  const [shouldUpdate, setShouldUpdate] = useState(false);

  const deliveriesHeader = [
    {
      label: Vocabulary.name,
      name: "name",
      options: { sort: false },
    },
    {
      label: Vocabulary.shortName,
      name: "shortName",
      options: {
        sort: false,
      },
    },
  ];
  /**
   *
   * @param item
   */
  function handleEdit(item: DeliveryModel | null) {
    setShouldUpdate(false);
    setOpen(!open);
    setEditItem(item);
  }
  /**
   *
   * @param step
   */
  function addOrUpdateDelivery(delivery: DeliveryModel | null) {
    setShouldUpdate(true);
    if (delivery?._id) {
      updateData(`${urlEnum.deliveries}/${delivery._id}`, delivery)
        .then((res) => {
          if (res) {
            setOpen(false);
          }
        })
        .catch((err) => {
          setOpen(false);
        });
    } else {
      postData(urlEnum.deliveries, delivery)
        .then((res: any) => {
          if (res) {
            setOpen(false);
          }
        })
        .catch((err) => {
          setOpen(false);
        });
    }
  }

  return (
    <div
      className={phoneStyle ? styles.mainContainerMobile : styles.mainContainer}
    >
      <MainTable
        urlEnumApi={urlEnum.deliveries}
        titleDelete={Vocabulary.deleteDelivery}
        textDelete={Vocabulary.deleteConfirmationDelivery}
        header={deliveriesHeader}
        tableTitle={Vocabulary.deliveriesList}
        shouldUpdate={shouldUpdate}
        handleEdit={handleEdit}
      />
      {open ? (
        <Delivery
          editDelivery={editItem}
          open={open}
          addOrUpdateDelivery={(delivery) => addOrUpdateDelivery(delivery)}
          onClose={() => setOpen(false)}
        />
      ) : null}
    </div>
  );
}
