/* eslint-disable @typescript-eslint/no-explicit-any */
import { Checkbox, Typography, useMediaQuery } from "@mui/material";
import { Step } from "../../Utils/Models";
import EditDeleteButtons from "../GenericComponents/EditDeleteButtons";
import DividerWithLabel from "../GenericComponents/DividerWithLabel";
import { Vocabulary } from "../../Utils/Vocabulary";
import { OrderContext } from "../../Contexts/OrderContext";
import { useContext, useState } from "react";
import { updateData } from "../../Services/updateData";
import { urlEnum } from "../../Utils/UrlEnum";
import { toast } from "react-toastify";
import TimeTrackingHandledBy from "./TimeTrackingHandledBy";
import DisplayStepsList from "./TimeTracking/DisplayStepsList";
import ExpendButtons from "../GenericComponents/ExpendButtons";

type OrderWorkflowProps = {
  steps: Step[];
  handleChangeModifyStep: (step: Step, index: number) => void;
  handleOpenOrderStep: () => void;
  onEdit?: () => void;
};

export default function OrderWorkflow(props: OrderWorkflowProps) {
  const {
    steps,
    handleChangeModifyStep,
    handleOpenOrderStep: handleModifyStep,
    onEdit,
  } = props;
  const [showMore, setShowMore] = useState(false);
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const orderContext = useContext(OrderContext);
  const [checkedSteps, setCheckedSteps] = useState<string[]>([]);
  const [handledBy, setHandledBy] = useState<any>(null);

  /**
   *
   */
  function saveStepsInOrder() {
    if (checkedSteps.length === 0 || !handledBy?._id) {
      toast.error(Vocabulary.selectStepAndUser);
      return;
    }
    const body = {
      workflowSteps: checkedSteps,
      createdBy: handledBy?._id || null,
    };
    updateData(
      `${urlEnum.orders}/saveStep/${orderContext.order._id}`,
      body
    ).then((res) => {
      if (res)
        orderContext.setOrder({
          ...orderContext.order,
          workflowSteps: res.data.result,
        });
      setCheckedSteps([]);
      setHandledBy(null);
    });
  }

  /**
   *
   * @param step
   * @param index
   */
  function changeHandledBy(step: any, index: number) {
    setHandledBy(step.createdBy ?? null);
  }

  /**
   *
   * @param event
   * @param step
   */
  function handleChangeCheckedSteps(event: any, step: any) {
    const { checked } = event.target;
    if (checked) {
      setCheckedSteps([...checkedSteps, step.stepId?._id ?? ""]);
    } else {
      setCheckedSteps(
        checkedSteps.filter((checkedStep) => checkedStep !== step.stepId?._id)
      );
    }
  }

  return (
    <div style={{ position: "relative" }}>
      {orderContext.order.workflow ? (
        <div>
          <Typography
            variant="h6"
            style={
              phoneStyle
                ? { marginBottom: 20, width: "70%" }
                : { marginBottom: 20 }
            }
          >
            {orderContext.order.workflow?.name}
          </Typography>
          {onEdit ? (
            <EditDeleteButtons
              onEdit={onEdit}
              onDelete={() => {
                orderContext.setOrder({
                  ...orderContext.order,
                  status: null,
                  workflow: null,
                  workflowSteps: [],
                });
              }}
              style={{ position: "absolute", top: 0, right: 0 }}
            />
          ) : null}
          <DividerWithLabel label={Vocabulary.steps} />
          {onEdit ? null : (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "10px 2px 15px 2px",
                justifyContent: "left",
                cursor: "pointer",
                flexWrap: "wrap",
              }}
            >
              <Checkbox
                tabIndex={-1}
                style={{ marginRight: 5, flex: 1 }}
                checked={checkedSteps.length === steps.length ? true : false}
                onChange={(event) => {
                  const { checked } = event.target;
                  if (checked) {
                    setCheckedSteps(
                      steps.map((step) => step.stepId?._id ?? "")
                    );
                  } else {
                    setCheckedSteps([]);
                  }
                }}
              ></Checkbox>
              <TimeTrackingHandledBy
                handledBy={handledBy}
                changeHandledBy={changeHandledBy}
                saveStepsInOrder={saveStepsInOrder}
              />
              <ExpendButtons
                showMore={showMore}
                handleChangeShowMore={() => setShowMore(!showMore)}
                style={{ marginLeft: 10 }}
              />
            </div>
          )}
          {steps.map((step, index) => (
            <span key={`${step.stepId?._id}`}>
              <DisplayStepsList
                step={step}
                index={index}
                checkedSteps={checkedSteps}
                showMoreForAll={showMore}
                onEdit={onEdit}
                handleChangeCheckedSteps={handleChangeCheckedSteps}
                handleChangeModifyStep={handleChangeModifyStep}
                handleModifyStep={handleModifyStep}
              />
            </span>
          ))}
        </div>
      ) : null}
    </div>
  );
}
