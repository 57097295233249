/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
// LanguageContext.js or LanguageContext.tsx
import React, { createContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Vocabulary } from "../Utils/Vocabulary";

export const LanguageContext = createContext<{
  language: {
    name: string;
    short: string;
  };
  setLanguage: (event: any, value: any) => void;
}>({
  language: {
    name: `🇬🇧  ${Vocabulary.english}`,
    short: "en",
  },
  setLanguage: () => {},
});

export const LanguageProvider = (props: any) => {
  const { i18n } = useTranslation();
  const setLanguage = (event: any, value: any) => {
    if (value && value.short) {
      i18n.changeLanguage(value.short);
      setState({ ...state, language: value });
    }
  };
  const initState = {
    language: {
      name: `🇬🇧  ${Vocabulary.english}`,
      short: "en",
    },
    setLanguage: setLanguage,
  };
  const [state, setState] = useState(initState);

  return (
    <LanguageContext.Provider value={state}>
      {props.children}
    </LanguageContext.Provider>
  );
};
