import { Grid } from "@mui/material";
import { TextValidator } from "react-material-ui-form-validator";
import { Vocabulary } from "../../Utils/Vocabulary";
import { handleChangeCustomFields } from "../../Utils/Utils";
import DividerWithLabel from "./DividerWithLabel";

/* eslint-disable @typescript-eslint/no-explicit-any */
type CustomFieldsProps = {
  customFields: any;
  onChangeCustomField: (customFields: any) => void;
};
export default function CustomFields(props: CustomFieldsProps) {
  const { customFields, onChangeCustomField } = props;

  /**
   *
   * @param field
   * @param index
   * @returns
   */
  function renderField(field: any, index: number) {
    const values = Object.entries(field);
    return values.map((value: any) => (
      <Grid item xs={12} sm={12} md={6} lg={6} key={`${value[0]}_${value[1]}`}>
        <TextValidator
          size="small"
          id={value[0]}
          name={value[0]}
          label={Vocabulary[value[0]]}
          fullWidth
          validators={["required"]}
          errorMessages={[Vocabulary.requiredField]}
          variant="outlined"
          value={value[1]}
          onChange={(event: any) =>
            onChangeCustomField(
              handleChangeCustomFields(event, index, customFields)
            )
          }
        />
      </Grid>
    ));
  }
  return (
    <Grid container spacing={2} style={{ marginTop: 10 }}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <DividerWithLabel label={Vocabulary.customField} />
      </Grid>
      {customFields.map((field: any, index: number) =>
        renderField(field, index)
      )}
    </Grid>
  );
}
