/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid } from "@mui/material";
import { TextValidator } from "react-material-ui-form-validator";
import { Vocabulary } from "../../Utils/Vocabulary";
import { useEffect, useState } from "react";
import { AddressModel } from "../../Utils/Models";
import { onChangeTextField } from "../../Utils/Utils";

type ShippingAddressProps = {
  editAddress: AddressModel | null;
  handleChangeAddressToCustomer: (address: AddressModel) => void;
};
export default function Address(props: ShippingAddressProps) {
  const { editAddress, handleChangeAddressToCustomer } = props;
  const [address, setAddress] = useState(new AddressModel());

  /**
   *
   */
  useEffect(() => {
    if (editAddress) {
      setAddress(editAddress);
    } else {
      setAddress(new AddressModel());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editAddress]);

  function handleChangeAddressAndUpdateCustomer(event: any) {
    const newAddress = onChangeTextField(event, address);
    setAddress(newAddress);
    handleChangeAddressToCustomer(newAddress);
  }

  return (
    <Grid container spacing={2} style={{ marginTop: 10 }}>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="name"
          name="name"
          label={Vocabulary.name}
          fullWidth
          variant="outlined"
          value={address.name}
          onChange={handleChangeAddressAndUpdateCustomer}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="address1"
          name="address1"
          label={Vocabulary.address1}
          fullWidth
          variant="outlined"
          value={address.address1}
          onChange={handleChangeAddressAndUpdateCustomer}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="address2"
          name="address2"
          label={Vocabulary.address2}
          fullWidth
          variant="outlined"
          value={address.address2}
          onChange={handleChangeAddressAndUpdateCustomer}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="address3"
          name="address3"
          label={Vocabulary.address3}
          fullWidth
          variant="outlined"
          value={address.address3}
          onChange={handleChangeAddressAndUpdateCustomer}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="zip"
          name="zip"
          label={Vocabulary.zip}
          fullWidth
          variant="outlined"
          value={address.zip}
          onChange={handleChangeAddressAndUpdateCustomer}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="city"
          name="city"
          label={Vocabulary.city}
          fullWidth
          variant="outlined"
          value={address.city}
          onChange={handleChangeAddressAndUpdateCustomer}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="state"
          name="state"
          label={Vocabulary.state}
          fullWidth
          variant="outlined"
          value={address.state}
          onChange={handleChangeAddressAndUpdateCustomer}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="country"
          name="country"
          label={Vocabulary.country}
          fullWidth
          variant="outlined"
          value={address.country}
          onChange={handleChangeAddressAndUpdateCustomer}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="identifier"
          name="identifier"
          label={Vocabulary.identifier}
          fullWidth
          variant="outlined"
          value={address.identifier}
          onChange={handleChangeAddressAndUpdateCustomer}
        />
      </Grid>
    </Grid>
  );
}
