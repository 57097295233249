/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, TextField } from "@mui/material";
import { Vocabulary } from "../../../Utils/Vocabulary";
import { Step, UserModel } from "../../../Utils/Models";
import { handleChangeAutocomplete } from "../../../Utils/Utils";

type AssignToUserProps = {
  step: any;
  index: number;
  users: Array<UserModel>;
  handleChangeModifyStep: (step: Step, index: number) => void;
};

export default function AssignToUser(props: AssignToUserProps) {
  const { step, users, index, handleChangeModifyStep } = props;

  return (
    <div style={{ flex: 18 }}>
      <Autocomplete
        size="small"
        id="createdBy"
        freeSolo={false}
        fullWidth
        disablePortal
        getOptionLabel={(option: any) =>
          `${option.firstName} ${option.lastName}`
        }
        isOptionEqualToValue={(option: any, value: any) =>
          option._id === value._id
        }
        options={users}
        value={step.createdBy || null}
        onChange={(event: any, value: any) => {
          handleChangeModifyStep(
            handleChangeAutocomplete(event, value, step, "createdBy"),
            index
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name="createdBy"
            label={Vocabulary.handledBy}
            variant="outlined"
          />
        )}
      />
    </div>
  );
}
