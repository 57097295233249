/* eslint-disable @typescript-eslint/no-explicit-any */
import styles from "../Styles/dashboard.module.css";
import { Vocabulary } from "../Utils/Vocabulary";
import { useEffect, useMemo, useState } from "react";
import { getData } from "../Services/getData";
import { urlEnum } from "../Utils/UrlEnum";
import { Divider, Grid, Paper, useMediaQuery } from "@mui/material";
import StatisticsCard from "../Components/Dashboard/StatisticsCard";
import { useLocation } from "react-router-dom";
import { FiltersProps } from "./Reports/Reports";
import moment from "moment";
import { roles } from "../Utils/Constants";
import ReportsAndOrdersFilters from "../Components/Reports/ReportsAndOrdersFilters";
import GraphicBar from "../Components/GenericComponents/GraphicBar";
import TechnicianEarning from "../Components/Dashboard/TechnicianEarnings";
import useCustomMemo from "../Components/GenericComponents/useCustomMemo";
import { dayPilotFormat } from "../Utils/Config";

export type StatisticsObjectProps = {
  stepsNumber: number;
  totalTrackedTime: number;
  totalPredefinedTime: number;
  totalTrackedCosts: number;
  totalPredefinedCost: number;
  totalEarnings: number;
};

type StatisticsProps = {
  statisticsForADay: StatisticsObjectProps;
  statisticsForAMonth: StatisticsObjectProps;
  statisticsForAYear: StatisticsObjectProps;
};
export default function LocalDashboard() {
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const location = useLocation();
  const [filters, setFilters] = useState<FiltersProps>({
    startDate: moment().startOf("year"),
    endDate: moment().date(25),
    user: null,
  });
  const [cache, setCache] = useState(localStorage.getItem("cache"));
  const [statistics, setStatistics] = useState<StatisticsProps>({
    statisticsForADay: {
      stepsNumber: 0,
      totalTrackedTime: 0,
      totalPredefinedTime: 0,
      totalTrackedCosts: 0,
      totalPredefinedCost: 0,
      totalEarnings: 0,
    },
    statisticsForAMonth: {
      stepsNumber: 0,
      totalTrackedTime: 0,
      totalPredefinedTime: 0,
      totalTrackedCosts: 0,
      totalPredefinedCost: 0,
      totalEarnings: 0,
    },
    statisticsForAYear: {
      stepsNumber: 0,
      totalTrackedTime: 0,
      totalPredefinedTime: 0,
      totalTrackedCosts: 0,
      totalPredefinedCost: 0,
      totalEarnings: 0,
    },
  });
  const role = localStorage.getItem("role");
  const [graphData, setGraphData] = useState<any>(null);
  const userId = useMemo(() => {
    return localStorage.getItem("userId") || null;
  }, []);
  const appCache = useCustomMemo();
  const user = useMemo(() => {
    const localStorageCache = localStorage.getItem("cache");
    if (!localStorageCache) return null;
    const cache = JSON.parse(localStorageCache);
    if (cache) {
      return cache.users.find((user: any) => user._id === userId);
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cache]);

  /**
   *
   */
  useEffect(() => {
    const handleStorageChange = () => {
      setCache(localStorage.getItem("cache"));
    };

    window.addEventListener("storage", handleStorageChange);
    getStatistics();
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *
   */
  useEffect(() => {
    const search = location.search.split(/[?&]+/);
    const newFilters: any = Object.assign({}, filters);
    search.forEach((value: any) => {
      if (value !== "") {
        const values = value.split("=");
        newFilters[values[0]] = isNaN(+values[1]) ? values[1] : +values[1];
        if (values[0] === "page") newFilters[values[0]] = +values[1] - 1;
        if (values[1] === "null") newFilters[values[0]] = null;
      }
    });
    if (!filters.user && !newFilters.user && location.search === "") {
      newFilters.user = user?._id || null;
    }
    setFilters({
      ...newFilters,
      startDate: moment(newFilters.startDate, dayPilotFormat),
      endDate: moment(newFilters.endDate, dayPilotFormat),
    });
    getGraphData(newFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, user]);

  /**
   * Fetch statistics
   */
  async function getStatistics() {
    const res = await getData(`${urlEnum.reports}/${userId}`);
    if (res) {
      setStatistics(res.data.result);
    }
  }

  /**
   * Fetch graph data
   */
  async function getGraphData(newFilters: FiltersProps) {
    const res = await getData(
      `${urlEnum.reports}/${newFilters.user ? newFilters.user : null}/${
        newFilters.startDate
      }/${newFilters.endDate}`
    );
    if (res) {
      setGraphData(res.data);
    }
  }

  return (
    <div
      className={
        phoneStyle ? styles.mainContainerMobile : styles.mainContainerDashboard
      }
    >
      <Grid container spacing={4}>
        <StatisticsCard
          title={Vocabulary.statisticsForADay}
          statistics={statistics.statisticsForADay}
        />
        <StatisticsCard
          title={Vocabulary.statisticsForAMonth}
          statistics={statistics.statisticsForAMonth}
        />
        <StatisticsCard
          title={Vocabulary.statisticsForAYear}
          statistics={statistics.statisticsForAYear}
        />
        <Grid item xs={12} md={12} lg={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} md={12} lg={12} style={{ marginTop: 15 }}>
          <ReportsAndOrdersFilters
            filters={filters}
            url=""
            disableClearable={true}
            multiple={false}
            showUser={true}
            recalculateReportsButton={false}
          />
        </Grid>
        {graphData ? (
          <Grid item xs={12} md={6} lg={6}>
            <GraphicBar
              title={Vocabulary.totalSteps}
              keys={graphData.keys.stepsGraph}
              data={graphData.stepsGraph}
              color={true}
            />
          </Grid>
        ) : null}
        {graphData ? (
          <Grid item xs={12} md={6} lg={6}>
            <GraphicBar
              title={`${Vocabulary.totalDuration} (minutes)`}
              keys={graphData.keys.durationGraph}
              data={graphData.durationGraph}
              color={false}
            />
          </Grid>
        ) : null}
        {graphData ? (
          <Grid item xs={12} md={6} lg={6}>
            <GraphicBar
              title={Vocabulary.totalCosts}
              keys={graphData.keys.costsGraph}
              data={graphData.costsGraph}
              color={true}
            />
          </Grid>
        ) : null}

        {role === roles.admin ? (
          <>
            <Grid item xs={12} md={12} lg={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
              <Paper style={{ padding: 10 }}>
                <div
                  style={{
                    textAlign: "center",
                    fontSize: 25,
                    fontWeight: 400,
                    marginBottom: 10,
                  }}
                >
                  {Vocabulary.totalEarnings}
                </div>
                <TechnicianEarning
                  technicianId={null}
                  technicianName=""
                  labels={{
                    username: Vocabulary.name,
                    day: Vocabulary.day,
                    month: Vocabulary.month,
                    year: Vocabulary.year,
                  }}
                  showDivider={true}
                />
                {appCache &&
                  appCache.users &&
                  appCache.users.map((user: any, index: number) => (
                    <TechnicianEarning
                      key={user._id}
                      technicianId={user._id}
                      technicianName={`${user.firstName} ${user.lastName}`}
                      showDivider={
                        appCache.users.length === index + 1 ? false : true
                      }
                    />
                  ))}
              </Paper>
            </Grid>
          </>
        ) : null}
      </Grid>
    </div>
  );
}
