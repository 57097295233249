/* eslint-disable @typescript-eslint/no-explicit-any */
import ProductMainPage from "../../Products/ProductMainPage";
import Note from "../../Notes/Note";
import Device from "../../Devices/Device";
import AddDeviceToOrder from "../AddDeviceToOrder";
import CustomerMainPage from "../../Customers/CustomerMainPage";
import {
  CustomerModel,
  DeviceModel,
  EmailTemplateModel,
  NotesModel,
  ProductModel,
  Step,
  WorkflowModel,
  WorkflowStepModel,
} from "../../../Utils/Models";
import { EditNoteType } from "../Order";
import { postData } from "../../../Services/postData";
import { urlEnum } from "../../../Utils/UrlEnum";
import { OpenAddProps, OpenEditProps } from "../OrderDataComponents";
import { startLoading, stopLoading } from "../../../Utils/Request";
import { updateData } from "../../../Services/updateData";
import AddDataToOrder from "../AddDataToOrder";
import { Vocabulary } from "../../../Utils/Vocabulary";
import { OrderContext } from "../../../Contexts/OrderContext";
import { useContext, useEffect, useState } from "react";
import WorkflowMainPage from "../../Workflows/WorkflowMainPage";
import TimeTracking from "./TimeTracking";
import { HttpStatusCode } from "../../../Utils/Constants";
import SendEmailModal from "../Emails/SendEmailModal";
import { getData } from "../../../Services/getData";

type CreateAddOrUpdateAssociatedDataInOrderProps = {
  modifyStep: any;
  openAdd: OpenAddProps;
  openEdit: OpenEditProps;
  editNote: EditNoteType;
  handleChangeOpenAdd: (openAdd: OpenAddProps) => void;
  handleChangeOpenEdit: (openEdit: OpenEditProps) => void;
  handleChangeEditNote: (editNote: EditNoteType) => void;
  handleChangeModifyStep: (step: Step | null, index: number) => void;
};
export default function CreateAddOrUpdateAssociatedDataInOrder(
  props: CreateAddOrUpdateAssociatedDataInOrderProps
) {
  const {
    modifyStep,
    editNote,
    openAdd,
    openEdit,
    handleChangeOpenAdd,
    handleChangeOpenEdit,
    handleChangeEditNote,
    handleChangeModifyStep,
  } = props;
  const orderContext = useContext(OrderContext);
  const [shouldRerenderSubWorkflows, setShouldRerenderSubWorkflows] =
    useState(false);
  const [databaseTemplate, setDatabaseTemplate] =
    useState<EmailTemplateModel | null>(null);

  /**
   *
   */
  useEffect(() => {
    getDatabaseTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderContext.order.status]);

  /**
   * Get database template
   */
  async function getDatabaseTemplate() {
    if (!orderContext.order.status) return;
    await getData(
      `${urlEnum.emailTemplates}/status/${orderContext.order.status?.name}`
    ).then((res) => {
      if (res) {
        setDatabaseTemplate(res.data.result);
      }
    });
  }

  ///////////////////////////  NOTES ///////////////////////////
  /**
   *
   */
  function addNoteToOrder(note: NotesModel | null) {
    if (editNote.note) {
      const newNotes = orderContext.order.notes;
      newNotes[editNote.index] = note;
      orderContext.setOrder({
        ...orderContext.order,
        notes: newNotes,
      });
      handleChangeOpenAdd({ ...openAdd, note: false });
    } else {
      const newNotes = orderContext.order.notes;
      newNotes.unshift(note);
      orderContext.setOrder({
        ...orderContext.order,
        notes: newNotes,
      });
      handleChangeOpenAdd({ ...openAdd, note: false });
    }
  }
  ///////////////////////////  END NOTES ///////////////////////////

  /////////////////////////// PRODUCTS ///////////////////////////
  /**
   *
   * @param product
   */
  function addProduct(product: ProductModel) {
    //form data
    const formData: any = new FormData();
    if (product.files && product.files.length !== 0)
      product.files.forEach((file: any) => {
        formData.append("files", file, file.name);
      });
    else formData.append("files", []);
    product.images = product.images.filter(
      (img: any) => typeof img === "string"
    );
    product.files = [];
    formData.append("product", JSON.stringify(product));
    //end form data
    postData(urlEnum.products, formData)
      .then((res: any) => {
        if (res) {
          handleChangeOpenAdd({ ...openAdd, product: false });
        }
      })
      .catch((err) => {
        handleChangeOpenAdd({ ...openAdd, product: false });
      });
  }

  /////////////////////////// END PRODUCTS ///////////////////////////

  /////////////////////////// DEVICES ///////////////////////////

  /**
   *
   * @param device
   */
  function addOrUpdateDevice(device: DeviceModel) {
    if (device?._id) {
      updateData(`${urlEnum.devices}/${device._id}`, device)
        .then((res) => {
          if (res) {
            handleChangeOpenAdd({ ...openAdd, device: false });
            orderContext.setOrder({
              ...orderContext.order,
              device: res.data.result,
            });
          }
        })
        .catch((err) => {
          handleChangeOpenAdd({ ...openAdd, device: false });
        });
    } else {
      postData(urlEnum.devices, device)
        .then((res: any) => {
          if (res) {
            handleChangeOpenAdd({ ...openAdd, device: false });
            orderContext.setOrder({
              ...orderContext.order,
              device: res.data.result,
            });
          }
        })
        .catch((err) => {
          handleChangeOpenAdd({ ...openAdd, device: false });
        });
    }
  }
  /////////////////////////// END DEVICES ///////////////////////////

  ///////////////////////////  WORKFLOWS ///////////////////////////

  /**
   *
   * @param product
   */
  function addWorkflow(
    workflow: WorkflowModel | null,
    steps: WorkflowStepModel[]
  ) {
    const statuses = workflow?.statuses?.map((item: any) => item._id) || [];
    const newWorkflow = {
      ...workflow,
      statuses: statuses,
    };
    if (workflow?._id) {
      const data = {
        workflow: newWorkflow,
        steps: steps,
      };
      updateData(`${urlEnum.workflows}/${workflow._id}`, data)
        .then((res) => {
          if (res) {
            handleChangeOpenAdd({ ...openAdd, workflow: false });
            orderContext.setOrder({
              ...orderContext.order,
              workflow: res.data.result,
            });
          }
        })
        .catch((err) => {
          handleChangeOpenAdd({ ...openAdd, workflow: false });
        });
    } else {
      postData(urlEnum.workflows, workflow)
        .then((res: any) => {
          if (res) {
            handleChangeOpenAdd({ ...openAdd, workflow: false });
            orderContext.setOrder({
              ...orderContext.order,
              workflow: res.data.result,
            });
          }
        })
        .catch((err) => {
          handleChangeOpenAdd({ ...openAdd, workflow: false });
        });
    }
  }
  /**
   *
   * @param event
   * @param value
   */
  async function changeSubWorkflows(
    event: any,
    value: any,
    parentId: string | null,
    subWorkflows: any
  ) {
    // if the element is added
    //TODO is a workaround to avoid the error
    setShouldRerenderSubWorkflows(false);
    startLoading();
    const distinctElements1 = value.filter(
      (item1: WorkflowModel) =>
        !subWorkflows.some((item2: WorkflowModel) => item1._id === item2._id)
    );

    for (let i = 0; i < distinctElements1.length; i++) {
      await updateData(
        `${urlEnum.workflows}/subWorkflows/${parentId}/${distinctElements1[i]._id}`,
        null
      );
    }

    // if the element is removed
    const distinctElements2 = subWorkflows.filter(
      (item1: WorkflowModel) =>
        !value.some((item2: WorkflowModel) => item1._id === item2._id)
    );
    for (let i = 0; i < distinctElements2.length; i++) {
      await updateData(
        `${urlEnum.workflows}/subWorkflows/${null}/${distinctElements2[i]._id}`,
        null
      );
    }
    setShouldRerenderSubWorkflows(true);
    stopLoading();
  }

  /////////////////////////// END WORKFLOWS ///////////////////////////

  ///////////////////////////  CUSTOMERS ///////////////////////////

  /**
   *
   * @param product
   */
  function addCustomer(customer: CustomerModel | null) {
    if (customer?._id) {
      updateData(`${urlEnum.customers}/${customer._id}`, customer)
        .then((res) => {
          if (res) {
            handleChangeOpenAdd({ ...openAdd, customer: false });
            orderContext.setOrder({
              ...orderContext.order,
              customer: res.data.result,
            });
          }
        })
        .catch((err) => {
          handleChangeOpenAdd({ ...openAdd, customer: false });
        });
    } else {
      postData(urlEnum.customers, customer)
        .then((res: any) => {
          if (res) {
            handleChangeOpenAdd({ ...openAdd, customer: false });
            orderContext.setOrder({
              ...orderContext.order,
              customer: res.data.result,
            });
          }
        })
        .catch((err) => {
          handleChangeOpenAdd({ ...openAdd, customer: false });
        });
    }
  }

  /////////////////////////// END CUSTOMERS ///////////////////////////

  /**
   * Add or update step to order
   */
  function addOrUpdateStepToOrder(createdBy?: any) {
    handleChangeOpenEdit({ ...openEdit, step: false });
    const formData: any = new FormData();
    formData.append("stepId", modifyStep.step.stepId._id);
    formData.append("description", modifyStep.step.description);
    if (modifyStep.step.customStepValue) {
      formData.append(
        "customStepValue",
        modifyStep.step.customStepValue.value
          ? modifyStep.step.customStepValue.value
          : modifyStep.step.customStepValue
      );
    }
    formData.append(
      "createdBy",
      createdBy
        ? createdBy._id
        : modifyStep.step.createdBy
        ? modifyStep.step.createdBy._id
        : null
    );
    formData.append(
      "timeTracking",
      JSON.stringify(modifyStep.step.timeTracking)
    );
    if (modifyStep.step.files && modifyStep.step.files.length !== 0)
      modifyStep.step.files.forEach((file: any) => {
        formData.append("files", file, file.name);
      });
    else formData.append("files", []);
    const images = modifyStep.step.images.filter(
      (img: any) => typeof img === "string"
    );
    formData.append("images", JSON.stringify(images));
    postData(
      `${urlEnum.orders}/saveStep/${orderContext.order._id}`,
      formData
    ).then((res) => {
      if (res) {
        if (
          res.data.status === HttpStatusCode.OK ||
          res.data.status === HttpStatusCode.Created
        )
          orderContext.setOrder({
            ...orderContext.order,
            workflowSteps: res.data.result,
          });
      }
    });
  }

  return (
    <>
      {/* ADD NEW PRODUCT */}
      {openAdd.product ? (
        <ProductMainPage
          editProduct={null}
          open={openAdd.product}
          addOrUpdateProduct={(product) => {
            product.inventories.forEach((inv: any) => {
              delete inv.isNew;
            });
            addProduct(product);
          }}
          onClose={() => handleChangeOpenAdd({ ...openAdd, product: false })}
        />
      ) : null}
      {/* ADD OR UPDATE NOTE */}
      {openAdd.note ? (
        <Note
          editNote={editNote.note}
          open={openAdd.note}
          onClose={() => {
            handleChangeOpenAdd({ ...openAdd, note: false });
            handleChangeEditNote({ ...editNote, note: null, index: 0 });
          }}
          addOrUpdateNote={(note) => addNoteToOrder(note)}
          isFromOrder={true}
        />
      ) : null}
      {/* ADD OR UPDATE DEVICE */}
      {openAdd.device ? (
        <Device
          editDevice={orderContext.order.device}
          open={openAdd.device}
          onClose={() => {
            handleChangeOpenAdd({ ...openAdd, device: false });
          }}
          addOrUpdateDevice={(device) => addOrUpdateDevice(device)}
        />
      ) : null}
      {/* ASSOCIATE ORDER WITH DEVICE */}
      {openEdit.device ? (
        <AddDeviceToOrder
          open={openEdit.device}
          onClose={() => handleChangeOpenEdit({ ...openEdit, device: false })}
        />
      ) : null}
      {/* ADD OR UPDATE WORKFLOW */}
      {openAdd.workflow ? (
        <WorkflowMainPage
          open={openAdd.workflow}
          shouldRerenderSubWorkflows={shouldRerenderSubWorkflows}
          onClose={() => handleChangeOpenAdd({ ...openAdd, workflow: false })}
          editWorkflow={orderContext.order.workflow}
          changeSubWorkflows={changeSubWorkflows}
          addOrUpdateWorkflow={(workflow, steps) =>
            addWorkflow(workflow, steps)
          }
        />
      ) : null}
      {/* ASSOCIATE ORDER WITH WORKFLOW */}
      {openEdit.workflow ? (
        <AddDataToOrder
          open={openEdit.workflow}
          url={urlEnum.workflows}
          name="workflow"
          title={Vocabulary.chooseWorkflow}
          onClose={() => handleChangeOpenEdit({ ...openEdit, workflow: false })}
        />
      ) : null}
      {/* ADD OR UPDATE CUSTOMER */}
      {openAdd.customer ? (
        <CustomerMainPage
          editCustomer={orderContext.order.customer}
          open={openAdd.customer}
          onClose={() => handleChangeOpenAdd({ ...openAdd, customer: false })}
          addOrUpdateCustomer={(customer) => addCustomer(customer)}
        />
      ) : null}
      {/* ASSOCIATE ORDER WITH CUSTOMER */}
      {openEdit.customer ? (
        <AddDataToOrder
          open={openEdit.customer}
          url={urlEnum.customers}
          name="customer"
          title={Vocabulary.chooseCustomer}
          onClose={() => handleChangeOpenEdit({ ...openEdit, customer: false })}
        />
      ) : null}
      {/* MODIFY STEP */}
      {openEdit.step ? (
        <TimeTracking
          open={openEdit.step}
          modifyStep={modifyStep}
          onClose={() => {
            handleChangeOpenEdit({ ...openEdit, step: false });
            handleChangeModifyStep(null, -1);
          }}
          addOrUpdateStepToOrder={addOrUpdateStepToOrder}
          handleChangeModifyStep={(step: Step | null, index: number) =>
            handleChangeModifyStep(step, index)
          }
        />
      ) : null}
      {/* SEND EMAIL */}
      {openAdd.sendEmail ? (
        <SendEmailModal
          open={openAdd.sendEmail}
          to={orderContext.order.customer?.email || ""}
          from={""} // aici din setari
          noteId={null}
          databaseTemplate={databaseTemplate}
          title={Vocabulary.sendEmail}
          onClose={() => handleChangeOpenAdd({ ...openAdd, sendEmail: false })}
        />
      ) : null}
    </>
  );
}
