/* eslint-disable @typescript-eslint/no-explicit-any */
import styles from "../Styles/dashboard.module.css";
import { Autocomplete, TextField, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Vocabulary } from "../Utils/Vocabulary";
import { useContext, useEffect } from "react";
import { LanguageContext } from "../Contexts/LanguageContext";

export default function MyAccount() {
  const { i18n } = useTranslation();
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const languageContext = useContext(LanguageContext);

  const languages = [
    {
      name: `🇬🇧  ${Vocabulary.english}`,
      short: "en",
    },
    {
      name: `🇷🇴  ${Vocabulary.romanian}`,
      short: "ro",
    },
    { name: `🇩🇪  ${Vocabulary.german}`, short: "de" },
  ];

  /**
   *
   */
  useEffect(() => {
    // Get the current language and find the corresponding language object
    const currentLanguage: any = languages.find(
      (lang) => lang.short === i18n.language
    );
    languageContext.setLanguage(null, currentLanguage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return (
    <div
      className={phoneStyle ? styles.mainContainerMobile : styles.mainContainer}
    >
      <Autocomplete
        size="small"
        id="languages"
        multiple={false}
        freeSolo={false}
        options={languages}
        value={languageContext.language}
        getOptionLabel={(option: any) => option.name}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        onChange={languageContext.setLanguage}
        fullWidth
        renderInput={(params: any) => (
          <TextField
            {...params}
            label={Vocabulary.languages}
            variant="outlined"
          />
        )}
      />
    </div>
  );
}
