import { Grid } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import ScheduleIcon from "@mui/icons-material/Schedule";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import PaymentsIcon from "@mui/icons-material/Payments";
import { StatisticsObjectProps } from "../../Pages/Dashboard";
import { displayTimeString, showFormattedDuration } from "../../Utils/Utils";
import theme from "../../Themes/Theme";
import StatisticCardPaper from "./StatisticCardPaper";

type StatisticsCardProps = {
  title: string;
  statistics: StatisticsObjectProps;
};
export default function StatisticsCard(props: StatisticsCardProps) {
  const { title, statistics } = props;
  return (
    <>
      <Grid item xs={12} md={4} lg={4}>
        <p style={{ fontSize: 22 }}>{title}</p>
        <StatisticCardPaper
          icon={
            <TaskAltIcon
              style={{
                verticalAlign: "middle",
                fontSize: 45,
                color: theme.palette.limeColor?.main,
              }}
            />
          }
          value={statistics.stepsNumber}
          name={Vocabulary.totalSteps}
        />
        <StatisticCardPaper
          icon={
            <ScheduleIcon
              style={{
                verticalAlign: "middle",
                fontSize: 45,
                color: theme.palette.yellowColor?.main,
              }}
            />
          }
          value={displayTimeString(statistics.totalPredefinedTime)}
          name={Vocabulary.predefinedHours}
        />
        <StatisticCardPaper
          icon={
            <TimelapseIcon
              style={{
                verticalAlign: "middle",
                fontSize: 45,
                color: theme.palette.primary?.main,
              }}
            />
          }
          value={showFormattedDuration(statistics.totalTrackedTime)}
          name={Vocabulary.trackedHours}
        />
        <StatisticCardPaper
          icon={
            <TrendingUpIcon
              style={{
                verticalAlign: "middle",
                fontSize: 45,
                color: theme.palette.orangeColor?.main,
              }}
            />
          }
          value={statistics.totalTrackedCosts.toFixed(2)}
          name={Vocabulary.trackedCost}
        />
        <StatisticCardPaper
          icon={
            <MonetizationOnIcon
              style={{
                verticalAlign: "middle",
                fontSize: 45,
                color: theme.palette.secondary?.main,
              }}
            />
          }
          value={statistics.totalPredefinedCost.toFixed(2)}
          name={Vocabulary.predefinedCost}
        />
        <StatisticCardPaper
          icon={
            <PaymentsIcon
              style={{
                verticalAlign: "middle",
                fontSize: 45,
                color: theme.palette.greenColor?.main,
              }}
            />
          }
          value={statistics.totalEarnings.toFixed(2)}
          name={Vocabulary.totalEarnings}
        />
      </Grid>
    </>
  );
}
