/* eslint-disable @typescript-eslint/no-explicit-any */
import { Divider, Typography } from "@mui/material";

type DividerWithLabelProps = {
  label: string;
  styles?: any;
};

export default function DividerWithLabel(props: DividerWithLabelProps) {
  const { label, styles } = props;
  return (
    <div
      style={styles ? styles : { marginTop: 0, marginBottom: 0, width: "100%" }}
    >
      <Typography
        style={{
          marginBottom: -8,
          marginLeft: 0,
          padding: 5,
          color: "#757575",
          zIndex: 100,
        }}
      >
        {label}
      </Typography>
      <Divider style={{ width: "100%" }} />
    </div>
  );
}
