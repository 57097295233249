/* eslint-disable @typescript-eslint/no-explicit-any */
import { Chip } from "@mui/material";
import styles from "../../../../Styles/dashboard.module.css";
import { useContext, useState } from "react";
import { InventoryModel } from "../../../../Utils/Models";
import { Vocabulary } from "../../../../Utils/Vocabulary";
import { updateData } from "../../../../Services/updateData";
import { urlEnum } from "../../../../Utils/UrlEnum";
import { postData } from "../../../../Services/postData";
import MainTable from "../../../GenericComponents/MainTable";
import Inventory from "./Inventory";
import { styleForTableWithTabs } from "../../../../Pages/Users";
import { WebsocketContext } from "../../../../Contexts/WebsocketContext";

export default function Inventories() {
  const websocketContext = useContext(WebsocketContext);
  const [open, setOpen] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [editItem, setEditItem] = useState<InventoryModel | null>(null);

  const inventoriesHeader = [
    {
      label: Vocabulary.name,
      name: "name",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.description,
      name: "description",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return value.length > 30 ? `${value.substring(0, 30)} ...` : value;
        },
      },
    },
    {
      label: Vocabulary.location,
      name: "location",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return (
            <Chip
              label={value.name}
              style={{
                margin: 5,
                backgroundColor: "#404a694d",
              }}
            />
          );
        },
      },
    },
  ];
  /**
   *
   * @param item
   */
  function handleEdit(item: InventoryModel | null) {
    setShouldUpdate(false);
    setOpen(!open);
    setEditItem(item);
  }
  /**
   *
   * @param inventory
   */
  function addOrUpdateInventory(inventory: InventoryModel | null) {
    if (inventory?._id) {
      updateData(`${urlEnum.inventories}/${inventory._id}`, inventory)
        .then((res) => {
          if (res) {
            websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
            setShouldUpdate(true);
            setOpen(false);
          }
        })
        .catch((err) => {
          setOpen(false);
        });
    } else {
      postData(urlEnum.inventories, inventory)
        .then((res: any) => {
          if (res) {
            websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
            setShouldUpdate(true);
            setOpen(false);
          }
        })
        .catch((err) => {
          setOpen(false);
        });
    }
  }

  return (
    <div className={styles.mainContainerUser}>
      <MainTable
        urlEnumApi={urlEnum.inventories}
        titleDelete={Vocabulary.deleteInventory}
        textDelete={Vocabulary.deleteConfirmationInventory}
        header={inventoriesHeader}
        tableTitle={Vocabulary.inventoriesList}
        shouldUpdate={shouldUpdate}
        handleEdit={handleEdit}
        style={styleForTableWithTabs}
      />
      {open ? (
        <Inventory
          editInventory={editItem}
          open={open}
          addOrUpdateInventory={(inventory) => addOrUpdateInventory(inventory)}
          onClose={() => handleEdit(null)}
        />
      ) : null}
    </div>
  );
}
