/* eslint-disable @typescript-eslint/no-explicit-any */
import { Tab, Tabs } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { getData } from "../../Services/getData";
import { LocationModel } from "../../Utils/Models";
import { urlEnum } from "../../Utils/UrlEnum";
import { Vocabulary } from "../../Utils/Vocabulary";
import AddOrUpdateModel from "../GenericComponents/AddOrUpdateModel";
import Location from "./Location";
import OpeningHours from "./OpeningHours";

type LocationMainPageProps = {
  editLocation: LocationModel | null;
  open: boolean;
  addOrUpdateLocation: (customer: LocationModel | null) => void;
  onClose: () => void;
};
export default function LocationMainPage(props: LocationMainPageProps) {
  const { editLocation, open, addOrUpdateLocation, onClose } = props;
  const ref: any = useRef();
  const [location, setLocation] = useState<LocationModel>(new LocationModel());
  const [pressOnSubmit, setPressOnSubmit] = useState(false);
  const [value, setValue] = useState(0);
  const [deliveries, setDeliveries] = useState<any>();
  /**
   *
   */
  useEffect(() => {
    if (editLocation) {
      setLocation(editLocation);
    } else {
      setLocation(new LocationModel());
    }
    getDelivers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editLocation]);

  /**
   *
   */
  useEffect(() => {
    if (pressOnSubmit) {
      ref.current.submit();
      //reset the value
      handleChangePressOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressOnSubmit]);

  /**
   *
   */
  function getDelivers() {
    getData(`${urlEnum.deliveries}`).then((res: any) => {
      if (res.data.status === 200) setDeliveries(res.data.result);
    });
  }
  /**
   *
   */
  function handleChangePressOnSubmit() {
    setPressOnSubmit(!pressOnSubmit);
  }
  /**
   *
   * @param event
   * @param newValue
   */
  function handleChangeValue(event: React.SyntheticEvent, newValue: number) {
    setValue(newValue);
  }
  /**
   *
   * @param value
   */
  function handleChangeLocation(value: LocationModel) {
    setLocation(value);
  }

  function showInformation() {
    switch (value) {
      case 0:
        return (
          <Location
            location={location}
            handleChangeLocation={handleChangeLocation}
            deliveries={deliveries}
          />
        );
      case 1:
        return (
          <OpeningHours
            editOpeningHours={location.openingHours}
            handleChangeOpeningHoursToLocation={(openingHours: any) =>
              handleChangeLocation({ ...location, openingHours: openingHours })
            } />
        );
      default:
        break;
    }
  }
  return (
    <ValidatorForm ref={ref} onSubmit={() => addOrUpdateLocation(location)}>
      <AddOrUpdateModel
        open={open}
        title={Vocabulary.location}
        onClose={onClose}
        isSubmit={true}
        addOrUpdateItem={() => null}
        handleChangePressOnSubmit={handleChangePressOnSubmit}
      >
        <Tabs value={value} onChange={handleChangeValue} textColor="inherit">
          <Tab label={Vocabulary.location} />
          <Tab label={Vocabulary.openingHours} />
        </Tabs>
        {showInformation()}
      </AddOrUpdateModel>
    </ValidatorForm>
  );
}
