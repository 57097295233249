/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Autocomplete,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import useCustomMemo from "../GenericComponents/useCustomMemo";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { AttributeModel } from "../../Utils/Models";
import { useEffect, useState } from "react";
import { onChangeTextField } from "../../Utils/Utils";

type AutocompleteAttributeProps = {
  attribute: AttributeModel;
  handleChangeAttribute: (attribute: AttributeModel) => void;
};

export default function AutocompleteAttribute(
  props: AutocompleteAttributeProps
) {
  const { attribute, handleChangeAttribute } = props;
  const cache = useCustomMemo();
  const cacheAttributes = cache.attributes || [];
  const [errorAttributeAlreadyExists, setErrorAttributeAlreadyExists] =
    useState<any>({
      index: null,
      value: false,
    });
  const [selectedAttribute, setSelectedAttribute] =
    useState<AttributeModel | null>(null);

  useEffect(() => {
    setSelectedAttribute(
      cacheAttributes.find((attr: any) => attr.name === attribute.name)
    );
    setErrorAttributeAlreadyExists({
      index: null,
      value: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attribute]);
  /**
   *
   * @param event
   * @param valueIndex
   */
  function handleChangeCurrentOptionValue(value: string, valueIndex: number) {
    setErrorAttributeAlreadyExists({
      index: null,
      value: false,
    });
    const newCurrentAttribute: any = {
      ...attribute,
      values: [...attribute.values],
    };
    const findIndex = newCurrentAttribute.values.findIndex(
      (val: any) => val === value
    );
    if (findIndex !== -1) {
      setErrorAttributeAlreadyExists({
        index: valueIndex,
        value: true,
      });
      return;
    }
    newCurrentAttribute.values[valueIndex] = value ? value : "";
    // Find all indices of empty string values
    const emptyIndices = newCurrentAttribute.values.reduce(
      (acc: Array<string>, val: string, idx: string) => {
        if (val === "") acc.push(idx);
        return acc;
      },
      []
    );
    // Remove the last empty string if there are more than one
    if (emptyIndices.length > 1) {
      newCurrentAttribute.values.splice(
        emptyIndices[emptyIndices.length - 1],
        1
      );
    }
    // Push a new empty string if there is no empty string left
    if (newCurrentAttribute.values.findIndex((val: any) => val === "") === -1) {
      newCurrentAttribute.values.push("");
    }
    handleChangeAttribute(newCurrentAttribute);
  }

  /**
   *
   * @param valueIndex
   */
  function deleteCurrentOptionValue(valueIndex: number) {
    const newCurrentAttribute = Object.assign({}, attribute);
    newCurrentAttribute.values.splice(valueIndex, 1);
    handleChangeAttribute(newCurrentAttribute);
  }

  /**
   *
   * @param value
   * @param valueIndex
   * @returns
   */
  function validateCurrentOptionValue(value: string, valueIndex: number) {
    return (
      value === "" &&
      (attribute.values.length === 1
        ? true
        : attribute.values.length !== valueIndex + 1
        ? true
        : false)
    );
  }

  return (
    <div style={{ margin: "10px 0px" }}>
      <InputLabel style={{ paddingLeft: 2 }}>
        {Vocabulary.optionName}
      </InputLabel>
      <Autocomplete
        size="small"
        id="name"
        freeSolo={true}
        disablePortal
        getOptionLabel={(option: any) => option.name}
        isOptionEqualToValue={(option: any, value: any) =>
          option.name === value.name
        }
        options={cacheAttributes}
        value={attribute}
        onChange={(event: any, value: any) => {
          handleChangeAttribute({
            ...attribute,
            name: value ? value.name : "",
          });
          setSelectedAttribute(value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name="name"
            label={Vocabulary.name}
            variant="outlined"
            onChange={(e) => {
              handleChangeAttribute(onChangeTextField(e, attribute));
            }}
            error={attribute.name === ""}
            helperText={attribute.name === "" ? Vocabulary.requiredField : null}
          />
        )}
        style={{ marginBottom: 10, marginTop: 10 }}
      />
      <InputLabel style={{ paddingLeft: 2 }}>
        {Vocabulary.optionValues}
      </InputLabel>
      {attribute.values.map((value: string, valueIndex: number) => (
        <Autocomplete
          size="small"
          key={`values[${valueIndex}]`}
          id={`values[${valueIndex}]`}
          freeSolo={true}
          disablePortal
          getOptionLabel={(option: any) => option}
          isOptionEqualToValue={(option: any, value: any) => option === value}
          getOptionDisabled={(option) =>
            !!attribute.values.find((element: any) => element === option)
          }
          options={selectedAttribute?.values || []}
          value={value}
          onChange={(event: any, value: any) => {
            handleChangeCurrentOptionValue(value, valueIndex);
          }}
          style={{ marginBottom: 10 }}
          renderInput={(params) => (
            <TextField
              {...params}
              name="name"
              variant="outlined"
              onChange={(e) => {
                // setCurrentOption(onChangeTextField(e, currentOption));
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {attribute.values.length > 1 && value !== "" ? (
                      <InputAdornment
                        position="end"
                        style={{ cursor: "pointer" }}
                        onClick={() => deleteCurrentOptionValue(valueIndex)}
                      >
                        <DeleteForeverIcon />
                      </InputAdornment>
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              error={
                validateCurrentOptionValue(value, valueIndex) ||
                (errorAttributeAlreadyExists.index === valueIndex &&
                  errorAttributeAlreadyExists.value)
              }
              helperText={
                errorAttributeAlreadyExists.index === valueIndex &&
                errorAttributeAlreadyExists.value
                  ? Vocabulary.attributeAlreadyExists
                  : validateCurrentOptionValue(value, valueIndex)
                  ? Vocabulary.requiredField
                  : null
              }
            />
          )}
        />
      ))}
    </div>
  );
}
