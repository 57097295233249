/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from "@mui/material";
import AssignToUser from "./TimeTracking/AssignToUser";
import { Vocabulary } from "../../Utils/Vocabulary";
import useCustomMemo from "../GenericComponents/useCustomMemo";
import { Step } from "../../Utils/Models";

type TimeTrackingHandledByProps = {
  handledBy: any;
  changeHandledBy: (step: Step, index: number) => void;
  saveStepsInOrder: () => void;
};
export default function TimeTrackingHandledBy(
  props: TimeTrackingHandledByProps
) {
  const { handledBy,  changeHandledBy, saveStepsInOrder } = props;
  // all users from cache
  const cache = useCustomMemo();
  const users = cache.users || [];
  //
  return (
    <>
      <AssignToUser
        step={{ createdBy: handledBy }}
        index={0}
        users={users}
        handleChangeModifyStep={(step: Step, index: number) =>
          changeHandledBy(step, index)
        }
      />
      <Button
        variant="contained"
        style={{ margin: "0px 0px 0px 20px", flex: 1 }}
        onClick={() => saveStepsInOrder()}
        tabIndex={-1}
      >
        {Vocabulary.save}
      </Button>
    </>
  );
}
