/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import styles from "../Styles/mainSlider.module.css";
import { useLocation } from "react-router-dom";
import SubMenuForMainSlider from "./SubMenuForMainSlider";

type Props = {
  data: any;
};
export default function MainSlider(props: Props, location: any) {
  location = useLocation();

  return (
    <div id="container" className="containerLarge">
      <div className={styles.slider}>
        <div>
          {props.data?.map((element: any, key: any) => {
            return (
              <SubMenuForMainSlider
                key={`${element.path}_${key}`}
                element={element}
                location={location}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
