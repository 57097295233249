/* eslint-disable @typescript-eslint/no-explicit-any */
import { IconButton, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import styles from "../Styles/mainSlider.module.css";
import { Link } from "react-router-dom";
import { useState } from "react";

type SubMenuForMainSliderProps = {
  element: any;
  location: any;
};

export default function SubMenuForMainSlider(props: SubMenuForMainSliderProps) {
  const { element, location } = props;
  const [openMenu, setOpenMenu] = useState(false);
  return (
    <span>
      <Link
        style={{ display: "flex", textDecoration: "none" }}
        to={element.path}
        onClick={
          element?.subMenu
            ? (e: any) => {
                e.preventDefault();
                setOpenMenu(!openMenu);
              }
            : () => {
                ("");
              }
        }
      >
        <IconButton
          className={
            location.pathname === element.path ||
            `${location.pathname}${location.search}` === element.path
              ? styles.selected
              : styles.nonSelected
          }
          id="1"
        >
          {element.icon}
        </IconButton>
        <Typography
          className={
            location.pathname === element.path ||
            `${location.pathname}${location.search}` === element.path
              ? styles.selectedText
              : styles.nonSelectedText
          }
        >
          {element.name}
        </Typography>
        {element?.subMenu ? (
          <div style={{ paddingTop: 10, marginLeft: 20 }}>
            {openMenu ? (
              <IconButton size="small">
                <KeyboardArrowUpIcon
                  style={{ marginTop: 5 }}
                ></KeyboardArrowUpIcon>
              </IconButton>
            ) : (
              <IconButton size="small">
                <KeyboardArrowDownIcon
                  style={{ marginTop: 5 }}
                ></KeyboardArrowDownIcon>
              </IconButton>
            )}
          </div>
        ) : null}
      </Link>
      {element?.subMenu && openMenu ? (
        <div
          className={
            openMenu
              ? styles.selectedTextSubMenu
              : styles.nonSelectedTextSubMenu
          }
        >
          {element?.subMenu?.map((el: any, key: any) => {
            return (
              <Link
                key={`${el.orderId}-${el.name}`}
                style={{
                  display: "flex",
                  textDecoration: "none",
                  minWidth: 200,
                }}
                to={el.path}
              >
                <IconButton
                  id="1"
                  size="small"
                  className={
                    location.pathname === el.path ||
                    `${location.pathname}${location.search}` === el.path
                      ? styles.selected
                      : styles.nonSelected
                  }
                >
                  {el.icon}
                </IconButton>
                <Typography
                  className={
                    location.pathname === el.path ||
                    `${location.pathname}${location.search}` === el.path
                      ? styles.selectedText
                      : styles.nonSelectedText
                  }
                >
                  {el.name}
                </Typography>
              </Link>
            );
          })}
        </div>
      ) : null}
    </span>
  );
}
