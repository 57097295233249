/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Chip,
  TableCell,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import React, { useContext, useState } from "react";
import MUIDataTableCustomStyle from "../Components/MUIDataTableCustomStyle";
import { Vocabulary } from "../Utils/Vocabulary";
import { Delete, Edit } from "@mui/icons-material";
import styles from "../Styles/dashboard.module.css";
import { urlEnum } from "../Utils/UrlEnum";
import { WorkflowModel, WorkflowStepModel } from "../Utils/Models";
import { postData } from "../Services/postData";
import { updateData } from "../Services/updateData";
import { startLoading, stopLoading } from "../Utils/Request";
import { findObjectByName } from "../Utils/Utils";
import MainTable from "../Components/GenericComponents/MainTable";
import WorkflowMainPage from "../Components/Workflows/WorkflowMainPage";
import { WebsocketContext } from "../Contexts/WebsocketContext";

export default function Workflows() {
  const [open, setOpen] = useState(false);
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const websocketContext = useContext(WebsocketContext);
  const [editWorkflow, setEditWorkflow] = useState<WorkflowModel | null>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedId, setDeletedId] = useState<string | null>(null);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [shouldRerenderSubWorkflows, setShouldRerenderSubWorkflows] =
    useState(false);

  let extendableChildren: any = [];

  /**
   *
   */
  const workflowHeader = [
    {
      label: Vocabulary.name,
      name: "name",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.statuses,
      name: "statuses",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return (
            <>
              {value.map((item: any, key: number) => (
                <span key={key}>
                  {key < 4 ? (
                    <Chip
                      key={value.id}
                      label={item.name}
                      style={{
                        margin: 5,
                        backgroundColor: "#404a694d",
                      }}
                    />
                  ) : key === 4 ? (
                    <Chip
                      key={value.id}
                      label="..."
                      style={{
                        margin: 5,
                        backgroundColor: "#404a694d",
                      }}
                    />
                  ) : null}
                </span>
              ))}
            </>
          );
        },
      },
    },
    {
      label: Vocabulary.enabled,
      name: "enabled",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return value ? Vocabulary.enabled : Vocabulary.disabled;
        },
      },
    },
  ];
  /**
   *
   */
  const subWorkflowHeader = [
    {
      label: Vocabulary.name,
      name: "name",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.enabled,
      name: "enabled",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return value ? Vocabulary.enabled : Vocabulary.disabled;
        },
      },
    },
    {
      label: Vocabulary.options,
      name: "Optiuni",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        sort: false,
        empty: true,
        customBodyRender: (index: any, data: any) => {
          const item = findObjectByName(extendableChildren, data.rowData[0]);
          return (
            <ToggleButtonGroup exclusive aria-label="text alignment">
              <Tooltip title={Vocabulary.edit}>
                <ToggleButton
                  value="left"
                  aria-label="left aligned"
                  onClick={() => {
                    setOpen(true);
                    setEditWorkflow(item);
                  }}
                >
                  <Edit />
                </ToggleButton>
              </Tooltip>
              <Tooltip title={Vocabulary.delete}>
                <ToggleButton
                  value="center"
                  aria-label="centered"
                  onClick={() => {
                    setDeletedId(item?._id);
                    setOpenDeleteModal(true);
                  }}
                >
                  <Delete />
                </ToggleButton>
              </Tooltip>
            </ToggleButtonGroup>
          );
        },
      },
    },
  ];

  /**
   *
   * @returns
   */
  const getTableOptionsForSubWorkflows = (data: any) => {
    const responsive: "standard" | "vertical" | "simple" | undefined =
      "standard";
    return {
      selectableRows: "none" as any,
      viewColumns: false as any,
      responsive: responsive,
      confirmFilters: false,
      filter: false,
      print: false,
      download: false,
      expandableRows: true,
      expandableRowsHeader: false,
      header: false,
      search: false,
      serverSide: true,
      customFooter: () => {
        return null;
      },
      customHeadRender: () => {
        return null;
      },
      renderExpandableRow: (rowData: any, rowMeta: any) => {
        const item = data[rowMeta.dataIndex].children;
        if (!item || item.length === 0) return null;
        const colSpan = rowData.length + 1;
        extendableChildren = [...extendableChildren, ...item];
        return (
          <TableRow>
            <TableCell colSpan={colSpan}>
              <MUIDataTableCustomStyle
                title={""}
                data={item}
                columns={subWorkflowHeader}
                options={getTableOptionsForSubWorkflows(data)}
              />
            </TableCell>
          </TableRow>
        );
      },
    };
  };

  /**
   *
   * @param rowData
   * @param rowMeta
   * @returns
   */
  function renderExpandableRow(rowData: any, rowMeta: any, data: any) {
    const colSpan = rowData.length + 1;
    extendableChildren = [...extendableChildren, ...data];
    return (
      <TableRow>
        <TableCell colSpan={colSpan}>
          <MUIDataTableCustomStyle
            title={""}
            data={data}
            columns={subWorkflowHeader}
            options={getTableOptionsForSubWorkflows(data)}
          />
        </TableCell>
      </TableRow>
    );
  }

  /**
   *
   * @param item
   */
  function handleEdit(item: WorkflowModel | null) {
    setShouldUpdate(false);
    setOpen(!open);
    setEditWorkflow(item);
  }

  /**
   *
   * @param workflow
   */
  function addOrUpdateWorkflow(
    workflow: WorkflowModel | null,
    steps: WorkflowStepModel[]
  ) {
    const statuses = workflow?.statuses?.map((item: any) => item._id) || [];
    const newWorkflow = {
      ...workflow,
      statuses: statuses,
    };
    setShouldUpdate(true);
    if (workflow?._id) {
      const data = {
        workflow: newWorkflow,
        steps: steps,
      };
      updateData(`${urlEnum.workflows}/${workflow._id}`, data)
        .then((res) => {
          if (res) {
            websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
            setOpen(false);
          }
        })
        .catch((err) => {
          setOpen(false);
        });
    } else {
      postData(urlEnum.workflows, newWorkflow)
        .then((res: any) => {
          if (res) {
            websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
            setOpen(false);
          }
        })
        .catch((err) => {
          setOpen(false);
        });
    }
  }

  /**
   *
   * @param event
   * @param value
   */
  async function changeSubWorkflows(
    event: any,
    value: any,
    parentId: string | null,
    subWorkflows: any
  ) {
    // if the element is added
    //TODO is a workaround to avoid the error
    setShouldRerenderSubWorkflows(false);
    setShouldUpdate(false);
    startLoading();
    const distinctElements1 = value.filter(
      (item1: WorkflowModel) =>
        !subWorkflows.some((item2: WorkflowModel) => item1._id === item2._id)
    );

    for (let i = 0; i < distinctElements1.length; i++) {
      await updateData(
        `${urlEnum.workflows}/subWorkflows/${parentId}/${distinctElements1[i]._id}`,
        null
      );
    }

    // if the element is removed
    const distinctElements2 = subWorkflows.filter(
      (item1: WorkflowModel) =>
        !value.some((item2: WorkflowModel) => item1._id === item2._id)
    );
    for (let i = 0; i < distinctElements2.length; i++) {
      await updateData(
        `${urlEnum.workflows}/subWorkflows/${null}/${distinctElements2[i]._id}`,
        null
      );
    }
    setShouldRerenderSubWorkflows(true);
    setShouldUpdate(true);
    stopLoading();
  }

  return (
    <div
      className={phoneStyle ? styles.mainContainerMobile : styles.mainContainer}
    >
      <MainTable
        urlEnumApi={urlEnum.workflows}
        titleDelete={Vocabulary.deleteWorkflow}
        textDelete={Vocabulary.deleteConfirmationWorkflow}
        header={workflowHeader}
        tableTitle={Vocabulary.workflowsList}
        shouldUpdate={shouldUpdate}
        expandableRows={true}
        handleEdit={handleEdit}
        renderExpandableRow={renderExpandableRow}
        openDeleteModalProp={openDeleteModal}
        deletedIdProp={deletedId}
      />
      {open ? (
        <WorkflowMainPage
          editWorkflow={editWorkflow}
          open={open}
          shouldRerenderSubWorkflows={shouldRerenderSubWorkflows}
          changeSubWorkflows={changeSubWorkflows}
          addOrUpdateWorkflow={addOrUpdateWorkflow}
          onClose={() => {
            setShouldRerenderSubWorkflows(false);
            setOpen(false);
            setEditWorkflow(null);
          }}
        />
      ) : null}
    </div>
  );
}
