/* eslint-disable @typescript-eslint/no-explicit-any */
import { Tab, Tabs } from "@mui/material";
import AddOrUpdateModel from "../GenericComponents/AddOrUpdateModel";
import { ValidatorForm } from "react-material-ui-form-validator";
import { WorkflowModel, WorkflowStepModel } from "../../Utils/Models";
import { getData } from "../../Services/getData";
import { urlEnum } from "../../Utils/UrlEnum";
import { useEffect, useRef, useState } from "react";
import { Vocabulary } from "../../Utils/Vocabulary";
import Workflow from "./Workflow";
import WorkflowCost from "./WorkflowCost";

type WorkflowMainPageProps = {
  editWorkflow: WorkflowModel | null;
  open: boolean;
  shouldRerenderSubWorkflows: boolean;
  changeSubWorkflows: (
    event: any,
    value: any,
    parentId: string | null,
    subWorkflows: any
  ) => void;
  addOrUpdateWorkflow: (
    workflow: WorkflowModel | null,
    steps: WorkflowStepModel[]
  ) => void;
  onClose: () => void;
};

export default function WorkflowMainPage(props: WorkflowMainPageProps) {
  const {
    editWorkflow,
    open,
    shouldRerenderSubWorkflows,
    changeSubWorkflows,
    addOrUpdateWorkflow,
    onClose,
  } = props;
  const ref: any = useRef();
  const [value, setValue] = useState(0);
  const [workflow, setWorkflow] = useState<WorkflowModel>(new WorkflowModel());
  const [workflows, setWorkflows] = useState<WorkflowModel[]>([]);
  const [subWorkflows, setSubWorkflows] = useState<WorkflowModel[]>([]);
  const [steps, setSteps] = useState<WorkflowStepModel[]>([]);
  const [pressOnSubmit, setPressOnSubmit] = useState(false);

  /**
   *
   */
  useEffect(() => {
    if (editWorkflow) {
      getSubWorkflows(editWorkflow);
      getStepsForWorkflow(editWorkflow._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRerenderSubWorkflows]);

  /**
   *
   */
  useEffect(() => {
    if (editWorkflow) {
      setWorkflow(editWorkflow);
    } else {
      setWorkflow(new WorkflowModel());
    }
    getWorkflows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editWorkflow]);

  /**
   *
   */
  useEffect(() => {
    if (pressOnSubmit) {
      ref.current.submit();
      //reset the value
      handleChangePressOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressOnSubmit]);

  /**
   *
   * @param workflowId
   * @returns
   */
  async function getStepsForWorkflow(workflowId: string | null) {
    if (!workflowId) return;
    const res = await getData(
      `${urlEnum.steps}/stepsForOneWorkflow/${workflowId}`
    );
    setSteps(res.data.result);
  }
  /**
   *
   * @param id
   */
  function getSubWorkflows(editWorkflow: WorkflowModel | null) {
    if (editWorkflow && editWorkflow._id) {
      getData(`${urlEnum.workflows}/subWorkflows/${editWorkflow._id}`).then(
        (res: any) => {
          if (res) {
            setSubWorkflows(res.data.result);
          }
        }
      );
    }
  }
  /**
   *
   */
  function handleChangePressOnSubmit() {
    setPressOnSubmit(!pressOnSubmit);
  }

  /**
   *
   */
  function getWorkflows() {
    getData(urlEnum.workflows).then((res: any) => {
      if (res) {
        setWorkflows(res.data.result);
      }
    });
  }

  /**
   *
   * @param value
   */
  function handleChangeWorkflow(value: WorkflowModel) {
    setWorkflow(value);
  }

  function handleChangeStep(newSteps: WorkflowStepModel[]) {
    setSteps(newSteps);
  }

  /**
   *
   * @param event
   * @param newValue
   */
  function handleChangeValue(event: React.SyntheticEvent, newValue: number) {
    setValue(newValue);
  }

  /**
   *
   */
  function showInformation() {
    switch (value) {
      case 0:
        return (
          <Workflow
            workflow={workflow}
            workflows={workflows}
            subWorkflows={subWorkflows}
            handleChangeWorkflow={handleChangeWorkflow}
            changeSubWorkflows={changeSubWorkflows}
          />
        );
      case 1:
        return (
          <WorkflowCost
            workflow={workflow}
            steps={steps}
            handleChangeWorkflow={handleChangeWorkflow}
            handleChangeStep={handleChangeStep}
          />
        );
      default:
        break;
    }
  }

  return (
    <ValidatorForm
      ref={ref}
      onSubmit={() => addOrUpdateWorkflow(workflow, steps)}
    >
      <AddOrUpdateModel
        open={open}
        title={Vocabulary.workflow}
        onClose={onClose}
        isSubmit={true}
        addOrUpdateItem={() => null}
        handleChangePressOnSubmit={handleChangePressOnSubmit}
        // buttons={buttons ? buttons : null}
      >
        <Tabs value={value} onChange={handleChangeValue} textColor="inherit">
          <Tab label={Vocabulary.generalInfo} />
          <Tab label={`${Vocabulary.cost} & ${Vocabulary.time}`} />
        </Tabs>
        {showInformation()}
      </AddOrUpdateModel>
    </ValidatorForm>
  );
}
