/* eslint-disable @typescript-eslint/no-explicit-any */
const Config: any = {
  apiUrl: "/api",
  momentUSDateFormat: "YYYY-MM-DD ",
  momentEUDateFormat: "DD-MM-YYYY ",
  datePickerFormat: "YYYY-MM-DD HH:mm:ss",
  rowsPerPage: [10, 20, 50, 100],
};
export default Config;

export const userExcelHeader = ["Nume", "Prenume", "Email", "Departament"];

export const departmentsExcelHeader = ["Nume"];

export const tasksExcelHeader = [
  "Departament",
  "Task",
  "Tip",
  "Periodicitate",
  "Prioritate",
  "Start",
  "Stop",
  "Stare",
  "Buget",
  "Echipamente",
  "Personal",
  "Progres",
  "Responsabil",
  "Observatii",
];

export const euFormat = "DD.MM.YYYY";
export const euFormatForTime = "HH:mm";
export const euFormatForDateTime = "DD.MM.YYYY HH:mm";
export const euFormatForDateTimeWithSeconds = "DD.MM.YYYY HH:mm:ss";
export const dayPilotFormatWithTime = "YYYY-MM-DDTHH:mm:ss";
export const dayPilotFormat = "YYYY-MM-DD";

export const numberOfDigits = 2;
