/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SyncIcon from "@mui/icons-material/Sync";
import moment from "moment";
import { Button, useMediaQuery } from "@mui/material";
import styles from "../../Styles/dashboard.module.css";
import { OrderContext } from "../../Contexts/OrderContext";
import { FiltersProps } from "../Reports/Reports";
import { Vocabulary } from "../../Utils/Vocabulary";
import theme from "../../Themes/Theme";
import { dayPilotFormat, euFormatForDateTime } from "../../Utils/Config";
import { LineModel, OrderModel, Step } from "../../Utils/Models";
import { localUrlEnum, urlEnum } from "../../Utils/UrlEnum";
import { postData } from "../../Services/postData";
import { HttpStatusCode } from "../../Utils/Constants";
import { getData } from "../../Services/getData";
import ReportsAndOrdersFilters from "../../Components/Reports/ReportsAndOrdersFilters";
import MainTable from "../../Components/GenericComponents/MainTable";
import GenericModal from "../../Components/GenericComponents/GenericModal";
import OrderWorkflow from "../../Components/Orders/OrderWorkflow";
import TimeTracking from "../../Components/Orders/TimeTracking/TimeTracking";
import SyncOrdersModal from "../../Components/Orders/SyncOrdersModal";

export default function Orders() {
  const navigate = useNavigate();
  const location = useLocation();
  const orderContext = useContext(OrderContext);
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [openTimeTracking, setOpenTimeTracking] = useState(false);
  const [openEditStep, setOpenEditStep] = useState(false);
  const [openSync, setOpenSync] = useState(false);
  const [modifyStep, setModifyStep] = useState<any>({
    step: null,
    index: 0,
  });
  const [filters, setFilters] = useState<FiltersProps>({
    startDate: moment().startOf("year"),
    endDate: moment().date(25),
    user: [],
  });

  const ordersHeaderMobile = [
    {
      label: Vocabulary.orderReference,
      name: "fixablyId",
      options: {
        sort: true,
      },
    },
    {
      label: Vocabulary.timeTracking,
      name: "_id",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        sort: false,
        empty: true,
        customBodyRender: (value: any) => {
          return (
            <AccessTimeIcon
              style={{
                color: theme.palette.primary?.main,
                cursor: "pointer",
                padding: 5,
                borderRadius: 4,
                border: `1.7px solid ${theme.palette.primary?.main}`,
              }}
              onClick={(e: any) => {
                e.stopPropagation();
                e.preventDefault();
                getOrder(value);
                setOpenTimeTracking(true);
              }}
            />
          );
        },
      },
    },
  ];
  const ordersHeader = [
    {
      label: Vocabulary.orderReference.toUpperCase(),
      name: "fixablyId",
      options: {
        sort: true,
      },
    },
    {
      label: Vocabulary.priority.toUpperCase(),
      name: "priority",
      options: {
        sort: true,
      },
    },
    {
      label: Vocabulary.device,
      name: "device",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return <>{value?.name || ""}</>;
        },
      },
    },
    {
      label: Vocabulary.workflow,
      name: "workflow",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return <>{value?.name || ""}</>;
        },
      },
    },
    {
      label: Vocabulary.status.toUpperCase(),
      name: "status",
      options: {
        sort: true,
        customBodyRender: (value: any) => {
          return <>{value?.name || ""}</>;
        },
      },
    },
    {
      label: Vocabulary.user,
      name: "user",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return <>{value ? `${value.firstName} ${value.lastName}` : ""}</>;
        },
      },
    },
    {
      label: Vocabulary.total.toUpperCase(),
      name: "total",
      options: {
        sort: true,
      },
    },
    {
      label: Vocabulary.date.toUpperCase(),
      name: "createdAt",
      options: {
        sort: true,
        customBodyRender: (value: any) => {
          return <>{moment(value).format(euFormatForDateTime)}</>;
        },
      },
    },

    {
      label: Vocabulary.timeTracking,
      name: "_id",
      options: {
        filter: false,
        setCellHeaderProps: () => ({
          align: "center",
        }),
        setCellProps: () => ({
          align: "center",
        }),
        sort: false,
        empty: true,
        customBodyRender: (value: any) => {
          return (
            <AccessTimeIcon
              style={{
                color: theme.palette.primary?.main,
                cursor: "pointer",
                padding: 5,
                borderRadius: 4,
                border: `1.7px solid ${theme.palette.primary?.main}`,
              }}
              onClick={(e: any) => {
                e.stopPropagation();
                e.preventDefault();
                getOrder(value);
                setOpenTimeTracking(true);
              }}
            />
          );
        },
      },
    },
  ];

  /**
   *
   */
  useEffect(() => {
    const search = location.search.split(/[?&]+/);
    const newFilters: any = Object.assign({}, filters);
    search.forEach((value: any) => {
      if (value !== "") {
        const values = value.split("=");
        newFilters[values[0]] = isNaN(+values[1]) ? values[1] : +values[1];
        if (values[0] === "page") newFilters[values[0]] = +values[1] - 1;
        if (values[0] === "user") newFilters[values[0]] = values[1].split(",");
        if (values[1] === "null") newFilters[values[0]] = null;
      }
    });
    setFilters({
      ...newFilters,
      startDate: moment(newFilters.startDate, dayPilotFormat),
      endDate: moment(newFilters.endDate, dayPilotFormat),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /**
   *
   * @param id
   */
  function getOrder(id: string) {
    getData(`${urlEnum.orders}/${id}`)
      .then((res) => {
        if (res) {
          const resOrder = res.data.result;
          if (resOrder.lines && resOrder.lines.length === 0)
            resOrder.lines = [new LineModel()];
          if (resOrder.notes && resOrder.notes.length !== 0)
            resOrder.notes = resOrder.notes.reverse();
          orderContext.setOrder(res.data.result);
        }
      })
      .catch((err) => {
        //
      });
  }
  /**
   *
   * @param step
   * @param index
   */
  function handleChangeModifyStep(step: Step | null, index: number) {
    setModifyStep({ step: step, index: index });
  }

  /**
   *
   * @param item
   */
  function handleEdit(item: OrderModel | null) {
    navigate(`${localUrlEnum.orders}/${item?._id ? item?._id : "newOrder"}`);
  }

  /**
   * Add or update step to order
   */
  function addOrUpdateStepToOrder(createdBy?: any) {
    setOpenEditStep(false);
    const formData: any = new FormData();
    formData.append("stepId", modifyStep.step.stepId._id);
    formData.append(
      "description",
      modifyStep.step.description &&
        modifyStep.step.description !== "" &&
        modifyStep.step.description !== "null" &&
        modifyStep.step.description !== "undefined"
        ? modifyStep.step.description
        : null
    );
    if (modifyStep.step.customStepValue) {
      formData.append(
        "customStepValue",
        modifyStep.step.customStepValue.value
          ? modifyStep.step.customStepValue.value
          : modifyStep.step.customStepValue
      );
    }
    formData.append(
      "createdBy",
      createdBy
        ? createdBy._id
        : modifyStep.step.createdBy
        ? modifyStep.step.createdBy._id
        : null
    );
    formData.append(
      "timeTracking",
      JSON.stringify(modifyStep.step.timeTracking)
    );
    if (modifyStep.step.files && modifyStep.step.files.length !== 0)
      modifyStep.step.files.forEach((file: any) => {
        formData.append("files", file, file.name);
      });
    else formData.append("files", []);
    const images = modifyStep.step.images.filter(
      (img: any) => typeof img === "string"
    );
    formData.append("images", JSON.stringify(images));
    postData(
      `${urlEnum.orders}/saveStep/${orderContext.order._id}`,
      formData
    ).then((res) => {
      if (res) {
        if (
          res.data.status === HttpStatusCode.OK ||
          res.data.status === HttpStatusCode.Created
        )
          orderContext.setOrder({
            ...orderContext.order,
            workflowSteps: res.data.result,
          });
      }
    });
  }

  /**
   *
   */
  function handleChangeOpenSync() {
    setShouldUpdate(true);
    setOpenSync(!openSync);
  }

  /**
   *
   */
  function syncOrders(orderNumber: number | null) {
    if (orderNumber) {
      getData(`${urlEnum.cronjob}/${orderNumber}`).then((res) => {
        if (res) {
          setShouldUpdate(true);
          handleChangeOpenSync();
        }
      });
    } else {
      getData(urlEnum.cronjob).then((res) => {
        if (res) {
          setShouldUpdate(true);
          handleChangeOpenSync();
        }
      });
    }
  }
  return (
    <>
      <div
        className={
          phoneStyle ? styles.mainContainerMobile : styles.mainContainer
        }
      >
        <ReportsAndOrdersFilters
          filters={filters}
          url={localUrlEnum.orders}
          disableClearable={false}
          multiple={true}
          showUser={true}
          recalculateReportsButton={false}
        />
        <MainTable
          urlEnumApi={urlEnum.orders}
          titleDelete={Vocabulary.deleteOrder}
          textDelete={Vocabulary.deleteConfirmationOrder}
          header={phoneStyle ? ordersHeaderMobile : ordersHeader}
          tableTitle={Vocabulary.ordersList}
          handleEdit={handleEdit}
          enableSort={true}
          shouldUpdate={shouldUpdate}
          headerButtons={
            <Button
              style={{
                marginLeft: 5,
                verticalAlign: "middle",
              }}
              onClick={() => setOpenSync(true)}
            >
              <SyncIcon color="primary" style={{ marginRight: 5 }} />
              {Vocabulary.sync}
            </Button>
          }
        />
      </div>
      <GenericModal
        open={openTimeTracking}
        onClose={() => {
          setOpenTimeTracking(false);
          orderContext.setOrder(new OrderModel());
        }}
        title={Vocabulary.timeTracking}
        styleTitle={{
          backgroundColor: theme.palette.primary?.main,
          color: "white",
          minWidth: 500,
        }}
      >
        <OrderWorkflow
          steps={orderContext.order.workflowSteps.filter((step) =>
            step.stepId?.name?.includes("Time")
          )}
          handleOpenOrderStep={() => setOpenEditStep(true)}
          handleChangeModifyStep={(step, index) =>
            handleChangeModifyStep(step, index)
          }
        />
      </GenericModal>
      {openEditStep ? (
        <TimeTracking
          open={openEditStep}
          modifyStep={modifyStep}
          onClose={() => {
            setOpenEditStep(false);
            handleChangeModifyStep(null, -1);
          }}
          addOrUpdateStepToOrder={addOrUpdateStepToOrder}
          handleChangeModifyStep={(step: Step | null, index: number) =>
            handleChangeModifyStep(step, index)
          }
        />
      ) : null}
      {openSync ? (
        <SyncOrdersModal
          openSync={openSync}
          handleChangeOpenSync={handleChangeOpenSync}
          syncOrders={syncOrders}
        />
      ) : null}
    </>
  );
}
