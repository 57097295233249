/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import styles from "../Styles/mainSlider.module.css";
import { useLocation } from "react-router-dom";
import SubMenuForMainSliderMobile from "./SubMenuForMainSliderMobile";
import { useState } from "react";

type Props = {
  data: any;
};
export default function MainSliderMobile(props: Props, location: any) {
  location = useLocation();
  const [openMenu, setOpenMenu] = useState(null);

  /**
   *
   * @param menuId
   */
  const handleMenuClick = (menuId: any) => {
    if (openMenu === menuId) {
      setOpenMenu(null);
      const container = document.getElementById("mainSliderMobile");
      container?.classList.remove("mainSliderMobileOpen");
      container?.classList.add("mainSliderMobile");
    } else {
      setOpenMenu(menuId);
      const container = document.getElementById("mainSliderMobile");
      container?.classList.remove("mainSliderMobile");
      container?.classList.add("mainSliderMobileOpen");
    }
  };

  return (
    <div className="containerMobile">
      <div className={styles.sliderMobile}>
        {props.data?.map((element: any, key: any) => {
          return (
            <SubMenuForMainSliderMobile
              key={`${element.path}_${key}`}
              element={element}
              location={location}
              handleMenuClick={() => handleMenuClick(key)}
              isOpen={openMenu === key}
            />
          );
        })}
      </div>
    </div>
  );
}
