/* eslint-disable @typescript-eslint/no-explicit-any */
import MainHeader from "./MainHeader";
import { useContext, useEffect, useState } from "react";
import MainSlider from "./MainSlider";
import styles from "../Styles/dashboardContainer.module.css";
import { Outlet, useNavigate } from "react-router-dom";
import { localUrlEnum } from "../Utils/UrlEnum";
import { useMediaQuery } from "@mui/material";
import MainSliderMobile from "./MainSliderMobile";
import { getRoutesForRole } from "../Utils/Constants";
import { LanguageContext } from "../Contexts/LanguageContext";

export default function DashboardContainer(props: any) {
  const navigate = useNavigate();
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const role = localStorage.getItem("role");
  const [menu, setMenu] = useState<any>(null);
  const languageContext = useContext(LanguageContext);
  /**
   *
   */
  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (!token) {
      navigate(localUrlEnum.login);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *
   */
  useEffect(() => {
    setMenu(getRoutesForRole(role));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageContext.language]);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.mainHeader}>
        <MainHeader title="" />
      </div>
      <div
        style={
          phoneStyle
            ? { height: "auto", minHeight: "100vh", backgroundColor: "#F7F8FA" }
            : {
                display: "flex",
                height: "auto",
                minHeight: "100vh",
                backgroundColor: "#F7F8FA",
              }
        }
      >
        {phoneStyle ? (
          <div id="mainSliderMobile" className="mainSliderMobile">
            <MainSliderMobile data={menu} />
          </div>
        ) : (
          <div id="mainSlider" className="mainSliderLarge">
            <MainSlider data={menu} />
          </div>
        )}
        {props.children ? props.children : <Outlet />}
      </div>
    </div>
  );
}
