/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import styles from "../Styles/dashboard.module.css";
import { Vocabulary } from "../Utils/Vocabulary";
import { urlEnum } from "../Utils/UrlEnum";
import MainTable from "../Components/GenericComponents/MainTable";
import { useState } from "react";
import { DeviceModel } from "../Utils/Models";
import { updateData } from "../Services/updateData";
import { postData } from "../Services/postData";
import Device from "../Components/Devices/Device";
import moment from "moment";
import Config from "../Utils/Config";
import { useMediaQuery } from "@mui/material";

export default function Devices() {
  const [open, setOpen] = useState(false);
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const [editItem, setEditItem] = useState<DeviceModel | null>(null);
  const [shouldUpdate, setShouldUpdate] = useState(false);

  const deviceHeader = [
    { label: Vocabulary.name, name: "name", options: { sort: false } },
    {
      label: Vocabulary.serialNumber,
      name: "serialNumber",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.brand,
      name: "brand",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.model,
      name: "model",
      options: {
        sort: false,
      },
    },
  ];
  /**
   *
   * @param item
   */
  function handleEdit(item: DeviceModel | null) {
    setShouldUpdate(false);
    setOpen(!open);
    setEditItem(item);
  }
  /**
   *
   * @param step
   */
  function addOrUpdateDevice(device: DeviceModel) {
    device.purchaseDate = moment(device.purchaseDate).format(
      Config.momentUSDateFormat
    );
    setShouldUpdate(true);
    if (device?._id) {
      updateData(`${urlEnum.devices}/${device._id}`, device)
        .then((res) => {
          if (res) {
            setOpen(false);
          }
        })
        .catch((err) => {
          setOpen(false);
        });
    } else {
      postData(urlEnum.devices, device)
        .then((res: any) => {
          if (res) {
            setOpen(false);
          }
        })
        .catch((err) => {
          setOpen(false);
        });
    }
  }

  return (
    <div
      className={phoneStyle ? styles.mainContainerMobile : styles.mainContainer}
    >
      <MainTable
        urlEnumApi={urlEnum.devices}
        titleDelete={Vocabulary.deleteDevice}
        textDelete={Vocabulary.deleteConfirmationDevice}
        header={deviceHeader}
        tableTitle={Vocabulary.devicesList}
        shouldUpdate={shouldUpdate}
        handleEdit={handleEdit}
      />
      {open ? (
        <Device
          editDevice={editItem}
          open={open}
          addOrUpdateDevice={(device) => addOrUpdateDevice(device)}
          onClose={() => handleEdit(null)}
        />
      ) : null}
    </div>
  );
}
