/* eslint-disable @typescript-eslint/no-explicit-any */
import { Vocabulary } from "../../Utils/Vocabulary";
import styles from "../../Styles/dashboard.module.css";
import MainTable from "../../Components/GenericComponents/MainTable";
import { urlEnum } from "../../Utils/UrlEnum";
import { useState } from "react";
import { DocumentTemplateModel } from "../../Utils/Models";
import { postData } from "../../Services/postData";
import DocumentTemplate from "../../Components/Templates/DocumentTemplate";
import { styleForTableWithTabs } from "../Users";

export default function DocumentsTemplates() {
  const [open, setOpen] = useState(false);
  const [editItem, setEditItem] = useState<DocumentTemplateModel | null>(null);
  const [shouldUpdate, setShouldUpdate] = useState(false);

  /**
   *
   */
  const documentsTemplateHeader = [
    {
      label: Vocabulary.name,
      name: "name",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.filePath,
      name: "filePath",
      options: {
        sort: false,
        customBodyRender: (filePath: any) => {
          return <>{filePath.split("/").pop() || ""}</>;
        },
      },
    },
    {
      label: Vocabulary.type,
      name: "type",
      options: {
        sort: false,
        customBodyRender: (value: any) => {
          return <>{value}</>;
        },
      },
    },
  ];

  /**
   *
   * @param item
   */
  function handleEdit(item: DocumentTemplateModel | null) {
    setShouldUpdate(false);
    setOpen(!open);
    setEditItem(item);
  }

  /**
   *
   * @param documentTemplate
   */
  function addOrUpdateDocumentTemplate(
    documentTemplate: DocumentTemplateModel
  ) {
    setShouldUpdate(true);
    let url = urlEnum.documentsTemplates;
    if (documentTemplate?._id) {
      url = `${urlEnum.documentsTemplates}/${documentTemplate._id}`;
    }
    const formData: any = new FormData();
    formData.append("name", documentTemplate.name);
    formData.append("type", documentTemplate.type);
    if (documentTemplate && documentTemplate.file)
      formData.append(
        "files",
        documentTemplate.file,
        documentTemplate.file.name
      );
    else formData.append("file", documentTemplate.filePath || "");
    formData.append(
      "documentInformation",
      documentTemplate.documentInformation || null
    );
    postData(url, formData)
      .then((res: any) => {
        if (res) {
          setOpen(false);
        }
      })
      .catch((err) => {
        setOpen(false);
      });
  }

  return (
    <div className={styles.mainContainerUser}>
      <MainTable
        urlEnumApi={urlEnum.documentsTemplates}
        titleDelete={Vocabulary.deleteDocumentsTemplates}
        textDelete={Vocabulary.deleteConfirmationDocumentsTemplates}
        header={documentsTemplateHeader}
        tableTitle={Vocabulary.documentsTemplatesList}
        shouldUpdate={shouldUpdate}
        handleEdit={handleEdit}
        style={styleForTableWithTabs}
      />
      {open ? (
        <DocumentTemplate
          open={open}
          editDocumentTemplate={editItem}
          onClose={() => setOpen(false)}
          addOrUpdateDocumentTemplate={addOrUpdateDocumentTemplate}
        />
      ) : null}
    </div>
  );
}
