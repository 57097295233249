/* eslint-disable @typescript-eslint/no-explicit-any */
import { Checkbox, Divider, Grid, useMediaQuery } from "@mui/material";

type PurchaseOrderProductsProps = {
  productId: any;
  product: any;
  manufacturer: any;
  quantity: any;
  stock: any;
  price: any;
  styles: any;
  showDivider: boolean;
  checked: boolean;
  disabled: boolean;
  handleChangeSelectedProducts: (
    selectedAll: boolean,
    id: string | null
  ) => void;
};

export default function PurchaseOrderProducts(
  props: PurchaseOrderProductsProps
) {
  const {
    productId,
    product,
    manufacturer,
    quantity,
    stock,
    price,
    styles,
    showDivider,
    checked,
    disabled,
    handleChangeSelectedProducts,
  } = props;
  const phoneStyle = useMediaQuery("(max-width: 800px)");

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid
        item
        xs={phoneStyle ? 1.5 : 1}
        md={phoneStyle ? 1.5 : 1}
        lg={phoneStyle ? 1.5 : 1}
        className={styles}
      >
        <Checkbox
          disabled={disabled}
          checked={checked}
          onChange={(event) =>
            handleChangeSelectedProducts(productId ? false : true, productId)
          }
        />
      </Grid>
      <Grid
        item
        xs={phoneStyle ? 3.5 : 3}
        md={phoneStyle ? 3.5 : 3}
        lg={phoneStyle ? 3.5 : 3}
        className={styles}
      >
        {product}
      </Grid>
      {phoneStyle ? null : (
        <Grid item xs={2} md={2} lg={2} className={styles}>
          {manufacturer}
        </Grid>
      )}
      <Grid
        item
        xs={phoneStyle ? 2.5 : 2}
        md={phoneStyle ? 2.5 : 2}
        lg={phoneStyle ? 2.5 : 2}
        className={styles}
      >
        {quantity}
      </Grid>
      <Grid
        item
        xs={phoneStyle ? 2.5 : 2}
        md={phoneStyle ? 2.5 : 2}
        lg={phoneStyle ? 2.5 : 2}
        className={styles}
      >
        {stock}
      </Grid>
      <Grid item xs={2} md={2} lg={2} className={styles}>
        {price}
      </Grid>
      {showDivider ? (
        <Grid item xs={12} md={12} lg={12}>
          <Divider />
        </Grid>
      ) : null}
    </Grid>
  );
}
