/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from "react";
import { PurchaseOrderModel } from "../../../../Utils/Models";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import AddOrUpdateModel from "../../../GenericComponents/AddOrUpdateModel";
import { Vocabulary } from "../../../../Utils/Vocabulary";
import { Autocomplete, Grid, TextField } from "@mui/material";
import {
  handleChangeAutocomplete,
  onChangeTextField,
} from "../../../../Utils/Utils";
import ProductsAutocomplete from "../../ProductsAutocomplete";
import ProductsList from "../StockManagement/ProductsList";
import { getData } from "../../../../Services/getData";
import { urlEnum } from "../../../../Utils/UrlEnum";
import {
  PurchaseOrderStatuses,
  usersTypes,
} from "../../../../Utils/AutocompleteUtils";
import useCustomMemo from "../../../GenericComponents/useCustomMemo";

type PurchaseOrderProps = {
  editPurchaseOrder: PurchaseOrderModel | null;
  open: boolean;
  addOrUpdatePurchaseOrder: (inventory: PurchaseOrderModel | null) => void;
  onClose: () => void;
};

export default function PurchaseOrder(props: PurchaseOrderProps) {
  const { editPurchaseOrder, open, addOrUpdatePurchaseOrder, onClose } = props;
  const ref: any = useRef();
  const cache = useCustomMemo();
  const inventories = cache.inventories || [];
  const [purchaseOrder, setPurchaseOrder] = useState<PurchaseOrderModel>(
    new PurchaseOrderModel()
  );
  const [customers, setCustomers] = useState([]);
  const [pressOnSubmit, setPressOnSubmit] = useState(false);

  /**
   *
   */
  useEffect(() => {
    if (editPurchaseOrder) {
      setPurchaseOrder(editPurchaseOrder);
    } else {
      setPurchaseOrder(new PurchaseOrderModel());
    }
    getCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editPurchaseOrder]);

  /**
   *
   */
  useEffect(() => {
    if (pressOnSubmit) {
      ref.current.submit();
      //reset the value
      handleChangePressOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressOnSubmit]);

  /**
   *
   */
  function getCustomers() {
    getData(`${urlEnum.customers}/type/${usersTypes.vendors}`).then(
      (res: any) => {
        if (res.data.status === 200) setCustomers(res.data.result);
      }
    );
  }

  /**
   *
   */
  function handleChangePressOnSubmit() {
    setPressOnSubmit(!pressOnSubmit);
  }

  /**
   * Add new product in stock transfer
   */
  function addNewProductInStockTransfer(event: any, value: any) {
    if (value) {
      const products = [...purchaseOrder.products];
      const inventory = value.inventories.find(
        (inv: any) =>
          purchaseOrder.inventory &&
          inv.inventory._id === purchaseOrder.inventory._id
      );
      if (inventory)
        products.push({
          product: value,
          quantity: inventory.quantity,
          price: inventory.price,
          status: PurchaseOrderStatuses.open,
        });
      else
        products.push({
          product: value,
          quantity: 0,
          price: 0,
          status: PurchaseOrderStatuses.open,
        });
      setPurchaseOrder({ ...purchaseOrder, products });
    }
  }

  /**
   *
   * @param event
   * @param index
   */
  function onChangeProducts(products: any) {
    setPurchaseOrder({ ...purchaseOrder, products });
  }

  return (
    <ValidatorForm
      ref={ref}
      onSubmit={() => addOrUpdatePurchaseOrder(purchaseOrder)}
    >
      <AddOrUpdateModel
        open={open}
        title={Vocabulary.purchaseOrder}
        onClose={onClose}
        isSubmit={true}
        addOrUpdateItem={() => null}
        handleChangePressOnSubmit={handleChangePressOnSubmit}
      >
        <Grid container spacing={2} style={{ marginTop: 10 }}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextValidator
              size="small"
              id="purchaseOrderNumber"
              name="purchaseOrderNumber"
              label={Vocabulary.purchaseOrderNumber}
              fullWidth
              variant="outlined"
              value={purchaseOrder.purchaseOrderNumber}
              onChange={(e: any) =>
                setPurchaseOrder(onChangeTextField(e, purchaseOrder))
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextValidator
              size="small"
              id="referenceNumber"
              name="referenceNumber"
              label={Vocabulary.referenceNumber}
              fullWidth
              variant="outlined"
              value={purchaseOrder.referenceNumber}
              onChange={(e: any) =>
                setPurchaseOrder(onChangeTextField(e, purchaseOrder))
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Autocomplete
              size="small"
              id="inventory"
              freeSolo={false}
              disablePortal
              getOptionLabel={(option: any) =>
                `${option.name} - ${option.location?.name || ""}`
              }
              isOptionEqualToValue={(option, value) => option._id === value._id}
              options={inventories}
              value={purchaseOrder.inventory}
              onChange={(event: any, value: any) => {
                const newPurchaseOrder = handleChangeAutocomplete(
                  event,
                  value,
                  purchaseOrder,
                  "inventory"
                );
                newPurchaseOrder.products = [];
                setPurchaseOrder(newPurchaseOrder);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="inventory"
                  label={Vocabulary.inventory}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Autocomplete
              size="small"
              id="vendor"
              freeSolo={false}
              disablePortal
              getOptionLabel={(option: any) => option.company}
              isOptionEqualToValue={(option: any, value: any) =>
                option._id === value._id
              }
              options={customers}
              value={purchaseOrder.vendor}
              onChange={(event: any, value: any) => {
                setPurchaseOrder(
                  handleChangeAutocomplete(
                    event,
                    value,
                    purchaseOrder,
                    "vendor"
                  )
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="vendor"
                  label={Vocabulary.vendor}
                  variant="outlined"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <ProductsAutocomplete
              parentProduct={null}
              handleChangeProductInParent={(event: any, value: any) => {
                addNewProductInStockTransfer(event, value);
              }}
              variant="outlined"
              disabled={purchaseOrder.inventory ? false : true}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <ProductsList
              products={purchaseOrder.products}
              showPrice={true}
              onChangeProducts={onChangeProducts}
            />
          </Grid>
        </Grid>
      </AddOrUpdateModel>
    </ValidatorForm>
  );
}
