/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import styles from "../../Styles/dashboard.module.css";
import { Vocabulary } from "../../Utils/Vocabulary";
import { urlEnum } from "../../Utils/UrlEnum";
import MainTable from "../../Components/GenericComponents/MainTable";
import { useContext, useState } from "react";
import { ProductModel } from "../../Utils/Models";
import { postData } from "../../Services/postData";
import ProductMainPage from "../../Components/Products/ProductMainPage";
import PublishIcon from "@mui/icons-material/Publish";
import { Button, useMediaQuery } from "@mui/material";
import { WebsocketContext } from "../../Contexts/WebsocketContext";
import { updateData } from "../../Services/updateData";
import { Delete } from "@mui/icons-material";
import theme from "../../Themes/Theme";
import GenericConfirmModal from "../../Components/GenericComponents/GenericConfirmModal";
import { HttpVerbs } from "../../Utils/Constants";

type OpenModal = {
  action: string | null;
  value: boolean;
};
export default function Products() {
  const [open, setOpen] = useState(false);
  const websocketContext = useContext(WebsocketContext);
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const [openModal, setOpenModal] = useState<OpenModal>({
    action: null,
    value: false,
  });
  const [editItem, setEditItem] = useState<ProductModel | null>(null);
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const [selectedIds, setSelectedIds] = useState<Array<string>>([]);
  const productsHeader = [
    { label: Vocabulary.name, name: "name", options: { sort: false } },
    {
      label: Vocabulary.code,
      name: "code",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.typeString,
      name: "typeString",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.categoryString,
      name: "categoryString",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.manufacturer,
      name: "manufacturer",
      options: {
        sort: false,
      },
    },
    {
      label: Vocabulary.stockPrice,
      name: "stockPrice",
      options: {
        sort: false,
      },
    },
  ];
  /**
   *
   * @param item
   */
  function handleEdit(item: ProductModel | null) {
    setShouldUpdate(false);
    setOpen(!open);
    setEditItem(item);
  }
  /**
   *
   * @param step
   */
  function addOrUpdateProduct(product: ProductModel) {
    product.inventories.forEach((inv: any) => {
      delete inv.isNew;
    });
    //form data
    const formData: any = new FormData();
    if (product.files && product.files.length !== 0)
      product.files.forEach((file: any) => {
        formData.append("files", file, file.name);
      });
    else formData.append("files", []);
    product.images = product.images.filter(
      (img: any) => typeof img === "string"
    );
    product.files = [];
    formData.append("product", JSON.stringify(product));
    //end form data

    if (product?._id) {
      postData(`${urlEnum.products}/${product._id}`, formData)
        .then((res) => {
          if (res) {
            websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
            setOpen(false);
            setShouldUpdate(true);
          }
        })
        .catch((err) => {
          setOpen(false);
          setShouldUpdate(true);
        });
    } else {
      postData(urlEnum.products, formData)
        .then((res: any) => {
          if (res) {
            websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
            setOpen(false);
            setShouldUpdate(true);
          }
        })
        .catch((err) => {
          setOpen(false);
          setShouldUpdate(true);
        });
    }
  }

  /**
   *
   */
  function postOnShopify(product: ProductModel) {
    updateData(`${urlEnum.products}/shopify/${product._id}`).then((res) => {
      websocketContext.setShouldUpdate(!websocketContext.shouldUpdate);
      setOpen(false);
      setShouldUpdate(true);
    });
  }

  /**
   *
   */
  function multiplePostOnShopify(url: string) {
    setShouldUpdate(false);
    updateData(`${urlEnum.products}/${url}`, { ids: selectedIds }).then(
      (res) => {
        setOpen(false);
        setOpenModal({ action: null, value: false });
        setSelectedIds([]);
        setShouldUpdate(true);
      }
    );
  }

  /**
   *
   * @param rowsSelected
   * @param allRows
   */
  function onRowsSelect(newSelectedIds: Array<string>) {
    setSelectedIds(newSelectedIds);
  }

  /**
   *
   * @returns
   */
  function customToolbarSelect() {
    return (
      <div>
        <Button
          variant="contained"
          style={{
            marginRight: 10,
            backgroundColor: theme.palette.primary?.main,
          }}
          color="primary"
          onClick={() => setOpenModal({ action: HttpVerbs.patch, value: true })}
        >
          <PublishIcon style={{ marginRight: 5 }} />
          {Vocabulary.publishOnShopify}
        </Button>
        <Button
          variant="contained"
          style={{
            marginRight: 10,
            backgroundColor: theme.palette.errorColor?.main,
          }}
          onClick={() =>
            setOpenModal({ action: HttpVerbs.delete, value: true })
          }
        >
          <Delete style={{ marginRight: 5 }} />
          {Vocabulary.deleteFromShopify}
        </Button>
      </div>
    );
  }
  return (
    <div
      className={phoneStyle ? styles.mainContainerMobile : styles.mainContainer}
    >
      <MainTable
        urlEnumApi={urlEnum.products}
        titleDelete={Vocabulary.deleteProduct}
        textDelete={Vocabulary.deleteConfirmationProduct}
        header={productsHeader}
        tableTitle={Vocabulary.productsList}
        shouldUpdate={shouldUpdate}
        handleEdit={handleEdit}
        selectableRows={"multiple"}
        onRowsSelect={(selectedIds: Array<string>) => onRowsSelect(selectedIds)}
        customToolbarSelect={customToolbarSelect}
      />
      {open ? (
        <ProductMainPage
          editProduct={editItem}
          open={open}
          addOrUpdateProduct={(product) => addOrUpdateProduct(product)}
          postOnShopify={(product) => postOnShopify(product)}
          onClose={() => handleEdit(null)}
          deleteProductOnShopify={(id: string | null) => {
            setOpenModal({ action: HttpVerbs.delete, value: true });
            if (id) setSelectedIds([id]);
          }}
        />
      ) : null}
      {openModal.value ? (
        <GenericConfirmModal
          title={
            openModal.action === HttpVerbs.patch
              ? Vocabulary.publishOnShopify
              : Vocabulary.deleteFromShopify
          }
          text={
            openModal.action === HttpVerbs.patch
              ? Vocabulary.publishOnShopifyMessage
              : Vocabulary.deleteFromShopifyMessage
          }
          openDeleteModal={openModal.value}
          onClose={() => setOpenModal({ action: null, value: false })}
          deleteItem={() => {
            if (openModal.action === HttpVerbs.patch)
              multiplePostOnShopify("shopify");
            else multiplePostOnShopify("shopify/delete");
          }}
        />
      ) : null}
    </div>
  );
}
