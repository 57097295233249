/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Autocomplete, Grid, TextField } from "@mui/material";
import { WorkflowStepModel } from "../../Utils/Models";
import { Vocabulary } from "../../Utils/Vocabulary";
import { TextValidator } from "react-material-ui-form-validator";
import { handleChangeAutocomplete, onChangeTextField } from "../../Utils/Utils";
import { booleanTypesArray, stepTypes } from "../../Utils/AutocompleteUtils";
import MultiSelect from "./MultiSelect";
import { useEffect } from "react";

type StepProps = {
  step: WorkflowStepModel;
  types: string[];
  handleChangeStep: (step: WorkflowStepModel) => void;
};
export default function Step(props: StepProps) {
  const { step, types, handleChangeStep } = props;

  useEffect(() => {
    if (step._id && step.defaultValues.length === 0) {
      const newDefaultValues = setDefaultValues();
      handleChangeStep({ ...step, defaultValues: newDefaultValues });
    }
  }, [step]);

  /**
   *
   */
  function setDefaultValues(type?: string) {
    const switchType = type ? type : step.type;
    switch (switchType) {
      case stepTypes.text:
      case stepTypes.boolean:
      case stepTypes.password:
        return [{ text: "" }];
      case stepTypes.multiselect:
        return [{ label: "", value: "" }];
      default:
        return [];
    }
  }
  /**
   *
   * @param defaultValues
   */
  function handleChangeMultiselect(defaultValues: Array<any>) {
    const newStep = Object.assign({}, step);
    newStep.defaultValues = defaultValues;
    handleChangeStep(newStep);
  }
  /**
   *
   * @returns
   */
  function showDataAccordingToTypes() {
    switch (step.type) {
      case stepTypes.text:
      case stepTypes.password:
        // TODO:  nu este facut pe roluri =>
        // => "Restrict who can reveal task data, default value - autocomplete";
        // return "- textfield";
        // return "- checkbox";
        return (
          <TextField
            id="text"
            name="text"
            size="small"
            fullWidth
            label={Vocabulary.defaultValue}
            value={
              step.defaultValues.length > 0 && step.defaultValues[0].text
                ? step.defaultValues[0].text
                : ""
            }
            onChange={(event: any) => {
              const res = onChangeTextField(event, step.defaultValues[0]);
              const newStep = Object.assign({}, step);
              newStep.defaultValues[0] = res;
              handleChangeStep(newStep);
            }}
          ></TextField>
        );
      case stepTypes.boolean:
        return (
          <Autocomplete
            size="small"
            id="defaultValues"
            freeSolo={false}
            disablePortal
            getOptionLabel={(option: any) => option.text}
            isOptionEqualToValue={(option, value) => option.text === value.text}
            options={booleanTypesArray}
            value={step.defaultValues[0] || { text: "" }}
            onChange={(event: any, value: any) => {
              let res = handleChangeAutocomplete(
                event,
                value,
                step.defaultValues[0]
              );
              if (!res) res = { text: "" };
              handleChangeStep({
                ...step,
                defaultValues: [res],
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                name="defaultValues"
                label={Vocabulary.defaultValue}
                variant="outlined"
              />
            )}
          />
        );
      case stepTypes.multiselect:
        // return "- autocomplete";
        //TODO: nu stiu care e treaba cu product
        return (
          <MultiSelect
            defaultValues={step.defaultValues}
            handleChangeMultiselect={handleChangeMultiselect}
          />
        );
      default:
        return "";
    }
  }

  return (
    <Grid container spacing={2} style={{ marginTop: 15 }}>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          variant="outlined"
          label={Vocabulary.name}
          value={step.name || ""}
          fullWidth
          size="small"
          id={"name"}
          name="name"
          validators={["required"]}
          errorMessages={[Vocabulary.requiredField]}
          InputProps={
            {
              // readOnly: step.fixablyId ? true : false,
            }
          }
          onChange={(event) => handleChangeStep(onChangeTextField(event, step))}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          variant="outlined"
          label={Vocabulary.internalName}
          value={step.internalName || ""}
          fullWidth
          size="small"
          id={"internalName"}
          name="internalName"
          onChange={(event) => handleChangeStep(onChangeTextField(event, step))}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          variant="outlined"
          label={Vocabulary.identifier}
          value={step.identifier || ""}
          fullWidth
          size="small"
          id={"identifier"}
          name="identifier"
          onChange={(event) => handleChangeStep(onChangeTextField(event, step))}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          variant="outlined"
          label={Vocabulary.notes}
          value={step.notes || ""}
          fullWidth
          size="small"
          id={"notes"}
          name="notes"
          onChange={(event) => handleChangeStep(onChangeTextField(event, step))}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <fieldset
          style={{ borderColor: "#ffffff", padding: 15, borderRadius: 5 }}
        >
          <legend style={{ color: "rgb(133, 133, 133)" }}>
            {Vocabulary.customField}
          </legend>
          <Autocomplete
            size="small"
            id="type"
            freeSolo={false}
            disablePortal
            getOptionLabel={(option: any) => option}
            isOptionEqualToValue={(option, value) => option === value}
            options={types}
            value={step.type}
            onChange={(event: any, value: any) => {
              const res = handleChangeAutocomplete(event, value, step, "type");
              const newDefaultValues = setDefaultValues(res.type);
              res.defaultValues = newDefaultValues;
              handleChangeStep(res);
            }}
            style={{ marginBottom: 20 }}
            renderInput={(params) => (
              <TextField
                {...params}
                name="type"
                label={Vocabulary.type}
                variant="outlined"
              />
            )}
          />
          {showDataAccordingToTypes()}
        </fieldset>
      </Grid>
    </Grid>
  );
}
