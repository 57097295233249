/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useEffect, useState } from "react";
import { Vocabulary } from "../../Utils/Vocabulary";
import AddOrUpdateModel from "../GenericComponents/AddOrUpdateModel";
import { getData } from "../../Services/getData";
import { Autocomplete, TextField } from "@mui/material";
import { handleChangeAutocomplete } from "../../Utils/Utils";
import { OrderContext } from "../../Contexts/OrderContext";
import { urlEnum } from "../../Utils/UrlEnum";
import { Step } from "../../Utils/Models";

type AddDataToOrderProps = {
  open: boolean;
  onClose: () => void;
  url: string;
  name: string;
  title: string;
};
export default function AddDataToOrder(props: AddDataToOrderProps) {
  const { open, url, name, title, onClose } = props;
  const orderContext = useContext(OrderContext);
  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    getOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *
   */
  function getOptions() {
    getData(url).then((res) => {
      if (res) {
        setAutocompleteOptions(res.data.result);
      }
    });
  }

  /**
   *
   * @param workflowId
   * @returns
   */
  async function getWorkflowStepsByWorkflowId(workflowId: string | null) {
    if (!workflowId) return [];
    const res = await getData(
      `${urlEnum.steps}/stepsForOneWorkflow/${workflowId}`
    );
    const steps = [];
    for (const step of res.data.result) {
      const newItem = new Step();
      newItem.stepId = step;
      steps.push(newItem);
    }
    return steps;
  }

  /**
   *
   * @param state
   */
  async function handleChange(data: any) {
    if (name === "workflow") {
      const steps = await getWorkflowStepsByWorkflowId(
        data && data._id ? data._id : null
      );
      orderContext.setOrder({
        ...orderContext.order,
        [name]: data,
        workflowSteps: steps,
      });
    } else {
      orderContext.setOrder({
        ...orderContext.order,
        [name]: data,
      });
    }
  }

  return (
    <AddOrUpdateModel
      open={open}
      title={title}
      onClose={onClose}
      addOrUpdateItem={() => {
        handleChange(data);
        onClose();
      }}
      saveButtonTitle={Vocabulary.select}
    >
      <Autocomplete
        size="small"
        id={name}
        freeSolo={false}
        disablePortal
        getOptionLabel={(option: any) =>
          option.firstName && option.lastName
            ? `${option.firstName} ${option.lastName}`
            : option.name
        }
        isOptionEqualToValue={(option, value) =>
          `${option.firstName} ${option.lastName}` ===
          `${value.firstName} ${value.lastName}`
        }
        options={autocompleteOptions}
        value={data}
        onChange={(event: any, value: any) => {
          setData(handleChangeAutocomplete(event, value, data));
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            label={Vocabulary[name]}
            variant="outlined"
          />
        )}
        style={{ marginTop: 20 }}
      />
    </AddOrUpdateModel>
  );
}
