/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { TextValidator } from "react-material-ui-form-validator";
import { OpeningHoursModel } from "../../Utils/Models";
import { onChangeTextField } from "../../Utils/Utils";
import { Vocabulary } from "../../Utils/Vocabulary";

type OpeningHoursProps = {
  editOpeningHours: OpeningHoursModel | null;
  handleChangeOpeningHoursToLocation: (openingHours: OpeningHoursModel) => void;
};
export default function OpeningHours(props: OpeningHoursProps) {
  const { editOpeningHours, handleChangeOpeningHoursToLocation } = props;
  const [openingHours, setOpeningHours] = useState(new OpeningHoursModel());
  /**
   *
   */
  useEffect(() => {
    if (editOpeningHours) {
      setOpeningHours(editOpeningHours);
    } else {
      setOpeningHours(new OpeningHoursModel());
    }

  }, [editOpeningHours]);

  function handleChangeOpeningHoursAndUpdateLocation(event: any) {
    const newOpeningHours = onChangeTextField(event, openingHours);
    setOpeningHours(newOpeningHours);
    handleChangeOpeningHoursToLocation(newOpeningHours);
  }

  return (
    <Grid container spacing={2} style={{ marginTop: 10 }}>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="aa"
          name="aa"
          label={Vocabulary.aa}
          fullWidth
          variant="outlined"
          value={openingHours.aa}
          onChange={handleChangeOpeningHoursAndUpdateLocation}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="de"
          name="de"
          label={Vocabulary.de}
          fullWidth
          variant="outlined"
          value={openingHours.de}
          onChange={handleChangeOpeningHoursAndUpdateLocation}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="en"
          name="en"
          label={Vocabulary.en}
          fullWidth
          variant="outlined"
          value={openingHours.en}
          onChange={handleChangeOpeningHoursAndUpdateLocation}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="fr"
          name="fr"
          label={Vocabulary.fr}
          fullWidth
          variant="outlined"
          value={openingHours.fr}
          onChange={handleChangeOpeningHoursAndUpdateLocation}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="it"
          name="it"
          label={Vocabulary.it}
          fullWidth
          variant="outlined"
          value={openingHours.it}
          onChange={handleChangeOpeningHoursAndUpdateLocation}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="help"
          name="help"
          label={Vocabulary.help}
          fullWidth
          variant="outlined"
          value={openingHours.help}
          onChange={handleChangeOpeningHoursAndUpdateLocation}
        />
      </Grid>
    </Grid>
  );
}
