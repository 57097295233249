/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Card,
  CardContent,
  CardHeader,
  ThemeProvider,
  createTheme,
  useMediaQuery,
} from "@mui/material";

type CardComponentProps = {
  icon: any;
  title: string;
  headerAction: any;
  children: any;
  style?: any;
  titleStyle?: any;
};
export default function CardComponent(props: CardComponentProps) {
  const { icon, title, headerAction, children, style, titleStyle } = props;
  const phoneStyle = useMediaQuery("(max-width: 800px)");

  const theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            backgroundColor: "#FFF",
            color: "#000",
            borderRadius: "50px",
            "&:hover": {
              backgroundColor: "#fff",
              color: "#189DD9",
            },
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: titleStyle
            ? titleStyle
            : {
                fontSize: 16,
              },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          notchedOutline: {
            border: "none",
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          action: phoneStyle
            ? {
                display: "grid",
              }
            : {},
        },
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Card style={style ? style : {}}>
        <CardHeader
          avatar={icon}
          action={headerAction}
          title={title}
          style={{
            backgroundColor: "#404a694d",
            minHeight: 28,
          }}
          sx={{ title: { fontSize: 20 } }}
        />
        <CardContent>{children}</CardContent>
      </Card>
    </ThemeProvider>
  );
}
