import { Grid, Paper } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import PeopleIcon from "@mui/icons-material/People";
import { displayTimeString, showFormattedDuration } from "../../Utils/Utils";
import { ReportProps } from "./ReportsPerUser";
import styles from "../../Styles/reports.module.css";
import { numberOfDigits } from "../../Utils/Config";

type ReportHeaderProps = {
  report: ReportProps;
};
export default function ReportHeader(props: ReportHeaderProps) {
  const { report } = props;
  return (
    <>
      <Paper className={styles.reportsHeader}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <PeopleIcon style={{ marginRight: 15 }} />
          {report.technicianName}
        </div>
        <div style={{ fontWeight: "500", fontSize: 18 }}>
          {Vocabulary.totalSteps}: {report.stepsNumber}
        </div>
      </Paper>
      <Paper className={styles.reportsPaperWithTotals}>
        <Grid container spacing={1} style={{ margin: 0, width: "100%" }}>
          <Grid
            item
            xs={12}
            md={3}
            lg={3}
            textAlign="center"
            style={{
              borderLeft: "1px solid #f3f3f3",
            }}
            className={styles.reportsTotalsGridItems}
          >
            <div className={styles.reportsTotalsItemContainer}>
              <div className={styles.reportsTotalsItemLabel}>
                {Vocabulary.totalTrackedTime}
              </div>
              <div className={styles.reportsTotalsItemValue}>
                {showFormattedDuration(report.totalTrackedTime)}
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            lg={3}
            textAlign="center"
            className={styles.reportsTotalsGridItems}
          >
            <div className={styles.reportsTotalsItemContainer}>
              <div className={styles.reportsTotalsItemLabel}>
                {Vocabulary.totalPredefinedTime}
              </div>
              <div className={styles.reportsTotalsItemValue}>
                {displayTimeString(report.totalPredefinedTime)}
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            lg={3}
            textAlign="center"
            className={styles.reportsTotalsGridItems}
          >
            <div className={styles.reportsTotalsItemContainer}>
              <div className={styles.reportsTotalsItemLabel}>
                {Vocabulary.totalTrackedCosts}
              </div>
              <div className={styles.reportsTotalsItemValue}>
                {report.totalTrackedCosts.toFixed(numberOfDigits)}
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={3}
            lg={3}
            textAlign="center"
            className={styles.reportsTotalsGridItems}
          >
            <div className={styles.reportsTotalsItemContainer}>
              <div className={styles.reportsTotalsItemLabel}>
                {Vocabulary.totalPredefinedCost}
              </div>
              <div className={styles.reportsTotalsItemValue}>
                {report.totalPredefinedCost.toFixed(numberOfDigits)}
              </div>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
