/* eslint-disable @typescript-eslint/no-explicit-any */
import i18n from "../i18n";

const lang: any = {
  en: {
    english: "English",
    romanian: "Romanian",
    german: "German",
    login: "Login",
    loginValidationError: "Your username or password are incorrect!",
    requiredField: "Required field!",
    emailValidation: "Enter a valid email address.",
    pageNotFound: "Page not found!",
    youDoNotHavePermission: "You do not have permission to access this page!",
    search: "Search",
    siteName: "UPVOLT",
    logout: "Logout",
    myAccount: "My account",
    users: "Users",
    active: "Active",
    inactive: "Inactive",
    activate: "Activate",
    user: "User",
    employees: "Employees",
    vendors: "Vendors",
    departments: "Departments",
    lastName: "Last name",
    firstName: "First name",
    password: "Password",
    crtNo: "Crt. No",
    email: "Email",
    department: "Department",
    options: "Options",
    edit: "Edit",
    delete: "Delete",
    description: "Description",
    noResultsFound: "No results found",
    nextPage: "Next page",
    previousPage: "Previous page",
    rowsPerPage: "Rows per page",
    of: "of",
    downloadCSV: "Download CSV",
    print: "Print",
    rowsDeleted: "rows deleted",
    rowsSelected: "rows selected",
    usersList: "Users list",
    employeesList: "Employees list",
    deleteUsers: "Delete users",
    deleteConfirmationUser: "Are you sure you want to delete this user?",
    departmentsList: "Departments list",
    tasksList: "Tasks list",
    reportsList: "Reports list",
    deleteFilters: "Delete filters",
    tasks: "Tasks",
    meetings: "Meetings",
    media: "Media",
    add: "Add",
    no: "No",
    yes: "Yes",
    import: "Import",
    name: "Name",
    departmentHead: "Department head",
    addNewUser: "Add new user",
    editUser: "Edit user",
    changePassword: "Change password",
    confirmPassword: "Confirm password",
    atLeast8Characters: "At least 8 characters",
    passwordNoMatch: "Passwords do not match",
    required: "Required",
    addNewDepartment: "Add new department",
    save: "Save",
    cancel: "Cancel",
    addFiles: "Add files",
    finish: "Finish",
    generate: "Generate",
    finished: "Finished",
    unfinished: "Unfinished",
    userName: "Username",
    taskName: "Task name",
    archived: "Archived",
    messages: "Messages",
    baseInfo: "Base info",
    dynamicInfo: "Dynamic information",
    attachments: "Attachments",
    otherInfos: "Other information",
    startDate: "Start date",
    endDate: "End date",
    date: "Date",
    position: "Position",
    dragAndDrop:
      "Drag and drop a file here or click to select a file from your computer.",
    error: "Error!",
    notFound: "Not found!",
    youDoNotHaveAccessToThisInformation:
      "You do not have access to this information!",
    dashboard: "Dashboard",
    newTask: "New task",
    profilePicture: "Profile picture",
    addCustomField: "Add custom field",
    customField: "Custom field",
    value: "Value",
    priority: "Priority",
    periodicity: "Periodicity",
    type: "Type",
    filePath: "File path",
    highPriority: "High priority",
    mediumPriority: "Medium priority",
    lowPriority: "Low priority",
    chooseUser: "Choose user",
    chooseDepartment: "Choose department",
    enabled: "Enabled",
    disabled: "Disabled",
    intervalError: "The interval is not valid! Date overlap!",
    recalculateReports: "Recalculate reports",
    numberOfSteps: "Number of steps",

    //workflows
    workflow: "Workflow",
    workflows: "Workflows",
    subWorkflows: "Sub workflows",
    workflowsList: "Workflows list",
    newWorkflow: "New workflow",
    deleteWorkflow: "Delete workflow",
    selectSubWorkflows: "Select sub workflows",
    workflowParent: "Workflow parent",
    deleteConfirmationWorkflow:
      "Are you sure you want to delete this workflow?",
    perHour: "per hour",
    totalEarnings: "Total earnings",
    totalDelayedSteps: "Total delayed steps",
    assignToSelf: "Assign to self",
    pleaseFirstDeallocateTheUserForThisStep:
      "Please first deallocate the user for this step!",
    timePerWorkflow: "Time per workflow",
    costPerWorkflow: "Cost per workflow",

    //orders
    order: "Order",
    orders: "Orders",
    ordersList: "Orders list",
    allOrders: "All orders",
    orderNumber: "Order number",
    deleteOrder: "Delete order",
    deleteConfirmationOrder: "Are you sure you want to delete this order?",
    internalLocation: "Internal location",
    total: "Total with VAT",
    totalCharged: "Total charged with VAT",
    totalRemaining: "Total remaining with VAT",
    productsAndServices: "Products and services",
    create: "Create",
    subTotal: "Subtotal",
    subtotalWithVAT: "Subtotal with VAT",
    remove: "Remove",
    totalDiscount: "Total discount",
    generalInformation: "General information",
    technician: "Technician",
    internalReference: "Internal reference",
    serviceLocation: "Service location",
    customerVisibility: "Visible to customer",
    printOutsVisibility: "Visible on print-outs",
    filterTimeline: "Filter timeline",
    reset: "Reset",
    chooseDevice: "Choose device",
    chooseWorkflow: "Choose workflow",
    chooseCustomer: "Choose customer",
    select: "Select",
    noIssue: "No issue",
    noDiagnosis: "No diagnosis",
    noResolution: "No solution",
    nothingToBill: "Nothing to bill",
    stillToBePaid: "Still to be paid",
    billed: "Billed",
    orderTotal: "Order total",
    orderCreated: "Order created",
    orderedBy: "Ordered by",
    handledBy: "Handled by",
    saveOrder: "Save order",
    addToOrder: "Add to order",
    saveToOrder: "Save to order",
    normal: "Normal",
    highest: "Highest",
    higher: "Higher",
    duplicateOrder: "Duplicate order",
    modify: "Modify",
    modifyOrderStep: "Modify order step",
    stepName: "Step name",
    time: "Time",
    editTimeTracking: "Edit time tracking",
    attachingFilesNumberError: "You can attach a maximum of ",
    cost: "Cost",
    minutes: "Minutes",
    startTimer: "Start timer",
    stopTimer: "Stop timer",
    intervals: "Intervals",
    startTime: "Start time",
    endTime: "End time",
    noEndTime: "No end time",
    duration: "Duration",
    attachFiles: "Attach files",
    totalDuration: "Total duration",
    timeTracking: "Time tracking",
    endTimeError: "End time must be greater than start time!",
    orderReference: "Order reference",
    others: "Others",
    percent: "Percent",
    workflowCost: "Workflow cost",
    workflowTime: "Workflow time",
    generalInfo: "General information",
    sumOfPercentagesMessage: "*The sum of percentages must be 100!",
    sumOfTimeMessage: "*The sum of time must be equal to the workflow time!",
    trackedCost: "Tracked cost",
    predefinedCost: "Predefined cost",
    takePredefinedCost: "Take predefined cost",
    selectStepAndUser: "Select step and user!",
    sync: "Sync",
    syncLastTenOrdersFromFixably: "Sync the last 10 orders from Fixably",
    syncByOrderNumber: "Sync by order number",
    orderNumberIsRequired: "Order number is required!",
    postOnShopify: "Post on Shopify",
    updateProductOnShopify: "Update product on Shopify",
    publishOnShopify: "Publish on Shopify",
    deleteFromShopify: "Delete from Shopify",
    publishOnShopifyMessage:
      "Are you sure you want to publish these products on Shopify?",
    deleteFromShopifyMessage:
      "Are you sure you want to delete these products from Shopify?",
    shopifyId: "Shopify ID",
    attributeAlreadyExists: "Attribute already exists!",

    //trashed orders
    trashedOrders: "Trashed orders",
    trashedOrdersList: "Trashed orders list",
    deleteConfirmationTrashedOrders:
      "Are you sure you want to delete this order? This action cannot be undone!",
    deleteTrashedOrder: "Delete order",
    restore: "Restore",
    restoreOrder: "Restore order",
    restoreConfirmationOrder: "Are you sure you want to restore this order?",

    //steps
    step: "Step",
    steps: "Steps",
    stepsList: "Steps list",
    internalName: "Internal name",
    identifier: "Identifier",
    deleteStep: "Delete step",
    deleteConfirmationStep: "Are you sure you want to delete this step?",
    assignWithWorkflow: "Assign with workflow",
    stepInformation: "Step information",
    defaultValue: "Default value",
    newOption: "New option",
    customStepValue: "Custom step value",
    defaultValues: "Default values",
    savingStep: "Saving step",
    saveStepMessage:
      "Are you sure you want to save the changes made to this step? Linking or unlinking this step with these workflows will impact the related orders.",

    //products
    product: "Product",
    products: "Products",
    productsList: "Products list",
    deleteProduct: "Delete product",
    deleteConfirmationProduct: "Are you sure you want to delete this product?",
    productManager: "Product manager",
    chooseProduct: "Choose product",
    code: "Code",
    typeString: "Type",
    categoryString: "Category",
    stockPrice: "Selling price",
    manufacturer: "Manufacturer",
    chooseProject: "Choose project",
    partNumber: "Part number",
    ean: "EAN",
    family: "Family",
    version: "Version",
    category: "Category",
    web: "Web",
    purchaseStockPrice: "Purchase stock price",
    purchaseExchangePrice: "Purchase exchange price",
    exchangePrice: "Exchange price",
    markup: "Markup",
    eeeCode: "EEE code",
    provider: "Provider",
    serialized: "Serialized",
    fixed: "Fixed",
    visibleOnPrintouts: "Visible on printouts",
    selling: "Selling",
    purchase: "Purchase",
    details: "Details",
    garantie: "Garantie",
    additionalData: "Additional data",
    addOptions: "Add options",
    done: "Done",
    thereIsNoOptions: "There is no options!",
    optionName: "Option name",
    optionValues: "Option values",
    variants: "Variants",
    removeOption: "Remove option",
    removeVariants: "Remove variants",
    restoreVariants: "Restore variants",
    editPrice: "Edit price",
    editQuantity: "Edit quantity",
    deletedVariantMessage: "The variant has been deleted!",
    undo: "Undo",
    addImages: "Add images",
    variantsAndAttributes: "Variants & Attributes",
    attributes: "Product attributes",
    attribute: "Attribute",
    deleteAttribute: "Delete attribute",
    deleteConfirmationAttribute:
      "Are you sure you want to delete this attribute?",
    attributesList: "Product attributes list",
    values: "Values",
    sku: "SKU",
    percentageVAT: "Percentage VAT",
    languages: "Languages",
    settings: "Settings",

    //stock
    stock: "Stock",
    stocks: "Stocks",
    stocksManagement: "Stocks management",
    inventory: "Inventory",
    inventories: "Inventories",
    inventoriesList: "Inventories list",
    deleteInventory: "Delete inventory",
    deleteConfirmationInventory:
      "Are you sure you want to delete this inventory?",
    defaultInventory: "Default inventory",
    purchaseOrders: "Purchase orders",
    incomingGoods: "Incoming goods",
    receivedGoods: "Received goods",
    stockTransfers: "Stock transfers",
    stockTransfer: "Stock transfer",
    stockTransfersList: "Stock transfers list",
    deleteStockTransfer: "Delete stock transfer",
    deleteConfirmationStockTransfer:
      "Are you sure you want to delete this stock transfer?",
    from: "From",
    to: "To",
    addOuttake: "Add outtake",
    comments: "Comments",
    outtake: "Outtake",
    open: "Open",
    closed: "Closed",
    purchaseOrderNumber: "Purchase order number",
    referenceNumber: "Reference number",
    vendor: "Vendor",
    purchaseOrder: "Purchase order",
    purchaseOrdersList: "Purchase orders list",
    deletePurchaseOrder: "Delete purchase order",
    deleteConfirmationPurchaseOrder:
      "Are you sure you want to delete this purchase order?",
    receivedOrder: "Received order",
    receivedOrderConfirmationMessage:
      "Are you sure you want to confirm this order?",
    receiveSelected: "Receive selected",
    showPurchaseOrder: "Show purchase order",
    inventoryPrice: "Inventory price",
    inventoryQuantity: "Inventory quantity",
    productPrice: "Product price",
    openPurchaseOrdersList: "Open purchase orders list",
    closedPurchaseOrdersList: "Closed purchase orders list",
    changeStockTransferStatus: "Change stock transfer status",
    selectStatus: "Select status",
    purchaseOrderTotal: "Purchase order total",
    maxQuantityIs: "Max quantity is",
    chooseInventory: "Choose inventory",
    quantityExceedsInventory: "Quantity exceeds inventory!",
    emailConfiguration: "Email configuration",
    sendEmail: "Send email",
    invalidEmail: "Invalid email",
    replyEmail: "Reply email",

    //taxRates
    taxRates: "Tax rates",
    taxRate: "Tax rate",
    deleteTaxRate: "Delete tax rate",
    deleteConfirmationTaxRate: "Are you sure you want to delete this tax rate?",
    taxRatesList: "Tax rates list",
    default: "Default",
    setAsDefault: "Set as default",
    setAsDefaultConfirmation:
      "Are you sure you want to set this tax rate as default?",

    //devices
    device: "Device",
    devices: "Devices",
    devicesList: "Devices list",
    deleteDevice: "Delete device",
    deleteConfirmationDevice: "Are you sure you want to delete this device?",
    brand: "Brand",
    model: "Model",
    serialNumber: "Serial number",
    imeiNumber1: "IMEI number 1",
    imeiNumber2: "IMEI number 2",
    configuration: "Configuration",
    deviceFamily: "Device family",
    distributor: "Distributor",
    purchaseCountry: "Purchase Country",
    purchaseDate: "Purchase Date",
    isActivationLocked: "Is Activation Locked",
    isCarrierLocked: "Is Carrier Locked",
    isVintage: "Is Vintage",
    isObsolete: "Is Obsolete",
    isLoaner: "Is Loaner",
    tags: "Tags",
    customFields: "Custom fields",
    productImageUrl: "Product image URL",
    createdAt: "Created at",
    deviceName: "Device name",
    deviceSerialNumber: "Device serial number",
    deviceModel: "Device model",
    deviceBrand: "Device brand",
    informationSource: "Information source",

    //customers
    customer: "Customer",
    customers: "Customers",
    customerName: "Customer name",
    customersList: "Customers list",
    vendorsList: "Vendors list",
    deleteCustomer: "Delete customer",
    deleteConfirmationCustomer:
      "Are you sure you want to delete this customer?",
    company: "Company",
    phone: "Phone",
    business: "Business",
    vatNumber: "VAT number",
    group: "Group",
    language: "Language",
    defaultPaymentTerm: "Default payment term",
    allShippingAddresses: "All shipping addresses",
    allowShareEmail: "Allow share email",
    parent: "Parent",
    address1: "Address 1",
    address2: "Address 2",
    address3: "Address 3",
    city: "City",
    zip: "Zip",
    state: "State",
    shippingAddress: "Shipping address",
    billingAddress: "Billing address",
    customerAddress: "Customer address",

    //deliveries
    delivery: "Delivery",
    deliveries: "Deliveries",
    deliveriesList: "Deliveries list",
    deleteDelivery: "Delete delivery",
    deleteConfirmationDelivery:
      "Are you sure you want to delete this delivery?",
    shortName: "Short name",

    //lines
    line: "Line",
    lines: "Lines",
    linesList: "Lines list",
    deleteLine: "Delete line",
    deleteConfirmationLine: "Are you sure you want to delete this line?",
    price: "Price",
    vat: "VAT",
    priceRange: "Price range",
    quantity: "Quantity",
    quantityCharged: "Quantity charged",
    quantityDispatched: "Quantity dispatched",
    discount: "Discount",
    originalSerialNumber: "Original serial number",
    imeiNumber: "IMEI number",
    totalRemaining0: "Total remaining",
    totalCharged0: "Total charged",
    total0: "Total",
    purchasePrice: "Purchase price",
    isHidden: "Is hidden",

    //locations
    location: "Location",
    locations: "Locations",
    locationsList: "Locations list",
    deleteLocation: "Delete location",
    deleteConfirmationLocation:
      "Are you sure you want to delete this location?",
    country: "Country",
    address: "Address",
    tat: "Tat",
    longitude: "Longitude",
    latitude: "Latitude",
    aa: "Aa",
    de: "De",
    en: "En",
    fr: "Fr",
    it: "It",
    help: "Help",
    openingHours: "Opening Hours",

    //notes
    note: "Note",
    notes: "Notes",
    notesList: "Notes list",
    deleteNote: "Delete note",
    deleteConfirmationNote: "Are you sure you want to delete this note?",
    text: "Text",
    visibility: "Visibility",
    title: "Title",
    createdBy: "Created by",
    noteReportMessage: "Select if note should be visible on printouts.",
    draft: "Draft",

    //shipments
    shipment: "Shipment",
    shipments: "Shipments",
    shipmentsList: "Shipments list",
    deleteShipment: "Delete shipment",
    deleteConfirmationShipment:
      "Are you sure you want to delete this shipment?",
    trackingNumber: "Tracking number",
    carrier: "Carrier",
    reference: "Reference",
    sender: "Sender",
    recipient: "Recipient",
    senderLocation: "Sender location",
    recipientLocation: "Recipient location",
    senderStore: "Sender store",
    recipientStore: "Recipient store",
    serviceCode: "Service code",
    packages: "Packages",
    senderAddress: "Sender address",
    recipientAddress: "Recipient address",
    requestShipment: "Request shipment",
    cancelShipment: "Cancel shipment",
    noPendingShipmentRequests: "No pending shipment requests",
    pendingShipmentRequests: "Pending shipment requests",
    created: "Created",
    pending: "Pending",
    inTransit: "In transit",
    delivered: "Delivered",
    processed: "Processed",
    canceled: "Canceled",
    confirmAndProcess: "Confirm and process",
    inbound: "Inbound",
    outbound: "Outbound",
    allShipments: "All shipments",

    //statuses
    status: "Status",
    statuses: "Statuses",
    statusesList: "Statuses list",
    deleteStatus: "Delete status",
    deleteConfirmationStatus: "Are you sure you want to delete this status?",
    liable: "Liable",
    isEnabled: "Is enabled",
    isDefault: "Is default",
    chooseWorkflowFirst: "Choose workflow first!",

    //roles
    roles: "Roles",
    role: "Role",
    rolesList: "Roles list",
    deleteRole: "Delete role",
    deleteConfirmationRole: "Are you sure you want to delete this role?",
    displayName: "Display name",

    //reports
    reports: "Reports",
    report: "Report",
    ordersReports: "Orders reports",
    workflowsReports: "Workflows reports",
    statistics: "Statistics",
    totalTrackedCosts: "Total tracked costs",
    totalPredefinedCost: "Total predefined cost",
    trackedHours: "Tracked hours",
    predefinedHours: "Predefined hours",
    trackedTime: "Tracked time",
    trackedPercent: "Tracked percent",
    predefinedTime: "Predefined time",
    predTimeShort: "Pred. time",
    timeFactor: "Time factor",
    orderCost: "Order cost",
    totalPredefinedTime: "Total predefined time",
    totalTrackedTime: "Total tracked time",
    totalSteps: "Total steps",
    filters: "Filters",
    noReportsMessage: "No reports found!",
    noUserFound: "No user found!",
    statisticsForADay: "Statistics for a day",
    statisticsForAMonth: "Statistics for a month",
    statisticsForAYear: "Statistics for a year",
    totalCosts: "Total costs",
    averageTrackedTime: "Average tracked time",
    averageTrackedPercent: "Average tracked percent",
    predictedPercent: "Predicted percent",
    averageTimeFactor: "Average time factor",
    day: "Day",
    month: "Month",
    year: "Year",

    //templates
    emailTemplates: "Email templates",
    emailTemplate: "Email template",
    emailTemplatesList: "Email templates list",
    deleteEmailTemplate: "Delete email template",
    deleteConfirmationEmailTemplate:
      "Are you sure you want to delete this email template?",
    subject: "Subject",
    cc: "CC",
    addDynamicData: "Add dynamic data",
    templates: "Templates",
    documentsTemplates: "Documents templates",
    documentTemplate: "Document template",
    deleteDocumentsTemplates: "Delete documents templates",
    deleteConfirmationDocumentsTemplates:
      "Are you sure you want to delete this documents template?",
    documentsTemplatesList: "Documents templates list",
    uploadADocument: "Upload a document!",
    downloadDocument: "Download document",
    filename: "Filename",

    //templates statuses
    templateStatus: "Template status",
    templatesStatuses: "Template statuses",
    templateStatusesList: "Template statuses list",
    deleteTemplateStatus: "Delete template status",
    deleteConfirmationTemplateStatus:
      "Are you sure you want to delete this template status?",
    color: "Color",
    chooseTheColorForStatus: "Choose the color for status",
    enterEmailAddressesSeparatedByCommas:
      "Enter email addresses separated by commas",

    //DON'T CHANGE OR REMOVE THIS LINES
    HIDDEN: "Internal",
    VISIBLE: "Public",
    DELETED: "Removed",

    /**ERRORS */
    systemError: "System error! Contact the administrator!",
    getDataError: "Error getting data!",
    deleteDataError: "Error deleting data!",
    importDataError: "Error importing data!",
    createDataError: "Error creating data!",
    updateDataError: "Error updating data!",
    deleteDataSuccess: "Data has been deleted successfully!",
    importDataSuccess: "Data has been imported successfully!",
    createDataSuccess: "Data has been created successfully!",
    updateDataSuccess: "Data has been updated successfully!",

    /**STATUSES TYPE */
    CUSTOM: "Custom",
    IN_QUEUE: "In queue",
    WAITING_FOR_PARTS: "Waiting for parts",
    READY_FOR_PICKUP: "Ready for pickup",
    READY_TO_BE_SHIPPED: "Ready to be shipped",
    SHIPPED: "Shipped",
    PICKED_UP: "Picked up",
    PROCESSING: "Processing",
    WAITING_FOR_MANUFACTURER: "Waiting for manufacturer",
    WAITING_FOR_PROCESSING: "Waiting for processing",
    WAITING_RETURN_FROM_APPLE: "Waiting return from Apple",
    PARTS_ARRIVED: "Parts arrived",
    WAITING_FOR_LOCAL_APPROVAL: "Waiting for local approval",
  },
  ro: {
    english: "Engleză",
    romanian: "Română",
    german: "Germană",
    login: "Autentificare",
    loginValidationError: "Numele de utilizator sau parola sunt incorecte!",
    requiredField: "Câmp obligatoriu!",
    emailValidation: "Introduceți o adresă de email validă.",
    pageNotFound: "Pagina nu a fost găsită!",
    youDoNotHavePermission: "Nu aveți permisiunea de a accesa această pagină!",
    search: "Căutare",
    siteName: "UPVOLT",
    logout: "Deconectare",
    myAccount: "Contul meu",
    users: "Utilizatori",
    active: "Activ",
    inactive: "Inactiv",
    activate: "Activează",
    user: "Utilizator",
    employees: "Angajați",
    vendors: "Furnizori",
    departments: "Departamente",
    lastName: "Nume",
    firstName: "Prenume",
    password: "Parolă",
    crtNo: "Nr. crt.",
    email: "Email",
    department: "Departament",
    options: "Opțiuni",
    edit: "Editează",
    delete: "Șterge",
    description: "Descriere",
    noResultsFound: "Nu au fost găsite rezultate",
    nextPage: "Pagina următoare",
    previousPage: "Pagina anterioară",
    rowsPerPage: "Rânduri pe pagină",
    of: "din",
    downloadCSV: "Descarcă CSV",
    print: "Printează",
    rowsDeleted: "rânduri șterse",
    rowsSelected: "rânduri selectate",
    usersList: "Lista utilizatorilor",
    employeesList: "Lista angajaților",
    deleteUsers: "Șterge utilizatorii",
    deleteConfirmationUser:
      "Sunteți sigur că doriți să ștergeți acest utilizator?",
    departmentsList: "Lista departamentelor",
    tasksList: "Lista sarcinilor",
    reportsList: "Lista rapoartelor",
    deleteFilters: "Șterge filtrele",
    tasks: "Sarcini",
    meetings: "Întâlniri",
    media: "Media",
    add: "Adaugă",
    no: "Nu",
    yes: "Da",
    import: "Importă",
    name: "Nume",
    departmentHead: "Șef departament",
    addNewUser: "Adaugă utilizator nou",
    editUser: "Editează utilizator",
    changePassword: "Schimbă parola",
    confirmPassword: "Confirmați parola",
    atLeast8Characters: "Cel puțin 8 caractere",
    passwordNoMatch: "Parolele nu se potrivesc",
    required: "Obligatoriu",
    addNewDepartment: "Adaugă departament nou",
    save: "Salvează",
    cancel: "Anulează",
    addFiles: "Adaugă fișiere",
    finish: "Finalizează",
    generate: "Generează",
    finished: "Finalizat",
    unfinished: "Nefinalizat",
    userName: "Nume de utilizator",
    taskName: "Nume sarcină",
    archived: "Arhivat",
    messages: "Mesaje",
    baseInfo: "Informații de bază",
    dynamicInfo: "Informații dinamice",
    attachments: "Atașamente",
    otherInfos: "Alte informații",
    startDate: "Data începerii",
    endDate: "Data încheierii",
    date: "Dată",
    position: "Poziție",
    dragAndDrop:
      "Trageți și plasați un fișier aici sau faceți clic pentru a selecta un fișier de pe computerul dvs.",
    error: "Eroare!",
    notFound: "Nu a fost găsit!",
    youDoNotHaveAccessToThisInformation: "Nu aveți acces la aceste informații!",
    dashboard: "Tabloul de bord",
    newTask: "Sarcină nouă",
    profilePicture: "Poză de profil",
    addCustomField: "Adaugă câmp personalizat",
    customField: "Câmp personalizat",
    value: "Valoare",
    priority: "Prioritate",
    periodicity: "Periodicitate",
    type: "Tip",
    filePath: "Cale fișier",
    highPriority: "Prioritate mare",
    mediumPriority: "Prioritate medie",
    lowPriority: "Prioritate mică",
    chooseUser: "Alege utilizator",
    chooseDepartment: "Alege departament",
    enabled: "Activat",
    disabled: "Dezactivat",
    intervalError: "Intervalul nu este valid! Se suprapun datele!",
    recalculateReports: "Recalculează rapoartele",
    numberOfSteps: "Număr de pași",

    //workflows
    workflow: "Flux de lucru",
    workflows: "Fluxuri de lucru",
    subWorkflows: "Sub-fluxuri de lucru",
    workflowsList: "Lista fluxurilor de lucru",
    newWorkflow: "Flux de lucru nou",
    deleteWorkflow: "Șterge fluxul de lucru",
    selectSubWorkflows: "Selectați sub-fluxuri de lucru",
    workflowParent: "Flux de lucru părinte",
    deleteConfirmationWorkflow:
      "Sunteți sigur că doriți să ștergeți acest flux de lucru?",
    perHour: "pe oră",
    totalEarnings: "Venituri totale",
    totalDelayedSteps: "Total pași întârziati",
    assignToSelf: "Atribuie-mi mie",
    pleaseFirstDeallocateTheUserForThisStep:
      "Vă rugăm să de-alocați mai întâi utilizatorul pentru acest pas!",
    timePerWorkflow: "Timp per flux de lucru",
    costPerWorkflow: "Cost per flux de lucru",

    //orders
    order: "Comandă",
    orders: "Comenzi",
    ordersList: "Lista comenzilor",
    allOrders: "Toate comenzile",
    orderNumber: "Număr comandă",
    deleteOrder: "Șterge comanda",
    deleteConfirmationOrder:
      "Sunteți sigur că doriți să ștergeți această comandă?",
    internalLocation: "Locație internă",
    total: "Total cu TVA",
    totalCharged: "Total facturat cu TVA",
    totalRemaining: "Total restant cu TVA",
    productsAndServices: "Produse și servicii",
    create: "Creează",
    subTotal: "Subtotal",
    subtotalWithVAT: "Subtotal cu TVA",
    remove: "Îndepărtează",
    totalDiscount: "Discount total",
    generalInformation: "Informații generale",
    technician: "Tehnician",
    internalReference: "Referință internă",
    serviceLocation: "Locație serviciu",
    customerVisibility: "Vizibil pentru client",
    printOutsVisibility: "Vizibil pe printuri",
    filterTimeline: "Filtrează cronologia",
    reset: "Resetează",
    chooseDevice: "Alege dispozitiv",
    chooseWorkflow: "Alege flux de lucru",
    chooseCustomer: "Alege client",
    select: "Selectează",
    noIssue: "Fără problemă",
    noDiagnosis: "Fără diagnostic",
    noResolution: "Fără soluție",
    nothingToBill: "Nimic de facturat",
    stillToBePaid: "Încă de plată",
    billed: "Facturat",
    orderTotal: "Total comandă",
    orderCreated: "Comandă creată",
    orderedBy: "Comandată de",
    handledBy: "Gestionată de",
    saveOrder: "Salvează comanda",
    addToOrder: "Adaugă la comandă",
    saveToOrder: "Salvează în comandă",
    normal: "Normal",
    highest: "Cel mai mare",
    higher: "Mai mare",
    duplicateOrder: "Duplică comanda",
    modify: "Modifică",
    modifyOrderStep: "Modifică pasul comenzii",
    stepName: "Nume pas",
    time: "Timp",
    editTimeTracking: "Editează urmărirea timpului",
    attachingFilesNumberError: "Puteți atașa un maximum de ",
    cost: "Cost",
    minutes: "Minute",
    startTimer: "Începe cronometrul",
    stopTimer: "Oprește cronometrul",
    intervals: "Intervale",
    startTime: "Ora începerii",
    endTime: "Ora încheierii",
    noEndTime: "Fără ora încheierii",
    duration: "Durată",
    attachFiles: "Atașează fișiere",
    totalDuration: "Durata totală",
    timeTracking: "Urmărirea timpului",
    endTimeError: "Ora încheierii trebuie să fie mai mare decât ora începerii!",
    orderReference: "Referință comandă",
    others: "Altele",
    percent: "Procent",
    workflowCost: "Cost flux de lucru",
    workflowTime: "Timp flux de lucru",
    generalInfo: "Informații generale",
    sumOfPercentagesMessage: "*Suma procentelor trebuie să fie 100!",
    sumOfTimeMessage:
      "*Suma timpului trebuie să fie egală cu timpul fluxului de lucru!",
    trackedCost: "Cost urmărit",
    predefinedCost: "Cost predefinit",
    takePredefinedCost: "Ia costul predefinit",
    selectStepAndUser: "Selectați pasul și utilizatorul!",
    sync: "Sincronizează",
    syncLastTenOrdersFromFixably:
      "Sincronizează ultimele 10 comenzi din Fixably",
    syncByOrderNumber: "Sincronizează după numărul comenzii",
    orderNumberIsRequired: "Numărul comenzii este obligatoriu!",
    postOnShopify: "Publică pe Shopify",
    updateProductOnShopify: "Actualizează produsul pe Shopify",
    publishOnShopify: "Publică pe Shopify",
    deleteFromShopify: "Șterge de pe Shopify",
    publishOnShopifyMessage:
      "Sunteți sigur că doriți să publicați aceste produse pe Shopify?",
    deleteFromShopifyMessage:
      "Sunteți sigur că doriți să ștergeți aceste produse de pe Shopify?",
    shopifyId: "ID Shopify",
    attributeAlreadyExists: "Atributul există deja!",
    confirmOrder: "Confirmă comanda",
    orderType: "Tip comandă",
    estimate: "Deviz",

    //trashed orders
    trashedOrders: "Comenzi șterse",
    trashedOrdersList: "Lista comenzilor șterse",
    deleteConfirmationTrashedOrders:
      "Sunteți sigur că doriți să ștergeți această comandă? Această acțiune nu poate fi desfăcută!",
    deleteTrashedOrder: "Șterge comanda",
    restore: "Restaurare",
    restoreOrder: "Restaurare comandă",
    restoreConfirmationOrder:
      "Sunteți sigur că doriți să restaurați această comandă?",

    //steps
    step: "Pas",
    steps: "Pași",
    stepsList: "Lista pașilor",
    internalName: "Nume intern",
    identifier: "Identificator",
    deleteStep: "Șterge pasul",
    deleteConfirmationStep: "Sunteți sigur că doriți să ștergeți acest pas?",
    assignWithWorkflow: "Atribuie cu flux de lucru",
    stepInformation: "Informații pas",
    defaultValue: "Valoare implicită",
    newOption: "Opțiune nouă",
    customStepValue: "Valoare personalizată pas",
    defaultValues: "Valori implicite",
    savingStep: "Salvarea pasului",
    saveStepMessage:
      "Sunteți sigur că doriți să salvați modificările efectuate în acest pas? Legarea sau de-legarea acestui pas cu aceste fluxuri de lucru va influența comenzile aferente.",

    //products
    product: "Produs",
    products: "Produse",
    productsList: "Lista produselor",
    deleteProduct: "Șterge produsul",
    deleteConfirmationProduct:
      "Sunteți sigur că doriți să ștergeți acest produs?",
    productManager: "Manager produs",
    chooseProduct: "Alege produs",
    code: "Cod",
    typeString: "Tip",
    categoryString: "Categorie",
    stockPrice: "Preț de vânzare",
    manufacturer: "Producător",
    chooseProject: "Alege proiect",
    partNumber: "Număr piesă",
    ean: "EAN",
    family: "Familie",
    version: "Versiune",
    category: "Categorie",
    web: "Web",
    purchaseStockPrice: "Preț achiziție stoc",
    purchaseExchangePrice: "Preț schimb achiziție",
    exchangePrice: "Preț schimb",
    markup: "Marjă",
    eeeCode: "Cod EEE",
    provider: "Furnizor",
    serialized: "Serializat",
    fixed: "Fix",
    visibleOnPrintouts: "Vizibil pe printuri",
    selling: "Vânzare",
    purchase: "Achiziție",
    details: "Detalii",
    garantie: "Garanție",
    additionalData: "Date suplimentare",
    addOptions: "Adaugă opțiuni",
    done: "Finalizat",
    thereIsNoOptions: "Nu există opțiuni!",
    optionName: "Nume opțiune",
    optionValues: "Valori opțiune",
    variants: "Variante",
    removeOption: "Îndepărtează opțiune",
    removeVariants: "Îndepărtează variante",
    restoreVariants: "Restaurare variante",
    editPrice: "Editează prețul",
    editQuantity: "Editează cantitatea",
    deletedVariantMessage: "Varianta a fost ștersă!",
    undo: "Anulează",
    addImages: "Adaugă imagini",
    variantsAndAttributes: "Variante & Atribute",
    attributes: "Atribute produs",
    attribute: "Atribut",
    deleteAttribute: "Șterge atribut",
    deleteConfirmationAttribute:
      "Sunteți sigur că doriți să ștergeți acest atribut?",
    attributesList: "Lista atributelor produsului",
    values: "Valori",
    sku: "SKU",
    percentageVAT: "Procent TVA",
    languages: "Limbi",
    settings: "Setări",

    //stock
    stock: "Stoc",
    stocks: "Stocuri",
    stocksManagement: "Management stocuri",
    inventory: "Gestiune",
    inventories: "Gestiuni",
    inventoriesList: "Lista gestiunilor",
    deleteInventory: "Șterge gestiunea",
    deleteConfirmationInventory:
      "Sunteți sigur că doriți să ștergeți acestă gestiune?",
    defaultInventory: "Gestiune implicită",
    purchaseOrders: "Comenzi achiziție",
    incomingGoods: "Bunuri în curs de primire",
    receivedGoods: "Bunuri primite",
    stockTransfers: "Transferuri de stoc",
    stockTransfer: "Transfer de stoc",
    stockTransfersList: "Lista transferurilor de stoc",
    deleteStockTransfer: "Șterge transferul de stoc",
    deleteConfirmationStockTransfer:
      "Sunteți sigur că doriți să ștergeți acest transfer de stoc?",
    from: "De la",
    to: "Către",
    addOuttake: "Adaugă ieșire",
    comments: "Comentarii",
    outtake: "Ieșire",
    open: "Deschis",
    closed: "Închis",
    purchaseOrderNumber: "Număr comandă achiziție",
    referenceNumber: "Număr referință",
    vendor: "Furnizor",
    purchaseOrder: "Comandă achiziție",
    purchaseOrdersList: "Lista comenzilor de achiziție",
    deletePurchaseOrder: "Șterge comanda de achiziție",
    deleteConfirmationPurchaseOrder:
      "Sunteți sigur că doriți să ștergeți această comandă de achiziție?",
    receivedOrder: "Comandă primită",
    receivedOrderConfirmationMessage:
      "Sunteți sigur că doriți să confirmați această comandă?",
    receiveSelected: "Primiți produsele selectate",
    showPurchaseOrder: "Afișează comanda de achiziție",
    inventoryPrice: "Preț gestiune",
    inventoryQuantity: "Cantitate gestiune",
    productPrice: "Preț produs",
    openPurchaseOrdersList: "Lista comenzilor de achiziție deschise",
    closedPurchaseOrdersList: "Lista comenzilor de achiziție închise",
    changeStockTransferStatus: "Schimbă statusul transferului de stoc",
    selectStatus: "Selectează status",
    purchaseOrderTotal: "Total comandă achiziție",
    maxQuantityIs: "Cantitatea maximă este",
    chooseInventory: "Alege gestiune",
    quantityExceedsInventory: "Cantitatea depășește stocul!",
    emailConfiguration: "Configurație email",
    sendEmail: "Trimite email",
    invalidEmail: "Email invalid",
    replyEmail: "Răspunde la email",

    //taxRates
    taxRates: "Rate TVA",
    taxRate: "Rată TVA",
    deleteTaxRate: "Șterge rata TVA",
    deleteConfirmationTaxRate:
      "Sunteți sigur că doriți să ștergeți această rată TVA?",
    taxRatesList: "Lista ratelor TVA",
    default: "Implicit",
    setAsDefault: "Setează ca implicit",
    setAsDefaultConfirmation:
      "Sunteți sigur că doriți să setați această rată TVA ca implicită?",

    //devices
    device: "Dispozitiv",
    devices: "Dispozitive",
    devicesList: "Lista dispozitivelor",
    deleteDevice: "Șterge dispozitivul",
    deleteConfirmationDevice:
      "Sunteți sigur că doriți să ștergeți acest dispozitiv?",
    brand: "Marcă",
    model: "Model",
    serialNumber: "Număr serial",
    imeiNumber1: "Număr IMEI 1",
    imeiNumber2: "Număr IMEI 2",
    configuration: "Configurație",
    deviceFamily: "Familie dispozitiv",
    distributor: "Distribuitor",
    purchaseCountry: "Țara achiziției",
    purchaseDate: "Data achiziției",
    isActivationLocked: "Activarea este blocată",
    isCarrierLocked: "Carrierul este blocat",
    isVintage: "Este vintage",
    isObsolete: "Este învechit",
    isLoaner: "Este de împrumut",
    tags: "Etichete",
    customFields: "Câmpuri personalizate",
    productImageUrl: "URL imagine produs",
    createdAt: "Creat la",
    deviceName: "Nume dispozitiv",
    deviceSerialNumber: "Număr serial dispozitiv",
    deviceModel: "Model dispozitiv",
    deviceBrand: "Marcă dispozitiv",
    informationSource: "Sursa informației",

    //customers
    customer: "Client",
    customers: "Clienți",
    customerName: "Numele clientului",
    customersList: "Lista clienților",
    vendorsList: "Lista furnizorilor",
    deleteCustomer: "Șterge clientul",
    deleteConfirmationCustomer:
      "Sunteți sigur că doriți să ștergeți acest client?",
    company: "Companie",
    phone: "Telefon",
    business: "Afacere",
    vatNumber: "Număr TVA",
    group: "Grup",
    language: "Limbă",
    defaultPaymentTerm: "Termen de plată implicit",
    allShippingAddresses: "Toate adresele de livrare",
    allowShareEmail: "Permite partajarea email-ului",
    parent: "Părinte",
    address1: "Adresa 1",
    address2: "Adresa 2",
    address3: "Adresa 3",
    city: "Oraș",
    zip: "Cod poștal",
    state: "Stat",
    shippingAddress: "Adresa de livrare",
    billingAddress: "Adresa de facturare",
    customerAddress: "Adresa clientului",

    //deliveries
    delivery: "Livrare",
    deliveries: "Livrări",
    deliveriesList: "Lista livrărilor",
    deleteDelivery: "Șterge livrarea",
    deleteConfirmationDelivery:
      "Sunteți sigur că doriți să ștergeți această livrare?",
    shortName: "Nume scurt",

    //lines
    line: "Linie",
    lines: "Linii",
    linesList: "Lista liniilor",
    deleteLine: "Șterge linia",
    deleteConfirmationLine:
      "Sunteți sigur că doriți să ștergeți această linie?",
    price: "Preț",
    vat: "TVA",
    priceRange: "Interval de preț",
    quantity: "Cantitate",
    quantityCharged: "Cantitate facturată",
    quantityDispatched: "Cantitate expediată",
    discount: "Reducere",
    originalSerialNumber: "Număr serial original",
    imeiNumber: "Număr IMEI",
    totalRemaining0: "Total rămas",
    totalCharged0: "Total facturat",
    total0: "Total",
    purchasePrice: "Preț de achiziție",
    isHidden: "Este ascuns",

    //locations
    location: "Locație",
    locations: "Locații",
    locationsList: "Lista locațiilor",
    deleteLocation: "Șterge locația",
    deleteConfirmationLocation:
      "Sunteți sigur că doriți să ștergeți această locație?",
    country: "Țară",
    address: "Adresă",
    tat: "TAT",
    longitude: "Longitudine",
    latitude: "Latitudine",
    aa: "Aa",
    de: "De",
    en: "En",
    fr: "Fr",
    it: "It",
    help: "Ajutor",
    openingHours: "Ore de deschidere",

    //notes
    note: "Notă",
    notes: "Note",
    notesList: "Lista notelor",
    deleteNote: "Șterge nota",
    deleteConfirmationNote: "Sunteți sigur că doriți să ștergeți această notă?",
    text: "Text",
    visibility: "Vizibilitate",
    title: "Titlu",
    createdBy: "Creat de",
    noteReportMessage:
      "Selectați dacă nota ar trebui să fie vizibilă pe printuri.",
    draft: "Schiță",

    //shipments
    shipment: "Expediere",
    shipments: "Expedieri",
    shipmentsList: "Lista expedierilor",
    deleteShipment: "Șterge expedierea",
    deleteConfirmationShipment:
      "Sunteți sigur că doriți să ștergeți această expediere?",
    trackingNumber: "Număr de urmărire",
    carrier: "Curier",
    reference: "Referință",
    sender: "Expeditor",
    recipient: "Destinatar",
    senderLocation: "Locația expeditorului",
    recipientLocation: "Locația destinatarului",
    senderStore: "Magazinul expeditorului",
    recipientStore: "Magazinul destinatarului",
    serviceCode: "Cod serviciu",
    packages: "Pachete",
    senderAddress: "Adresa expeditorului",
    recipientAddress: "Adresa destinatarului",
    requestShipment: "Cerere expediere",
    cancelShipment: "Anulează expedierea",
    noPendingShipmentRequests: "Nu există cereri de expediere în așteptare!",
    pendingShipmentRequests: "Cereri de expediere în așteptare",
    created: "Creat",
    pending: "În așteptare",
    inTransit: "În tranzit",
    delivered: "Livrare",
    processed: "Procesat",
    canceled: "Anulat",
    confirmAndProcess: "Confirmă și procesează",
    inbound: "Intrare",
    outbound: "Plecare",
    allShipments: "Toate expedierile",

    //statuses
    status: "Status",
    statuses: "Statusuri",
    statusesList: "Lista statusurilor",
    deleteStatus: "Șterge statusul",
    deleteConfirmationStatus:
      "Sunteți sigur că doriți să ștergeți acest status?",
    liable: "Responsabil",
    isEnabled: "Este activ",
    isDefault: "Este implicit",
    chooseWorkflowFirst: "Alegeți mai întâi fluxul de lucru!",

    //roles
    roles: "Roluri",
    role: "Rol",
    rolesList: "Lista rolurilor",
    deleteRole: "Șterge rolul",
    deleteConfirmationRole: "Sunteți sigur că doriți să ștergeți acest rol?",
    displayName: "Nume afișat",

    //reports
    reports: "Rapoarte",
    report: "Raport",
    ordersReports: "Rapoarte comenzi",
    workflowsReports: "Rapoarte fluxuri de lucru",
    statistics: "Statistici",
    totalTrackedCosts: "Costuri totale urmărite",
    totalPredefinedCost: "Cost total predefinit",
    trackedHours: "Ore urmărite",
    predefinedHours: "Ore predefinite",
    trackedTime: "Timp urmărit",
    trackedPercent: "Procent urmărit",
    predefinedTime: "Timp predefinit",
    predTimeShort: "Timp pred.",
    timeFactor: "Factor de timp",
    orderCost: "Cost comandă",
    totalPredefinedTime: "Timp total predefinit",
    totalTrackedTime: "Timp total urmărit",
    totalSteps: "Total pași",
    filters: "Filtre",
    noReportsMessage: "Nu au fost găsite rapoarte!",
    noUserFound: "Nu a fost găsit niciun utilizator!",
    statisticsForADay: "Statistici pentru o zi",
    statisticsForAMonth: "Statistici pentru o lună",
    statisticsForAYear: "Statistici pentru un an",
    totalCosts: "Costuri totale",
    averageTrackedTime: "Timp mediu urmărit",
    averageTrackedPercent: "Procent mediu urmărit",
    predictedPercent: "Procent prezis",
    averageTimeFactor: "Factor mediu de timp",
    day: "Zi",
    month: "Lună",
    year: "An",

    //templates
    emailTemplates: "Șabloane email",
    emailTemplate: "Șablon email",
    emailTemplatesList: "Lista șabloanelor de email",
    deleteEmailTemplate: "Șterge șablonul de email",
    deleteConfirmationEmailTemplate:
      "Sunteți sigur că doriți să ștergeți acest șablon de email?",
    subject: "Subiect",
    cc: "CC",
    addDynamicData: "Adaugă date dinamice",
    templates: "Șabloane",
    documentsTemplates: "Șabloane documente",
    documentTemplate: "Șablon document",
    deleteDocumentsTemplates: "Șterge șabloane documente",
    deleteConfirmationDocumentsTemplates:
      "Sunteți sigur că doriți să ștergeți acest șablon de document?",
    documentsTemplatesList: "Lista șabloanelor de documente",
    uploadADocument: "Încărcați un document!",
    downloadDocument: "Descărcați documentul",
    filename: "Nume fișier",

    //templates statuses
    templateStatus: "Status șablon",
    templatesStatuses: "Statusuri șabloane",
    templateStatusesList: "Lista statusurilor șabloanelor",
    deleteTemplateStatus: "Șterge statusul șablonului",
    deleteConfirmationTemplateStatus:
      "Sunteți sigur că doriți să ștergeți acest status al șablonului?",
    color: "Culoare",
    chooseTheColorForStatus: "Alegeți culoarea pentru status",
    enterEmailAddressesSeparatedByCommas:
      "Introduceți adresele de email separate prin virgule",

    //DON'T CHANGE OR REMOVE THIS LINES
    HIDDEN: "Intern",
    VISIBLE: "Public",
    DELETED: "Eliminat",

    /**ERRORS */
    systemError: "Eroare de sistem! Contactați administratorul!",
    getDataError: "Eroare la obținerea datelor!",
    deleteDataError: "Eroare la ștergerea datelor!",
    importDataError: "Eroare la importul datelor!",
    createDataError: "Eroare la crearea datelor!",
    updateDataError: "Eroare la actualizarea datelor!",
    deleteDataSuccess: "Datele au fost șterse cu succes!",
    importDataSuccess: "Datele au fost importate cu succes!",
    createDataSuccess: "Datele au fost create cu succes!",
    updateDataSuccess: "Datele au fost actualizate cu succes!",

    /**STATUSES TYPE */
    CUSTOM: "Personalizat",
    IN_QUEUE: "În coadă",
    WAITING_FOR_PARTS: "Așteaptă piese",
    READY_FOR_PICKUP: "Gata de ridicare",
    READY_TO_BE_SHIPPED: "Gata de expediere",
    SHIPPED: "Expediat",
    PICKED_UP: "Ridicat",
    PROCESSING: "În procesare",
    WAITING_FOR_MANUFACTURER: "Așteaptă producătorul",
    WAITING_FOR_PROCESSING: "Așteaptă procesarea",
    WAITING_RETURN_FROM_APPLE: "Așteaptă returnarea de la Apple",
    PARTS_ARRIVED: "Piesele au sosit",
    WAITING_FOR_LOCAL_APPROVAL: "Așteaptă aprobarea locală",
  },
  de: {
    english: "Englisch",
    romanian: "Rumänisch",
    german: "Deutsch",
    login: "Anmelden",
    loginValidationError: "Ihr Benutzername oder Passwort ist falsch!",
    requiredField: "Pflichtfeld!",
    emailValidation: "Geben Sie eine gültige E-Mail-Adresse ein.",
    pageNotFound: "Seite nicht gefunden!",
    youDoNotHavePermission:
      "Sie haben keine Berechtigung, diese Seite zuzugreifen!",
    search: "Suche",
    siteName: "UPVOLT",
    logout: "Abmelden",
    myAccount: "Mein Konto",
    users: "Benutzer",
    active: "Aktiv",
    inactive: "Inaktiv",
    activate: "Aktivieren",
    user: "Benutzer",
    employees: "Mitarbeiter",
    vendors: "Lieferanten",
    departments: "Abteilungen",
    lastName: "Nachname",
    firstName: "Vorname",
    password: "Passwort",
    crtNo: "Nr.",
    email: "E-Mail",
    department: "Abteilung",
    options: "Optionen",
    edit: "Bearbeiten",
    delete: "Löschen",
    description: "Beschreibung",
    noResultsFound: "Keine Ergebnisse gefunden",
    nextPage: "Nächste Seite",
    previousPage: "Vorherige Seite",
    rowsPerPage: "Zeilen pro Seite",
    of: "von",
    downloadCSV: "CSV herunterladen",
    print: "Drucken",
    rowsDeleted: "Zeilen gelöscht",
    rowsSelected: "Zeilen ausgewählt",
    usersList: "Benutzerliste",
    employeesList: "Mitarbeiterliste",
    deleteUsers: "Benutzer löschen",
    deleteConfirmationUser:
      "Sind Sie sicher, dass Sie diesen Benutzer löschen möchten?",
    departmentsList: "Abteilungsliste",
    tasksList: "Aufgabenliste",
    reportsList: "Berichtsliste",
    deleteFilters: "Filter löschen",
    tasks: "Aufgaben",
    meetings: "Besprechungen",
    media: "Medien",
    add: "Hinzufügen",
    no: "Nein",
    yes: "Ja",
    import: "Importieren",
    name: "Name",
    departmentHead: "Abteilungsleiter",
    addNewUser: "Neuen Benutzer hinzufügen",
    editUser: "Benutzer bearbeiten",
    changePassword: "Passwort ändern",
    confirmPassword: "Passwort bestätigen",
    atLeast8Characters: "Mindestens 8 Zeichen",
    passwordNoMatch: "Passwörter stimmen nicht überein",
    required: "Erforderlich",
    addNewDepartment: "Neue Abteilung hinzufügen",
    save: "Speichern",
    cancel: "Abbrechen",
    addFiles: "Dateien hinzufügen",
    finish: "Abschließen",
    generate: "Generieren",
    finished: "Abgeschlossen",
    unfinished: "Unvollständig",
    userName: "Benutzername",
    taskName: "Aufgabenname",
    archived: "Archiviert",
    messages: "Nachrichten",
    baseInfo: "Grundlegende Informationen",
    dynamicInfo: "Dynamische Informationen",
    attachments: "Anhänge",
    otherInfos: "Weitere Informationen",
    startDate: "Startdatum",
    endDate: "Enddatum",
    date: "Datum",
    position: "Position",
    dragAndDrop:
      "Ziehen Sie eine Datei hierher oder klicken Sie, um eine Datei von Ihrem Computer auszuwählen.",
    error: "Fehler!",
    notFound: "Nicht gefunden!",
    youDoNotHaveAccessToThisInformation:
      "Sie haben keinen Zugriff auf diese Informationen!",
    dashboard: "Dashboard",
    newTask: "Neue Aufgabe",
    profilePicture: "Profilbild",
    addCustomField: "Benutzerdefiniertes Feld hinzufügen",
    customField: "Benutzerdefiniertes Feld",
    value: "Wert",
    priority: "Priorität",
    periodicity: "Periodizität",
    type: "Typ",
    filePath: "Dateipfad",
    highPriority: "Hohe Priorität",
    mediumPriority: "Mittlere Priorität",
    lowPriority: "Niedrige Priorität",
    chooseUser: "Benutzer auswählen",
    chooseDepartment: "Abteilung auswählen",
    enabled: "Aktiviert",
    disabled: "Deaktiviert",
    intervalError: "Das Intervall ist ungültig! Daten überschneiden sich!",
    recalculateReports: "Berichte neu berechnen",
    numberOfSteps: "Anzahl der Schritte",

    //workflows
    workflow: "Workflow",
    workflows: "Workflows",
    subWorkflows: "Teil-Workflows",
    workflowsList: "Workflows-Liste",
    newWorkflow: "Neuer Workflow",
    deleteWorkflow: "Workflow löschen",
    selectSubWorkflows: "Teil-Workflows auswählen",
    workflowParent: "Workflow-Elternelement",
    deleteConfirmationWorkflow:
      "Sind Sie sicher, dass Sie diesen Workflow löschen möchten?",
    perHour: "pro Stunde",
    totalEarnings: "Gesamterlös",
    totalDelayedSteps: "Gesamtzahl der verzögerten Schritte",
    assignToSelf: "Selbst zuweisen",
    pleaseFirstDeallocateTheUserForThisStep:
      "Bitte weisen Sie den Benutzer zuerst von diesem Schritt ab!",
    timePerWorkflow: "Zeit pro Workflow",
    costPerWorkflow: "Kosten pro Workflow",

    //orders
    order: "Bestellung",
    orders: "Bestellungen",
    ordersList: "Bestellungen-Liste",
    allOrders: "Alle Bestellungen",
    orderNumber: "Bestellnummer",
    deleteOrder: "Bestellung löschen",
    deleteConfirmationOrder:
      "Sind Sie sicher, dass Sie diese Bestellung löschen möchten?",
    internalLocation: "Interne Position",
    total: "Gesamt mit MwSt.",
    totalCharged: "Gesamt berechnet mit MwSt.",
    totalRemaining: "Gesamt verbleibend mit MwSt.",
    productsAndServices: "Produkte und Dienstleistungen",
    create: "Erstellen",
    subTotal: "Zwischensumme",
    subtotalWithVAT: "Zwischensumme mit MwSt.",
    remove: "Entfernen",
    totalDiscount: "Gesamt-Rabatt",
    generalInformation: "Allgemeine Informationen",
    technician: "Techniker",
    internalReference: "Interne Referenz",
    serviceLocation: "Serviceort",
    customerVisibility: "Für den Kunden sichtbar",
    printOutsVisibility: "Auf Ausdrucken sichtbar",
    filterTimeline: "Zeitleiste filtern",
    reset: "Zurücksetzen",
    chooseDevice: "Gerät auswählen",
    chooseWorkflow: "Workflow auswählen",
    chooseCustomer: "Kunden auswählen",
    select: "Auswählen",
    noIssue: "Kein Problem",
    noDiagnosis: "Keine Diagnose",
    noResolution: "Keine Lösung",
    nothingToBill: "Nichts zu berechnen",
    stillToBePaid: "Noch zu bezahlen",
    billed: "Berechnet",
    orderTotal: "Bestellsumme",
    orderCreated: "Bestellung erstellt",
    orderedBy: "Bestellt von",
    handledBy: "Bearbeitet von",
    saveOrder: "Bestellung speichern",
    addToOrder: "Zur Bestellung hinzufügen",
    saveToOrder: "In Bestellung speichern",
    normal: "Normal",
    highest: "Höchste",
    higher: "Höher",
    duplicateOrder: "Bestellung duplizieren",
    modify: "Ändern",
    modifyOrderStep: "Bestellungsschritt ändern",
    stepName: "Schrittname",
    time: "Zeit",
    editTimeTracking: "Zeitverfolgung bearbeiten",
    attachingFilesNumberError: "Sie können maximal ",
    cost: "Kosten",
    minutes: "Minuten",
    startTimer: "Stoppuhr starten",
    stopTimer: "Stoppuhr stoppen",
    intervals: "Intervalle",
    startTime: "Startzeit",
    endTime: "Endzeit",
    noEndTime: "Keine Endzeit",
    duration: "Dauer",
    attachFiles: "Dateien anhängen",
    totalDuration: "Gesamtdauer",
    timeTracking: "Zeitverfolgung",
    endTimeError: "Endzeit muss nach Startzeit liegen!",
    orderReference: "Bestellreferenz",
    others: "Sonstiges",
    percent: "Prozent",
    workflowCost: "Workflow-Kosten",
    workflowTime: "Workflow-Zeit",
    generalInfo: "Allgemeine Informationen",
    sumOfPercentagesMessage: "*Die Summe der Prozentsätze muss 100 % betragen!",
    sumOfTimeMessage:
      "*Die Summe der Zeiten muss der Workflow-Zeit entsprechen!",
    trackedCost: "Verfolgte Kosten",
    predefinedCost: "Vordefinierte Kosten",
    takePredefinedCost: "Vordefinierte Kosten übernehmen",
    selectStepAndUser: "Schritt und Benutzer auswählen!",
    sync: "Synchronisieren",
    syncLastTenOrdersFromFixably:
      "Letzte 10 Bestellungen von Fixably synchronisieren",
    syncByOrderNumber: "Nach Bestellnummer synchronisieren",
    orderNumberIsRequired: "Bestellnummer ist erforderlich!",
    postOnShopify: "Auf Shopify veröffentlichen",
    updateProductOnShopify: "Produkt auf Shopify aktualisieren",
    publishOnShopify: "Auf Shopify veröffentlichen",
    deleteFromShopify: "Von Shopify löschen",
    publishOnShopifyMessage:
      "Sind Sie sicher, dass Sie diese Produkte auf Shopify veröffentlichen möchten?",
    deleteFromShopifyMessage:
      "Sind Sie sicher, dass Sie diese Produkte von Shopify löschen möchten?",
    shopifyId: "Shopify-ID",
    attributeAlreadyExists: "Attribut existiert bereits!",
    confirmOrder: "Bestellung bestätigen",
    orderType: "Bestelltyp",
    estimate: "Kostenvoranschlag",

    //trashed orders
    trashedOrders: "Papierkorb-Bestellungen",
    trashedOrdersList: "Papierkorb-Bestellungen-Liste",
    deleteConfirmationTrashedOrders:
      "Sind Sie sicher, dass Sie diese Bestellung löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden!",
    deleteTrashedOrder: "Bestellung löschen",
    restore: "Wiederherstellen",
    restoreOrder: "Bestellung wiederherstellen",
    restoreConfirmationOrder:
      "Sind Sie sicher, dass Sie diese Bestellung wiederherstellen möchten?",

    //steps
    step: "Schritt",
    steps: "Schritte",
    stepsList: "Schritte-Liste",
    internalName: "Interner Name",
    identifier: "Identifikator",
    deleteStep: "Schritt löschen",
    deleteConfirmationStep:
      "Sind Sie sicher, dass Sie diesen Schritt löschen möchten?",
    assignWithWorkflow: "Mit Workflow zuweisen",
    stepInformation: "Schritt-Informationen",
    defaultValue: "Standardwert",
    newOption: "Neue Option",
    customStepValue: "Benutzerdefinierter Schrittwert",
    defaultValues: "Standardwerte",
    savingStep: "Schritt speichern",
    saveStepMessage:
      "Sind Sie sicher, dass Sie die Änderungen an diesem Schritt speichern möchten? Das Verknüpfen oder Entkoppeln dieses Schritts mit diesen Workflows wirkt sich auf die betreffenden Bestellungen aus.",

    //products
    product: "Produkt",
    products: "Produkte",
    productsList: "Produkte-Liste",
    deleteProduct: "Produkt löschen",
    deleteConfirmationProduct:
      "Sind Sie sicher, dass Sie dieses Produkt löschen möchten?",
    productManager: "Produktmanager",
    chooseProduct: "Produkt auswählen",
    code: "Code",
    typeString: "Typ",
    categoryString: "Kategorie",
    stockPrice: "Verkaufspreis",
    manufacturer: "Hersteller",
    chooseProject: "Projekt auswählen",
    partNumber: "Teilenummer",
    ean: "EAN",
    family: "Familie",
    version: "Version",
    category: "Kategorie",
    web: "Web",
    purchaseStockPrice: "Einkaufsbestandspreis",
    purchaseExchangePrice: "Einkaufswechselpreis",
    exchangePrice: "Wechselkurs",
    markup: "Aufschlag",
    eeeCode: "EEE-Code",
    provider: "Anbieter",
    serialized: "Seriennummer",
    fixed: "Fest",
    visibleOnPrintouts: "Auf Ausdrucken sichtbar",
    selling: "Verkauf",
    purchase: "Einkauf",
    details: "Details",
    garantie: "Garantie",
    additionalData: "Zusätzliche Daten",
    addOptions: "Optionen hinzufügen",
    done: "Fertig",
    thereIsNoOptions: "Es gibt keine Optionen!",
    optionName: "Optionname",
    optionValues: "Optionswerte",
    variants: "Varianten",
    removeOption: "Option entfernen",
    removeVariants: "Varianten entfernen",
    restoreVariants: "Varianten wiederherstellen",
    editPrice: "Preis bearbeiten",
    editQuantity: "Menge bearbeiten",
    deletedVariantMessage: "Die Variante wurde gelöscht!",
    undo: "Rückgängig machen",
    addImages: "Bilder hinzufügen",
    variantsAndAttributes: "Varianten & Attribute",
    attributes: "Produktattribute",
    attribute: "Attribut",
    deleteAttribute: "Attribut löschen",
    deleteConfirmationAttribute:
      "Sind Sie sicher, dass Sie dieses Attribut löschen möchten?",
    attributesList: "Produktattribute-Liste",
    values: "Werte",
    sku: "SKU",
    percentageVAT: "Prozentuale MwSt.",
    languages: "Sprachen",
    settings: "Einstellungen",

    //stock
    stock: "Lager",
    stocks: "Lagerbestände",
    stocksManagement: "Lagerverwaltung",
    inventory: "Inventarverwaltung",
    inventories: "Bestände",
    inventoriesList: "Bestandsliste",
    deleteInventory: "Lagerverwaltung löschen",
    deleteConfirmationInventory:
      "Sind Sie sicher, dass Sie diese Lagerverwaltung löschen möchten?",
    defaultInventory: "Standardlager",
    purchaseOrders: "Einkaufsbestellungen",
    incomingGoods: "Eingehende Waren",
    receivedGoods: "Erhaltene Waren",
    stockTransfers: "Lagertransfers",
    stockTransfer: "Lagertransfer",
    stockTransfersList: "Liste der Lagertransfers",
    deleteStockTransfer: "Lagertransfer löschen",
    deleteConfirmationStockTransfer:
      "Sind Sie sicher, dass Sie diesen Lagertransfer löschen möchten?",
    from: "Von",
    to: "Zu",
    addOuttake: "Ausgabe hinzufügen",
    comments: "Kommentare",
    outtake: "Ausgabe",
    open: "Öffnen",
    closed: "Geschlossen",
    purchaseOrderNumber: "Bestellnummer",
    referenceNumber: "Referenznummer",
    vendor: "Lieferant",
    purchaseOrder: "Einkaufsbestellung",
    purchaseOrdersList: "Liste der Einkaufsbestellungen",
    deletePurchaseOrder: "Einkaufsbestellung löschen",
    deleteConfirmationPurchaseOrder:
      "Sind Sie sicher, dass Sie diese Einkaufsbestellung löschen möchten?",
    receivedOrder: "Erhaltene Bestellung",
    receivedOrderConfirmationMessage:
      "Sind Sie sicher, dass Sie diese Bestellung bestätigen möchten?",
    receiveSelected: "Ausgewählte empfangen",
    showPurchaseOrder: "Bestellung anzeigen",
    inventoryPrice: "Lagerpreis",
    inventoryQuantity: "Lagermenge",
    productPrice: "Produktpreis",
    openPurchaseOrdersList: "Liste der offenen Bestellungen",
    closedPurchaseOrdersList: "Liste der geschlossenen Bestellungen",
    changeStockTransferStatus: "Ändern Sie den Lagertransferstatus",
    selectStatus: "Status auswählen",
    purchaseOrderTotal: "Gesamtbestellmenge",
    maxQuantityIs: "Maximale Menge beträgt",
    chooseInventory: "Lager auswählen",
    quantityExceedsInventory: "Die Menge überschreitet das Lager!",
    emailConfiguration: "E-Mail-Konfiguration",
    sendEmail: "E-Mail senden",
    invalidEmail: "Ungültige E-Mail-Adresse!",
    replyEmail: "Antwort-E-Mail",

    //taxRates
    taxRates: "MwSt.-Sätze",
    taxRate: "MwSt.-Satz",
    deleteTaxRate: "MwSt.-Satz löschen",
    deleteConfirmationTaxRate:
      "Sind Sie sicher, dass Sie diesen MwSt.-Satz löschen möchten?",
    taxRatesList: "MwSt.-Satz-Liste",
    default: "Standard",
    setAsDefault: "Als Standard festlegen",
    setAsDefaultConfirmation:
      "Möchten Sie diesen MwSt.-Satz als Standard festlegen?",

    //devices
    device: "Gerät",
    devices: "Geräte",
    devicesList: "Geräte-Liste",
    deleteDevice: "Gerät löschen",
    deleteConfirmationDevice:
      "Sind Sie sicher, dass Sie dieses Gerät löschen möchten?",
    brand: "Marke",
    model: "Modell",
    serialNumber: "Seriennummer",
    imeiNumber1: "IMEI-Nummer 1",
    imeiNumber2: "IMEI-Nummer 2",
    configuration: "Konfiguration",
    deviceFamily: "Gerätefamilie",
    distributor: "Vertriebspartner",
    purchaseCountry: "Kaufsland",
    purchaseDate: "Kaufdatum",
    isActivationLocked: "Aktivierung gesperrt",
    isCarrierLocked: "Netzanbieter gesperrt",
    isVintage: "Vintage",
    isObsolete: "Veraltet",
    isLoaner: "Leihgerät",
    tags: "Tags",
    customFields: "Benutzerdefinierte Felder",
    productImageUrl: "Produktbild-URL",
    createdAt: "Erstellt am",
    deviceName: "Gerätename",
    deviceSerialNumber: "Geräte-Seriennummer",
    deviceModel: "Gerätemodell",
    deviceBrand: "Gerätemarke",
    informationSource: "Informationsquelle",

    //customers
    customer: "Kunde",
    customers: "Kunden",
    customerName: "Kundenname",
    customersList: "Kundenliste",
    vendorsList: "Lieferantenliste",
    deleteCustomer: "Kunden löschen",
    deleteConfirmationCustomer:
      "Sind Sie sicher, dass Sie diesen Kunden löschen möchten?",
    company: "Firma",
    phone: "Telefon",
    business: "Geschäft",
    vatNumber: "USt-IdNr.",
    group: "Gruppe",
    language: "Sprache",
    defaultPaymentTerm: "Standard-Zahlungsbedingung",
    allShippingAddresses: "Alle Lieferadressen",
    allowShareEmail: "E-Mail teilen erlauben",
    parent: "Übergeordnet",
    address1: "Adresse 1",
    address2: "Adresse 2",
    address3: "Adresse 3",
    city: "Stadt",
    zip: "Postleitzahl",
    state: "Bundesland",
    shippingAddress: "Lieferadresse",
    billingAddress: "Rechnungsadresse",
    customerAddress: "Kundenadresse",

    //deliveries
    delivery: "Lieferung",
    deliveries: "Lieferungen",
    deliveriesList: "Lieferungen-Liste",
    deleteDelivery: "Lieferung löschen",
    deleteConfirmationDelivery:
      "Sind Sie sicher, dass Sie diese Lieferung löschen möchten?",
    shortName: "Kurzname",

    //lines
    line: "Zeile",
    lines: "Zeilen",
    linesList: "Zeilenliste",
    deleteLine: "Zeile löschen",
    deleteConfirmationLine:
      "Sind Sie sicher, dass Sie diese Zeile löschen möchten?",
    price: "Preis",
    vat: "MwSt.",
    priceRange: "Preisspanne",
    quantity: "Menge",
    quantityCharged: "In Rechnung gestellte Menge",
    quantityDispatched: "Versandmenge",
    discount: "Rabatt",
    originalSerialNumber: "Original-Seriennummer",
    imeiNumber: "IMEI-Nummer",
    totalRemaining0: "Verbleibend gesamt",
    totalCharged0: "In Rechnung gestellt gesamt",
    total0: "Gesamt",
    purchasePrice: "Einkaufspreis",
    isHidden: "Ist versteckt",

    //locations
    location: "Standort",
    locations: "Standorte",
    locationsList: "Standortliste",
    deleteLocation: "Standort löschen",
    deleteConfirmationLocation:
      "Sind Sie sicher, dass Sie diesen Standort löschen möchten?",
    country: "Land",
    address: "Adresse",
    tat: "TAT",
    longitude: "Längengrad",
    latitude: "Breitengrad",
    aa: "Aa",
    de: "De",
    en: "En",
    fr: "Fr",
    it: "It",
    help: "Hilfe",
    openingHours: "Öffnungszeiten",

    //notes
    note: "Notiz",
    notes: "Notizen",
    notesList: "Notizen-Liste",
    deleteNote: "Notiz löschen",
    deleteConfirmationNote:
      "Sind Sie sicher, dass Sie diese Notiz löschen möchten?",
    text: "Text",
    visibility: "Sichtbarkeit",
    title: "Titel",
    createdBy: "Erstellt von",
    noteReportMessage:
      "Wählen Sie aus, ob die Notiz auf Ausdrucken sichtbar sein soll.",
    draft: "Entwurf",

    //shipments
    shipment: "Sendung",
    shipments: "Sendungen",
    shipmentsList: "Sendungen-Liste",
    deleteShipment: "Sendung löschen",
    deleteConfirmationShipment:
      "Sind Sie sicher, dass Sie diese Sendung löschen möchten?",
    trackingNumber: "Sendungsnummer",
    carrier: "Spediteur",
    reference: "Referenz",
    sender: "Absender",
    recipient: "Empfänger",
    senderLocation: "Absenderstandort",
    recipientLocation: "Empfängerstandort",
    senderStore: "Absendergeschäft",
    recipientStore: "Empfängergeschäft",
    serviceCode: "Servicenummer",
    packages: "Pakete",
    senderAddress: "Absenderadresse",
    recipientAddress: "Empfängeradresse",
    requestShipment: "Sendung anfordern",
    cancelShipment: "Sendung abbrechen",
    noPendingShipmentRequests: "Keine ausstehenden Sendeanfragen!",
    pendingShipmentRequests: "Ausstehende Sendeanfragen",
    created: "Erstellt",
    pending: "Ausstehend",
    inTransit: "In Transit",
    delivered: "Geliefert",
    processed: "Verarbeitet",
    cancelled: "Abgebrochen",
    confirmAndProcess: "Bestätigen und verarbeiten",
    inbound: "Eingehend",
    outbound: "Ausgehend",
    allShipments: "Alle Sendungen",

    //statuses
    status: "Status",
    statuses: "Statuss",
    statusesList: "Statusliste",
    deleteStatus: "Status löschen",
    deleteConfirmationStatus:
      "Sind Sie sicher, dass Sie diesen Status löschen möchten?",
    liable: "Haftbar",
    isEnabled: "Ist aktiviert",
    isDefault: "Ist Standard",
    chooseWorkflowFirst: "Wählen Sie zuerst einen Workflow aus!",

    //roles
    roles: "Rollen",
    role: "Rolle",
    rolesList: "Rollenliste",
    deleteRole: "Rolle löschen",
    deleteConfirmationRole:
      "Sind Sie sicher, dass Sie diese Rolle löschen möchten?",
    displayName: "Anzeigename",

    //reports
    reports: "Berichte",
    report: "Bericht",
    ordersReports: "Bestellberichte",
    workflowsReports: "Workflow-Berichte",
    statistics: "Statistiken",
    totalTrackedCosts: "Gesamtkosten erfasst",
    totalPredefinedCost: "Gesamtkosten vordefiniert",
    trackedHours: "Erfasste Stunden",
    predefinedHours: "Vordefinierte Stunden",
    trackedTime: "Erfasste Zeit",
    trackedPercent: "Erfasstes Prozent",
    predefinedTime: "Vordefinierte Zeit",
    predTimeShort: "Vord. Zeit",
    timeFactor: "Zeitfaktor",
    orderCost: "Bestellungskosten",
    totalPredefinedTime: "Gesamte vordefinierte Zeit",
    totalTrackedTime: "Gesamte erfasste Zeit",
    totalSteps: "Gesamtanzahl der Schritte",
    filters: "Filter",
    noReportsMessage: "Keine Berichte gefunden!",
    noUserFound: "Kein Benutzer gefunden!",
    statisticsForADay: "Statistiken für einen Tag",
    statisticsForAMonth: "Statistiken für einen Monat",
    statisticsForAYear: "Statistiken für ein Jahr",
    totalCosts: "Gesamtkosten",
    averageTrackedTime: "Durchschnittliche erfasste Zeit",
    averageTrackedPercent: "Durchschnittliches erfasstes Prozent",
    predictedPercent: "Vorhergesagtes Prozent",
    averageTimeFactor: "Durchschnittlicher Zeitfaktor",
    day: "Tag",
    month: "Monat",
    year: "Jahr",

    //templates
    emailTemplates: "E-Mail-Vorlagen",
    emailTemplate: "E-Mail-Vorlage",
    emailTemplatesList: "E-Mail-Vorlagenliste",
    deleteEmailTemplate: "E-Mail-Vorlage löschen",
    deleteConfirmationEmailTemplate:
      "Sind Sie sicher, dass Sie diese E-Mail-Vorlage löschen möchten?",
    subject: "Betreff",
    cc: "CC",
    addDynamicData: "Dynamische Daten hinzufügen",
    templates: "Vorlagen",
    documentsTemplates: "Dokumentenvorlagen",
    documentTemplate: "Dokumentenvorlage",
    deleteDocumentsTemplates: "Dokumentenvorlagen löschen",
    deleteConfirmationDocumentsTemplates:
      "Sind Sie sicher, dass Sie diese Dokumentenvorlage löschen möchten?",
    documentsTemplatesList: "Dokumentenvorlagenliste",
    uploadADocument: "Dokument hochladen!",
    downloadDocument: "Dokument herunterladen",
    filename: "Dateiname",

    //templates statuses
    templateStatus: "Vorlagenstatus",
    templatesStatuses: "Vorlagenstatus",
    templateStatusesList: "Vorlagenstatus-Liste",
    deleteTemplateStatus: "Vorlagenstatus löschen",
    deleteConfirmationTemplateStatus:
      "Sind Sie sicher, dass Sie diesen Vorlagenstatus löschen möchten?",
    color: "Farbe",
    chooseTheColorForStatus: "Wählen Sie die Farbe für den Status",
    enterEmailAddressesSeparatedByCommas:
      "Geben Sie E-Mail-Adressen durch Kommas getrennt ein",

    //DON'T CHANGE OR REMOVE THIS LINES
    HIDDEN: "Intern",
    VISIBLE: "Öffentlich",
    DELETED: "Entfernt",

    /**ERRORS */
    systemError: "Systemfehler! Kontaktieren Sie den Administrator!",
    getDataError: "Fehler beim Abrufen der Daten!",
    deleteDataError: "Fehler beim Löschen der Daten!",
    importDataError: "Fehler beim Importieren der Daten!",
    createDataError: "Fehler beim Erstellen der Daten!",
    updateDataError: "Fehler beim Aktualisieren der Daten!",
    deleteDataSuccess: "Daten wurden erfolgreich gelöscht!",
    importDataSuccess: "Daten wurden erfolgreich importiert!",
    createDataSuccess: "Daten wurden erfolgreich erstellt!",
    updateDataSuccess: "Daten wurden erfolgreich aktualisiert!",

    /**STATUSES TYPE */
    CUSTOM: "Benutzerdefiniert",
    IN_QUEUE: "In Warteschlange",
    WAITING_FOR_PARTS: "Wartet auf Teile",
    READY_FOR_PICKUP: "Bereit zur Abholung",
    READY_TO_BE_SHIPPED: "Bereit zum Versand",
    SHIPPED: "Versandt",
    PICKED_UP: "Abgeholt",
    PROCESSING: "Wird bearbeitet",
    WAITING_FOR_MANUFACTURER: "Wartet auf Hersteller",
    WAITING_FOR_PROCESSING: "Wartet auf Bearbeitung",
    WAITING_RETURN_FROM_APPLE: "Wartet auf Rückgabe von Apple",
    PARTS_ARRIVED: "Teile angekommen",
    WAITING_FOR_LOCAL_APPROVAL: "Wartet auf lokale Genehmigung",
  },
};

// Define a function to get the current vocabulary
const getCurrentVocabulary = () => {
  const currentLanguage: any =
    i18n.language || i18n.options.fallbackLng || "en";
  if (Array.isArray(currentLanguage)) {
    return lang[currentLanguage[0]] || lang.en;
  }
  return lang[currentLanguage] || lang.en;
};

/**
 *
 * @param prop
 * @returns
 */
export const getTranslation = (prop: any) => {
  const currentVocabulary = getCurrentVocabulary();
  // Check if the property exists in the current vocabulary
  if (prop in currentVocabulary) {
    return currentVocabulary[prop];
  }
  // Fallback to i18n.t if the property is not found in the current vocabulary
  return i18n.t(prop);
};

export const Vocabulary: any = new Proxy(
  {},
  {
    get: (target, prop: any) => getTranslation(prop),
  }
);
