/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { ProductStockModel, TaxRateModel } from "../../Utils/Models";
import { onChangeTextField } from "../../Utils/Utils";
import { Vocabulary } from "../../Utils/Vocabulary";
import { Delete } from "@mui/icons-material";
import useCustomMemo from "../GenericComponents/useCustomMemo";

type ProductStockListProps = {
  index: number;
  inventory: ProductStockModel;
  handleChangeOneInventory: (index: number, inventory: any) => void;
};

export default function ProductStockList(props: ProductStockListProps) {
  const { index, inventory, handleChangeOneInventory } = props;
  const cache = useCustomMemo();
  const taxRates = cache.taxRates || [];

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{
          margin: "20px 0px",
          padding: 0,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={3} md={3} lg={3}>
          {inventory.inventory?.name} <br />
          {inventory.inventory?.location?.name}
        </Grid>
        <Grid item xs={12} sm={2.7} md={2.7} lg={2.7}>
          {inventory.isNew ? (
            <TextField
              id="quantity"
              name="quantity"
              size="small"
              variant="outlined"
              fullWidth
              label={Vocabulary.quantity}
              value={inventory.quantity}
              onChange={(event: any) => {
                handleChangeOneInventory(
                  index,
                  onChangeTextField(event, inventory)
                );
              }}
            />
          ) : (
            inventory.quantity
          )}
        </Grid>
        <Grid item xs={12} sm={2.7} md={2.7} lg={2.7}>
          {inventory.isNew ? (
            <TextField
              id="price"
              name="price"
              size="small"
              variant="outlined"
              fullWidth
              label={Vocabulary.price}
              value={inventory.price}
              onChange={(event: any) => {
                handleChangeOneInventory(
                  index,
                  onChangeTextField(event, inventory)
                );
              }}
            />
          ) : (
            inventory.price
          )}
        </Grid>
        <Grid item xs={12} sm={2.7} md={2.7} lg={2.7}>
          {inventory.isNew ? (
            <FormControl fullWidth>
              <InputLabel>{Vocabulary.percentageVAT}</InputLabel>
              <Select
                size="small"
                id="percentageVAT"
                value={inventory.percentageVAT || ""}
                label={Vocabulary.percentageVAT}
                fullWidth
                onChange={(event: SelectChangeEvent) => {
                  handleChangeOneInventory(index, {
                    ...inventory,
                    percentageVAT: event.target.value,
                  });
                }}
              >
                {taxRates.map((taxRate: TaxRateModel) => {
                  return (
                    <MenuItem value={taxRate.value}>{taxRate.value}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          ) : (
            inventory.percentageVAT
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={0.9}
          md={0.9}
          lg={0.9}
          style={{ verticalAlign: "middle", color: "red" }}
        >
          <Delete
            style={{ cursor: "pointer" }}
            onClick={() => {
              handleChangeOneInventory(index, null);
            }}
          />
        </Grid>
      </Grid>
      <Divider />
    </>
  );
}
