/* eslint-disable @typescript-eslint/no-explicit-any */
import { Tab, Tabs, useMediaQuery } from "@mui/material";
import styles from "../Styles/dashboard.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import theme from "../Themes/Theme";
import { Vocabulary } from "../Utils/Vocabulary";
import { localUrlEnum } from "../Utils/UrlEnum";
import { shipmentStatuses } from "../Utils/AutocompleteUtils";
import ProcessedShipments from "../Components/Shipments/ProcessedShipments";
import PendingShipments from "../Components/Shipments/PendingShipments";

export default function Shipments() {
  const navigate = useNavigate();
  const location = useLocation();
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const [value, setValue] = useState(0);
  //   const [filters, setFilters] = useState<any>({
  //     inventory: null,
  //     product: null,
  //     status: null,
  //     vendor: null,
  //   });

  useEffect(() => {
    const search: any = location.search.split(/[?&]+/);
    const newFilters: any = {
      inventory: null,
      product: null,
      vendor: null,
    };

    search.forEach((value: any) => {
      if (value !== "") {
        const values = value.split("=");
        newFilters[values[0]] = isNaN(+values[1]) ? values[1] : +values[1];
        if (values[0] === "page") newFilters[values[0]] = +values[1] - 1;
        if (values[1] === "null") newFilters[values[0]] = null;
        if (values[1].includes("+"))
          newFilters[values[0]] = values[1].replace("+", " ");
      }
    });
    // setFilters(newFilters);
    setValueFromLocation(newFilters.type);
  }, [location]);

  /**
   *
   * @param newValue
   */
  function setValueFromLocation(newValue: string) {
    switch (newValue) {
      case shipmentStatuses.pending:
        setValue(0);
        break;
      case shipmentStatuses.inTransit:
        setValue(1);
        break;
      case shipmentStatuses.delivered:
        setValue(2);
        break;
      default:
        setValue(0);
        break;
    }
  }
  /**
   *
   * @param event
   * @param newValue
   */
  function handleChangeValue(event: React.SyntheticEvent, newValue: number) {
    switch (newValue) {
      case 0:
        navigate(`${localUrlEnum.shipments}?type=${shipmentStatuses.pending}`);
        break;
      case 1:
        navigate(
          `${localUrlEnum.shipments}?type=${shipmentStatuses.inTransit}`
        );
        break;
      case 2:
        navigate(
          `${localUrlEnum.shipments}?type=${shipmentStatuses.delivered}`
        );
        break;
      default:
        navigate(`${localUrlEnum.shipments}?type=${shipmentStatuses.pending}`);
        break;
    }
  }

  /**
   *
   */
  function showInformationForTabs() {
    switch (value) {
      case 0:
        return <PendingShipments />;
      case 1:
        return <ProcessedShipments />;
      case 2:
        return <ProcessedShipments />;
      default:
        return <PendingShipments />;
    }
  }

  function showFilters() {
    switch (value) {
      case 0:
        return null;
      case 1:
        return null;
      case 2:
        return null;
      default:
        return null;
    }
  }

  /**
   *
   * @param tabValue
   * @returns
   */
  function getTabStyle(tabValue: number) {
    return {
      backgroundColor:
        value === tabValue
          ? theme.palette.secondary.main
          : theme.palette.cancel?.main,
      color: value === tabValue ? "white" : "#9c9b9b",
      borderTopRightRadius: "10px",
      borderTopLeftRadius: "10px",
      marginRight: "1px",
    };
  }

  return (
    <div
      className={phoneStyle ? styles.mainContainerMobile : styles.mainContainer}
    >
      {showFilters()}
      <Tabs
        value={value}
        onChange={handleChangeValue}
        TabIndicatorProps={{
          style: {
            backgroundColor: theme.palette.secondary.main,
          },
        }}
        style={{ width: "100%" }}
      >
        <Tab label={Vocabulary.pending} style={getTabStyle(0)} />
        <Tab label={Vocabulary.inTransit} style={getTabStyle(1)} />
        <Tab label={Vocabulary.processed} style={getTabStyle(2)} />
      </Tabs>
      <> {showInformationForTabs()}</>
    </div>
  );
}
