import moment from "moment";
import { InventoryModel } from "../../../../Utils/Models";
import { Vocabulary } from "../../../../Utils/Vocabulary";
import AddOrUpdateModel from "../../../GenericComponents/AddOrUpdateModel";
import { euFormatForDateTime } from "../../../../Utils/Config";

type ShowInventoryInfoProps = {
  inventory: InventoryModel;
  open: boolean;
  onClose: () => void;
};

export function ShowInventoryInfo(props: ShowInventoryInfoProps) {
  const { open, inventory, onClose } = props;
  return (
    <AddOrUpdateModel
      open={open}
      title={Vocabulary.inventory}
      onClose={onClose}
      addOrUpdateItem={() => null}
      hideButtons={true}
    >
      <div style={{ textAlign: "justify" }}>
        <p>
          <span style={{ fontWeight: 300 }}> {Vocabulary.name}:</span>
          &nbsp;&nbsp;
          <span style={{ fontWeight: 500 }}>{inventory.name}</span>
        </p>
        <p>
          <span style={{ fontWeight: 300 }}> {Vocabulary.description}:</span>
          &nbsp;&nbsp;
          <span style={{ fontWeight: 500 }}>{inventory.description}</span>
        </p>
        <p>
          <span style={{ fontWeight: 300 }}>
            {Vocabulary.defaultInventory}:&nbsp;&nbsp;
          </span>
          <span style={{ fontWeight: 500 }}>
            {inventory.defaultInventory ? Vocabulary.yes : Vocabulary.no}
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 300 }}> {Vocabulary.location}:</span>
          &nbsp;&nbsp;
          <span style={{ fontWeight: 500 }}>{inventory?.location?.name}</span>
        </p>
        <p>
          <span style={{ fontWeight: 300 }}>{Vocabulary.address}:</span>
          &nbsp;&nbsp;
          <span style={{ fontWeight: 500 }}>
            {inventory?.location?.address}
          </span>
        </p>
        <p>
          <span style={{ fontWeight: 300 }}>
            {Vocabulary.createdAt}:&nbsp;&nbsp;
          </span>
          <span style={{ fontWeight: 500 }}>
            {moment(inventory.createdAt).format(euFormatForDateTime)}
          </span>
        </p>
      </div>
    </AddOrUpdateModel>
  );
}
