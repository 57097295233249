/* eslint-disable @typescript-eslint/no-explicit-any */
import { ProductModel } from "../../Utils/Models";
import { Vocabulary } from "../../Utils/Vocabulary";
import { TextValidator } from "react-material-ui-form-validator";
import { Autocomplete, Grid, TextField } from "@mui/material";
import {
  deleteImagesFromProductVariants,
  handleChangeAutocomplete,
  onChangeTextField,
} from "../../Utils/Utils";
import { useEffect, useState } from "react";
import AddAndShowImages from "../GenericComponents/AddAndShowImages";

type ProductProps = {
  product: ProductModel;
  arraysOfString: any;
  handleChangeArraysOfString: (arraysOfString: any) => void;
  handleChangeProduct: (product: ProductModel) => void;
};
export default function Product(props: ProductProps) {
  const {
    product,
    handleChangeProduct,
    arraysOfString,
    handleChangeArraysOfString,
  } = props;

  const [images, setImages] = useState<any>([]);
  const [files, setFiles] = useState<any>([]);

  /**
   *
   */
  useEffect(() => {
    if (product.images) setImages(product.images);
    else setImages([]);
  }, [product.images]);

  /////////////////////////// ADD/UPDATE IMAGES ///////////////////////////
  /**
   *
   */
  function handleDeleteImages(newImageList: any, newFileList: any) {
    setImages(newImageList);
    setFiles(newFileList);
    const newProduct = Object.assign({}, product);
    newProduct.images = newImageList;
    newProduct.files = newFileList;
    newProduct.variants = deleteImagesFromProductVariants(
      newImageList,
      product.variants
    );
    handleChangeProduct(newProduct);
  }

  /**
   *
   * @param files
   */
  function handleChangeFiles(newFileList: any) {
    setFiles(newFileList);
    const newProduct = Object.assign({}, product);
    newProduct.files = newFileList;
    handleChangeProduct(newProduct);
  }

  /**
   *
   * @param images
   */
  function displayDropzoneImages(newImageList: any) {
    setImages(newImageList);
    const newProduct = Object.assign({}, product);
    newProduct.images = newImageList;
    handleChangeProduct(newProduct);
  }

  return (
    <Grid container spacing={2} style={{ marginTop: 20 }}>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="name"
          name="name"
          label={Vocabulary.name}
          fullWidth
          validators={["required"]}
          errorMessages={[Vocabulary.requiredField]}
          variant="outlined"
          value={product.name}
          onChange={(event: any) =>
            handleChangeProduct(onChangeTextField(event, product))
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="description"
          name="description"
          label={Vocabulary.description}
          fullWidth
          variant="outlined"
          validators={["required"]}
          errorMessages={[Vocabulary.requiredField]}
          value={product.description || ""}
          onChange={(event: any) =>
            handleChangeProduct(onChangeTextField(event, product))
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="code"
          name="code"
          label={Vocabulary.code}
          fullWidth
          validators={["required"]}
          errorMessages={[Vocabulary.requiredField]}
          variant="outlined"
          value={product.code}
          onChange={(event: any) =>
            handleChangeProduct(onChangeTextField(event, product))
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="manufacturer"
          name="manufacturer"
          label={Vocabulary.manufacturer}
          fullWidth
          variant="outlined"
          validators={["required"]}
          errorMessages={[Vocabulary.requiredField]}
          value={product.manufacturer}
          onChange={(event: any) =>
            handleChangeProduct(onChangeTextField(event, product))
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="typeString"
          name="typeString"
          label={Vocabulary.typeString}
          fullWidth
          validators={["required"]}
          errorMessages={[Vocabulary.requiredField]}
          value={product.typeString}
          onChange={(event: any) =>
            handleChangeProduct(onChangeTextField(event, product))
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <TextValidator
          size="small"
          id="categoryString"
          name="categoryString"
          label={Vocabulary.categoryString}
          fullWidth
          variant="outlined"
          validators={["required"]}
          errorMessages={[Vocabulary.requiredField]}
          value={product.categoryString}
          onChange={(event: any) =>
            handleChangeProduct(onChangeTextField(event, product))
          }
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Autocomplete
          size="small"
          id="tags"
          multiple
          freeSolo={true}
          options={arraysOfString.tags}
          value={product.tags || []}
          getOptionLabel={(option: any) => option}
          isOptionEqualToValue={(option, value) => option === value}
          getOptionDisabled={(option) =>
            !!product.tags.find((element: any) => element === option)
          }
          onChange={(event: any, value: any) => {
            handleChangeProduct(
              handleChangeAutocomplete(event, value, product, "tags")
            );
            const tags = new Set([...arraysOfString.tags, ...value]);
            handleChangeArraysOfString({
              ...arraysOfString,
              tags: Array.from(tags),
            });
          }}
          renderInput={(params: any) => (
            <TextField
              {...params}
              fullWidth
              label={Vocabulary.tags}
              variant="outlined"
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <AddAndShowImages
          images={images}
          files={files}
          displayDropzoneImages={displayDropzoneImages}
          handleDeleteImages={handleDeleteImages}
          handleChangeFiles={handleChangeFiles}
          isSmall={true}
        />
      </Grid>
    </Grid>
  );
}
