import OrderReportHeader from "./OrderReportHeader";
import StepsReportsForOrder from "./StepsReportsForOrder";

/* eslint-disable @typescript-eslint/no-explicit-any */
type AdminReportForOrderProps = {
  orderReport: any;
};
export default function OrderReport(props: AdminReportForOrderProps) {
  const { orderReport } = props;
  return (
    <div>
      <OrderReportHeader report={orderReport} />
      <StepsReportsForOrder reportSteps={orderReport.steps} />
    </div>
  );
}
