import { Divider } from "@mui/material";
import ProductPanel from "./ProductPanel";
import ProductTotals from "./ProductTotals";
import { LineModel } from "../../Utils/Models";
import { OrderContext } from "../../Contexts/OrderContext";
import { useContext } from "react";

export default function OrderLines() {
  const orderContext = useContext(OrderContext);
  /**
   *
   * @param line
   * @param index
   */
  function handleChangeLines(line: LineModel, index: number) {
    const newLines = [...orderContext.order.lines];
    newLines[index] = line;
    orderContext.setOrder({ ...orderContext.order, lines: newLines });
  }

  /**
   *
   * @param arr
   * @param index
   * @returns
   */
  function deleteLine(index: number) {
    const newLines = [...orderContext.order.lines];
    newLines.splice(index, 1);
    orderContext.setOrder({ ...orderContext.order, lines: newLines });
  }
  return (
    <>
      {orderContext.order.lines?.map((line, index) => {
        return (
          <ProductPanel
            key={line?._id}
            index={index}
            editLine={line}
            handleChange={handleChangeLines}
            deleteLine={deleteLine}
          ></ProductPanel>
        );
      })}
      <Divider style={{ marginTop: 10 }} />
      <ProductTotals />
    </>
  );
}
