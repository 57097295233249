/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
import { Box, Button, useMediaQuery } from "@mui/material";
import { Vocabulary } from "../../Utils/Vocabulary";
import RenderVariant from "./RenderVariant";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import EditMultipleVariants from "./EditMultipleVariants";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import { ProductModel } from "../../Utils/Models";
import { additionalInformationForVariants } from "../../Utils/AutocompleteUtils";

export type VariantProps = {
  [key: string]:
    | string
    | number
    | boolean
    | Array<string | any>
    | undefined
    | null;
  price: number;
  sku: string;
  inventory_quantity: number;
  images: Array<string | any> | null;
  mustBeDeleted: boolean;
};

type ProductVariantsProps = {
  product: ProductModel;
  variants: Array<VariantProps>;
  handleChangeVariants: (variants: Array<any>) => void;
  handleChangeProduct: (product: ProductModel) => void;
};

export type EditModalProps = {
  openModal: boolean;
  type: string;
  value: string;
};

export default function ProductVariants(props: ProductVariantsProps) {
  const { product, variants, handleChangeVariants, handleChangeProduct } =
    props;
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const [selectedVariants, setSelectedVariants] = useState<Array<number>>([]);
  const [editModal, setEditModal] = useState<EditModalProps>({
    openModal: false,
    type: "",
    value: "",
  });

  /**
   *
   * @param index
   */
  function handleChangeSelectedVariants(index: number) {
    const newSelectedVariants = selectedVariants.slice();
    if (selectedVariants.includes(index)) {
      newSelectedVariants.splice(newSelectedVariants.indexOf(index), 1);
    } else {
      newSelectedVariants.push(index);
    }
    setSelectedVariants(newSelectedVariants);
  }
  /**
   *
   * @param index
   * @param variant
   */
  function changeOneVariant(index: number, variant: VariantProps) {
    const newVariants = variants.slice();
    newVariants[index] = variant;
    handleChangeVariants(newVariants);
  }

  /**
   *
   */
  function handleRemoveVariant() {
    const newVariants: any = variants.slice();
    selectedVariants.forEach((index) => {
      newVariants[index].mustBeDeleted = true;
    });
    handleChangeVariants(newVariants);
    setSelectedVariants([]);
  }

  /**
   *
   * @param name
   * @param value
   */
  function handleEditVariantInformation(name: string, value: string) {
    const newVariants: any = variants.slice();
    selectedVariants.forEach((index) => {
      newVariants[index][name] = value;
    });
    handleChangeVariants(newVariants);
    setSelectedVariants([]);
  }

  /**
   *
   * @param newEditModal
   */
  function handleEditModal(newEditModal: EditModalProps) {
    setEditModal(newEditModal);
  }

  /**
   *
   * @param indexes
   */
  function undoDeleteVariant(indexes: Array<number>) {
    const newVariants: any = variants.slice();
    indexes.forEach((index) => {
      newVariants[index].mustBeDeleted = false;
    });
    handleChangeVariants(newVariants);
    setSelectedVariants([]);
  }
  return (
    <>
      <Box
        component="fieldset"
        style={{ border: "1px solid #ccc", borderRadius: 4, margin: 10 }}
      >
        <legend style={{ color: "#999999" }}>{Vocabulary.variants}</legend>
        {selectedVariants.length !== 0 ? (
          <div
            style={
              phoneStyle
                ? {}
                : {
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }
            }
          >
            <div>
              <Button
                color="error"
                variant="outlined"
                onClick={handleRemoveVariant}
                fullWidth={phoneStyle ? true : false}
                style={{ marginRight: 10, marginBottom: 10 }}
              >
                <DeleteForeverIcon color="error" style={{ marginRight: 10 }} />
                {Vocabulary.removeVariants}
              </Button>
              <Button
                color="warning"
                variant="outlined"
                onClick={() => undoDeleteVariant(selectedVariants)}
                fullWidth={phoneStyle ? true : false}
                style={{ marginRight: 10, marginBottom: 10 }}
              >
                <RestoreFromTrashIcon
                  color="warning"
                  style={{ marginRight: 10 }}
                />
                {Vocabulary.restoreVariants}
              </Button>
            </div>
            <div>
              <Button
                color="primary"
                variant="outlined"
                onClick={() =>
                  setEditModal({
                    openModal: true,
                    type: additionalInformationForVariants.price,
                    value: "",
                  })
                }
                fullWidth={phoneStyle ? true : false}
                style={{ marginRight: 10, marginBottom: 10 }}
              >
                <EditIcon color="primary" style={{ marginRight: 10 }} />
                {Vocabulary.editPrice}
              </Button>
              <Button
                color="primary"
                variant="outlined"
                onClick={() =>
                  setEditModal({
                    openModal: true,
                    type: additionalInformationForVariants.inventory_quantity,
                    value: "",
                  })
                }
                fullWidth={phoneStyle ? true : false}
                style={{ marginRight: 10, marginBottom: 10 }}
              >
                <EditIcon color="primary" style={{ marginRight: 10 }} />
                {Vocabulary.editQuantity}
              </Button>
            </div>
          </div>
        ) : null}

        {variants && variants.length !== 0 ? (
          <>
            {variants.map((variant, index) => (
              <RenderVariant
                key={`${variant}_${index}`}
                index={index}
                product={product}
                variant={variant}
                selectedVariants={selectedVariants}
                changeOneVariant={changeOneVariant}
                handleChangeSelectedVariants={handleChangeSelectedVariants}
                undoDeleteVariant={undoDeleteVariant}
                handleChangeProduct={handleChangeProduct}
              />
            ))}
          </>
        ) : null}
      </Box>
      <EditMultipleVariants
        editModal={editModal}
        handleEditModal={handleEditModal}
        handleEditVariantInformation={handleEditVariantInformation}
      />
    </>
  );
}
