import AttachFileIcon from "@mui/icons-material/AttachFile";
import styles from "../../Styles/dropzone.module.css";

export default function GenericDocIcon() {
  return (
    <div className={styles.genericDocIcon}>
      <AttachFileIcon color="primary" style={{fontSize: 100}}/>
    </div>
  );
}
