/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLocation, useNavigate } from "react-router-dom";
import GenericFilters from "../../GenericComponents/GenericFilters";
import useCustomMemo from "../../GenericComponents/useCustomMemo";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { CustomerModel, InventoryModel } from "../../../Utils/Models";
import { Vocabulary } from "../../../Utils/Vocabulary";
import ProductsAutocomplete from "../ProductsAutocomplete";
import {
  StockTransferStatusesAutocomplete,
  usersTypes,
} from "../../../Utils/AutocompleteUtils";
import { useEffect, useState } from "react";
import { getData } from "../../../Services/getData";
import { urlEnum } from "../../../Utils/UrlEnum";

type StocksFiltersProps = {
  filters: any;
  hideInventory?: boolean;
  hideProduct?: boolean;
  hideStatus?: boolean;
  hideVendor?: boolean;
};

export default function StocksFilters(props: StocksFiltersProps) {
  const { filters, hideInventory, hideProduct, hideStatus, hideVendor } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const cache = useCustomMemo();
  const inventories = cache.inventories || [];
  const [vendors, setVendors] = useState([]);
  const [product, setProduct] = useState<any>(null);

  /**
   *
   */
  useEffect(() => {
    getVendors();
  }, []);

  /**
   *
   */
  useEffect(() => {
    if (filters.product) {
      getProductById();
    } else {
      setProduct(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  /**
   *
   */
  function getVendors() {
    getData(`${urlEnum.customers}/type/${usersTypes.vendors}`).then(
      (res: any) => {
        if (res.data.status === 200) setVendors(res.data.result);
      }
    );
  }

  /**
   *
   */
  async function getProductById() {
    await getData(`${urlEnum.products}/${filters.product}`).then((res: any) => {
      if (res) setProduct(res.data.result);
    });
  }

  /**
   *
   * @returns
   */
  function showInventoryAutocomplete() {
    return (
      <Grid item xs={12} md={6} lg={4}>
        <Autocomplete
          freeSolo={false}
          disablePortal
          options={inventories}
          getOptionLabel={(option: any) =>
            `${option.name} - ${option.location?.name || ""}`
          }
          isOptionEqualToValue={(option, value) => option._id === value._id}
          value={
            inventories.filter(
              (inventory: InventoryModel) => inventory._id === filters.inventory
            )[0] || null
          }
          handleHomeEndKeys
          renderInput={(params) => (
            <TextField {...params} label={Vocabulary.inventory} size="small" />
          )}
          onChange={(event, newValue: any) => {
            const params = new URLSearchParams(location.search);
            params.set("inventory", newValue ? newValue._id : null);
            navigate({
              pathname: location.pathname,
              search: params.toString(),
            });
          }}
        />
      </Grid>
    );
  }

  /**
   *
   * @returns
   */
  function showProductAutocomplete() {
    return (
      <Grid item xs={12} md={6} lg={4}>
        <ProductsAutocomplete
          parentProduct={product}
          handleChangeProductInParent={(event: any, newValue: any) => {
            const params = new URLSearchParams(location.search);
            params.set("product", newValue ? newValue._id : null);
            navigate({
              pathname: location.pathname,
              search: params.toString(),
            });
          }}
          variant="outlined"
        />
      </Grid>
    );
  }

  /**
   *
   * @returns
   */
  function showStatusAutocomplete() {
    return (
      <Grid item xs={12} md={6} lg={4}>
        <Autocomplete
          freeSolo={false}
          disablePortal
          options={StockTransferStatusesAutocomplete}
          getOptionLabel={(option: any) => option}
          isOptionEqualToValue={(option, value) => option === value}
          value={filters.status}
          handleHomeEndKeys
          renderInput={(params) => (
            <TextField {...params} label={Vocabulary.status} size="small" />
          )}
          onChange={(event, newValue: any) => {
            const params = new URLSearchParams(location.search);
            params.set("status", newValue ? newValue : null);
            navigate({
              pathname: location.pathname,
              search: params.toString(),
            });
          }}
        />
      </Grid>
    );
  }

  /**
   *
   * @returns
   */
  function showVendorAutocomplete() {
    return (
      <Grid item xs={12} md={6} lg={4}>
        <Autocomplete
          size="small"
          id="vendor"
          freeSolo={false}
          disablePortal
          getOptionLabel={(option: any) => option.company}
          isOptionEqualToValue={(option: any, value: any) =>
            option._id === value._id
          }
          options={vendors}
          value={
            vendors.filter(
              (vendor: CustomerModel) => vendor._id === filters.vendor
            )[0] || null
          }
          onChange={(event: any, newValue: any) => {
            const params = new URLSearchParams(location.search);
            params.set("vendor", newValue ? newValue._id : null);
            navigate({
              pathname: location.pathname,
              search: params.toString(),
            });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              name="vendor"
              label={Vocabulary.vendor}
              variant="outlined"
            />
          )}
        />
      </Grid>
    );
  }

  return (
    <GenericFilters>
      <Grid container spacing={2} style={{ marginBottom: 10 }}>
        {hideInventory ? null : showInventoryAutocomplete()}
        {hideProduct ? null : showProductAutocomplete()}
        {hideStatus ? null : showStatusAutocomplete()}
        {hideVendor ? null : showVendorAutocomplete()}
      </Grid>
    </GenericFilters>
  );
}
