import { Chip } from "@mui/material";
import { NotesModel } from "../../Utils/Models";

type ShowImportantNotesForInformationProps = {
  note: NotesModel;
  backgroundColor: string;
  doesNotExistsMessage: string;
};
export default function ShowImportantNotesForInformation(
  props: ShowImportantNotesForInformationProps
) {
  const { note, backgroundColor, doesNotExistsMessage } = props;
  return (
    <>
      {note ? (
        <>
          <Chip
            label={note.type}
            size="small"
            style={{
              color: "white",
              backgroundColor: backgroundColor,
              borderRadius: 5,
              padding: 0,
              fontWeight: "bold",
            }}
          ></Chip>
          <p style={{ padding: 0, margin: 5 }}>{note.text}</p>
          <p
            style={{
              fontSize: 12,
              color: "#0000008a",
              padding: 0,
            }}
          >
            - {note.createdBy?.firstName} {note.createdBy?.lastName}
          </p>
        </>
      ) : (
        <p>{doesNotExistsMessage}</p>
      )}
    </>
  );
}
