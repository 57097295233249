/* eslint-disable @typescript-eslint/no-explicit-any */
import { Chip, Divider, TextField, useMediaQuery } from "@mui/material";
import { Vocabulary } from "../../../../Utils/Vocabulary";
import { Delete } from "@mui/icons-material";
import { toast } from "react-toastify";

type ProductsListProps = {
  products: any;
  showPrice: boolean;
  onChangeProducts: (products: any) => void;
};

export default function ProductsList(props: ProductsListProps) {
  const { products, showPrice, onChangeProducts } = props;
  const phoneStyle = useMediaQuery("(max-width: 800px)");
  const productMaxLength = phoneStyle ? 7 : 30;

  /**
   *
   * @param event
   * @param index
   */
  function onChangeQuantityOrPrice(event: any, index: number) {
    const newProducts: any = [...products];
    newProducts[index][event.target.name] = event.target.value;
    onChangeProducts(newProducts);
  }

  /**
   *
   * @param index
   */
  function deleteProductFromStockTransfer(index: number) {
    const newProducts = [...products];
    newProducts.splice(index, 1);
    onChangeProducts(newProducts);
  }

  return (
    <div style={{ marginTop: 20 }}>
      {products.map((product: any, index: number) => (
        <>
          <div
            key={product._id}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignContent: "left",
              alignItems: "center",
              margin: "10px 0px",
            }}
          >
            <Chip
              label={
                <>
                  {product.product.code} -{" "}
                  {product.product.name?.length > productMaxLength
                    ? `${product.product.name?.substring(
                        0,
                        productMaxLength
                      )}...`
                    : product.product.name}
                </>
              }
              style={{
                flex: showPrice ? 0.45 : 0.65,
                borderRadius: 4,
                fontSize: 15,
                backgroundColor: "#404a694d",
                height: "auto",
                padding: 7,
              }}
            />
            <TextField
              type="number"
              size="small"
              name="quantity"
              label={Vocabulary.quantity}
              variant="standard"
              value={product.quantity}
              onChange={(event: any) => {
                if (
                  parseFloat(event.target.value) >
                  parseFloat(product.maxQuantity)
                ) {
                  toast.error(
                    `${Vocabulary.maxQuantityIs} ${product.maxQuantity}`
                  );
                  return;
                }
                onChangeQuantityOrPrice(event, index);
              }}
              style={{ flex: 0.2 }}
            />
            {showPrice ? (
              <TextField
                type="number"
                size="small"
                name="price"
                label={Vocabulary.price}
                variant="standard"
                value={product.price}
                onChange={(event: any) => {
                  onChangeQuantityOrPrice(event, index);
                }}
                style={{ flex: 0.2 }}
              />
            ) : null}
            <Delete
              style={{
                cursor: "pointer",
                flex: phoneStyle ? 0.1 : 0.05,
                color: "red",
              }}
              onClick={() => {
                deleteProductFromStockTransfer(index);
              }}
            />
          </div>
          <Divider />
        </>
      ))}
    </div>
  );
}
