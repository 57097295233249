/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from "react";
import AddOrUpdateModel from "../GenericComponents/AddOrUpdateModel";
import { Vocabulary } from "../../Utils/Vocabulary";
import { DeliveryModel } from "../../Utils/Models";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { Grid } from "@mui/material";
import { onChangeTextField } from "../../Utils/Utils";

type DeliveryProps = {
  editDelivery: DeliveryModel | null;
  open: boolean;
  addOrUpdateDelivery: (delivery: DeliveryModel | null) => void;
  onClose: () => void;
};
export default function Delivery(props: DeliveryProps) {
  const { editDelivery, open, addOrUpdateDelivery, onClose } = props;
  const ref: any = useRef();
  const [delivery, setDelivery] = useState<DeliveryModel>(new DeliveryModel());
  const [pressOnSubmit, setPressOnSubmit] = useState(false);

  /**
   *
   */
  useEffect(() => {
    if (editDelivery) {
      setDelivery(editDelivery);
    } else {
      setDelivery(new DeliveryModel());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editDelivery]);

  /**
   * 
   */
  useEffect(() => {
    if (pressOnSubmit) {
      ref.current.submit();
      //reset the value
      handleChangePressOnSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressOnSubmit]);

  /**
   *
   */
  function handleChangePressOnSubmit() {
    setPressOnSubmit(!pressOnSubmit);
  }

  
  return (
    <ValidatorForm ref={ref} onSubmit={() => addOrUpdateDelivery(delivery)}>
      <AddOrUpdateModel
        open={open}
        title={Vocabulary.delivery}
        onClose={onClose}
        isSubmit={true}
        addOrUpdateItem={() => null}
        handleChangePressOnSubmit={handleChangePressOnSubmit}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextValidator size="small"
              id="name"
              name="name"
              label={Vocabulary.name}
              fullWidth
              variant="outlined"
              value={delivery.name}
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              onChange={(event: any) =>
                setDelivery(onChangeTextField(event, delivery))
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextValidator size="small"
              id="shortName"
              name="shortName"
              label={Vocabulary.shortName}
              fullWidth
              variant="outlined"
              value={delivery.shortName}
              validators={["required"]}
              errorMessages={[Vocabulary.requiredField]}
              onChange={(event: any) =>
                setDelivery(onChangeTextField(event, delivery))
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextValidator size="small"
              id="description"
              name="description"
              label={Vocabulary.description}
              fullWidth
              variant="outlined"
              value={delivery.description}
              onChange={(event: any) =>
                setDelivery(onChangeTextField(event, delivery))
              }
            />
          </Grid>
        </Grid>
      </AddOrUpdateModel>
    </ValidatorForm>
  );
}
